<div class="techSocial">
    <ul class="SocialList">
        <li>
            <a href="https://www.facebook.com/techcompose" class="fb-icon" target="_blank" rel="noopener">
                <i>
                    <svg height="100%" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;" version="1.1" viewBox="0 0 512 512" width="100%" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:serif="http://www.serif.com/" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M374.245,285.825l14.104,-91.961l-88.233,0l0,-59.677c0,-25.159 12.325,-49.682 51.845,-49.682l40.117,0l0,-78.291c0,0 -36.408,-6.214 -71.214,-6.214c-72.67,0 -120.165,44.042 -120.165,123.775l0,70.089l-80.777,0l0,91.961l80.777,0l0,222.31c16.197,2.542 32.798,3.865 49.709,3.865c16.911,0 33.512,-1.323 49.708,-3.865l0,-222.31l74.129,0Z" fill="currentColor" style="fill-rule:nonzero;"/></svg>
                </i>
            </a>
        </li>
        <li>
            <a href="https://www.instagram.com/techcompose/?hl=en" class="insta-icon" target="_blank" rel="noopener">
                <i>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.25 0H3.75C1.67925 0 0 1.67925 0 3.75V8.25C0 10.3208 1.67925 12 3.75 12H8.25C10.3208 12 12 10.3208 12 8.25V3.75C12 1.67925 10.3208 0 8.25 0ZM10.875 8.25C10.875 9.6975 9.6975 10.875 8.25 10.875H3.75C2.3025 10.875 1.125 9.6975 1.125 8.25V3.75C1.125 2.3025 2.3025 1.125 3.75 1.125H8.25C9.6975 1.125 10.875 2.3025 10.875 3.75V8.25Z" fill="currentColor"/>
                        <path d="M6 3C4.34325 3 3 4.34325 3 6C3 7.65675 4.34325 9 6 9C7.65675 9 9 7.65675 9 6C9 4.34325 7.65675 3 6 3ZM6 7.875C4.9665 7.875 4.125 7.0335 4.125 6C4.125 4.96575 4.9665 4.125 6 4.125C7.0335 4.125 7.875 4.96575 7.875 6C7.875 7.0335 7.0335 7.875 6 7.875Z" fill="currentColor"/>
                        <path d="M9.22513 3.17499C9.4459 3.17499 9.62488 2.99601 9.62488 2.77524C9.62488 2.55446 9.4459 2.37549 9.22513 2.37549C9.00435 2.37549 8.82538 2.55446 8.82538 2.77524C8.82538 2.99601 9.00435 3.17499 9.22513 3.17499Z" fill="currentColor"/>
                    </svg>
                </i>
            </a>
            </li>
          <li>
              <a href="https://www.linkedin.com/company/techcompose" class="linkedin-icon" target="_blank" rel="noopener">
                  <i>
                      <svg height="512px" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" width="512px" ><g><rect height="328.97" width="106.716" x="17.397" y="166.28" fill="currentColor"/><path d="M414.789,170.138c-1.133-0.355-2.207-0.743-3.396-1.078c-1.438-0.327-2.865-0.6-4.328-0.833     c-5.662-1.139-11.875-1.946-19.148-1.946c-62.211,0-101.678,45.372-114.674,62.894V166.28H166.526v328.97h106.716V315.813     c0,0,80.643-112.628,114.674-29.911V495.25h106.688V273.257C494.604,223.549,460.629,182.13,414.789,170.138L414.789,170.138z      M414.789,170.138" fill="currentColor"/><path d="M121.789,69.085c0,28.909-23.373,52.337-52.195,52.337c-28.824,0-52.196-23.429-52.196-52.337     c0-28.903,23.372-52.335,52.196-52.335C98.416,16.75,121.789,40.182,121.789,69.085L121.789,69.085z M121.789,69.085" fill="currentColor"/></g></svg>
                  </i>
              </a>
          </li>
        <li>
            <a href="http://www.twitter.com/techcompose" class="twitter-icon" target="_blank" rel="noopener">
                <i>
                    <svg style="enable-background:new 0 0 64 64;" version="1.1" viewBox="0 0 64 64"><g id="twitter"><path class="st4" d="M59.5,15.8c-0.8,0.6-1.6,1.1-2.5,1.6c0.1,0.8,0.1,1.7,0.1,2.6c0,11.6-5,22.1-12.9,29.4   c-6.2,4.6-13.9,7.3-22.3,7.3c-7.3,0-14.1-2.1-19.9-5.7c1.2,0.2,2.4,0.3,3.7,0.3c5.8,0,11.1-2,15.3-5.3c-5.4-0.1-10-3.7-11.6-8.7   c0.7,0.1,1.5,0.2,2.3,0.2c1.1,0,2.1-0.1,3.1-0.4c-2.3-0.5-4.3-1.7-5.9-3.3c-2.3-2.2-3.7-5.4-3.7-8.8c0-0.1,0-0.1,0-0.2   c1.5,0.8,3.1,1.3,4.9,1.5c-1.8-1.4-3.3-3.3-4.1-5.5c-0.5-1.4-0.8-2.9-0.8-4.4c0-0.6,0-1.3,0.1-1.9c0.3-1.7,0.9-3.3,1.7-4.6   c0.9,1,1.9,2,3,3c0.5,0.5,1.1,0.9,1.6,1.4c3.1,2.5,6.6,4.5,10.3,6c0.5,0.2,0.9,0.4,1.4,0.5c1.7,0.6,3.4,1.1,5.2,1.4   c0.4,0.1,0.8,0.2,1.2,0.2c0.2,0,0.3,0.1,0.5,0.1c0.8,0.1,1.5,0.2,2.3,0.3c-0.2-0.9-0.4-1.9-0.4-3c0-4.2,2.1-7.9,5.3-10.1   c0.2-0.2,0.4-0.3,0.7-0.4c0,0,0.1,0,0.1,0.1c0,0,0-0.1,0-0.1c1.9-1.1,4-1.8,6.4-1.8c3.6,0,6.8,1.5,9.1,4c0.8-0.2,1.7-0.4,2.5-0.6   c0.9-0.3,1.7-0.5,2.5-0.9c1-0.4,1.9-0.9,2.8-1.4c-0.5,1.5-1.3,2.9-2.3,4.1c-0.7,0.8-1.5,1.5-2.3,2.2c-0.2,0.1-0.4,0.3-0.6,0.4   c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0.1c0,0,0.1-0.1,0.1-0.1c0.2,0,0.4-0.1,0.7-0.1c0.9-0.1,1.7-0.3,2.6-0.5c0.9-0.2,1.8-0.5,2.6-0.8   C61.2,14.5,60.3,15.1,59.5,15.8z" fill="currentColor"/></g></svg>
                </i>
            </a>
        </li>
        <li>
            <a href="https://www.behance.net/TechCompose" class="be-icon" target="_blank" rel="noopener">
                <i>
                    <svg enable-background="new 0 0 128 128" id="Social_Icons" version="1.1" viewBox="0 0 128 128"><g id="Behance_1_"><rect clip-rule="evenodd" fill="none" fill-rule="evenodd" height="128" width="128"/><path clip-rule="evenodd" d="M58.725,32.316    c2.416,3.319,3.627,7.284,3.627,11.909c0,4.759-1.212,8.598-3.658,11.493c-1.37,1.619-3.379,3.103-6.036,4.44    c4.034,1.455,7.074,3.749,9.144,6.89c2.047,3.133,3.078,6.949,3.078,11.434c0,4.626-1.174,8.776-3.53,12.444    c-1.49,2.428-3.357,4.477-5.599,6.14c-2.521,1.908-5.494,3.207-8.933,3.92c-3.439,0.705-7.157,1.047-11.176,1.047H0V24h38.224    C47.872,24.141,54.698,26.91,58.725,32.316z M15.767,37.55v17.218h19.236c3.424,0,6.216-0.646,8.369-1.938    c2.137-1.292,3.214-3.579,3.214-6.845c0-3.645-1.415-6.044-4.245-7.217c-2.453-0.809-5.569-1.218-9.355-1.218H15.767z     M15.767,67.679V88.49h19.206c3.432,0,6.111-0.46,8.015-1.374c3.469-1.708,5.2-4.945,5.2-9.741c0-4.069-1.671-6.853-5.035-8.375    c-1.881-0.846-4.523-1.292-7.91-1.322H15.767z M112.7,45.502c4.087,1.797,7.451,4.648,10.1,8.538    c2.416,3.43,3.966,7.41,4.674,11.931c0.414,2.643,0.579,6.474,0.512,11.456H85.366c0.256,5.784,2.273,9.845,6.126,12.169    c2.333,1.448,5.14,2.175,8.437,2.175c3.469,0,6.307-0.884,8.482-2.643c1.197-0.958,2.25-2.279,3.153-3.972h15.616    c-0.414,3.415-2.31,6.897-5.667,10.432c-5.238,5.606-12.576,8.412-22.006,8.412c-7.789,0-14.66-2.361-20.598-7.098    c-5.968-4.729-8.933-12.436-8.933-23.098c0-10.001,2.687-17.671,8.053-23.009c5.381-5.323,12.342-8.004,20.914-8.004    C104.038,42.792,108.621,43.697,112.7,45.502z M89.821,58.539c-2.16,2.198-3.522,5.175-4.079,8.932h26.363    c-0.278-4.009-1.648-7.039-4.072-9.125c-2.453-2.071-5.471-3.103-9.084-3.103C95.037,55.243,91.981,56.342,89.821,58.539z     M81.791,37.349h33.897v-9.711H81.791V37.349z" fill="currentColor" fill-rule="evenodd" id="Behance"/></g></svg>
                </i>
            </a>
        </li>
    </ul>
</div>
