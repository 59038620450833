<app-header></app-header>
<app-header-menu *ngIf="isHeaderMenuOpen"></app-header-menu>
<div class="scroller">
  <div class="header-class"></div>
  <div class="page-grid-block">
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
  </div>
  <div class="main-service-wrapper">
      <div class="background-overlay bg-services"></div>
      <section class="service-in-hero">
          <app-tech-logo></app-tech-logo>
          <div class="service-in-hero-container">
              <div class="service-in-hero-block">
                  <div class="service-in-deail-block">
                      <div class="page-title">
                          <!-- <h1>Digital Marketing Agency</h1> -->
                          <h1>Happy, Super & Effective Team</h1>
                      </div>
                      <div class="service-in-info-detail page-info">
                          <!-- <p>Techcompose is a Top Digital Marketing Agency in India and USA that offers SEO Services, Social Media Marketing, PPC, Online Marketing, Content Marketing, Branding & Advertising, search engine marketing, email marketing etc to make your company more visible online and increase your website traffic which leads to more conversion of visitors and increase in revenue.</p> -->
                          <ul>
                              <li>Team of trained, skilled, dedicated, happy, genuine people.</li>
                              <li>Delivering super solutions which gives effectiveness to users.</li>
                              <li>Involvement and Contribution.</li>
                              <li>Brainstorming and Idea sharing activities at all levels.</li>
                              <li>Complete sense of responsibility, carefulness and sensibility while working.</li>
                              <li>Mindful efforts to cherish what we build.</li>
                              <li>Purposeful processes and no unnecessary hinderance.</li>
                              <li>Individuals with Individuality yet shared objective.</li>
                              <li>Working in Rhythm with Harmony unlike Assembly line in Factory.</li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      <section class="philosophie">
          <div class="wrapper">
              <div class="point point-1">
                  <article>
                      <div class="article-inner">
                          <h3>Skilled, dedicated and passionate </h3>
                          <div class="article-desc-block">
                              <p>We are a team with mindset of developing solutions with product and process oriented mindset. Happy, genuine and skilled professionals are working with one goal of developing the right solution as per the project planning. Knowledge of the framework with strong grip on different concepts, working on customization and complex features. Team is following all the standard practices for development and always looking forward to perform due diligence. </p>
                          </div>
                      </div>
                  </article>
                  <div class="services-img-block">
                      <img src="assets/images/services/sub-page/team-trained.webp" alt="Team of trained, skilled, dedicated" width="1920" height="1275">
                  </div>
              </div>
              <div class="point point-2">
                  <article>
                      <div class="article-inner">
                          <h3>Involvement and Contribution</h3>
                          <div class="article-desc-block">
                              <p>We created an environment for product development in our team and involve members from different teams to participate in development activities. Our team members are sharing ideas at functional and technical levels of the product and conducting training for other team members to quickly learn new approach/features/updates. We also do Demo sessions with clients and internal team members before major releases.</p>
                          </div>
                      </div>
                  </article>
                  <div class="services-img-block">
                      <img src="assets/images/services/sub-page/involvement-contribution-.webp" alt="Involvement and Contribution" width="1920" height="1275">
                  </div>
              </div>
              <div class="point point-3">
                  <article>
                      <div class="article-inner">
                          <h3>Brainstorming and Product Demo</h3>
                          <div class="article-desc-block">
                              <p>We do brainstorming and demo sessions with product team consisting of Stack owners, Engineers, QA, UI/UX and Project managers. People from junior level to highest level are participating in these activities. These sessions are recorded and documented as they are very insightful. It is a practice followed by very few product centric companies. </p>
                          </div>
                      </div>
                  </article>
                  <div class="services-img-block">
                      <img src="assets/images/services/sub-page/brainstorming.webp" alt="Brainstorming and Idea sharing" width="2000" height="1275">
                  </div>
              </div>
              <div class="point point-4">
                  <article>
                      <div class="article-inner">
                          <h3>Purposeful processes</h3>
                          <div class="article-desc-block">
                              <p>Every process followed in our company made with the reason for developing effective solutions. Our team understands the importance of these processes and believes in company's mission of delivering effective solutions. At the same time we are also constantly working towards accountability with no stress and providing environment to follow processes effortlessly. From Recruitment process to daily operational processes to solution development process, all of them are very reasonable and effective to establish the harmony.</p>
                          </div>
                      </div>
                  </article>
                  <div class="services-img-block">
                      <img src="assets/images/services/sub-page/powerful.webp" alt="Purposeful processes" width="1920" height="1275">
                  </div>
              </div>
          </div>
      </section>
  </div>
  <app-footer></app-footer>
</div>
