<section class="tech-testimonial-wrapper">
  <div class="tech-testimonial-desc">
      <div class="tech-testimonial-innerblock">
          <h2>What <span><i class="friends-icon"><img src="assets/images/friends-icon.svg" alt="Friends" width="98" height="52"></i> Client <i class="strike-icon"><img src="assets/images/strike-icon.svg" alt="Strike Icon" width="106" height="8"></i></span> thinks</h2>
          <p>We build websites that engage your users and generate results for your business.</p>
          <div class="see-more-btn-block">
              <a [routerLink]="['our-testimonials']" class="morebtn">
                  More Testimonials
                  <i class="link-icon">
                      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M14.5687 7.92313C14.3312 7.68545 13.946 7.68545 13.7081 7.92313C13.4704 8.16062 13.4704 8.5461 13.7081 8.78378L18.3174 13.3931H6.0705C5.73339 13.3931 5.45996 13.6666 5.45996 14.0037C5.45996 14.341 5.73339 14.6142 6.0705 14.6142H18.3113L13.702 19.2236C13.5876 19.3375 13.5234 19.4926 13.5234 19.654C13.5234 19.8156 13.5876 19.9703 13.702 20.0844C13.8163 20.1997 13.9729 20.2636 14.1354 20.2615C14.2974 20.2607 14.4529 20.1972 14.5687 20.0844L20.216 14.4373C20.3309 14.3222 20.395 14.166 20.3932 14.0037C20.3921 13.8417 20.3286 13.6868 20.216 13.5704L14.5687 7.92313Z" fill="currentColor"/>
                          <path d="M14.0072 0.877724C8.69816 0.876768 3.91155 4.0746 1.87993 8.97957C-0.151319 13.8847 0.972243 19.5305 4.72746 23.2834C9.8527 28.4086 18.162 28.4086 23.2871 23.2834C28.4121 18.1585 28.4121 9.84921 23.2871 4.72396C20.8313 2.25426 17.4899 0.86931 14.0072 0.877724ZM14.0072 25.9087C7.4442 25.9087 2.10231 20.5666 2.10231 14.0037C2.10231 7.4407 7.4442 2.0988 14.0072 2.0988C20.5701 2.0988 25.9122 7.4407 25.9122 14.0037C25.9122 20.5666 20.5701 25.9087 14.0072 25.9087Z" fill="currentColor"/>
                      </svg>
                  </i>
              </a>
          </div>
      </div>
  </div>
  <div class="tech-testimonial-block">
      <div class="tech-testimonial-row">
        <div class="tech-testimonial-inner-block">
          <div class="tech-user-detail">
            <div class="userImg">
              <!-- <img src="assets/images/smarter.webp" width="150" height="150" alt="Sam"> -->
              <span>S</span>
            </div>
            <div class="user-name-block">
              <h3>Sam</h3>
              <span>CEO</span>
            </div>
          </div>
          <div class="testimonial-desc">
            <div class="testimonial-inner-desc">
              <p> The Best team I could even not get from here in US or anywhere else. Stood up with me at every stage of product development. Coming from non-IT background, never thought it would be this much exciting to develop such a complex system delivering such a simplicity to the users. Product driven, skilled and happy team.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="tech-testimonial-row">
          <div class="tech-testimonial-inner-block">
              <div class="tech-user-detail">
                  <div class="userImg">
                      <img src="assets/images/testimonials/david-scholar.jpg" alt="David Scholar" width="150" height="150">
                  </div>
                  <div class="user-name-block">
                      <h3>David</h3>
                      <span>CEO</span>
                  </div>
              </div>
              <div class="testimonial-desc">
                  <div class="testimonial-inner-desc">
                      <p>They are very professional and skillful. They listen to my requirement. Suggest the best tools and technology for the job. They are committed to work.</p>
                  </div>
              </div>
          </div>
      </div>
      <div class="tech-testimonial-row">
          <div class="tech-testimonial-inner-block">
              <div class="tech-user-detail">
                  <div class="userImg">
                    <!-- <img src="assets/images/smarter.webp" width="150" height="150" alt="Sam"> -->
                    <span>D</span>
                  </div>
                  <div class="user-name-block">
                      <h3>Danial</h3>
                      <span>Co-Founder and CEO</span>
                  </div>
              </div>
              <div class="testimonial-desc">
                  <div class="testimonial-inner-desc">
                      <p>Working with this team for more than 3 years now. Developers are different now but the delivery standards are same with even better productivity as they understand the application much better. Team is genuine to make sure we are not overbilled for the value being delivered. Strong engineering and creative team helped us achieve more than what is expected.</p>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>
