<div class="share-block">
    <label>Share On:</label>
    <div class="share-icon-list">
        <ul>
            <li><a class="fb-icon" href="https://www.facebook.com/sharer/sharer.php?u={{ currentBlogUrl }}" target="_blank">
                <i>
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_86_2)">
                            <path d="M7.90672 15.978H11.1952V29.5161C11.1952 29.7834 11.4118 30 11.6791 30H17.2548C17.5221 30 17.7387 29.7834 17.7387 29.5161V16.0418H21.5191C21.7649 16.0418 21.9717 15.8573 21.9998 15.6132L22.5739 10.6291C22.5897 10.492 22.5462 10.3546 22.4545 10.2518C22.3627 10.1488 22.2312 10.0899 22.0933 10.0899H17.7389V6.96561C17.7389 6.02381 18.246 5.54623 19.2462 5.54623C19.3888 5.54623 22.0933 5.54623 22.0933 5.54623C22.3606 5.54623 22.5772 5.32955 22.5772 5.06235V0.487452C22.5772 0.220161 22.3606 0.00358065 22.0933 0.00358065H18.1696C18.1419 0.00222581 18.0805 0 17.9899 0C17.3091 0 14.9427 0.133645 13.0734 1.85332C11.0022 3.759 11.2901 6.04074 11.3589 6.43635V10.0898H7.90672C7.63943 10.0898 7.42285 10.3064 7.42285 10.5736V15.494C7.42285 15.7613 7.63943 15.978 7.90672 15.978Z" fill="currentColor"/>
                        </g>
                    </svg>
                </i>
            </a></li>
            <li><a class="linkedin-icon" href="https://www.linkedin.com/shareArticle?mini=true&url={{ currentBlogUrl }}" target="_blank">
                <i>
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_86_5)">
                            <path d="M6.98298 9.65137H0.960433C0.693143 9.65137 0.476562 9.86804 0.476562 10.1352V29.4831C0.476562 29.7504 0.693143 29.967 0.960433 29.967H6.98298C7.25027 29.967 7.46685 29.7504 7.46685 29.4831V10.1352C7.46685 9.86804 7.25027 9.65137 6.98298 9.65137Z" fill="currentColor"/>
                            <path d="M3.97413 0.0332031C1.78277 0.0332031 0 1.81404 0 4.00298C0 6.19288 1.78277 7.9744 3.97413 7.9744C6.16374 7.9744 7.94506 6.19278 7.94506 4.00298C7.94516 1.81404 6.16374 0.0332031 3.97413 0.0332031Z" fill="currentColor"/>
                            <path d="M22.3018 9.1709C19.8829 9.1709 18.0948 10.2107 17.0103 11.3923V10.1356C17.0103 9.86845 16.7937 9.65177 16.5264 9.65177H10.7588C10.4915 9.65177 10.2749 9.86845 10.2749 10.1356V29.4835C10.2749 29.7508 10.4915 29.9674 10.7588 29.9674H16.7682C17.0354 29.9674 17.252 29.7508 17.252 29.4835V19.9108C17.252 16.685 18.1282 15.4283 20.3769 15.4283C22.8258 15.4283 23.0205 17.443 23.0205 20.0768V29.4836C23.0205 29.7509 23.237 29.9675 23.5043 29.9675H29.5159C29.7832 29.9675 29.9998 29.7509 29.9998 29.4836V18.871C29.9998 14.0743 29.0852 9.1709 22.3018 9.1709Z" fill="currentColor"/>
                        </g>
                    </svg>
                </i>
            </a></li>
            <li><a class="twitter-icon" href="https://twitter.com/share?url={{ currentBlogUrl }}" target="_blank">
                <i>
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_86_10)">
                        <path d="M17.8225 12.69L28.97 0H26.3287L16.645 11.0162L8.91625 0H0L11.69 16.66L0 29.9662H2.64125L12.8612 18.33L21.025 29.9662H29.9412L17.8225 12.69ZM14.2037 16.8062L13.0175 15.1462L3.59375 1.95H7.65125L15.2587 12.6038L16.44 14.2638L26.3275 28.1113H22.27L14.2037 16.8062Z" fill="currentColor"/>
                        </g>
                    </svg>
                </i>
            </a></li>
            <li><a class="email-icon" href="mailto:?subject={{ currentBlogTitle }}&body={{ currentBlogUrl }}" target="_blank">
                <i>
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_86_13)">
                        <path d="M1.92773 5L16.2539 16.6821L29.9673 5H1.92773ZM1 24.6582L11.2051 14.4263L1 6.10596V24.6606V24.6582ZM12.3306 15.3442L1.80811 25.8911H30.104L20.043 15.3442L16.7324 18.1689C16.6033 18.2765 16.4412 18.3365 16.2732 18.3387C16.1051 18.3409 15.9415 18.2853 15.8096 18.1812L12.3306 15.3442ZM21.1489 14.4067L31 24.7412V6.01562L21.1489 14.4067Z" fill="currentColor"/>
                        </g>
                    </svg>
                </i>
            </a></li>
        </ul>
        <!-- <a href="https://www.facebook.com/sharer/sharer.php?u=https://techcompose.com/blogs/rails-6-api-fast_jsonapi-gem-with-devise-and-jwt-authentication" target="_blank">Facebook</a> -->
    </div>
</div>
