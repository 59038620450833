<app-header></app-header>
<app-header-menu *ngIf="isHeaderMenuOpen"></app-header-menu>
<div class="scroller">
  <div class="header-class"></div>
  <div class="page-grid-block">
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
  </div>
  <div class="portfolio-wrapper">
      <div class="portfolio-overlay"></div>
      <div class="portfolio-detail-wrapper">
          <div class="portfolio-detail-inner">
              <div class="back-btn-block">
                  <a [routerLink]="['/work']" class="back-btn">
                      <i>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M5.828 11.0007H22V13.0007H5.828L11.192 18.3647L9.778 19.7787L2 12.0007L9.778 4.22266L11.192 5.63666L5.828 11.0007Z" fill="black"/>
                          </svg>
                      </i>
                      Back to Portfolio
                  </a>
              </div>
              <div class="project-logo">
                  <img src="assets/images/project-images/build-effective/logo.svg" alt="Buildeffective">
              </div>
              <h2 class="probstatement">
                  <span>Problem Statement</span>
                  Different personas involved to a construction projects(finished or in-progress), having different computer savviness and following typical ineffective processes to manage information.
              </h2>
              <div class="challange-block">
                  <div class="challange-list">
                      <ul>
                          <li>
                              <h3 class="section-title section-title-sub">Study</h3>
                              <ul>
                                  <li class="work-info">Many different manual methods being followed</li>
                                  <li class="work-info">Information not being documented/shared/processed </li>
                                  <li class="work-info">Many parameters for a decision making and delay </li>
                                  <li class="work-info">Lots of time consuming repetitions</li>
                              </ul>
                          </li>
                          <li>
                              <h3 class="section-title section-title-sub">Challenges</h3>
                              <ul>
                                  <li class="work-info">Database Modeling with boundless possibilities  </li>
                                  <li class="work-info">Define Standard UI/UX for different personas</li>
                                  <li class="work-info">Analysis and Intelligence </li>
                                  <li class="work-info">Modularize business logic </li>
                              </ul>
                          </li>
                          <li>
                              <h3 class="section-title section-title-sub">Approach</h3>
                              <ul>
                                  <li class="work-info">Study of data collected by Product Owner </li>
                                  <li class="work-info">Ask right questions to understand industry </li>
                                  <li class="work-info">Identified the patterns and generate algorithm </li>
                              </ul>
                          </li>
                          <li>
                              <h3 class="section-title section-title-sub">Involvement</h3>
                              <ul>
                                  <li class="work-info">From concept to conversations to various application versions to market share</li>
                                  <li class="work-info">From paper drawings to wireframes to UI/UX </li>
                                  <li class="work-info">From ER diagram to Activity diagram to Database Design to Application development to Scalability </li>
                              </ul>
                          </li>
                      </ul>
                  </div>
              </div>
              <div class="project-img-block">
                  <div class="project-main-img-block">
                      <img src="assets/images/project-images/build-effective/homeguide-img.png" alt="Buildeffective">
                  </div>
                  <div class="be-floating-img">
                      <div class="be-floating-img-main">
                          <img src="assets/images/project-images/build-effective/img-2.webp" alt="Buildeffective">
                      </div>
                      <div class="be-floating-mobile">
                          <img src="assets/images/project-images/build-effective/img-3.webp" alt="Buildeffective">
                      </div>
                  </div>
              </div>

              <div class="work-process" *ngIf="false">
                  <ul>
                      <li>
                          <h3 class="section-title">STRATEGY</h3>
                          <div>
                              <span>Design thinking</span>
                              <span>Product Roadmap</span>
                          </div>
                      </li>
                      <li>
                          <h3 class="section-title">DESIGN</h3>
                          <div>
                              <span>User Experience</span>
                              <span>User Interface</span>
                              <span>Illustrations & Icons</span>
                          </div>
                      </li>
                      <li>
                          <h3 class="section-title">FRONT END DEVELOPEMENT</h3>
                          <div>
                              <span>HTML</span>
                              <span>CSS</span>
                              <span>Posenet</span>
                          </div>
                      </li>
                  </ul>
              </div>
              <div class="visule-guide pt-0">
                  <h2>Visual Guide</h2>
                  <div class="visule-guide-row">
                      <div class="visule-guide-col">
                          <h3 class="section-title section-title-sub">TYPE FACE</h3>
                          <label>Inter</label>
                          <p>- Sans-Serif</p>
                      </div>
                      <div class="visule-guide-col">
                          <h3 class="section-title section-title-sub">COLOR</h3>
                          <ul class="color-list">
                              <li style="background: #008BBD; color: #fff; border: none">
                                  #008BBD
                              </li>
                              <li style="background: #2D3547; color: #fff;border: none">
                                  #2D3547
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="outcome-box-wrapper">
          <div class="outcome-box-inner">
              <div class="outcome-box-info">
                  <div class="outcome-box-info-inner">
                      <h3 class="section-title section-title-sub">THE OUTCOME</h3>
                      <!-- <p>Right information to right person on right screen, inter-linking on information and simple UI with standard practice for everyone.</p> -->
                      <ul>
                          <li>Right information to right person on right screen</li>
                          <li>Inter-linking on information and simple UI with standard practice for everyone.</li>
                      </ul>
                  </div>
              </div>
          </div>
          <div class="outcome-img-block">
              <img src="assets/images/project-images/build-effective/outcome-box.webp" alt="Buildeffective">
          </div>
      </div>
      <div class="project-info">
          <div class="project-info-inner">
              <div class="portfolio-quote">
                  <p>
                      <i class="quote-icon">
                          <img src="assets/images/quote-icon.svg" alt="Quote Icon">
                      </i>
                      They are very professional and skillful. They listen to my requirement. Suggest the best tools and technology for the job. They are committed to work.</p>
                  <div class="client-quote-info">
                      <div class="client-img">
                          <img src="assets/images/testimonials/david-scholar.jpg" alt="David">
                      </div>
                      <div class="client-name">
                          <h3>David</h3>
                          <span>CEO</span>
                      </div>
                  </div>
              </div>
              <div class="work-detail-box">
                  <div class="work-detail-box-inner">
                      <h5>Connect with us</h5>
                      <p class="work-info">If you have a concept/vision/product and you are looking for a right <span>design and developer team</span> OR you have right technical skills and looking for <span>exciting projects/environment to experience</span>, drop a message via contact us form. </p>
                      <div class="contact-btn-block">
                          <a [routerLink]="['/', 'contact-us']"><span>Contact Now</span></a>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <section class="horizontal">
          <div class="more-project-ovrelay"></div>
          <div class="pin-wrap">
              <div class="projects-title-block">
                  <h2>More Works</h2>
              </div>
              <div class="animation-wrap to-right">
                  <a class="item" [routerLink]="['/', 'work', 'ar-workflow']">
                      <div class="project-img">
                          <img src="assets/images/project-images/project-thumbnail/ar-workflow.webp" alt="ARWorkflow">
                      </div>
                      <div class="project-slider-info">
                          <span>Accounting</span>
                          <h2>ARWorkflow</h2>
                      </div>
                  </a>
                  <a class="item" [routerLink]="['/', 'work', 'linkmybooks']">
                      <div class="project-img">
                          <img src="assets/images/project-images/project-thumbnail/link-my-books.webp" alt="LinkMyBooks">
                      </div>
                      <div class="project-slider-info">
                          <span>Business</span>
                          <h2>LinkMyBooks</h2>
                      </div>
                  </a>
                  <a class="item" [routerLink]="['/', 'work', 'ccalerts']">
                      <div class="project-img">
                          <img src="assets/images/project-images/project-thumbnail/ccalerts.webp" alt="CCAlerts">
                      </div>
                      <div class="project-slider-info">
                          <span>Finance</span>
                          <h2>CCAlerts</h2>
                      </div>
                  </a>
                  <a class="item" [routerLink]="['/', 'work', 'talowiz']">
                      <div class="project-img">
                          <img src="assets/images/project-images/project-thumbnail/talowiz.webp" alt="Talowiz">
                      </div>
                      <div class="project-slider-info">
                          <span>Education</span>
                          <h2>Talowiz</h2>
                      </div>
                  </a>
                  <a class="item" [routerLink]="['/', 'work', 'percentology']">
                      <div class="project-img">
                          <img src="assets/images/project-images/project-thumbnail/percentology.webp" alt="Percentology">
                      </div>
                      <div class="project-slider-info">
                          <span>Account</span>
                          <h2>Percentology</h2>
                      </div>
                  </a>
                  <a class="item" [routerLink]="['/', 'work', 'facturazen']">
                    <div class="project-img">
                        <img src="assets/images/project-images/project-thumbnail/facturazen.webp" alt="Facturazen">
                    </div>
                    <div class="project-slider-info">
                        <span>Business</span>
                        <h2>Facturazen</h2>
                    </div>
                  </a>
                  <ng-container *ngIf="false">
                      <a class="item" [routerLink]="['/', 'work', 'stomerijcollectief']">
                          <div class="project-img">
                              <img src="assets/images/project-images/project-thumbnail/stomerij-collectief.webp" alt="Stomerij Collectief">
                          </div>
                          <div class="project-slider-info">
                              <span>Business</span>
                              <h2>Stomerij Collectief</h2>
                          </div>
                      </a>
                      <a class="item" [routerLink]="['/', 'work', 'food-trucks']">
                          <div class="project-img">
                              <img src="assets/images/project-images/project-thumbnail/food-truck.webp" alt="Food Truck">
                          </div>
                          <div class="project-slider-info">
                              <span>Food</span>
                              <h2>FoodTruck</h2>
                          </div>
                      </a>

                      <a class="item" [routerLink]="['/', 'work', 'local-grocery']">
                          <div class="project-img">
                              <img src="assets/images/project-images/project-thumbnail/local-grocery.webp" alt="Local Grocery">
                          </div>
                          <div class="project-slider-info">
                              <span>Utility</span>
                              <h2>Local Grocery</h2>
                          </div>
                      </a>
                      <a class="item" [routerLink]="['/', 'work', 'miniflix']">
                          <div class="project-img">
                              <img src="assets/images/project-images/project-thumbnail/miniflix.webp" alt="Miniflix">
                          </div>
                          <div class="project-slider-info">
                              <span>Entertainment</span>
                              <h2>Miniflix</h2>
                          </div>
                      </a>
                  </ng-container>
              </div>
          </div>
      </section>
  </div>
  <app-footer></app-footer>
</div>
