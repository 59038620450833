import { Component, OnInit } from '@angular/core';

import { Title, Meta } from '@angular/platform-browser';
import gsap from 'gsap';
import Scrollbar, { ScrollbarPlugin } from 'smooth-scrollbar';
import { sharedService } from 'src/app/shared/shared.service';
import { HighlightLoader } from 'ngx-highlightjs';

@Component({
  selector: 'app-rails-api-fast',
  templateUrl: './rails-api-fast.component.html',
  styleUrls: ['./rails-api-fast.component.scss']
})
export class RailsApiFastComponent implements OnInit {

  bodyScrollBar: Scrollbar | undefined;
  public isHeaderMenuOpen: boolean = false;

  public currentBlogUrl: string = window.location.href;
  public baseUrl: string = window.location.origin;

public code1 = `$ rails new test-app –api –database=postgresql`;

  public code2 = `config.middleware.insert_before 0, Rack::Cors do
  allow do
    origins '*'
    resource(
      '*',
      headers: :any,
      expose: ["Authorization"],
      methods: [:get, :patch, :put, :delete, :post, :options, :show]
    )
  end
end`;

public code3= `gem 'devise'
gem 'devise-jwt'
gem 'fast_jsonapi'
`;

public code4 = `$ rails generate devise:install`;

public code5 = `config.navigational_formats = []`;

public code6 = `config.action_mailer.default_url_options = { host: 'localhost', port: 3000 }`;

public code7 = `$ rails generate devise User`;

public code8 = `$ rake db:setup`;

public code9 = `$ rake db:create`;

public code10 = `$ rake db:migrate`;

public code11 = `rails g devise:controllers users -c sessions registrations`

public code12 = `class Users::SessionsController < Devise::SessionsController
  respond_to :json
end`;

public code13 =`class Users::RegistrationsController < Devise::SessionsController
  respond_to :json
end`;

public code14 = `Rails.application.routes.draw do
  devise_for :users, path: '', path_names: {
    sign_in: 'login',
    sign_out: 'logout',
    registration: 'signup'
  },
  controllers: {
    sessions: 'users/sessions',
    registrations: 'users/registrations'
  }
end`;

public code15 = `$ bundle exec rake secret`;

public code16 = `config.jwt do |jwt|
  jwt.secret = GENERATED_SECRET_KEY
  jwt.dispatch_requests = [
    ['POST', %r{^/login$}]
  ]
  jwt.revocation_requests = [
    ['DELETE', %r{^/logout$}]
  ]
  jwt.expiration_time = 30.minutes.to_i
end`;

public code17 = `$ rails g model jwt_blacklist jti:string:index exp:datetime`;

public code18 = `include Devise::JWT::RevocationStrategies::Blacklist
self.table_name = 'jwt_blacklists'`;

public code19 = `:jwt_authenticatable, jwt_revocation_strategy: JwtBlacklist`;

public code20 = `class User < ApplicationRecord
  devise :database_authenticatable, :registerable,
  :recoverable, :rememberable, :validatable,
  :jwt_authenticatable, jwt_revocation_strategy: JwtBlacklist
end`;

public code21 = `$ rails generate serializer user`;

public code22 = `class UserSerializer
  include FastJsonapi::ObjectSerializer
  attributes :id, :email, :created_at
end`;

public code23 = `class Users::RegistrationsController < Devise::SessionsController
  respond_to :json
  private

  def respond_with(resource, _opts = { + })
    render json: {
      status: {code: 200, message: 'Logged in successfully.'},
      data: UserSerializer.new(resource).serializable_hash[:data][:attributes]
    }
  end
end`;

public code24 = `class Users::SessionsController < Devise::SessionsController
  respond_to :json
  private

  def respond_with(resource, _opts = { + })
    render json: {
      status: {code: 200, message: 'Logged in successfully.'},
      data: UserSerializer.new(resource).serializable_hash[:data][:attributes]
    }
  end

  def respond_to_on_destroy
    head :ok
  end
end`;


  constructor(private metaTagService: Meta, private titleService: Title, public sharedservice: sharedService, private hljsLoader: HighlightLoader) { }

  ngOnInit(): void {
    // this.hljsLoader.setTheme('node_modules/highlight.js/styles/androidstudio.css');
    this.titleService.setTitle("Rails 6 API fast_jsonapi gem with Devise and JWT authentication - TechCompose");
    // this.metaTagService.addTags([
    //   { name: 'keywords', content: 'This article is all about authentication in rails 6 using devise and devise-jwt with fast_jsonapi response. Fast_jsonapi A lightning fast JSON:API serializer for Ruby Objects. It is better in performance compared to Active Model Serializer. Devise and JWT Devise-jwt is a devise extension which uses JSON Web Tokens(JWT) for user authentication. With JSON Web Tokens …' },
    //   { property: 'og:title', content: 'Techcompose | Rails 6 API fast_jsonapi gem with Devise and JWT authentication' },
    //   { property: 'og:type', content: 'website' },
    //   { property: 'og:url', content: 'https://www.techcompose.com/blogs/rails-6-api-fast_jsonapi-gem-with-devise-and-jwt-authentication' },
    //   { property: 'og:image', content: 'https://www.techcompose.com/assets/images/blog/rails-6-api.webp' },
    //   { property: 'og:description', content: 'This article is all about authentication in rails 6 using devise and devise-jwt with fast_jsonapi response. Fast_jsonapi A lightning fast JSON:API serializer for Ruby Objects. It is better in performance compared to Active Model Serializer. Devise and JWT Devise-jwt is a devise extension which uses JSON Web Tokens(JWT) for user authentication. With JSON Web Tokens …' }
    // ]);

    this.metaTagService.updateTag({ name: 'keywords', content: 'This article is all about authentication in rails 6 using devise and devise-jwt with fast_jsonapi response. Fast_jsonapi A lightning fast JSON:API serializer for Ruby Objects. It is better in performance compared to Active Model Serializer. Devise and JWT Devise-jwt is a devise extension which uses JSON Web Tokens(JWT) for user authentication. With JSON Web Tokens …' });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Techcompose | Rails 6 API fast_jsonapi gem with Devise and JWT authentication' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:url', content: `${this.currentBlogUrl}` });
    this.metaTagService.updateTag({ property: 'og:image', content: `${this.baseUrl}/assets/images/blog/rails-6-api.png` });
    this.metaTagService.updateTag({ property: 'og:description', content: 'This article is all about authentication in rails 6 using devise and devise-jwt with fast_jsonapi response. Fast_jsonapi A lightning fast JSON:API serializer for Ruby Objects. It is better in performance compared to Active Model Serializer. Devise and JWT Devise-jwt is a devise extension which uses JSON Web Tokens(JWT) for user authentication. With JSON Web Tokens …' })
    this.metaTagService.removeTag("name='google-site-verification'");

    this.sharedservice.showHeaderMenu$.subscribe((data) => {
      this.isHeaderMenuOpen = data;
      if (this.bodyScrollBar) {
        this.bodyScrollBar.updatePluginOptions('modal', { open: this.isHeaderMenuOpen });
      }
    });

    gsap.registerPlugin(ScrollTrigger);
    const scroller = document.querySelector('.scroller')as HTMLElement;

    this.bodyScrollBar = Scrollbar.init(scroller, { damping: 0.1, delegateTo: document, alwaysShowTracks: true });

    const content = this;
    ScrollTrigger.scrollerProxy(".scroller", {
      scrollTop(value) {
        if (content.bodyScrollBar) {
          if (arguments.length) {
            content.bodyScrollBar.scrollTop = value || 0;
          }
          return content.bodyScrollBar.scrollTop;
        } else {
          return 0;
        }
      }
    });

    this.bodyScrollBar.addListener(ScrollTrigger.update);
    ScrollTrigger.defaults({ scroller: scroller });

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    ScrollTrigger.create({
      trigger: ".header-class",
      start: "+50px",
      end: "bottom -=1000%",
      toggleClass: { targets: ".header", className: "active" }
    });

  }

}
class ModalPlugin extends ScrollbarPlugin {
  static pluginName = 'modal';

  static defaultOptions = {
    isHeaderMenuOpen: false
  };

  transformDelta(delta: any) {
      return this.options.open ? { x: 0, y: 0 } : delta;
  }
}

Scrollbar.use(ModalPlugin, /* OverscrollPlugin */);
