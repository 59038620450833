import { Component, OnInit } from '@angular/core';

import { Title, Meta } from '@angular/platform-browser';
import gsap from 'gsap';
import Scrollbar from 'smooth-scrollbar';
import { HighlightLoader } from 'ngx-highlightjs';

@Component({
  selector: 'app-create-gradient-android',
  templateUrl: './create-gradient-android.component.html',
  styleUrls: ['./create-gradient-android.component.scss']
})
export class CreateGradientAndroidComponent implements OnInit {

  bodyScrollBar: Scrollbar | undefined;
  public isHeaderMenuOpen: boolean = false;
  public currentBlogUrl: string = window.location.href;
  public baseUrl: string = window.location.origin;

public code1 =`<?xml version="1.0" encoding="UTF-8"?>
<shapexmlns:android="http://schemas.android.com/apk/res/android"android:shape="rectangle">

<gradient
android:angle="90"
android:centerColor="#555994"
android:endColor="#b5b6d2"
android:startColor="#555994"
android:type="linear"/>

<corners
android:radius="0dp"/>

</shape>`;

public code2 =`<?xml version="1.0" encoding="utf-8"?>
<LinearLayoutxmlns:android="http://schemas.android.com/apk/res/android"
android:orientation="vertical"
android:layout_width="fill_parent"
android:layout_height="fill_parent"
android:background="@drawable/bg_gradient "
>
</LinearLayout>`;

  constructor(private metaTagService: Meta, private titleService: Title,private hljsLoader: HighlightLoader) { }

  ngOnInit(): void {

    this.titleService.setTitle("How to Create Gradient Background in Android - TechCompose");

    this.metaTagService.updateTag({ name: 'keywords', content: 'How to Create Gradient Background in Android. Read this blog to know How to develop Gradient Background in Android app development.'});
    this.metaTagService.updateTag({ property: 'og:title', content: 'Techcompose | How to Create Gradient Background in Android' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:url', content: `${this.currentBlogUrl}` });
    this.metaTagService.updateTag({ property: 'og:image', content: `${this.baseUrl}/assets/images/blog/how-to-create-gradient-android.png` });
    this.metaTagService.updateTag({ property: 'og:description', content: 'How to Create Gradient Background in Android. Read this blog to know How to develop Gradient Background in Android app development.' });
    this.metaTagService.removeTag("name='google-site-verification'");

    gsap.registerPlugin(ScrollTrigger);
    const scroller = document.querySelector('.scroller')as HTMLElement;

    this.bodyScrollBar = Scrollbar.init(scroller, { damping: 0.1, delegateTo: document, alwaysShowTracks: true });

    const content = this;
    ScrollTrigger.scrollerProxy(".scroller", {
      scrollTop(value) {
        if (content.bodyScrollBar) {
          if (arguments.length) {
            content.bodyScrollBar.scrollTop = value || 0;
          }
          return content.bodyScrollBar.scrollTop;
        } else {
          return 0;
        }
      }
    });

    this.bodyScrollBar.addListener(ScrollTrigger.update);
    ScrollTrigger.defaults({ scroller: scroller });

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    ScrollTrigger.create({
      trigger: ".header-class",
      start: "+50px",
      end: "bottom -=1000%",
      toggleClass: { targets: ".header", className: "active" }
    });
  }

}
