<div class="portfolio-slider-wrapper">
  <div class="section-title-block">
    <div class="section-title-inner">
      <h2>Our Work</h2>
      <p>Our solution portfolio crosses different industry verticals and borders.</p>
    </div>
  </div>
  <swiper [config]="config">
    <div class="swiper-wrapper">
      <ng-container *ngFor="let project of projectLists">
        <div class="swiper-slide" *ngIf="project.technologies.indexOf(projectsFor) !== -1">
          <a class="work-primary-img-block" [routerLink]="[ '/', 'work', project['url']]">
            <div class="work-hover-img-block project-img">
                <img [src]="project.imgUrl" width="1520" height="1400" [alt]="project.altText">
            </div>
            <h4 [innerHtml]="project.label"></h4>
          </a>
        </div>
      </ng-container>
    </div>
  </swiper>
</div>
