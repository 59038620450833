<div class="portfolio-wrapper">
    <div class="portfolio-overlay"></div>
    <div class="portfolio-detail-wrapper">
        <div class="portfolio-detail-inner">
            <div class="back-btn-block">
                <a [routerLink]="['/work']" class="back-btn">
                    <i>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.828 11.0007H22V13.0007H5.828L11.192 18.3647L9.778 19.7787L2 12.0007L9.778 4.22266L11.192 5.63666L5.828 11.0007Z" fill="black"/>
                        </svg>
                    </i>
                    Back to Portfolio
                </a>
            </div>
            <div class="project-logo">
                <img src="assets/images/project-images/local-grocery/logo.svg" alt="Local Grocery">
            </div>
            <h2>Family grocery list app that improves the quality of your grocery shopping</h2>
            <div class="challange-block">
                <div class="challange-info">
                    <div>
                        <h3 class="section-title section-title-sub">The Challenge</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.</p>
                    </div>
                </div>
                <div class="challange-list">
                    <ul>
                        <li>
                            <h3 class="section-title section-title-sub">Discover</h3>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>
                        </li>
                        <li>
                            <h3 class="section-title section-title-sub">DEfine</h3>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>
                        </li>
                        <li>
                            <h3 class="section-title section-title-sub">Design</h3>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="project-img-block">
                <img src="assets/images/project-images/local-grocery/img-1.webp" alt="Local Grocery">
                <div class="small-img-block small-img-1">
                    <img src="assets/images/project-images/local-grocery/small-img-1.webp" alt="Local Grocery">
                </div>
                <div class="small-img-block small-img-2">
                    <img src="assets/images/project-images/local-grocery/small-img-2.webp" alt="Local Grocery">
                </div>
                <div class="small-img-block small-img-3">
                    <img src="assets/images/project-images/local-grocery/small-img-3.webp" alt="Local Grocery">
                </div>
                <div class="small-img-block small-img-4">
                    <img src="assets/images/project-images/local-grocery/small-img-4.webp" alt="Local Grocery">
                </div>
                <div class="small-img-block small-img-5">
                    <img src="assets/images/project-images/local-grocery/small-img-5.webp" alt="Local Grocery">
                </div>
                <div class="small-img-block small-img-6">
                    <img src="assets/images/project-images/local-grocery/small-img-6.webp" alt="Local Grocery">
                </div>
                <div class="small-img-block small-img-7">
                    <img src="assets/images/project-images/local-grocery/small-img-7.webp" alt="Local Grocery">
                </div>
            </div>
            <div class="work-process" *ngIf="false">
                <ul>
                    <li>
                        <h3 class="section-title">STRATEGY</h3>
                        <span>Design thinking</span>
                        <span>Product Roadmap</span>
                    </li>
                    <li>
                        <h3 class="section-title">DESIGN</h3>
                        <span>User Experience</span>
                        <span>User Interface</span>
                        <span>Illustrations & Icons</span>
                    </li>
                    <li>
                        <h3 class="section-title">FRONT END DEVELOPEMENT</h3>
                        <span>HTML</span>
                        <span>CSS</span>
                        <span>Posenet</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="outcome-box-wrapper">
        <div class="outcome-box-inner">
            <div class="outcome-box-info">
                <div class="outcome-box-info-inner">
                    <h3 class="section-title section-title-sub">THE OUTCOME</h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, </p>
                </div>
            </div>
        </div>
        <div class="outcome-img-block">
            <img src="assets/images/project-images/local-grocery/outcome-box.webp" alt="Local Grocery">
        </div>
    </div>
    <div class="project-info">
        <div class="project-info-inner">
            <div class="portfolio-quote">
                <p>
                    <i class="quote-icon">
                        <img src="assets/images/quote-icon.svg" alt="Quote Icon">
                    </i>
                    Eiusmod proident est duis veniam non laborum enim non irure. Elit culpa qui in deserunt dolore amet. Incididunt do eniam non sint dolore cupidatat occaecat Lorem aliquipeniam non sint dolore cupidatat occaecat Lorem aliquip pariatur officia aliqua veniam labori pariatur officia.</p>
                <div class="client-quote-info">
                    <div class="client-img">
                        <img src="assets/images/smarter.webp" alt="Local Grocery">
                    </div>
                    <div class="client-name">
                        <h3>Darlene Robertson</h3>
                        <span>CEO</span>
                    </div>
                </div>
            </div>
            <div class="visule-guide">
                <h2>Visual Guide</h2>
                <div class="visule-guide-row">
                    <div class="visule-guide-col">
                        <h3 class="section-title section-title-sub">TYPE FACE</h3>
                        <label>Nunito</label>
                        <p>a quick brown fox jumps over the lazy dog</p>
                    </div>
                    <div class="visule-guide-col">
                        <h3 class="section-title section-title-sub">COLOR</h3>
                        <ul class="color-list">
                            <li style="background: #6FD37B; color: #fff; border: none">
                                #6FD37B
                            </li>
                            <li style="color: #2B2B2B;">
                                #FFFFFF
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <section class="horizontal">
        <div class="more-project-ovrelay"></div>
        <div class="pin-wrap">
            <div class="projects-title-block">
                <h2>More Works</h2>
            </div>
            <div class="animation-wrap to-right">
                <a class="item" [routerLink]="['/', 'work', 'build-effective']">
                    <div class="project-img">
                        <img src="assets/images/project-images/project-thumbnail/build-effective.webp" alt="Build Effective">
                    </div>
                    <div class="project-slider-info">
                        <span>Real Estate</span>
                        <h2>Buildeffective</h2>
                    </div>
                </a>
                <a class="item" [routerLink]="['/', 'work', 'ar-workflow']">
                    <div class="project-img">
                        <img src="assets/images/project-images/project-thumbnail/ar-workflow.webp" alt="ARWorkflow">
                    </div>
                    <div class="project-slider-info">
                        <span>Accounting</span>
                        <h2>ARWorkflow</h2>
                    </div>
                </a>
                <a class="item" [routerLink]="['/', 'work', 'linkmybooks']">
                    <div class="project-img">
                        <img src="assets/images/project-images/project-thumbnail/link-my-books.webp" alt="LinkMyBooks">
                    </div>
                    <div class="project-slider-info">
                        <span>Accounting</span>
                        <h2>LinkMyBooks</h2>
                    </div>
                </a>
                <a class="item" [routerLink]="['/', 'work', 'ccalerts']">
                    <div class="project-img">
                        <img src="assets/images/project-images/project-thumbnail/ccalerts.webp" alt="CCAlerts">
                    </div>
                    <div class="project-slider-info">
                        <span>Finance</span>
                        <h2>CCAlerts</h2>
                    </div>
                </a>
                <a class="item" [routerLink]="['/', 'work', 'stomerijcollectief']">
                    <div class="project-img">
                        <img src="assets/images/project-images/project-thumbnail/stomerij-collectief.webp" alt="Stomerij Collectief">
                    </div>
                    <div class="project-slider-info">
                        <span>Business</span>
                        <h2>Stomerij Collectief</h2>
                    </div>
                </a>
                <a class="item" [routerLink]="['/', 'work', 'food-trucks']">
                    <div class="project-img">
                        <img src="assets/images/project-images/project-thumbnail/food-truck.webp" alt="Food Truck">
                    </div>
                    <div class="project-slider-info">
                        <span>Food</span>
                        <h2>FoodTruck</h2>
                    </div>
                </a>
                <a class="item" [routerLink]="['/', 'work', 'percentology']">
                    <div class="project-img">
                        <img src="assets/images/project-images/project-thumbnail/percentology.webp" alt="Percentology">
                    </div>
                    <div class="project-slider-info">
                        <span>Account</span>
                        <h2>Percentology</h2>
                    </div>
                </a>
                <a class="item" [routerLink]="['/', 'work', 'miniflix']">
                    <div class="project-img">
                        <img src="assets/images/project-images/project-thumbnail/miniflix.webp" alt="Miniflix">
                    </div>
                    <div class="project-slider-info">
                        <span>Entertainment</span>
                        <h2>Miniflix</h2>
                    </div>
                </a>
            </div>
        </div>
    </section>
</div>
