import { AfterViewInit, Component, OnInit } from '@angular/core';
import SplitType from 'split-type';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { Title, Meta } from '@angular/platform-browser';
import Scrollbar, { ScrollbarPlugin } from 'smooth-scrollbar';
import { sharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-digital-marketing',
  templateUrl: './digital-marketing.component.html',
  styleUrls: ['./digital-marketing.component.scss']
})
export class DigitalMarketingComponent implements AfterViewInit, OnInit {

  bodyScrollBar: Scrollbar | undefined;
  public isHeaderMenuOpen: boolean = false;
  public currentUrl: string = window.location.href;
  public baseUrl: string = window.location.origin;

  constructor(private metaTagService: Meta, private titleService: Title, public sharedservice: sharedService) { }

  ngOnInit() {
    this.titleService.setTitle("Digital Marketing Agency India | SEO | PPC | Social Media Company USA");

    this.metaTagService.updateTag({ name: 'keywords', content: 'Techcompose is a Top Digital Marketing Agency in India and USA that offers SEO Services, Social Media, PPC, Online Content Marketing, Branding & Advertising'}),
    this.metaTagService.updateTag({ property: 'og:title', content: 'Techcompose | Digital Marketing Agency India | SEO | PPC | Social Media Company USA' }),
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' }),
    this.metaTagService.updateTag({ property: 'og:url', content: `${this.currentUrl}` }),
    this.metaTagService.updateTag({ property: 'og:image', content: `${this.baseUrl}/assets/images/thumb.png` });
    this.metaTagService.updateTag({ property: 'og:description', content: 'Techcompose is a Top Digital Marketing Agency in India and USA that offers SEO Services, Social Media, PPC, Online Content Marketing, Branding & Advertising' })
    this.metaTagService.removeTag("name='google-site-verification'");

    this.sharedservice.showHeaderMenu$.subscribe((data) => {
      this.isHeaderMenuOpen = data;
      if (this.bodyScrollBar) {
        this.bodyScrollBar.updatePluginOptions('modal', { open: this.isHeaderMenuOpen });
      }
    });

    gsap.registerPlugin(ScrollTrigger);
    const scroller = document.querySelector('.scroller')as HTMLElement;

    this.bodyScrollBar = Scrollbar.init(scroller, { damping: 0.1, delegateTo: document, alwaysShowTracks: true });

    const content = this;
    ScrollTrigger.scrollerProxy(".scroller", {
      scrollTop(value) {
        if (content.bodyScrollBar) {
          if (arguments.length) {
            content.bodyScrollBar.scrollTop = value || 0;
          }
          return content.bodyScrollBar.scrollTop;
        } else {
          return 0;
        }
      }
    });

    this.bodyScrollBar.addListener(ScrollTrigger.update);
    ScrollTrigger.defaults({ scroller: scroller });
  }


  public ngAfterViewInit(): void {
    gsap.registerPlugin(ScrollTrigger);
    gsap.to('.hero-bg', {duration: 1, scaleY: 1, opacity: 1, ease: 'power1', delay: 0.2});
    gsap.to('.bannerImgBlock', {duration: 0.5, width: 'auto', ease: 'power1', delay: 1});
    gsap.to('.bannerImgOverlay', {duration: 1, width: '0', ease: 'power1', delay: 1.5});
    gsap.to('.service-in-info-detail', { opacity: 1, y: 0, duration: 0.5, delay: 0.5, ease: 'power1'});
    const aboutpagetitle = new SplitType('.page-title h1', {
      types: 'chars, words',
      absolute: true
    });
    gsap.from(aboutpagetitle.chars, {
      opacity: 0,
      x: 20,
      duration: 0.7,
      delay: 0.2,
      stagger: 0.03,
    });
    gsap.fromTo('.service-in-info-detail ul li', {
      autoAlpha: 0,
      y: 30,
    }, {
      autoAlpha: 1,
      y: 0,
      duration: 0.35,
      stagger: 0.2,
    });

    ScrollTrigger.create({
      trigger: '.point-1',
      start: '0s',
      end: '+=1000s',
      // toggleActions: 'play reverse play reverse',

      // onEnter: () => {
      // },
      onEnterBack: () => {
        gsap.fromTo(".point-1 .services-img-block", {autoAlpha: 0}, {autoAlpha: 1})
        gsap.fromTo(".point-1 article", {autoAlpha: 0, y: -50}, {autoAlpha: 1, y: 0})
      },
      onLeaveBack: () => {
        gsap.fromTo(".point-1 .services-img-block", {autoAlpha: 1}, {autoAlpha: 1})
        gsap.fromTo(".point-1 article", {autoAlpha: 1, y: 0}, {autoAlpha: 1, y: 0})
      }
    })

    ScrollTrigger.create({
      trigger: '.point-2',
      start: '+=1000s',
      end: '+=1000s',
      // toggleActions: 'play reverse play reverse',
      onEnter: () => {
        gsap.fromTo(".point-2 .services-img-block", {autoAlpha: 0}, {autoAlpha: 1})
        gsap.fromTo(".point-2 article", {autoAlpha: 0, y: 50}, {autoAlpha: 1, y: 0})
        gsap.fromTo(".point-1 .services-img-block", {autoAlpha: 1}, {autoAlpha: 0})
        gsap.fromTo(".point-1 article", {autoAlpha: 1, y: 0}, {autoAlpha: 0, y: -50})
      },
      onEnterBack: () => {
        gsap.fromTo(".point-2 .services-img-block", {autoAlpha: 0}, {autoAlpha: 1})
        gsap.fromTo(".point-2 article", {autoAlpha: 0, y: -50}, {autoAlpha: 1, y: 0})
      },
      onLeaveBack: () => {
        gsap.fromTo(".point-2 .services-img-block", {autoAlpha: 1}, {autoAlpha: 0})
        gsap.fromTo(".point-2 article", {autoAlpha: 1, y: 0}, {autoAlpha: 0, y: 50})
      }
    })
    ScrollTrigger.create({
      trigger: '.point-3',
      start: '+=2000s',
      end: '+=1000s',
      // toggleActions: 'play reverse play reverse',
      onEnter: () => {
        gsap.fromTo(".point-3 .services-img-block", {autoAlpha: 0}, {autoAlpha: 1})
        gsap.fromTo(".point-3 article", {autoAlpha: 0, y: 50}, {autoAlpha: 1, y: 0})
        gsap.fromTo(".point-2 .services-img-block", {autoAlpha: 1}, {autoAlpha: 0})
        gsap.fromTo(".point-2 article", {autoAlpha: 1, y: 0}, {autoAlpha: 0, y: -50})
      },
      onEnterBack: () => {
        gsap.fromTo(".point-3 .services-img-block", {autoAlpha: 0}, {autoAlpha: 1})
        gsap.fromTo(".point-3 article", {autoAlpha: 0, y: -50}, {autoAlpha: 1, y: 0})
      },
      onLeaveBack: () => {
        gsap.fromTo(".point-3 .services-img-block", {autoAlpha: 1}, {autoAlpha: 0})
        gsap.fromTo(".point-3 article", {autoAlpha: 1, y: 0}, {autoAlpha: 0, y: 50})
      }
    })
    ScrollTrigger.create({
      trigger: '.point-4',
      start: '+=3000s',
      end: '+=1000s',
      // toggleActions: 'play reverse play reverse',
      onEnter: () => {
        gsap.fromTo(".point-4 .services-img-block", {autoAlpha: 0}, {autoAlpha: 1})
        gsap.fromTo(".point-4 article", {autoAlpha: 0, y: 50}, {autoAlpha: 1, y: 0})
        gsap.fromTo(".point-3 .services-img-block", {autoAlpha: 1}, {autoAlpha: 0})
        gsap.fromTo(".point-3 article", {autoAlpha: 1, y: 0}, {autoAlpha: 0, y: -50})
      },
      onEnterBack: () => {
        gsap.fromTo(".point-4 .services-img-block", {autoAlpha: 1}, {autoAlpha: 1})
        gsap.fromTo(".point-4 article", {autoAlpha: 1, y: 0}, {autoAlpha: 1, y: 0})
      },
      onLeaveBack: () => {
        gsap.fromTo(".point-4 .services-img-block", {autoAlpha: 1}, {autoAlpha: 0})
        gsap.fromTo(".point-4 article", {autoAlpha: 1, y: 0}, {autoAlpha: 0, y: 50})
      }
    })


    ScrollTrigger.create({
      trigger: '.philosophie',
      pin: '.philosophie',
      end: '+=4000s',
      pinSpacing: true
    });

    gsap.fromTo('.philosophie', {
      autoAlpha: 0,
    }, {
      autoAlpha: 1,
      delay: 1.5,
    });

    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
    });

    ScrollTrigger.create({
      trigger: ".header-class",
      start: "+50px",
      end: "bottom -=1000%",
      toggleClass: { targets: ".header", className: "active" }
    });

    ScrollTrigger.create({
      trigger: '.philosophie',
      // markers:true,
      start:"top 50%",
      end:"bottom 0%",
      onEnter: () => {
        gsap.to('.bg-services', { duration: 1, backgroundColor: '#141414'})
        gsap.to('.service-in-deail-block h1',{color: '#ffffff'})
        gsap.to('.service-in-deail-block p',{color: '#ffffff'})
        gsap.to('.article-inner h3, .article-inner ul li, .article-inner p, .article-inner a', {color: '#ffffff'})
      },
      onLeaveBack: () => {
        gsap.to('.bg-services', { duration: 1.0, backgroundColor: '#ffffff'})
        gsap.to('.service-in-deail-block h1',{color: '#282828'})
        gsap.to('.service-in-deail-block p',{color: '#282828'})
        gsap.to('.article-inner h3, .article-inner ul li, .article-inner p, .article-inner a', {color: '#282828'})
      },
    });

  }
}
class ModalPlugin extends ScrollbarPlugin {
  static pluginName = 'modal';

  static defaultOptions = {
    isHeaderMenuOpen: false
  };

  transformDelta(delta: any) {
      return this.options.open ? { x: 0, y: 0 } : delta;
  }
}

Scrollbar.use(ModalPlugin, /* OverscrollPlugin */);
