import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appPhoneInput]'
})
export class PhoneInputDirective {

  private readonly allowedCharactersRegex = /[0-9()+-]/;

  constructor() { }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (
      event.key === 'Backspace' ||
      event.key === 'Delete' ||
      event.key === 'ArrowLeft' ||
      event.key === 'ArrowRight' ||
      event.key === 'Tab'
    ) {
      return;
    }

    if (!this.allowedCharactersRegex.test(event.key)) {
      event.preventDefault();
    }
  }
}
