import { Component, OnInit } from '@angular/core';

import { Title, Meta } from '@angular/platform-browser';
import gsap from 'gsap';
import Scrollbar, { ScrollbarPlugin } from 'smooth-scrollbar';
import { sharedService } from 'src/app/shared/shared.service';
import { HighlightLoader } from 'ngx-highlightjs';

@Component({
  selector: 'app-testcases-angular',
  templateUrl: './testcases-angular.component.html',
  styleUrls: ['./testcases-angular.component.scss']
})
export class TestcasesAngularComponent implements OnInit {

  bodyScrollBar: Scrollbar | undefined;
  public isHeaderMenuOpen: boolean = false;
  public currentBlogUrl: string = window.location.href;
  public baseUrl: string = window.location.origin;

public code1 = `beforeEach(waitForAsync(() => {
  TestBed.configureTestingModule({
    imports: [RouterTestingModule],
    declarations: [AppComponent],
  }).compileComponents();
});`;

public code2 = `beforeEach(waitForAsync(() => {
  TestBed.configureTestingModule({
    declarations: [AppComponent],
  }).compileComponents();
});`;

public code3 = `describe('AppComponent', () => {
  beforeEach(waitForAsync () => {
    TestBed.configureTestingModule({
      providers: [{ provide: UserService, useValue: userServiceMock }],
      declarations: [HomeComponent],
    }).compileComponents();
  });
});`;

public code4 = `public onLogOut(): void {
  this.userService.isUserLoggedIn.next(false);
  localStorage.removeItem('user');
}`;

public code5 = `let setUpLocalStorageMock = () => {
  localStorageMock = (function () {
    let store: any = {};

    return {
      getItem(key: string) {
        return store[key];
      },
      setItem(key: string, value: string) {
        store[key] = value;
      },
      clear() {
        store = {};
      },
      removeItem(key: string) {
        delete store[key];
      },
      getAll() {
        return store;
      },
    };
  })();

  // this is the place where we place our mocked localstorage instead of the real one.
  Object.defineProperty(window, 'localStorage', { value: localStorageMock });

  localStorage.setItem('user', JSON.stringify(userData));
};
`;

public code6 = `beforeEach(async () => {
  await TestBed.configureTestingModule({
    declarations: [HomeComponent],
    providers: [{ provide: UserService, useValue: userServiceMock }],
  }).compileComponents();

  setUpLocalStorageMock();

  fixture = TestBed.createComponent(HomeComponent);
  component = fixture.componentInstance;
  fixture.detectChanges();
});`;

public code7 = `it('onLogOut function test.', () => {
  /* just for the reference that the user data exist
  initially before we remove it by onLogOut function.
  */
  expect(localStorage.getItem('user')).not.toBeFalsy();

  //actual test case starts here.
  component.onLogOut();
  expect(userServiceMock.isUserLoggedIn.value).toBeFalsy();
  expect(localStorage.getItem('user')).toBeFalsy();
});`;

public code8 = `export class UserService {
  public usersArray: BehaviorSubject<any> = new BehaviorSubject([]);

  public isUserLoggedIn: BehaviorSubject<any> = new BehaviorSubject(false);

  public userData: BehaviorSubject<any> = new BehaviorSubject(undefined);

  constructor() {}

  public get userLoginValue(): boolean {
    return this.isUserLoggedIn.value;
  }
}`;

public code9 = `describe('UserService', () => {
  let service: UserService;

  beforeEach(() => {
    TestBed.configureTestingModule({});
    service = TestBed.inject(UserService);
  });

  it('should be created', () => {
    expect(service).toBeTruthy();
  });

  it('Check userLoginValue', () => {
    /* here we're changing the default of 
    isUserLoggedIn behavior subject i.e false to true.
    */
    service.isUserLoggedIn.next(true);

    // here's where we access the value of the isUserLoggedIn behavior subject.
    let loginValue = service.userLoginValue;

    expect(loginValue).toBeTruthy();
  });
});`;

public code10 = `ngOnInit(): void {
  /* here's where value of isUserLoggedIn behavior 
  subject is accessed via userLoginValue */
  this.isUserLoggedIn = this.userService.userLoginValue;

  this.userService.usersArray.subscribe((usersList: any[]) => {
    this.users = usersList;
  });
}`;

public code11 = `userServiceMock = {
  isUserLoggedIn: new BehaviorSubject(false),
  userLoginValue: {},
  userData: new BehaviorSubject(undefined),
  usersArray: new BehaviorSubject([]),
};

// attaching GET method to the userLoginValue function.
Object.defineProperty(userServiceMock, 'userLoginValue', {
  get: () => {
    return userServiceMock.isUserLoggedIn.value;
  },
});`;

public code12 = `it('Test isUserLoggedIn behavior subject & userLoginValue get method.', () => {
  // assigning true to the value instead of it's default value false.
  userServiceMock.isUserLoggedIn.next(true);

  component.ngOnInit();
  // storing value in variable to test
  const userLoginValue: boolean = userServiceMock.userLoginValue;

  /* testing the value of variable
  Note: storing userLoginValue value in variable & write 
  expect is not needed for actual code coverage it's just for reference */
  expect(userLoginValue).toBeTruthy();

  // testing the actual component variable using the value of userLoginValue
  expect(component.isUserLoggedIn).toBeTruthy();
});`;



  constructor(private metaTagService: Meta, private titleService: Title, public sharedservice: sharedService, private hljsLoader: HighlightLoader) { }

  ngOnInit(): void {
    this.titleService.setTitle("TestCases in Angular + Jest for Local Storage & Behavior Subject with get methods");
    this.metaTagService.updateTag({ name: 'keywords', content: ' TestCases in Angular + Jest for Local Storage & Behavior Subject with get methods. Read this blog to know more about latest web design trends for website. ' });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Techcompose | TestCases in Angular + Jest for Local Storage & Behavior Subject with get methods' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:url', content: `${this.currentBlogUrl}` });
    this.metaTagService.updateTag({ property: 'og:image', content: `${this.baseUrl}/assets/images/blog/testcases-angular.png` });
    this.metaTagService.updateTag({ property: 'og:description', content: 'TestCases in Angular + Jest for Local Storage & Behavior Subject with get methods. Read this blog to know more about latest web design trends for website.' });
    this.metaTagService.removeTag("name='google-site-verification'");

    this.sharedservice.showHeaderMenu$.subscribe((data) => {
      this.isHeaderMenuOpen = data;
      if (this.bodyScrollBar) {
        this.bodyScrollBar.updatePluginOptions('modal', { open: this.isHeaderMenuOpen });
      }
    });

    gsap.registerPlugin(ScrollTrigger);
    const scroller = document.querySelector('.scroller')as HTMLElement;

    this.bodyScrollBar = Scrollbar.init(scroller, { damping: 0.1, delegateTo: document, alwaysShowTracks: true });

    const content = this;
    ScrollTrigger.scrollerProxy(".scroller", {
      scrollTop(value) {
        if (content.bodyScrollBar) {
          if (arguments.length) {
            content.bodyScrollBar.scrollTop = value || 0;
          }
          return content.bodyScrollBar.scrollTop;
        } else {
          return 0;
        }
      }
    });

    this.bodyScrollBar.addListener(ScrollTrigger.update);
    ScrollTrigger.defaults({ scroller: scroller });

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    ScrollTrigger.create({
      trigger: ".header-class",
      start: "+50px",
      end: "bottom -=1000%",
      toggleClass: { targets: ".header", className: "active" }
    });
  }
}

class ModalPlugin extends ScrollbarPlugin {
  static pluginName = 'modal';

  static defaultOptions = {
    isHeaderMenuOpen: false
  };

  transformDelta(delta: any) {
      return this.options.open ? { x: 0, y: 0 } : delta;
  }
}

Scrollbar.use(ModalPlugin, /* OverscrollPlugin */);