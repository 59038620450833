<app-header></app-header>
<app-header-menu *ngIf="isHeaderMenuOpen"></app-header-menu>
<div class="scroller">
  <div class="header-class"></div>
  <div class="page-grid-block">
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
  </div>
  <div class="portfolio-wrapper">
      <div class="portfolio-overlay"></div>
      <div class="portfolio-detail-wrapper">
          <div class="portfolio-detail-inner">
              <div class="back-btn-block">
                  <a [routerLink]="['/work']" class="back-btn">
                      <i>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M5.828 11.0007H22V13.0007H5.828L11.192 18.3647L9.778 19.7787L2 12.0007L9.778 4.22266L11.192 5.63666L5.828 11.0007Z" fill="black"/>
                          </svg>
                      </i>
                      Back to Portfolio
                  </a>
              </div>
              <div class="project-logo">
                  <img src="assets/images/project-images/talowiz/talowiz.png" alt="Talowiz">
              </div>
              <h2 class="probstatement">
                  <span>Problem Statement </span>
                  Recruitment process is not structured and unified hence producing less result after all the efforts
              </h2>
              <div class="challange-block">
                  <div class="challange-list">
                      <ul>
                          <li>
                              <h3 class="section-title section-title-sub">Study</h3>
                              <ul>
                                  <li class="work-info">Loosing right candidates and hiring non-suitable candidates</li>
                                  <li class="work-info">Very good scope of automation</li>
                                  <li class="work-info">Better reporting and summarized dashboard for clarity </li>
                              </ul>
                          </li>
                          <li>
                              <h3 class="section-title section-title-sub">Challenges</h3>
                              <ul>
                                  <li class="work-info">Workflow creation and configurations</li>
                                  <li class="work-info">Multi-tenancy</li>
                                  <li class="work-info">Simple and Effective UI</li>
                                  <li class="work-info">Security and Privacy</li>
                                  <li class="work-info">Background processes and Scheduled tasks </li>
                              </ul>
                          </li>
                          <li>
                              <h3 class="section-title section-title-sub">Approach</h3>
                              <ul>
                                  <li class="work-info">Study of concept shared by Product Owner</li>
                                  <li class="work-info">Identifying the Modules and Processes</li>
                                  <li class="work-info">Micro-Effects for different user interactions</li>
                                  <li class="work-info">Collaborating with external Back-end team</li>
                                  <li class="work-info">Regular communication with all the teams</li>
                              </ul>
                          </li>
                          <li>
                              <h3 class="section-title section-title-sub">Involvement</h3>
                              <ul>
                                  <li class="work-info">From developing user interface with HTML/CSS to ReactJS components to developing whole Front-end </li>
                              </ul>
                          </li>
                      </ul>
                  </div>
              </div>
              <div class="project-img-block">
                  <img src="assets/images/project-images/talowiz/talowiz-img-1.webp" alt="Talowiz">
              </div>
              <div class="work-process" *ngIf="false">
                  <ul>
                      <li>
                          <h3 class="section-title">STRATEGY</h3>
                          <span>Design thinking</span>
                          <span>Product Roadmap</span>
                      </li>
                      <li>
                          <h3 class="section-title">DESIGN</h3>
                          <span>User Experience</span>
                          <span>User Interface</span>
                          <span>Illustrations & Icons</span>
                      </li>
                      <li>
                          <h3 class="section-title">FRONT END DEVELOPEMENT</h3>
                          <span>HTML</span>
                          <span>CSS</span>
                          <span>Posenet</span>
                      </li>
                  </ul>
              </div>
              <div class="visule-guide">
                  <h2>Visual Guide</h2>
                  <div class="visule-guide-row">
                      <div class="visule-guide-col">
                          <h3 class="section-title section-title-sub">TYPE FACE</h3>
                          <label>Open Sans</label>
                          <p>- Sans-Serif</p>
                      </div>
                      <div class="visule-guide-col">
                          <h3 class="section-title section-title-sub">COLOR</h3>
                          <ul class="color-list">
                              <li style="background: #142551; color: #fff; border: none">
                                  #142551
                              </li>
                              <li style="background: #6A35FF; color: #fff; border: none">
                                  #6A35FF
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="outcome-box-wrapper">
          <div class="outcome-box-inner">
              <div class="outcome-box-info">
                  <div class="outcome-box-info-inner">
                      <h3 class="section-title section-title-sub">THE OUTCOME</h3>
                      <!-- <p>User interface and user experience is the USP for our platform and TechCompose front-end team delivered exactly that. We have achieved what our creative and product team planned to deliver to our users. Our investors and business team is excited seeing the strong product delivered.</p> -->
                      <ul>
                          <li>User interface and user experience is the USP for our platform and TechCompose front-end team delivered exactly that.</li>
                          <li>We have achieved what our creative and product team planned to deliver to our users.</li>
                          <li>Our investors and business team is excited seeing the strong product delivered.</li>
                      </ul>
                  </div>
              </div>
          </div>
          <div class="outcome-img-block">
              <img src="assets/images/project-images/talowiz/outcome-box.webp" alt="Talowiz">
          </div>
      </div>
      <div class="project-info">
          <div class="project-info-inner">
              <div class="portfolio-quote">
                  <p>
                      <i class="quote-icon">
                          <img src="assets/images/quote-icon.svg" alt="Quote Icon">
                      </i>
                      We have worked with TechCompose team for other projects in the past. We knew their skills, potentials and dicipline. That was the reason to choose them again for this new solution we are planning to develop. I would recommend TechCompose team any day as my preferred team to work with.</p>
                  <div class="client-quote-info">
                      <div class="client-img">
                          <!-- <img src="assets/images/smarter.webp" alt="Talowiz"> -->
                          <span>D</span>
                      </div>
                      <div class="client-name">
                          <h3>Dheeraj</h3>
                          <span>CEO</span>
                      </div>
                  </div>
              </div>
              <div class="work-detail-box">
                  <div class="work-detail-box-inner">
                      <h5>Connect with us</h5>
                      <p class="work-info">If you have a concept/vision/product and you are looking for a right <span>design and developer team</span> OR you have right technical skills and looking for <span>exciting projects/environment to experience</span>, drop a message via contact us form. </p>
                      <div class="contact-btn-block">
                          <a [routerLink]="['/', 'contact-us']"><span>Contact Now</span></a>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <section class="horizontal">
          <div class="more-project-ovrelay"></div>
          <div class="pin-wrap">
              <div class="projects-title-block">
                  <h2>More Works</h2>
              </div>
              <div class="animation-wrap to-right">
                  <a class="item" [routerLink]="['/', 'work', 'build-effective']">
                      <div class="project-img">
                          <img src="assets/images/project-images/project-thumbnail/build-effective.webp" alt="Build Effective">
                      </div>
                      <div class="project-slider-info">
                          <span>Real Estate</span>
                          <h2>Buildeffective</h2>
                      </div>
                  </a>
                  <a class="item" [routerLink]="['/', 'work', 'ar-workflow']">
                      <div class="project-img">
                          <img src="assets/images/project-images/project-thumbnail/ar-workflow.webp" alt="ARWorkflow">
                      </div>
                      <div class="project-slider-info">
                          <span>Accounting</span>
                          <h2>ARWorkflow</h2>
                      </div>
                  </a>
                  <a class="item" [routerLink]="['/', 'work', 'linkmybooks']">
                      <div class="project-img">
                          <img src="assets/images/project-images/project-thumbnail/link-my-books.webp" alt="LinkMyBooks">
                      </div>
                      <div class="project-slider-info">
                          <span>Business</span>
                          <h2>LinkMyBooks</h2>
                      </div>
                  </a>
                  <a class="item" [routerLink]="['/', 'work', 'ccalerts']">
                      <div class="project-img">
                          <img src="assets/images/project-images/project-thumbnail/ccalerts.webp" alt="CCAlerts">
                      </div>
                      <div class="project-slider-info">
                          <span>Finance</span>
                          <h2>CCAlerts</h2>
                      </div>
                  </a>
                  <a class="item" [routerLink]="['/', 'work', 'percentology']">
                      <div class="project-img">
                          <img src="assets/images/project-images/project-thumbnail/percentology.webp" alt="Percentology">
                      </div>
                      <div class="project-slider-info">
                          <span>Account</span>
                          <h2>Percentology</h2>
                      </div>
                  </a>
                  <a class="item" [routerLink]="['/', 'work', 'facturazen']">
                    <div class="project-img">
                        <img src="assets/images/project-images/project-thumbnail/facturazen.webp" alt="Facturazen">
                    </div>
                    <div class="project-slider-info">
                        <span>Business</span>
                        <h2>Facturazen</h2>
                    </div>
                  </a>
                  <ng-container *ngIf="false">
                      <a class="item" [routerLink]="['/', 'work', 'stomerijcollectief']">
                          <div class="project-img">
                              <img src="assets/images/project-images/project-thumbnail/stomerij-collectief.webp" alt="Stomerij Collectief">
                          </div>
                          <div class="project-slider-info">
                              <span>Business</span>
                              <h2>Stomerij Collectief</h2>
                          </div>
                      </a>
                      <a class="item" [routerLink]="['/', 'work', 'food-trucks']">
                          <div class="project-img">
                              <img src="assets/images/project-images/project-thumbnail/food-truck.webp" alt="Food Truck">
                          </div>
                          <div class="project-slider-info">
                              <span>Food</span>
                              <h2>FoodTruck</h2>
                          </div>
                      </a>
                      <a class="item" [routerLink]="['/', 'work', 'local-grocery']">
                          <div class="project-img">
                              <img src="assets/images/project-images/project-thumbnail/local-grocery.webp" alt="Local Grocery">
                          </div>
                          <div class="project-slider-info">
                              <span>Utility</span>
                              <h2>Local Grocery</h2>
                          </div>
                      </a>
                  </ng-container>
              </div>
          </div>
      </section>
  </div>
  <app-footer></app-footer>
</div>
