<app-header></app-header>
<app-header-menu *ngIf="isHeaderMenuOpen"></app-header-menu>
<div class="scroller">
  <div class="header-class"></div>
  <div class="page-grid-block">
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
  </div>
  <div class="grid-background-overlay"></div>
  <section class="blog-hero">
      <app-tech-logo></app-tech-logo>
      <div class="blog-hero-container">
          <div class="blog-hero-block">
              <div class="blog-hero-inner-block">
                  <div class="page-title">
                      <h1>Blogs</h1>
                  </div>
                  <div class="blog-info-detail page-info">
                      <p> In our official company blogs hear from experts in the industry about technology trends, technical information, e-commerce, social and digital world, designing aspects, updates on frameworks and different platforms and everything else in between. </p>
                  </div>
              </div>
          </div>
      </div>
  </section>

  <section class="blog-list-wrapper">
      <div class="blog-list-row">
            <div class="blog-list-col">
                <a [routerLink]="['testcases-in-angular-jest-for-local-storage-behavior-subject-with-get-methods']" class="blog-img-block">
                    <div class="blog-thumbnail">
                        <div class="blog-thumbnail-wrapper">
                            <div class="blog-img-inner-block">
                                <picture>
                                    <img src="assets/images/blog/testcases-angular.webp" alt="TestCases Angular">
                                </picture>
                            </div>
                        </div>
                        <h2>TestCases in Angular + Jest for Local Storage & Behavior Subject with get methods</h2>
                    </div>
                </a>
            </div> 
          <div class="blog-list-col">
              <a [routerLink]="['rails-6-api-fast_jsonapi-gem-with-devise-and-jwt-authentication']" class="blog-img-block">
                <div class="blog-thumbnail">
                    <div class="blog-thumbnail-wrapper">
                        <div class="blog-img-inner-block">
                            <picture>
                                <img src="assets/images/blog/rails-6-api.webp" alt="rails 6 api">
                            </picture>
                      </div>
                    </div>
                    <h2>Rails 6 API fast_jsonapi gem with Devise and JWT authentication</h2>
                </div>
              </a>
          </div>
          <div class="blog-list-col">
              <a [routerLink]="['use-of-rails-6-webpack-with-front-end-js-framework']" class="blog-img-block">
                <div class="blog-thumbnail">
                    <div class="blog-thumbnail-wrapper">
                        <div class="blog-img-inner-block">
                            <picture>
                                <img src="assets/images/blog/how-to-use-rails.webp" alt="how to use rails">
                            </picture>
                        </div>
                  </div>
                  <h2>How to use Rails 6 webpack with front-end JS framework</h2>
                </div>
              </a>
          </div>
          <div class="blog-list-col">
              <a [routerLink]="['internationalization-i18n-in-ruby-on-rails']" class="blog-img-block">
                <div class="blog-thumbnail">
                    <div class="blog-thumbnail-wrapper">
                        <div class="blog-img-inner-block">
                            <picture>
                                <img src="assets/images/blog/international-ror.webp" alt="international ror">
                        </picture>
                    </div>
                  </div>
                  <h2>Internationalization I18n in Ruby on Rails</h2>
                </div>
              </a>
          </div>
          <div class="blog-list-col">
              <a [routerLink]="['convert-html-to-pdf-in-codeigniter-using-dompdf']" class="blog-img-block">
                <div class="blog-thumbnail">
                    <div class="blog-thumbnail-wrapper">
                        <div class="blog-img-inner-block">
                            <picture>
                      <img src="assets/images/blog/convert-html-to-pdf.webp" alt="convert html to pdf">
                      </picture>
                  </div>
                  </div>
                  <h2>Convert HTML to PDF in CodeIgniter using Dompdf</h2>
                </div>
              </a>
          </div>
          <div class="blog-list-col">
              <a [routerLink]="['importance-of-agile-methodology-in-automation-testing']" class="blog-img-block">
                <div class="blog-thumbnail">
                    <div class="blog-thumbnail-wrapper">
                        <div class="blog-img-inner-block">
                            <picture>
                      <img src="assets/images/blog/importance-of-agile-methodology.webp" alt="importance of agile methodology">
                      </picture>
                      </div>
                  </div>
                  <h2>Importance of Agile Methodology in Automation Testing</h2>
                  </div>
              </a>
          </div>
          <div class="blog-list-col">
              <a [routerLink]="['14-reasons-of-why-ruby-on-rails-community-chooses-postgresql-over-mysql']" class="blog-img-block">
                <div class="blog-thumbnail">
                    <div class="blog-thumbnail-wrapper">
                        <div class="blog-img-inner-block">
                            <picture>
                      <img src="assets/images/blog/14-reasons-of-ruby.webp" alt="14 reasons of ruby">
                      </picture>
                      </div>
                  </div>
                  <h2>14 Reasons of Why Ruby on Rails Community Chooses PostgreSQL Over MySQL</h2>
                </div>
              </a>
          </div>
          <div class="blog-list-col">
              <a [routerLink]="['how-to-create-gradient-background-in-android']" class="blog-img-block">
                <div class="blog-thumbnail">
                    <div class="blog-thumbnail-wrapper">
                        <div class="blog-img-inner-block">
                            <picture>
                      <img src="assets/images/blog/how-to-create-gradient-android.webp" alt="how to create gradient android">
                      </picture>
                      </div>
                  </div>
                  <h2>How to Create Gradient Background in Android</h2>
                  </div>
              </a>
          </div>
          <div class="blog-list-col">
              <a [routerLink]="['top-5-reasons-for-your-slow-wordpress-website']" class="blog-img-block">
                <div class="blog-thumbnail">
                    <div class="blog-thumbnail-wrapper">
                        <div class="blog-img-inner-block">
                            <picture>
                      <img src="assets/images/blog/5-reasons-slow-wordpress.webp" alt="5 reasons slow wordpress">
                      </picture>
                      </div>
                  </div>
                  <h2>Top 5 Reasons For Your Slow WordPress Website: Know How to Fix It</h2>
                  </div>
              </a>
          </div>
          <div class="blog-list-col">
              <a [routerLink]="['content-vs-design-7-points-to-consider-by-web-designer-before-creating-a-website']" class="blog-img-block">
                <div class="blog-thumbnail">
                    <div class="blog-thumbnail-wrapper">
                        <div class="blog-img-inner-block">
                            <picture>
                                <img src="assets/images/blog/content-vs-design.webp" alt="content vs design">
                            </picture>
                      </div>
                    </div>
                  <h2>Content Vs Design: 7 points to consider by web designer before creating a website</h2>
                </div>
              </a>
          </div>
      </div>
  </section>
  <app-footer></app-footer>
</div>
