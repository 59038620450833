import { Component, Input, Output, EventEmitter } from '@angular/core';
import gsap from 'gsap';
import { sharedService } from '../shared/shared.service';
import SplitType from 'split-type';

@Component({
  selector: 'app-industries-preview',
  templateUrl: './industries-preview.component.html',
  styleUrls: ['./industries-preview.component.scss']
})
export class IndustriesPreviewComponent {
  @Input() selectedIndustry: any;
  @Output() closeDOM = new EventEmitter<boolean>();
  constructor(public sharedservice: sharedService) {
      setTimeout(() => {
        new SplitType('#industryTitle').revert();
        gsap.to('.industires-item-preview-block', {height: '100%', ease: 'power1', duration: 0.25,});
        gsap.to('.close-artical', {duration: 0.25, visibility: 'visible', opacity: 1, ease: 'power1', delay: 0.25});
        gsap.to('.ind-preview-imgblock', {duration: 1, y: '0%', rotationX: 0, ease: 'expo.inOut',});
        gsap.to('.ind-preview-imgInner', {duration: 0.5, y: '0%', ease: 'expo.inOut' , delay: 0.25});
        gsap.to('body', {overflow: 'hidden'});
        gsap.to('.industires-info', {opacity: 1, y: 0, ease: 'power1', delay: 0.25});
        const elem = document.getElementById('industryTitle');
        if (elem) {
          elem.innerHTML = (this.selectedIndustry.title || '');
          const industriesTitle = new SplitType('#industryTitle', {
              types: 'chars words',
              absolute: true
          });
          gsap.to(industriesTitle.chars, {opacity: 1, y: 0, duration: 0.5, delay: 0.25, stagger: 0.05});
        }
      }, 0);
   }

   public closeArtical(): void {
    gsap.to('.ind-preview-imgblock', {duration: 1, y: '100%', rotationX: -20,  ease: 'expo.inOut'});
    gsap.to('.ind-preview-imgInner', {duration: 1, y: '-100%', ease: 'expo.inOut'});
    gsap.to('.close-artical', {duration: 0.5, opacity: 0, visibility: 'hidden', ease: 'power1'});
    gsap.to('.industires-item-preview-block', {height: 0, ease: 'power1', duration: 0.5, delay: 1});
    gsap.to('body', {overflow: 'visible'});
    gsap.to('.industires-info', {opacity: 0, y: 30, ease: 'power1'});
    const elem = document.getElementById('industryTitle');
    if (elem && this.selectedIndustry) {
      const nodes: any = [];
      elem.childNodes.forEach(node => {
        if (node.nodeName !== '#text') {
          nodes.push(node);
        }
      });
      console.log(nodes);
      gsap.to(nodes, {opacity: 0, y: -30, duration: 0.5, stagger: 0.1});
    }
    setTimeout(() => {
      this.closeDOM.emit(true);
        this.selectedIndustry = null;
    }, 1500);
    gsap.config({
      nullTargetWarn: false,
    });
    document.body.classList.remove('industry-preview-open');
  }
}
