import { AfterViewInit, Component, OnInit } from '@angular/core';
import SplitType from 'split-type';
import gsap from 'gsap/all';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { sharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-industries',
  templateUrl: './industries.component.html',
  styleUrls: ['./industries.component.scss']
})
export class IndustriesComponent implements AfterViewInit {
  public industrySolution = [
    {title: 'Media and Entertainment',
      description: '<p>This is one industry that everyone relates to. Obviously different persons have different choices as content for media and entertainment. With technological advancement like high internet speed, automation and shift in working patterns, the supply and demand of content has multiplied. This is a highly consumer driven industry and so access to media and entertainment is comparatively affordable to almost all economy classes. We have developed various systems where content can be processed for different languages, devices supplied by Amatures and Professionals for the purpose of entertainment, study and documentation. Web, Mobile, Tablets and Televisions are the devices our solutions are focused on. </p>',
      imgUrl: 'assets/images/idustries/Media-entertainment.webp'},
    {title: 'Real Estate',
      description: '<p>With different Real Estate properties like Land, Residential, Commercial, Industrial , Public and Infrastructure. </p><h3>There are basic 5 phases for such properties </h3><ul><li>Acquisition / Sales</li><li>Planning and Permit </li><li>Construction / Development </li><li>Maintenance </li><li>Demolition </li></ul><p>In any of the real estate project, many people are involved, performing different roles in different processes and phases. Making optimum use of time and material will have great impact on success of such projects. Additionally, managing various information and conveying it to right person is crucial for decision making. We have developed efficient systems like <span>BuildEffective.com</span> to manage tasks, generating estimations and reports to make the work more manageable and eliminate stress. Makes life easier for the users.</p>',
      imgUrl: 'assets/images/idustries/Real-estate.webp'},
    {title: 'Healthcare',
      description: '<p>Healthcare industry comprises of following information and processing of that information </p><ul><li>Research work for developing drugs </li><li>Medical records </li><li>Support services like Insurance plans, Hospital processes </li></ul><p>Some of these information should be public and maintained frequently while other must by kept very private and secured in the interest of the public only. </p><p>Keeping information public or private but relevant needs expert processes and high standards. We have taken lead role in developing such systems to produce, process and secure the data to make things easier for the users.</p>',
      imgUrl: 'assets/images/idustries/Healthcare.webp'},
    {title: 'Food',
      description: '<h3>We have developed different solutions for</h3><ul><li>Food ordering system </li><li>Restaurant Inventory Management system </li><li>Restaurant ordering system </li></ul><p>With these systems a foodtruck owners and a Restaurant can set up their business page with menu and customization and ordering system and their availability on a specific spot. Real time searching over the map and availability of seats used in innovative way provide new dimension to the customers and businesses both. We have managed full-featured Vendor Information System including real time order and payment processing with chat option to communicate with customer. With the solution of Table management and Reservation system, we have given comfort of order placing and payment over the mobile app. The data generated with these systems analyzed and immensely insightful for decision making for the management. </p>',
      imgUrl: 'assets/images/idustries/Food.webp'},
    {title: 'E-Commerce',
      description: '<p>E-Commerce can be largely define as any purchase or sale of goods and services that can be done without the restriction of place and time. So unlike, opening and closing time or Business hours, business is open for 24/7 on internet. There are many E-commerce variations but majorly </p><ul><li>Amazon / eBay / BestBuy etc. are E-commerce Market place</li><li>Business can also have their own web site for selling the products and services </li><li>Mobile applications on App Store </li></ul><p>Product catalogue, Service catalogue, Product searching, Payment methods, Order management, Shipping management, Ratings and Reviews, Coupon codes, Vendor management and Payout are some of the important modules for E-commerce system.</p>',
      imgUrl: 'assets/images/idustries/E-Commerce.webp'},
    {title: 'E-Learning and Education',
      description: '<p>With changes in working culture and specially in Post-Covid era, people are focusing more on skill upgradation and participating in activities related to their passion. Working remotely also opens the adoptability of learning remotely too. Technical Meet ups, Webinars, Meetings are organized over internet. For Exercises, Yoga, Singing, Dancing and even Stand-up comedy also, people are using these Online Meeting Platforms. Top universities are offering Off-campus programs for higher education. Schools are using the solution from Mobile application for over a year for teaching students as a routine. With WebRTC, we have developed platforms to communicate using Audio, Video, Screen sharing, Recording etc. Features supporting Desktop and Mobile devices </p>',
      imgUrl: 'assets/images/idustries/Education.webp'},
    {title: 'Lead Generation and Marketing',
      description: '<p>For Lead Generation activity, every data collected is useful. We have worked with the team consists of Data Scientists, Digital Marketing experts, Call Centers, Dialer Systems, Publishers and Advertisement agencies to create leads for Online Education, Health plans, Craigslist and eBay vendors. Our solution is capable to receive hundred thousand leads everyday from different channels, process them, forward to appropriate channels, generating reports and also used by more then 500 agents on daily bases. We reduced the CPL over the period of 3 months by 90% as well as lead reaction time to few seconds. We have recently developed the solution for <span>3zonemedia.com</span>.</p>',
      imgUrl: 'assets/images/idustries/Marketing.webp'},
    {title: 'Travel and Tourism',
      description: '<h3>Solutions for Travel and Tourism includes following </h3><ul><li>Travelling Ticket Booking like Bus, Flight, Ship </li><li>Transportation like Taxi, Bus, Boat etc. </li><li>Accommodation at Hotel, Resort, BnB </li><li>Food </li><li>Travelling Gears </li><li>Insurance plans </li><li>Performing activities and Site seeing </li></ul><p>The purpose of tourism can be more then just travel, fun, relaxation and discover. It can be for better health condition or for visiting pilgrimages. There are great possibilities for developing solution for this.</p>',
      imgUrl: 'assets/images/idustries/Travel.webp'},
    {title: 'Auto Mobile',
      description: '<h3>There can be different solutions built out there for Aotomobile inductry like </h3><ul><li>Systems for Manufacturing plant</li><li>ERP system for Logistic, Sales and Services</li><li>Solutions installed within the vehicle</li><li>Platforms for Buying and Selling Vehicles</li></ul><p>We are working with Largest Automobile company from Germany to develop a solution for producing Car maintenance report for different systems(Mechanical and Electronic) installed in the car. We are using some of the frameworks used in a very different manner and producing wonderful results in that solution.</p>',
      imgUrl: 'assets/images/idustries/Automobile.webp'},
    {title: 'Accounting',
      description: '<p>We have developed accounting based systems to provide the state of the businesses at any given time. We have also automated the business processes by letting businesses introducing workflows as well as manage accounting. Systems like <span>ARWorkflow.com</span> and <span>Percentology.com</span> are 2 systems we have developed for managing business processes and generate reports for the state of business including comparison with industry standards too. We have introduced scientific approaches to</p><ul><li>Evaluation of the business activities </li><li>Automated collection activities </li><li>Payment processing </li></ul><p>to have better alignment within different departments and with client.</p><h3>Our solutions also helped businesses for</h3><ul><li>Cost reduction</li><li>Higher asset utilization</li><li>New areas for higher revenue generation</li></ul>',
      imgUrl: 'assets/images/idustries/Accounting.webp'},
    {title: 'Talent Acquisition',
      description: '<p>The solutions we developed in Talent acquisition process for Small and Medium IT companies, gave them great clarity on the requirements, interview pipeline, comparison between candidates, detailed skill assessment, setting up and automate the customized recruitment process workflow. With highly interactive screens, resume scanning tools, and other tools to perform recruitment process effectively, we have raised the ratio of joining from 20% to 70% and increasing the concurrency of candidates for a specific Job Profile to 4 times with lesser stress for Recruitment team for those IT companies. We have also developed solutions for <span>Talowiz.com</span> and <span>Talscale.com</span></p>',
      imgUrl: 'assets/images/idustries/Talent-Acquistion.webp'},
    {title: 'Finance and Investments',
      description: '<p>We have developed a solution <span>CCAlerts.com</span> for individuals to take care of their liabilities and provide analysis of their pattern of liabilities and expenditure to suggest better investment options. Interactive reports, illustrations based on use cases and printable reports. This brings improvement in financial position of the individuals and strengthen the overall financial stability of the family and more. With Web and Mobile devices, this solutions is reachable to all sorts of audience being at Desk or Mobile. </p>',
      imgUrl: 'assets/images/idustries/Finance-Investments.webp'},
  ];
  public selectedIndustry: any = null;

  constructor(public sharedService: sharedService) { }

  public ngAfterViewInit(): void {
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.create ({
      trigger: '.industries-wrapper',
      start: 'top 50%',
      end: 'bottom 0%',
      toggleClass: {targets: ".work-primary-img-block h4", className: "active"},
      onEnter: () => {
        // gsap.to('.industries-title-desc h2', {duration: 1.0, color: '#282828'})
        // gsap.to('.industries-title-desc p', {duration: 1.0, color: '#282828'})
        gsap.to('.work-primary-img-block h4',{duration: 1.0, color: '#282828'})
        // gsap.to('.hero-bg',{duration: 1.0, background: '#ffffff'})
        // gsap.to('.hero-overlay',{duration: 1, background: '#141414'})
        gsap.to('.serviceList ul li', {duration: 1, color: '#ffffff' })
        gsap.to('.process-detail ul li *', {color: '#000000'})
        gsap.to('.process-circle ul li .point-icon-inner', { background: '#000000'})
        gsap.to('.process-circle ul li .point-icon-inner i', {color: '#000000'})
      },
      onLeaveBack: () => {
        // gsap.to('.industries-title-desc h2', {duration: 1.0, color: '#ffffff'})
        // gsap.to('.industries-title-desc p', {duration: 1.0, color: '#ffffff'})
        gsap.to('.work-primary-img-block h4', {duration: 1.0, color: '#ffffff'})
        // gsap.to('.hero-bg',{duration: 1.0, background: '#141414'})
        // gsap.to('.hero-overlay',{duration: 1, background: '#ffffff'})
        gsap.to('.serviceList ul li', {duration: 1, color: '#282828' })
        gsap.to('.process-detail ul li *', {color: '#ffffff'})
        gsap.to('.process-circle ul li .point-icon-inner', { background: '#ffffff'})
        gsap.to('.process-circle ul li .point-icon-inner i', {color: '#000000'})
      }
    })
    const listones = gsap.utils.toArray('.industries-listing');
    const tl = gsap.timeline({scrollTrigger: {
      trigger: '.industries-listing-row',
      start: 'top 70%',
      end: 'bottom top',
    }}).fromTo(listones, {opacity: 0, y: 50, stagger: 0.1, duration: 0.5, ease: 'power1'},{opacity: 1, y: 0, stagger: 0.1, duration: 0.5, ease: 'power1'}, 0);
    gsap.config({
      nullTargetWarn: false,
    });
    const blockTitle: any = gsap.utils.toArray('.block-title');
    gsap.set(blockTitle, {autoAlpha: 0, y: 50});
    blockTitle.forEach((titleanimBlock: any) => {
        const titleAnim = gsap.to(titleanimBlock, { duration: 1, autoAlpha: 1, y: 0, paused: true });
        ScrollTrigger.create({
            trigger: titleanimBlock,
            start: 'top 80%',
            once: true,
            onEnter: self => {
                self.progress === 1 ? titleAnim.progress(1) : titleAnim.play();
            }
        });
    });
  }

  public enlargeIndustry(event: any, article: any): void {
    document.body.classList.add('industry-preview-open');
    this.sharedService.indusPreview(article);
  }
}
