<app-header></app-header>
<app-header-menu *ngIf="isHeaderMenuOpen"></app-header-menu>
<div class="scroller">
  <div class="header-class"></div>
  <div class="page-grid-block">
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
  </div>
  <div class="main-service-wrapper">
      <div class="background-overlay bg-services"></div>
      <section class="service-in-hero">
          <app-tech-logo></app-tech-logo>
          <div class="service-in-hero-container">
              <div class="service-in-hero-block">
                  <div class="service-in-deail-block">
                      <div class="page-title">
                          <!-- <h1>Web Design Company</h1> -->
                          <h1>Creative and Functional Design </h1>
                      </div>
                      <div class="service-in-info-detail page-info">
                          <!-- <p>Techcompose is a best web design company in India and the USA that offers offshore custom e-commerce website and mobile app design services. Contact us for a free quote as Our expert designer team is well skilled with online tools like Zeplin, InVision and many more. We offer custom web design services like WordPress theme design, logo & corporate Identity designs, graphic design & brochure design, custom website design etc.</p> -->
                          <ul>
                              <li>Appealing to people</li>
                              <li>Impressive – Creatively and Technically</li>
                              <li>Pixel perfect and open to expand</li>
                              <li>User experience and Wireframing</li>
                              <li>Self-explaining Designs</li>
                              <li>Clear objective for Viewers</li>
                              <li>Easy to read and understand</li>
                              <li>Modern tools and technologies</li>
                              <li>Transmit Concept/Vision to working design</li>
                              <li>Expertise in Design Processes</li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      <section class="philosophie">
          <div class="wrapper">
              <div class="point point-1">
                  <article>
                      <div class="article-inner">
                          <h3>Creative Designs</h3>
                          <div class="article-desc-block">
                              <p>With more then 10 years of experience in designing and developing solutions, we have developed a process to evaluating the requirements for generating creative and functional designs. When we say creative design, it definitely comes from heart but we also consider the sensibleness in all the aspects like appeal to the viewers, easy to remember, adding to brand value, generating functional value and technically an exemplary work. We have developed Logo, Cover pages, Catalogues, Brochures, Web sites, Web Applications, Mobile Apps and Social Media Posts. </p>
                          </div>
                      </div>
                  </article>
                  <div class="services-img-block">
                      <img src="assets/images/services/sub-page/creative-design.webp" alt="Creative Designs" width="1680" height="1050">
                  </div>
              </div>
              <div class="point point-2">
                  <article>
                      <div class="article-inner">
                          <h3>Fonts, Colors</h3>
                          <div class="article-desc-block">
                              <p>Fonts, Colors, Effects and Animations are at core of creative designs. With our detailed understanding on Fonts and Colors we are producing various variations of creative design at any stage. We are open for suggestions on our creative designs and we touch the right aspect of the feedback to make changes in the direction required. At the same time, we are confident also to defend our creative designs if needed. </p>
                          </div>
                      </div>
                  </article>
                  <div class="services-img-block">
                      <img src="assets/images/services/sub-page/fonts.webp" alt="Fonts, Colors" width="1920" height="1275">
                  </div>
              </div>
              <div class="point point-3">
                  <article>
                      <div class="article-inner">
                          <h3>Functional Designs</h3>
                          <div class="article-desc-block">
                              <p>Appealing designs means creatively attractive and functionally productive. The user interface should be very natural and solving the purpose. User should quickly connect with it without additional guidance. As we are developing data centric applications for Desktop and Mobile users, purpose of the screen, flow of reading, language of information, importance of levels of information and persona of the viewer are the aspects where we are fully aware of while creating designs.</p>
                          </div>
                      </div>
                  </article>
                  <div class="services-img-block">
                      <img src="assets/images/services/sub-page/functional.webp" alt="Functional Designs" width="1920" height="1275">
                  </div>
              </div>
              <div class="point point-4">
                  <article>
                      <div class="article-inner">
                          <h3>Animations and Effects </h3>
                          <div class="article-desc-block">
                              <p>With right animation and effects, we add the WOW element in the user experience for the screens we are developing. It give more power to us as a product development team to educate user without additional instructions and communicates with the viewer for the past and future interactions within the application.</p>
                          </div>
                      </div>
                  </article>
                  <div class="services-img-block">
                      <img src="assets/images/services/sub-page/animation.webp" alt="Animations and Effects" width="1920" height="1275">
                  </div>
              </div>
              <div class="point point-5">
                  <article>
                      <div class="article-inner">
                          <h3>Tools</h3>
                          <div class="article-desc-block">
                              <p>We are using the designing tools like Figma, AdobeXD, InDesign, Illustrator, After Effects, JavaScript, CSS to create designs, animations, effects, UI & UX. </p>
                          </div>
                      </div>
                  </article>
                  <div class="services-img-block">
                      <img src="assets/images/services/sub-page/tools.webp" alt="Tools" width="1920" height="1275">
                  </div>
              </div>
          </div>
      </section>
  </div>
  <app-footer></app-footer>
</div>
