import { AfterViewInit, Component, OnInit } from '@angular/core';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements AfterViewInit {

  constructor() { }

  public ngAfterViewInit(): void {
    gsap.registerPlugin(ScrollTrigger);
    // ScrollTrigger.create({
    //   trigger: '.philosophie',
    //   // markers:true,
    //   start:"top 50%",
    //   end:"bottom 0%",
    //   onEnter: () => {
    //     gsap.to('.bg-services', { duration: 1, backgroundColor: '#141414'})
    //     gsap.to('.service-in-deail-block h1',{color: '#ffffff'})
    //     gsap.to('.service-in-deail-block p',{color: '#ffffff'})
    //     gsap.to('.article-inner h3, .article-inner ul li, .article-inner p, .article-inner a', {color: '#ffffff'})
    //   },
    //   onLeaveBack: () => {
    //     gsap.to('.bg-services', { duration: 1.0, backgroundColor: '#ffffff'})
    //     gsap.to('.service-in-deail-block h1',{color: '#282828'})
    //     gsap.to('.service-in-deail-block p',{color: '#282828'})
    //     gsap.to('.article-inner h3, .article-inner ul li, .article-inner p, .article-inner a', {color: '#282828'})
    //   },
    // });
  }

}
