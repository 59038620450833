import { AfterViewInit, Component, OnInit } from '@angular/core';

import gsap from "gsap";

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements AfterViewInit {

  constructor() { }

  ngAfterViewInit(): void {
    gsap.to(".grid-col-1", {duration: 1,scale: 1, ease: 'power1', delay: 0.2, repeat:-1, yoyo:true});
    gsap.to(".grid-col-2", {duration: 1,scale: 1, ease: 'power1', delay: 0.25, repeat:-1, yoyo:true});
    gsap.to(".grid-col-3", {duration: 1,scale: 1, ease: 'power1', delay: 0.3, repeat:-1, yoyo:true});
    gsap.to(".grid-col-4", {duration: 1,scale: 1, ease: 'power1', delay: 0.35, repeat:-1, yoyo:true});
    gsap.to(".grid-col-5", {duration: 1,scale: 1, ease: 'power1', delay: 0.4, repeat:-1, yoyo:true});
    gsap.to(".grid-col-6", {duration: 1,scale: 1, ease: 'power1', delay: 0.45, repeat:-1, yoyo:true});
    gsap.to(".grid-col-7", {duration: 1,scale: 1, ease: 'power1', delay: 0.5, repeat:-1, yoyo:true});
    gsap.to(".grid-col-8", {duration: 1,scale: 1, ease: 'power1', delay: 0.55, repeat:-1, yoyo:true});
    gsap.to(".grid-col-9", {duration: 1,scale: 1, ease: 'power1', delay: 0.6, repeat:-1, yoyo:true});
    gsap.to(".grid-col-10", {duration: 1,scale: 1, ease: 'power1', delay: 0.65, repeat:-1, yoyo:true});
    gsap.to(".grid-col-11", {duration: 1,scale: 1, ease: 'power1', delay: 0.7, repeat:-1, yoyo:true});

    // var tl = new TimelineMax();
    // tl.staggerFromTo('.box', 0.5, {scale:1}, {scale:1.1, repeat:-1, yoyo:true}, 0.1);

    gsap.fromTo(".loader-overlay", {
      height: "0%",
    },{
      height: "100%",duration: 0.5, ease: 'power1',delay: 0.1
    });

  }
}
