import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import gsap from 'gsap';
import { ApiService } from '../api.service';
import { sharedService } from '../shared/shared.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-project-drawer',
  templateUrl: './project-drawer.component.html',
  styleUrls: ['./project-drawer.component.scss']
})
export class ProjectDrawerComponent implements OnInit{
  @Output() closeDOM = new EventEmitter<boolean>();
  /**
     * startProject form
     */
  public startProjectForm!: FormGroup;
  public technologisArray: any[] = [];
  constructor(public sharedservice: sharedService,
    private apiService: ApiService,
    private fb: FormBuilder,
    private db: AngularFirestore
    ) {
    setTimeout(() => {
      gsap.to('.start-project-drawer', {autoAlpha: 1, ease: 'linear', duration: 0.15, });
      gsap.to('.start-project-box', {x: '0', autoAlpha: 1, ease: 'linear', duration: 0.15, delay: 0.3});
      gsap.to('.start-project-overlay', {autoAlpha: 1, ease: 'linear', duration: 0.5, delay: 0.5});
      gsap.to('body', {overflow: 'hidden'});
    }, 0);
    this.technologisArray = [
      {'name': 'Web Application Development', 'isSelected': false},
      {'name': 'Website Development', 'isSelected': false},
      {'name': 'Web/Graphics Design', 'isSelected': false},
      {'name': 'Mobile Application Development', 'isSelected': false},
      {'name': 'Digital Marketing', 'isSelected': false},
      {'name': 'Offshore Development Center', 'isSelected': false}
    ];
  }

  public ngOnInit(): void {
    let tech = this.technologisArray.map(x => {
      return this.fb.group({
         name: x.name,
         isSelected: x.isSelected
      });
   });
    this.startProjectForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', { validators: [Validators.required,
        Validators.pattern('^[A-Za-z0-9._+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$')]}],
      technologies: this.fb.array(tech),
      phone_number: [''],
      company: [''],
      message: ['', Validators.required],
    })
  }

  get userFormGroups (): FormArray {
    return this.startProjectForm.get('technologies') as FormArray;
  }

  public closeProjectDrawer(): void {
    setTimeout(() => {
      this.closeDOM.emit(true);
    }, 500);
    gsap.to('.start-project-drawer', {autoAlpha: 0, ease: 'linear', duration: 0.15, delay : 0.3});
    gsap.to('.start-project-box', {x: '100%', autoAlpha: 0, ease: 'linear', duration: 0.15, delay: 0.15});
    gsap.to('.start-project-overlay', {autoAlpha: 0, ease: 'linear', duration: 0.15, delay : 0.3});
    gsap.to('body', {overflow: 'visible'});
  }

  public submit(): void {
    const selected: Array<object> = this.startProjectForm.value.technologies.filter((tech: any) => tech.isSelected);
    const list: Array<string> = selected.map((tch: any) => tch.name);
    this.startProjectForm.value.technologies = list.join(', ');

    const emailBody: any = `
    Project Enquiry:\n\n
    
    Name: ${this.startProjectForm?.value?.name}\n
    Email: ${this.startProjectForm?.value?.email}\n
    Phone Number: ${this.startProjectForm?.value?.phone_number}\n
    Company: ${this.startProjectForm?.value?.company}\n
    Technologies: ${this.startProjectForm.value.technologies}\n
    Message: ${this.startProjectForm?.value?.message}\n \n

    Thank you.
    `;

    const emailBodyHTML: any = `
    Project Enquiry:<br><br>

    Name: ${this.startProjectForm?.value?.name}<br>
    Email: ${this.startProjectForm?.value?.email}<br> 
    Phone Number: ${this.startProjectForm?.value?.phone_number}<br>
    Company: ${this.startProjectForm?.value?.company}<br>
    Technologies: ${this.startProjectForm?.value.technologies}<br>
    Message: ${this.startProjectForm?.value?.message}<br><br>

    Thank you.
    `;

    const emailParams: any = {
      to: ["dharinrajgor@techcompose.com", "urjitrajgor@techcompose.com"],
      message: {
        subject: `Enquiry received from ${this.startProjectForm?.value?.name}`,
        text: emailBody,
        html: emailBodyHTML,
      },
    };
 
    this.db.collection('mail').add(emailParams)
    .then((res: any) => {
        this.db.collection('project_enquiry').add(this.startProjectForm?.value)
        .then((res: any) => {
            this.startProjectForm.reset();
            this.closeProjectDrawer();
        })
        .catch((error: any) => {
          this.startProjectForm.reset();
          this.closeProjectDrawer();
          console.error('Error adding data: ', error);
        });
    })
    .catch((error: any) => {
      console.error('Error adding data: ', error);
    });
  }
}
