import {
  Component,
  AfterViewInit,
  Renderer2,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import gsap from 'gsap';
import * as $ from 'jquery';
import { sharedService } from '../shared/shared.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  private listeners: any[] = [];

  public isHeaderMenuOpen: boolean = false;

  constructor(
    private renderer: Renderer2,
    private sharedService: sharedService
  ) {}

  public ngOnInit(): void {
    this.sharedService.showHeaderMenu$.subscribe((data) => {
      this.isHeaderMenuOpen = data;
    });
  }

  public toggleMenu(): void {
    if (!this.isHeaderMenuOpen) {
      this.isHeaderMenuOpen = !this.isHeaderMenuOpen;
      this.sharedService._showHeaderMenu.next(this.isHeaderMenuOpen);
      gsap.fromTo('.toggleIconfirst', {rotation: 0, top: 0}, {rotation: -45, top: 7, duration: 0.1})
      gsap.fromTo('.toggleIconSec', {opacity: 1}, {opacity: 0, duration: 0.1})
      gsap.fromTo('.toggleIconThird', {rotation: 0, top: 0}, {rotation: 45, top: -7, duration: 0.1})
      gsap.fromTo('.header', {backgroundColor: 'rgba(0, 0, 0, 0.7)'},{backgroundColor: 'transparent', duration: 0})
    } else {
      gsap.fromTo('.techMenu', {y: 0},{y: '-100%', delay: 1,});
      gsap.fromTo('.toggleIconfirst', {rotation: -45, top: 7, duration: 0.1}, {rotation: 0, top: 0})
      gsap.fromTo('.toggleIconSec', {opacity: 0, duration: 0.1}, {opacity: 1})
      gsap.fromTo('.toggleIconThird', {rotation: 45, top: -7, duration: 0.1}, {rotation: 0, top: 0})
      gsap.to('.MenuItem', {duration: 0.2, delay: 0.5, y: 50, opacity: 0, visibility: 'visible', ease: 'power1', stagger: 0.05})
      gsap.fromTo('.header', {backgroundColor: 'transparent'},{backgroundColor: 'rgba(0, 0, 0, 0.7)', duration: 0, delay: 2,})
      setTimeout(() => {
        this.isHeaderMenuOpen = !this.isHeaderMenuOpen;
        this.sharedService._showHeaderMenu.next(this.isHeaderMenuOpen);
      }, 1500);
    }
  }

  public ngAfterViewInit(): void {
    gsap.to('.header', {
      delay: 0.1,
      duration: 0.5,
      y: '0%',
      opacity: 1,
      ease: 'linear',
    });
    // let listener: any = this.renderer.listen(
    //     window,
    //     'resize',
    //     () => {
    //       this.resize();
    // });
    // this.listeners.push(listener);
    // this.resize();

    // const menu = gsap.timeline({paused: true, reversed: true});

    // menu
    // .fromTo( '.techMenu' , {y: '-100%'}, {duration: 0.2, ease: 'power1', y: 0})
    // .fromTo('.toggleIconfirst', {rotation: 0, top: 0}, {rotation: -45, top: 7, duration: 0.1}, '-=0.5')
    // .fromTo('.toggleIconSec', {opacity: 1}, {opacity: 0, duration: 0.1}, '-=0.5')
    // .fromTo('.toggleIconThird', {rotation: 0, top: 0}, {rotation: 45, top: -7, duration: 0.1}, '-=0.5')
    // .to( '.MenuItem', {duration: 0.3, y: 0, opacity: 1, visibility: 'visible', ease: 'power1', stagger: 0.025})
    // .fromTo('body', {overflow: 'visible'},{overflow: 'hidden'})

    // listener = this.renderer.listen(
    //     document.querySelector('.MenutoggleButton'),
    //     'click',
    //     () => {
    //       menu.reversed() ? menu.play() : menu.reverse();
    //       subMobileMenu.reverse();
    // });
    // this.listeners.push(listener);

    // document.querySelectorAll('.MenuItem a').forEach((e) => {
    //     listener = this.renderer.listen(
    //         e,
    //         'click',
    //         () => {
    //             menu.reversed() ? menu.play() : menu.reverse();
    //             subMobileMenu.reverse();
    //     });
    //     this.listeners.push(listener);
    // });

    // const subMobileMenu = gsap.timeline({paused: true, reversed: true});
    // subMobileMenu
    //     .fromTo('.submenu', {height: '0'}, {height: 'auto', duration: 0.25, autoAlpha: 1})
    //     .fromTo('.submenu > li', {y: '50%', opacity: 0},
    //       {delay: 0.5, y: '0', duration: 0.1, opacity: 1, ease: Sine.easeInOut, stagger: 0.025})
    //     .fromTo('.submenuBtn i', {rotation: -90}, {rotation: 0, duration: 0.5}, '-=1');

    // listener = this.renderer.listen(
    //     document.querySelector('.submenuBtn'),
    //     'click',
    //     () => {
    //       subMobileMenu.reversed() ? subMobileMenu.play() : subMobileMenu.reverse();
    // });
    // this.listeners.push(listener);

    // // yellow circle
    // const cursor = $('.cursor');
    // const follower = $('.cursor-follower');
    // const mouseInElms = document.querySelectorAll('.mouseIn');
    // mouseInElms.forEach(elm => {
    //   listener = this.renderer.listen(
    //     elm,
    //     'mouseenter',
    //     (e) => {
    //       cursor.addClass('active');
    //       follower.addClass('active');
    //   });
    //   this.listeners.push(listener);
    //   listener = this.renderer.listen(
    //     elm,
    //     'mouseleave',
    //     (e) => {
    //       cursor.removeClass('active');
    //       follower.removeClass('active');
    //   });
    //   this.listeners.push(listener);
    // });
    // gsap.config({
    //   nullTargetWarn: false,
    // });
  }

  // public resize(): void {
  //     if (window.matchMedia('(min-width: 991px)').matches) {
  //         // The view port is at least 991 pixels wide
  //         //  console.log('Desktop');
  //         $('.MenuListBlock > li.MenuItem').each((i, el) => {
  //             const tl = gsap.timeline({
  //               paused: true
  //             });
  //             tl.from($(el).find('.submenu'), {
  //                   autoAlpha: 0,
  //                   duration: 0.25
  //               }, 'Sametime')
  //               .from($(el).find('.submenu > li'), {
  //                   y: '50%',
  //                   opacity: 0,
  //                   duration: 0.25,
  //                   stagger: 0.05,
  //                   ease: Sine.easeInOut
  //               });

  //             const animation = tl;
  //             $(el).hover(() => {
  //                 animation.play();
  //             }, () => {
  //                 animation.reverse();
  //             });
  //         });
  //     } else {
  //       // The view port is less than 991 pixels wide
  //       // console.log('mobile');
  //     }
  // }

  public ngOnDestroy(): void {
    this.listeners.forEach((listener) => listener());
  }
}
