<section class="tech-services-wrapper panel">
    <div class="tech-services-slider-box">
        <div class="service-info-box">
            <div class="service-info-box-inner">
                <h3>
                    <span>Your business goals.</span>
                    <span>Our services.</span>
                    <span>Right solutions.</span>
                </h3>
                <div class="clearfix">
                    <a [routerLink]="['it-services']" class="morebtn service-more-btn">
                        Our Services
                        <i class="link-icon">
                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.5687 7.92313C14.3312 7.68545 13.946 7.68545 13.7081 7.92313C13.4704 8.16062 13.4704 8.5461 13.7081 8.78378L18.3174 13.3931H6.0705C5.73339 13.3931 5.45996 13.6666 5.45996 14.0037C5.45996 14.341 5.73339 14.6142 6.0705 14.6142H18.3113L13.702 19.2236C13.5876 19.3375 13.5234 19.4926 13.5234 19.654C13.5234 19.8156 13.5876 19.9703 13.702 20.0844C13.8163 20.1997 13.9729 20.2636 14.1354 20.2615C14.2974 20.2607 14.4529 20.1972 14.5687 20.0844L20.216 14.4373C20.3309 14.3222 20.395 14.166 20.3932 14.0037C20.3921 13.8417 20.3286 13.6868 20.216 13.5704L14.5687 7.92313Z" fill="currentColor"/>
                                <path d="M14.0072 0.877724C8.69816 0.876768 3.91155 4.0746 1.87993 8.97957C-0.151319 13.8847 0.972243 19.5305 4.72746 23.2834C9.8527 28.4086 18.162 28.4086 23.2871 23.2834C28.4121 18.1585 28.4121 9.84921 23.2871 4.72396C20.8313 2.25426 17.4899 0.86931 14.0072 0.877724ZM14.0072 25.9087C7.4442 25.9087 2.10231 20.5666 2.10231 14.0037C2.10231 7.4407 7.4442 2.0988 14.0072 2.0988C20.5701 2.0988 25.9122 7.4407 25.9122 14.0037C25.9122 20.5666 20.5701 25.9087 14.0072 25.9087Z" fill="currentColor"/>
                            </svg>
                        </i>
                    </a>
                </div>
            </div>
            <div class="item-border-box"></div>
            <div class="tech-services-slider-btn">
                <div class="swiper-nav-btn swiper-button-prev">
                    <i>
                        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.43078 14.0776C7.66827 14.3153 8.05356 14.3153 8.29143 14.0776C8.52911 13.8401 8.52911 13.4546 8.29143 13.217L3.68207 8.6076L15.929 8.6076C16.2661 8.6076 16.5396 8.33417 16.5396 7.99706C16.5396 7.65976 16.2661 7.38652 15.929 7.38652L3.68819 7.38652L8.29755 2.77716C8.41189 2.6632 8.47614 2.50813 8.47614 2.34675C8.47614 2.18517 8.41189 2.03048 8.29755 1.91633C8.1832 1.80103 8.0266 1.73716 7.86407 1.73926C7.70211 1.74003 7.54666 1.80351 7.43078 1.91633L1.78353 7.56339C1.66861 7.6785 1.60455 7.83472 1.60627 7.99706C1.60742 8.15902 1.6709 8.3139 1.78353 8.43035L7.43078 14.0776Z" fill="currentColor"/>
                        </svg>
                    </i>
                </div>
                <div class="swiper-nav-btn swiper-button-next">
                    <i>
                        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.5692 1.9224C10.3317 1.68472 9.94644 1.68472 9.70857 1.9224C9.47089 2.15988 9.47089 2.54537 9.70857 2.78304L14.3179 7.3924H2.07099C1.73388 7.3924 1.46045 7.66583 1.46045 8.00294C1.46045 8.34024 1.73388 8.61348 2.07099 8.61348H14.3118L9.70245 13.2228C9.58811 13.3368 9.52386 13.4919 9.52386 13.6533C9.52386 13.8148 9.58811 13.9695 9.70245 14.0837C9.8168 14.199 9.9734 14.2628 10.1359 14.2607C10.2979 14.26 10.4533 14.1965 10.5692 14.0837L16.2165 8.43661C16.3314 8.3215 16.3954 8.16528 16.3937 8.00294C16.3926 7.84098 16.3291 7.6861 16.2165 7.56965L10.5692 1.9224Z" fill="currentColor"/>
                        </svg>
                    </i>
                </div>
            </div>
        </div>
        <div class="tech-services-slider-box-inner">
            <swiper [config]="config" class="service-slider-row-main" *ngIf="showSwipper">
                <div class="swiper-wrapper service-slider-row">
                    <div class="swiper-slide service-slider-item">
                        <a [routerLink]="['it-services', 'start-up-remote-work-rdc']" class="service-slider-item-inner">
                            <div class="service-img-box">
                                <img src="assets/images/services/remote-work.webp" alt="Remote Work" width="958" height="1460">
                            </div>
                            <div class="service-detail-box">
                                <h3>Start-Up <br> Remote Work <br> RDC</h3>
                                <div class="service-list-box">
                                    <ul class="parent-list">
                                        <li><span>Concept to Marketshare </span></li>
                                        <li><span>Qualified and Skilled </span></li>
                                        <li><span>Happy and Motivated </span></li>
                                        <li><span>Engineers + Creators + Performers </span></li>
                                        <li><span>Effective communication with Clarity </span></li>
                                        <li><span>Fully Equipped </span></li>
                                        <li><span>Sound Infrastructure </span></li>
                                        <li><span>With Heart and Head </span></li>
                                        <li><span>Solution oriented mindset</span></li>
                                        <li><span>Genuine and Dedicated </span></li>
                                    </ul>
                                </div>
                            </div>
                        </a>
                        <div class="item-border-box"></div>
                    </div>
                    <div class="swiper-slide service-slider-item">
                        <a [routerLink]="['it-services', 'web-applications']" class="service-slider-item-inner">
                            <div class="service-img-box">
                                <img src="assets/images/services/web-app.webp" alt="Web App" width="958" height="1460">
                            </div>
                            <div class="service-detail-box">
                                <div>
                                    <h3>Web Applications</h3>
                                    <span class="sub-title">(Browser based Business solutions)</span>
                                </div>
                                <div class="service-list-box">
                                    <ul class="parent-list">
                                        <li><span>Front-end</span>
                                            <ul>
                                                <li>Angular, ReactJS </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <span>Back-end</span>
                                            <ul>
                                                <li>Node.js, Python, Ruby on Rails </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <span>E-commerce/ Online Shopping / CMS </span>
                                        </li>
                                        <li><span>SaaS Application </span></li>
                                        <li><span>Huge Data driven Reporting </span></li>
                                        <li><span>Business Workflows </span></li>
                                    </ul>
                                </div>
                            </div>
                        </a>
                        <div class="item-border-box"></div>
                    </div>

                    <div class="swiper-slide service-slider-item">
                        <a [routerLink]="['it-services', 'mobile-apps']" class="service-slider-item-inner">
                            <div class="service-img-box">
                                <img src="assets/images/services/mobile-app.webp" alt="Mobile App" width="958" height="1460">
                            </div>
                            <div class="service-detail-box">
                                <h3>Mobile Apps</h3>
                                <div class="service-list-box">
                                    <ul class="parent-list">
                                        <li><span>Mobile Phone, Tablet and Desktop </span></li>
                                        <li><span>Native iOS, Android </span></li>
                                        <li><span>Hybrid – Flutter, ReactNative</span></li>
                                    </ul>
                                    <ul>
                                        <li>Live modules</li>
                                        <li>Collaboration features</li>
                                        <li>Location tracking apps</li>
                                        <li>Shopping apps</li>
                                        <li>Ordering and reporting apps</li>
                                    </ul>
                                </div>
                            </div>
                        </a>
                        <div class="item-border-box"></div>
                    </div>
                    <div class="swiper-slide service-slider-item">
                        <a [routerLink]="['it-services', 'creative-and-functional-design']" class="service-slider-item-inner">
                            <div class="service-img-box">
                                <img src="assets/images/services/design.webp" alt="Creative & Functional Design" width="958" height="1460">
                            </div>
                            <div class="service-detail-box">
                                <h3>Creative & Functional Design</h3>
                                <div class="service-list-box">
                                    <ul class="parent-list">
                                        <li>
                                            <span>Designing</span>
                                            <ul>
                                                <li>UI / UX</li>
                                                <li>Websites</li>
                                                <li>Wireframing</li>
                                                <li>Logo & Animations</li>
                                                <li>Mobile Responsive</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <span>Platforms</span>
                                            <ul>
                                                <li>Web (Browsers)</li>
                                                <li>Desktop Apps</li>
                                                <li>Mobile & Tablet Screens</li>
                                                <li>Product Concepts </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <span>HTML5, CSS, Canvas, JavaScript, JS Libraries</span>
                                        </li>
                                        <li><span>Figma, AdobeXD </span></li>
                                    </ul>
                                </div>
                            </div>
                        </a>
                        <div class="item-border-box"></div>
                    </div>
                    <div class="swiper-slide service-slider-item">
                        <a [routerLink]="['it-services', 'websites']" class="service-slider-item-inner">
                            <div class="service-img-box">
                                <img src="assets/images/services/website.webp" alt="Websites" width="958" height="1460">
                            </div>
                            <div class="service-detail-box">
                                <h3>Websites</h3>
                                <div class="service-list-box">
                                    <ul class="parent-list">
                                        <li>
                                            <span>Features</span>
                                            <ul>
                                                <li>Engaging & Modern  </li>
                                                <li>E-commerce </li>
                                                <li>Payment integration </li>
                                                <li>Multi-language support </li>
                                                <li>Small businesses to large enterprises </li>
                                                <li>Webflow / Wordpress / Wix </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <span>Practice </span>
                                            <ul>
                                                <li>SEO </li>
                                                <li>Tracking and Analytics </li>
                                                <li>Secured and updated </li>
                                                <li>Regular Backups </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </a>
                        <div class="item-border-box"></div>
                    </div>
                </div>
            </swiper>
        </div>
    </div>
</section>
