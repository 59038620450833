import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DelayResolver } from '../delay-resolver';
import { DigitalMarketingComponent } from './digital-marketing/digital-marketing.component';
import { ItServicesComponent } from './it-services/it-services.component';
import { MobileApplicationDevelopmentComponent } from './mobile-application-development/mobile-application-development.component';
import { OffshoreDevelopmentCenterComponent } from './offshore-development-center/offshore-development-center.component';
import { ServicesComponent } from './services.component';
import { WebApplicationDevelopmentComponent } from './web-application-development/web-application-development.component';
import { WebDesignComponent } from './web-design/web-design.component';
import { WebsiteDevelopmentComponent } from './website-development/website-development.component';

const routes: Routes = [
  {
    path: '',
    component: ServicesComponent,
    children: [
      {
        path: '',
        component: ItServicesComponent,
        resolve: {data: DelayResolver }
      },
      {
        path: 'happy-super-and-effective-team',
        component: DigitalMarketingComponent,
        resolve: {data: DelayResolver }
      },
      {
        path: 'web-applications',
        component: WebApplicationDevelopmentComponent,
        resolve: {data: DelayResolver }
      },
      {
        path: 'mobile-apps',
        component: MobileApplicationDevelopmentComponent,
        resolve: {data: DelayResolver }
      },
      {
        path: 'creative-and-functional-design',
        component: WebDesignComponent,
        resolve: {data: DelayResolver }
      },
      {
        path: 'websites',
        component: WebsiteDevelopmentComponent,
        resolve: {data: DelayResolver }
      },
      {
        path: 'start-up-remote-work-rdc',
        component: OffshoreDevelopmentCenterComponent,
        resolve: {data: DelayResolver }
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ServicesRoutingModule { }
