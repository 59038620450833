import { AfterViewInit, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, Event, RouterOutlet } from '@angular/router';
import gsap from 'gsap';
import * as $ from 'jquery';
import { Title, Meta } from '@angular/platform-browser';

import Scrollbar, { ScrollbarPlugin } from 'smooth-scrollbar';
import ScrollTrigger from "gsap/ScrollTrigger";
import { sharedService } from './shared/shared.service';
import { CanonicalService } from './shared/canonical.service';
// import { animate, animateChild, group, query, style, transition, trigger } from "@angular/animations";
import { slideInAnimation } from './animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // animations: [
  //   slideInAnimation
  // ]
})
export class AppComponent implements AfterViewInit, OnDestroy {
  private listeners: any[] = [];
  title = 'tech';
  loading = true;
  open: boolean = false;
  selectedIndustry: any;
  openProjectDrawer: boolean = false;
  showSocialSymbol: boolean = false;
  bodyScrollBar: Scrollbar | undefined;
  constructor(private router: Router,
    private renderer: Renderer2,
    private metaTagService: Meta,
    private titleService: Title,
    public sharedservice: sharedService,
    private canonicalService: CanonicalService) {
    this.canonicalService.setCanonicalURL();
    this.sharedservice._indusPreview.subscribe(res => {
      this.open = true;
      this.selectedIndustry = res;
      if (this.bodyScrollBar) {
        this.bodyScrollBar.updatePluginOptions('modal', { open: true });
      }
    });
    this.sharedservice._projectDraw.subscribe(res => {
      this.openProjectDrawer = true;
    });
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.showSocialSymbol = event.url === '/';
        this.canonicalService.setCanonicalURL();
      }
    });
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }


  // ngOnInit() {
  //   this.titleService.setTitle("Custom Software, Web & Mobile App Development Company USA");
  //   this.metaTagService.addTags([
  //     { name: 'keywords', content: 'Designing and developing effective solutions for Web and Mobile platforms. Dedicated, skilled and genuine team to build solutions.' },
  //     { charset: 'UTF-8' },
  //     { property: 'og:title', content: 'Techcompose | Custom Software, Web & Mobile App Development Company USA' },
  //     { property: 'og:type', content: 'website' },
  //     { property: 'og:url', content: 'https://techcompose.com' },
  //     { property: 'og:description', content: 'TechCompose is a well-known software development and consulting company, that provides custom software, web, and mobile development services. Contact us now.' }
  //   ]);
  // }

  ngAfterViewInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.loading = true;
      } else if (event instanceof NavigationEnd) {
        if (event.url === '/') {
          // setTimeout(() => {
            this.loading = false;
          // }, 500);
        } else {
          this.loading = false;
        }
      }
      // this.playAudio();
    });
    gsap.set(".cursor", {xPercent: -50, yPercent: -50});

    const ball = document.querySelector(".cursor");
    const pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
    const mouse = { x: pos.x, y: pos.y };
    const speed = 0.15;

    const xSet = gsap.quickSetter(ball, "x", "px");
    const ySet = gsap.quickSetter(ball, "y", "px");

    window.addEventListener("mousemove", e => {
      mouse.x = e.x;
      mouse.y = e.y;
    });

    gsap.ticker.add(() => {

      // adjust speed for higher refresh monitors
      const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio());

      pos.x += (mouse.x - pos.x) * dt;
      pos.y += (mouse.y - pos.y) * dt;
      xSet(pos.x);
      ySet(pos.y);
    });


    setTimeout(() => {
      gsap.fromTo('.home-social-block', {autoAlpha: 0,}, {autoAlpha: 1,duration: 0.1,delay: 0});
    }, 500);

 gsap.registerPlugin(ScrollTrigger);

    // const scroller = document.querySelector('.scroller') as HTMLElement;

    // this.bodyScrollBar = Scrollbar.init(scroller, { damping: 0.1, delegateTo: document, alwaysShowTracks: true });
    // const content = this;
    // ScrollTrigger.scrollerProxy(".scroller", {
    //   scrollTop(value) {
    //     if (content.bodyScrollBar) {
    //       if (arguments.length) {
    //         content.bodyScrollBar.scrollTop = value || 0;
    //       }
    //       return content.bodyScrollBar.scrollTop;
    //     } else {
    //       return 0;
    //     }
    //   }
    // });

    // this.bodyScrollBar.addListener(ScrollTrigger.update);

    // ScrollTrigger.defaults({ scroller: scroller });


 // Only necessary to correct marker position - not needed in production
    // if (document.querySelector('.gsap-marker-scroller-start')) {
    //   const markers = gsap.utils.toArray('[class *= "gsap-marker"]');

    //   this.bodyScrollBar.addListener(({ offset }) => {
    //     gsap.set(markers, { marginTop: -offset.y })
    //   });
    // }

    gsap.config({
      nullTargetWarn: false,
    });

  }

  closeDOM(): void {
    this.open = false;
    this.openProjectDrawer = false;
    if (this.bodyScrollBar) {
      this.bodyScrollBar.updatePluginOptions('modal', { open: false });
    }
  }

  public ngOnDestroy(): void {
      this.listeners.forEach(listener => listener());
  }
}
