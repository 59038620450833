import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';

import { TechLogoComponent } from '../tech-logo/tech-logo.component';
import { PortfolioComponent } from '../home/portfolio/portfolio.component';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import { SocialComponent } from '../social/social.component';
import { FooterComponent } from '../footer/footer.component';
import { HeaderMenuComponent } from '../header-menu/header-menu.component';
@NgModule({
  declarations: [
    TechLogoComponent,
    PortfolioComponent,
    HeaderComponent,
    SocialComponent,
    FooterComponent,
    HeaderMenuComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgxUsefulSwiperModule
  ],
  exports: [
    TechLogoComponent,
    PortfolioComponent,
    HeaderComponent,
    SocialComponent,
    FooterComponent,
    HeaderMenuComponent
  ]
})
export class SharedModule { }
