<app-header></app-header>
<app-header-menu *ngIf="isHeaderMenuOpen"></app-header-menu>
<div class="scroller">
  <div class="header-class"></div>
  <div class="page-grid-block">
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
  </div>
  <div class="grid-background-overlay"></div>
  <section class="blog-hero">
      <app-tech-logo></app-tech-logo>
      <div class="blog-hero-container">
          <div class="blog-hero-block">
            <div class="back-btn-block">
                <a [routerLink]="['/blogs']" class="back-btn">
                    <i>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.828 11.0007H22V13.0007H5.828L11.192 18.3647L9.778 19.7787L2 12.0007L9.778 4.22266L11.192 5.63666L5.828 11.0007Z" fill="black"/>
                        </svg>
                    </i>
                    Back to Blogs
                </a>
            </div>
              <div class="blog-hero-inner-block">
                  <div class="page-title">
                      <h1>How to Create Gradient Background in Android</h1>
                  </div>
              </div>
          </div>
      </div>
  </section>
  <section class="blog-wrapper">
      <div class="blog-detail-row">
          <div class="blog-detail-box">
              <figure>
                  <picture>
                      <img src="assets/images/blog/how-to-create-gradient-android.webp" alt="how to create gradient android">
                  </picture>
              </figure>
              <p>How to Create Gradient Background in Android</p>
              <p>The mobile app holds much importance nowadays people are spending more and more time on their Smartphone and this is the reason why mobile apps are so popular nowadays. Android is one of the <a href="https://www.android.com/">most used OS</a> which holds almost 48% of market share when it comes to Smartphone OS usage.</p>
              <p>Gradient Backgrounds are used majorly in android app development to give very good eye-catching design factors in screen designing. In this Blog, we will see a simple and impactful tutorial on how to create Gradient Background in Android app development.</p>
              <h2>Step 1:</h2>
              <p>To Begin, we will first create an XML file where we are going to implement the gradient specifications. Named it&nbsp;<code><strong>bg_gradient.xml</strong></code>. Let us put this XML file under <code><strong>/res/drawable</strong></code> directory.</p>
              <h2>Step 2:</h2>
              <p>Next, place this code below the XML tags.</p>
              <pre><code [highlight]="code1" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
              <h2>Editor</h2>
              <p>As you can notice, we have a gradient tag, together with its attributes. For the android type attribute, we have only two types linear&nbsp;and&nbsp;radial. For you to create gradients, you have to specify a minimum of two colors such as the&nbsp;<code><strong>android:startColortag</strong></code> and&nbsp;<code><strong>android:endColor</strong></code>&nbsp;tag.</p>
              <p>And don’t forget to save the created file. After that, you can link your layout’s background to the one you’ve recently created in following way.</p>
              <h2>Step 3:</h2>
              <p>Now you can use this created gradient drawable file into any layout components easily as below.</p>
              <pre><code [highlight]="code2" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
              <h2>Editor</h2>
              <p>That`s it. This is How to create Gradient Background in Android. Mobile App is gaining more and more popularity as each day passes as Smartphone users are increasing all over the world so it is the right time for your business to have mobile apps. For any other query or to develop Android mobile application, you can hire our dedicated android developers who can solely work for you. You can reach us at <a href="mailto:inquiry@techcompose.com">inquiry@techcompose.com</a> for your android app development requirement.</p>
          </div>
          <app-share-blog-buttons
              [currentBlogUrl]="currentBlogUrl"
              [currentBlogTitle]="'How to Create Gradient Background in Android - TechCompose'">
          </app-share-blog-buttons>
      </div>
  </section>
  <app-footer></app-footer>
</div>
