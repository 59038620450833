<header class="header" id="header">
    <div class="container-fluid">
        <div class="header-row">
            <div class="logo">
                <a [routerLink]="['/']" class="mouseIn">
                    <svg width="265" height="42" viewBox="0 0 265 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g>
                            <path class="greenpath" d="M143.126 29.2579C146.321 29.2579 149.271 27.8753 151.329 25.7246L148.042 22.775C146.813 24.1884 145.062 25.1408 143.126 25.1408C139.593 25.1408 136.582 22.0069 136.582 18.32C136.582 14.6638 139.593 11.5299 143.126 11.5299C145.062 11.5299 146.813 12.4824 148.042 13.8957L151.329 10.9461C149.271 8.76472 146.321 7.41284 143.126 7.41284C137.073 7.41284 131.912 12.3902 131.912 18.32C131.912 24.2498 137.073 29.2579 143.126 29.2579Z" fill="#12B495"/>
                            <path class="greenpath" d="M160.586 29.2579C165.287 29.2579 169.22 25.4481 169.22 20.9316C169.22 16.4151 165.287 12.636 160.586 12.636C155.855 12.636 151.891 16.4151 151.891 20.9316C151.891 25.4481 155.855 29.2579 160.586 29.2579ZM160.586 25.4788C158.19 25.4788 156.193 23.3895 156.193 20.9316C156.193 18.4736 158.19 16.4151 160.586 16.4151C162.952 16.4151 164.949 18.4736 164.949 20.9316C164.949 23.3895 162.952 25.4788 160.586 25.4788Z" fill="#12B495"/>
                            <path class="greenpath" d="M189.922 12.636C187.556 12.636 185.59 13.7728 184.76 15.5241C183.777 13.7421 181.903 12.636 179.721 12.636C177.755 12.636 176.188 13.5884 175.42 15.0939V13.0047H170.934V28.8892H175.42V19.3032C175.42 17.6133 176.679 16.4151 178.492 16.4151C180.121 16.4151 181.288 17.7055 181.288 19.549V28.9199H185.59V19.3032C185.59 17.6133 186.88 16.4151 188.662 16.4151C190.321 16.4151 191.489 17.7055 191.489 19.549V28.9199H195.79V18.7809C195.79 15.1861 193.24 12.636 189.922 12.636Z" fill="#12B495"/>
                            <path class="greenpath" d="M208.132 12.636C205.92 12.636 204.077 13.5884 202.817 15.2168V13.0047H198.331V34.6928H202.817V26.6463C204.077 28.3054 205.92 29.2579 208.132 29.2579C212.372 29.2579 215.445 25.8168 215.445 20.9316C215.445 16.0771 212.372 12.636 208.132 12.636ZM206.842 25.4788C204.661 25.4788 203.063 23.8811 202.817 21.5461V20.3171C203.063 18.0128 204.661 16.4151 206.842 16.4151C209.331 16.4151 211.082 18.2893 211.082 20.9316C211.082 23.6046 209.331 25.4788 206.842 25.4788Z" fill="#12B495"/>
                            <path class="greenpath" d="M225.189 29.2579C229.89 29.2579 233.823 25.4481 233.823 20.9316C233.823 16.4151 229.89 12.636 225.189 12.636C220.458 12.636 216.494 16.4151 216.494 20.9316C216.494 25.4481 220.458 29.2579 225.189 29.2579ZM225.189 25.4788C222.793 25.4788 220.796 23.3895 220.796 20.9316C220.796 18.4736 222.793 16.4151 225.189 16.4151C227.555 16.4151 229.552 18.4736 229.552 20.9316C229.552 23.3895 227.555 25.4788 225.189 25.4788Z" fill="#12B495"/>
                            <path class="greenpath" d="M241.59 29.2579C245.492 29.2579 248.042 27.2608 248.042 24.1269C248.042 20.6551 245 19.7333 242.266 19.0267C240.115 18.4429 239.47 18.1971 239.47 17.3368C239.47 16.7838 240.238 16.3844 241.344 16.3844C242.696 16.3844 244.355 16.9067 245.799 17.7977L247.612 14.6024C245.86 13.4348 243.617 12.636 241.344 12.636C237.595 12.636 235.107 14.7867 235.137 17.7977C235.168 20.9316 237.688 21.8226 240.944 22.6214C242.573 23.0516 243.74 23.3588 243.74 24.342C243.74 25.0487 242.88 25.5402 241.682 25.5402C239.562 25.5402 237.78 24.7721 236.366 23.7582L234.431 26.8614C236.336 28.3976 239.039 29.2579 241.59 29.2579Z" fill="#12B495"/>
                            <path class="greenpath" d="M257.319 25.2944C255.445 25.2944 254.062 24.1269 253.54 22.4064H265C265 16.4151 262.081 12.636 257.104 12.636C252.465 12.636 248.931 16.1078 248.931 20.9009C248.931 25.786 252.618 29.2579 257.35 29.2579C259.685 29.2579 262.419 28.2747 263.833 26.7999L260.975 23.8811C260.115 24.7107 258.579 25.2944 257.319 25.2944ZM257.288 16.5994C259.101 16.5994 260.269 17.6133 260.699 19.1803H253.601C254.185 17.5826 255.598 16.5994 257.288 16.5994Z" fill="#12B495"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M117.837 14.9403C118.82 13.5577 120.571 12.636 122.599 12.636C126.04 12.636 128.498 15.1861 128.498 18.7808V28.8892H124.166V19.7333C124.166 17.7669 122.937 16.4151 121.155 16.4151C119.281 16.4151 117.837 17.7669 117.837 19.4875V28.8892H113.351V7.78147H117.837V14.9403ZM75.7869 28.8892H71.2397V11.8678H64.6647V7.78149H82.3619V11.8678H75.7869V28.8892ZM84.5429 22.4063C85.0653 24.1269 86.4478 25.2944 88.322 25.2944C89.5817 25.2944 91.118 24.7106 91.9782 23.8811L94.8356 26.7999C93.4223 28.2747 90.6878 29.2579 88.3528 29.2579C83.6212 29.2579 79.9343 25.786 79.9343 20.9008C79.9343 16.1078 83.4676 12.636 88.107 12.636C93.0843 12.636 96.0031 16.4151 96.0031 22.4063H84.5429ZM91.7017 19.1802C91.2716 17.6133 90.1041 16.5994 88.2913 16.5994C86.6015 16.5994 85.1881 17.5826 84.6044 19.1802H91.7017ZM105.582 29.2579C108.224 29.2579 110.375 28.1825 111.942 26.4619L108.962 23.9425C108.132 24.8643 106.842 25.4788 105.613 25.4788C103.247 25.4788 101.281 23.3895 101.281 20.9008C101.281 18.4736 103.247 16.4151 105.582 16.4151C106.811 16.4151 108.071 16.9988 108.87 17.8898L111.85 15.3397C110.314 13.6806 108.194 12.636 105.613 12.636C100.912 12.636 96.9179 16.4151 96.9179 20.9315C96.9179 25.448 100.881 29.2579 105.582 29.2579Z" fill="#3F5C76"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9913 3.05615L14.7499 6.81469L7.51707 14.0475L28.0574 34.5878L37.6074 25.0378L41.3659 28.7963L28.0574 42.1049L0 14.0475L10.9913 3.05615ZM45.1234 3.05615L56.1148 14.0475L45.1234 25.0388L41.3649 21.2803L48.5977 14.0475L41.3649 6.81469L45.1234 3.05615Z" fill="#3F5C76"/>
                            <path class="greenpath" fill-rule="evenodd" clip-rule="evenodd" d="M30.7144 1.37636V24.4932L28.0455 27.1346L25.4011 24.4925V1.37636H30.7144Z" fill="#12B495"/>
                            <path class="greenpath" fill-rule="evenodd" clip-rule="evenodd" d="M28.8196 5.31529L20.2738 5.31537L14.9868 0L28.8196 8.77254e-05V5.31529Z" fill="#12B495"/>
                            <path class="greenpath" fill-rule="evenodd" clip-rule="evenodd" d="M27.2847 5.31529L35.8306 5.31537L41.1175 0L27.2847 8.77254e-05V5.31529Z" fill="#12B495"/>
                        </g>
                    </svg>
                </a>
            </div>
            <div class="headerRight">
                <ul *ngIf="!isHeaderMenuOpen">
                  <li><a [routerLink]="['/it-services']" routerLinkActive="active">Services</a></li>
                  <li><a [routerLink]="['/work']" routerLinkActive="active">Work</a></li>
                  <li><a [routerLink]="['/contact-us']" routerLinkActive="active">Contact</a></li>
                </ul>
                <button class="MenutoggleButton mouseIn" (click)="toggleMenu()" type="button" id="menu-toggle" aria-label="toggle menu">
                    <span class="togglerIcon toggleIconfirst"></span>
                    <span class="togglerIcon toggleIconSec"></span>
                    <span class="togglerIcon toggleIconThird"></span>
                </button>
            </div>
        </div>
    </div>
</header>
<!-- <div class="techMenu">
    <div class="techMenuInnerBlock">
        <div class="MenuBlock">
            <ul class="MenuListBlock">
                <li class="MenuItem"><a [routerLink]="['/about-us']" routerLinkActive="active">About</a></li>
                <li class="MenuItem">
                    <div class="submentBtnBlock">
                        <a [routerLink]="['/it-services']" routerLinkActive="active">Services</a>
                        <button type="button" class="submenuBtn">
                            <i class="downarrow">
                                <svg width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.78787 3.78787L0.512132 0.512132C0.323143 0.323143 0.456993 0 0.724264 0H7.27574C7.54301 0 7.67686 0.323142 7.48787 0.512131L4.21213 3.78787C4.09497 3.90503 3.90503 3.90503 3.78787 3.78787Z" fill="currentColor"/>
                                </svg>
                            </i>
                        </button>
                    </div>
                    <ul class="submenu">
                        <li class="submenu-item"><a [routerLink]="['it-services', 'happy-super-and-effective-team']" routerLinkActive="active">Happy, Super & Effective Team</a></li>
                        <li class="submenu-item"><a [routerLink]="['it-services', 'web-applications']" routerLinkActive="active">Web Applications</a></li>
                        <li class="submenu-item"><a [routerLink]="['it-services', 'mobile-apps']" routerLinkActive="active">Mobile Apps</a></li>
                        <li class="submenu-item"><a [routerLink]="['it-services', 'creative-and-functional-design']" routerLinkActive="active">Creative & Functional Design</a></li>
                        <li class="submenu-item"><a [routerLink]="['it-services', 'websites']" routerLinkActive="active">Websites</a></li>
                        <li class="submenu-item"><a [routerLink]="['it-services', 'start-up-remote-work-rdc']" routerLinkActive="active">Start-Up Remote Work RDC</a></li>
                    </ul>
                </li>
                <li class="MenuItem"><a [routerLink]="['/work']" routerLinkActive="active">Work</a></li>
                <li class="MenuItem"><a [routerLink]="['/careers']" routerLinkActive="active">Careers</a></li>
                <li class="MenuItem"><a [routerLink]="['/contact-us']" routerLinkActive="active">Contact</a></li>
                <li class="MenuItem"><a [routerLink]="['/blogs']" routerLinkActive="active">Blogs</a></li>
            </ul>
        </div>
    </div>
    <div class="social-menu">
        <app-social></app-social>
    </div>
</div> -->

<div class="header-backdrop"></div>
