import { AfterViewInit, Component, OnInit } from '@angular/core';
import { sharedService } from '../shared/shared.service';

import SplitType from 'split-type';
import gsap from 'gsap';
import Scrollbar, { ScrollbarPlugin } from 'smooth-scrollbar';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-privary-policy',
  templateUrl: './privary-policy.component.html',
  styleUrls: ['./privary-policy.component.scss']
})
export class PrivaryPolicyComponent implements AfterViewInit, OnInit {

  bodyScrollBar: Scrollbar | undefined;
  public isHeaderMenuOpen: boolean = false;
  public currentUrl: string = window.location.href;
  public baseUrl: string = window.location.origin;

  constructor(
    public sharedservice: sharedService,
    private metaTagService: Meta,
    private titleService: Title,
  ) { }

  public ngAfterViewInit(): void {
    gsap.registerPlugin(ScrollTrigger);
    gsap.fromTo('.career-info-detail p', {
        opacity: 0,
        y: 30
    }, {
        y: 0,
        opacity: 1,
        duration: 0.5,
        ease: 'power1',
        delay: 0.8
    });
    const aboutpagetitle = new SplitType('.page-title h1',{
        types: 'chars, words',
        absolute: true
    });

    gsap.from(aboutpagetitle.chars, {
        opacity: 0,
        x: 20,
        duration: 0.7,
        delay: 0.7,
        stagger: 0.03,
    });

    gsap.fromTo('.privary-info', {autoAlpha: 0, y: 50},{
      autoAlpha: 1, y: 0, duration: 0.5, delay: 1
    })

  }

  public ngOnInit(): void {

    this.titleService.setTitle("Privacy Policy | TechCompose: Safeguarding Your Data Privacy");
      
    this.metaTagService.updateTag({ name: 'keywords', content: 'Safeguard your privacy with TechCompose comprehensive privacy policy. Learn how we handle your data responsibly and protect your personal information.' });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Techcompose | Privacy Policy | TechCompose: Safeguarding Your Data Privacy' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:url', content: `${this.currentUrl}` });
    this.metaTagService.updateTag({ property: 'og:image', content: `${this.baseUrl}/assets/images/thumb.png` });
    this.metaTagService.updateTag({ property: 'og:description', content: 'Safeguard your privacy with TechCompose comprehensive privacy policy. Learn how we handle your data responsibly and protect your personal information.' });
    this.metaTagService.removeTag("name='google-site-verification'");


    this.sharedservice.showHeaderMenu$.subscribe((data) => {
      this.isHeaderMenuOpen = data;
      if (this.bodyScrollBar) {
        this.bodyScrollBar.updatePluginOptions('modal', { open: this.isHeaderMenuOpen });
      }
    });


    gsap.registerPlugin(ScrollTrigger);
    const scroller = document.querySelector('.scroller')as HTMLElement;

    this.bodyScrollBar = Scrollbar.init(scroller, { damping: 0.1, delegateTo: document, alwaysShowTracks: true });

    const content = this;
    ScrollTrigger.scrollerProxy(".scroller", {
      scrollTop(value) {
        if (content.bodyScrollBar) {
          if (arguments.length) {
            content.bodyScrollBar.scrollTop = value || 0;
          }
          return content.bodyScrollBar.scrollTop;
        } else {
          return 0;
        }
      }
    });


  }

}

class ModalPlugin extends ScrollbarPlugin {
  static pluginName = 'modal';

  static defaultOptions = {
    isHeaderMenuOpen: false
  };

  transformDelta(delta: any) {
      return this.options.open ? { x: 0, y: 0 } : delta;
  }
}

Scrollbar.use(ModalPlugin, /* OverscrollPlugin */);
