import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BlogsRoutingModule } from './blogs-routing.module';
import { BlogListComponent } from './blog-list/blog-list.component';
import { SharedModule } from '../shared/shared.module';
import { RailsApiFastComponent } from './rails-api-fast/rails-api-fast.component';
import { BlogsComponent } from './blogs.component';
import { UseOfRailsComponent } from './use-of-rails/use-of-rails.component';
import { InternationalizationRorComponent } from './internationalization-ror/internationalization-ror.component';
import { ConvertHtmlToPdfComponent } from './convert-html-to-pdf/convert-html-to-pdf.component';
import { ImportanceMethodologyComponent } from './importance-methodology/importance-methodology.component';
import { ReasonsOfWhyRorComponent } from './reasons-of-why-ror/reasons-of-why-ror.component';
import { CreateGradientAndroidComponent } from './create-gradient-android/create-gradient-android.component';
import { SlowWordpressWebsiteComponent } from './slow-wordpress-website/slow-wordpress-website.component';
import { ContentVsDesignComponent } from './content-vs-design/content-vs-design.component';
import {
  HighlightAutoResult,
  HighlightLoader,
  HighlightModule,
  HighlightOptions,
  HIGHLIGHT_OPTIONS,
} from 'ngx-highlightjs';
import { TestcasesAngularComponent } from './testcases-angular/testcases-angular.component';
import { ShareBlogButtonsComponent } from './share-blog-buttons/share-blog-buttons.component';


@NgModule({
  declarations: [
    BlogsComponent,
    BlogListComponent,
    RailsApiFastComponent,
    UseOfRailsComponent,
    InternationalizationRorComponent,
    ConvertHtmlToPdfComponent,
    ImportanceMethodologyComponent,
    ReasonsOfWhyRorComponent,
    CreateGradientAndroidComponent,
    SlowWordpressWebsiteComponent,
    ContentVsDesignComponent,
    TestcasesAngularComponent,
    ShareBlogButtonsComponent
  ],
  imports: [
    CommonModule,
    BlogsRoutingModule,
    SharedModule,
    HighlightModule
  ],
  // providers: [
  //   {
  //     // provide: HIGHLIGHT_OPTIONS,
  //     // useValue: <HighlightOptions>{
  //     //   lineNumbers: true,
  //     //   coreLibraryLoader: () => import('highlight.js/lib/core'),
  //     //   // lineNumbersLoader: () => import('ngx-highlightjs/line-numbers'),
  //     //   themePath: 'node_modules/highlight.js/styles/androidstudio.css',
  //     //   languages: {
  //     //     typescript: () => import('highlight.js/lib/languages/typescript'),
  //     //     css: () => import('highlight.js/lib/languages/css'),
  //     //     xml: () => import('highlight.js/lib/languages/xml'),
  //     //   },
  //     // },
      
  //   },
  // ],
})
export class BlogsModule { }
