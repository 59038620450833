<app-header></app-header>
<app-header-menu *ngIf="isHeaderMenuOpen"></app-header-menu>
<div class="scroller">
  <div class="header-class"></div>
  <div class="page-grid-block">
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
  </div>
  <div class="portfolio-wrapper">
      <div class="portfolio-overlay"></div>
      <div class="portfolio-detail-wrapper">
          <div class="portfolio-detail-inner">
              <div class="back-btn-block">
                  <a [routerLink]="['/work']" class="back-btn">
                      <i>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M5.828 11.0007H22V13.0007H5.828L11.192 18.3647L9.778 19.7787L2 12.0007L9.778 4.22266L11.192 5.63666L5.828 11.0007Z" fill="black"/>
                          </svg>
                      </i>
                      Back to Portfolio
                  </a>
              </div>
              <div class="project-logo">
                  <img src="assets/images/project-images/lmb/logo.svg" alt="LinkMyBooks">
              </div>
              <h2 class="probstatement">
                  <span>Problem Statement </span>
                  It is really time consuming to keep accurate bookkeeping while selling on Amazon, eBay, Shopify and ETSY.
              </h2>
              <div class="challange-block">
                  <div class="challange-list">
                      <ul>
                          <li>
                              <h3 class="section-title section-title-sub">Study</h3>
                              <ul>
                                  <li class="work-info">Sellers spending lots of time at accounting and tax planning   </li>
                                  <li class="work-info">Very good scope of automation between sales channels and accounting softwares</li>
                                  <li class="work-info">Better reporting and summarized dashboard for clarity </li>
                              </ul>
                          </li>
                          <li>
                              <h3 class="section-title section-title-sub">Challenges</h3>
                              <ul>
                                  <li class="work-info">Data dependency on external service during business hours </li>
                                  <li class="work-info">Consistency of data and process with real time updates </li>
                                  <li class="work-info">Scalability as external services are also generating traffic </li>
                                  <li class="work-info">Security and Privacy </li>
                                  <li class="work-info">Background processes and Scheduled tasks</li>
                                  <li class="work-info">On-boarding of business </li>
                                  <li class="work-info">Keeping algorithm up-to-date with changes in external APIs  </li>
                              </ul>
                          </li>
                          <li>
                              <h3 class="section-title section-title-sub">Approach</h3>
                              <ul>
                                  <li class="work-info">Study of concept shared by Product Owners </li>
                                  <li class="work-info">Create pixel perfect User Interface based on the Designs </li>
                                  <li class="work-info">Develop shared UI components for future enhancement </li>
                              </ul>
                          </li>
                          <li>
                              <h3 class="section-title section-title-sub">Involvement</h3>
                              <ul>
                                  <li class="work-info">Developed front-end from the beginning till today </li>
                                  <li class="work-info">Development of Website pages in WebFlow platform </li>
                                  <li class="work-info">On-page SEO for the Website </li>
                              </ul>
                          </li>
                      </ul>
                  </div>
              </div>

          </div>
      </div>
      <div class="project-img-block">
          <div class="portfolio-detail-wrapper pt-0">
              <div class="portfolio-detail-inner">
                  <div class="project-img-block-inner">
                      <img src="assets/images/project-images/lmb/img-2.webp" alt="LinkMyBooks">
                  </div>
              </div>
          </div>
          <!-- <div class="bg-img-block">
              <img src="assets/images/project-images/lmb/img-1-bg.webp" alt="">
          </div> -->
      </div>
      <div class="portfolio-detail-wrapper pt-0">
          <div class="portfolio-detail-inner">
              <div class="work-process"  *ngIf="false">
                  <ul>
                      <li>
                          <h3 class="section-title">STRATEGY</h3>
                          <span>Design thinking</span>
                          <span>Product Roadmap</span>
                      </li>
                      <li>
                          <h3 class="section-title">DESIGN</h3>
                          <span>User Experience</span>
                          <span>User Interface</span>
                          <span>Illustrations & Icons</span>
                      </li>
                      <li>
                          <h3 class="section-title">FRONT END DEVELOPEMENT</h3>
                          <span>HTML</span>
                          <span>CSS</span>
                          <span>Posenet</span>
                      </li>
                  </ul>
              </div>
              <div class="visule-guide pt-0">
                  <h2>Visual Guide</h2>
                  <div class="visule-guide-row">
                      <div class="visule-guide-col">
                          <h3 class="section-title section-title-sub">TYPE FACE</h3>
                          <label>Inter</label>
                          <p>- Sans-Serif</p>
                      </div>
                      <div class="visule-guide-col">
                          <h3 class="section-title section-title-sub">COLOR</h3>
                          <ul class="color-list">
                              <li style="background: #FF9957; color: #fff; border: none">
                                  #FF9957
                              </li>
                              <li style="background: #1AB4D7; color: #fff; border: none">
                                  #1AB4D7
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="outcome-box-wrapper">
          <div class="outcome-box-inner">
              <div class="outcome-box-info">
                  <div class="outcome-box-info-inner">
                      <h3 class="section-title section-title-sub">THE OUTCOME</h3>
                      <!-- <p>Front-end is very responsive and light weight to interact for our users. Smooth and uniform experience for users while interacting with the application.</p> -->
                      <ul>
                          <li>Accurate bookkeeping</li>
                          <li>Responsive and lightweight front-end</li>
                          <li>Smooth user experience while interacting with application</li>
                      </ul>
                  </div>
              </div>
          </div>
          <div class="outcome-img-block">
              <img src="assets/images/project-images/lmb/outcome-box.webp" alt="Link My Books">
          </div>
      </div>
      <div class="project-info">
          <div class="project-info-inner">
              <div class="portfolio-quote">
                  <p>
                      <i class="quote-icon">
                          <img src="assets/images/quote-icon.svg" alt="Quote Icon">
                      </i>
                      Working with this team for more than 3 years now. Developers are different now but the delivery standards are same with even better productivity as they understand the application much better. Team is genuine to make sure we are not overbilled for the value being delivered. Strong engineering and creative team helped us achieve more than what is expected.</p>
                  <div class="client-quote-info">
                      <div class="client-img">
                          <!-- <img src="assets/images/smarter.webp" alt="LinkMyBooks"> -->
                          <span>D</span>
                      </div>
                      <div class="client-name">
                          <h3>Danial</h3>
                          <span>CEO</span>
                      </div>
                  </div>
              </div>
              <div class="work-detail-box">
                  <div class="work-detail-box-inner">
                      <h5>Connect with us</h5>
                      <p class="work-info">If you have a concept/vision/product and you are looking for a right <span>design and developer team</span> OR you have right technical skills and looking for <span>exciting projects/environment to experience</span>, drop a message via contact us form. </p>
                      <div class="contact-btn-block">
                          <a [routerLink]="['/', 'contact-us']"><span>Contact Now</span></a>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <section class="horizontal">
          <div class="more-project-ovrelay"></div>
          <div class="pin-wrap">
              <div class="projects-title-block">
                  <h2>More Works</h2>
              </div>
              <div class="animation-wrap to-right">
                  <a class="item" [routerLink]="['/', 'work', 'build-effective']">
                      <div class="project-img">
                          <img src="assets/images/project-images/project-thumbnail/build-effective.webp" alt="Build Effective">
                      </div>
                      <div class="project-slider-info">
                          <span>Real Estate</span>
                          <h2>Buildeffective</h2>
                      </div>
                  </a>
                  <a class="item" [routerLink]="['/', 'work', 'ar-workflow']">
                      <div class="project-img">
                          <img src="assets/images/project-images/project-thumbnail/ar-workflow.webp" alt="ARWorkflow">
                      </div>
                      <div class="project-slider-info">
                          <span>Accounting</span>
                          <h2>ARWorkflow</h2>
                      </div>
                  </a>
                  <a class="item" [routerLink]="['/', 'work', 'ccalerts']">
                      <div class="project-img">
                          <img src="assets/images/project-images/project-thumbnail/ccalerts.webp" alt="CCAlerts">
                      </div>
                      <div class="project-slider-info">
                          <span>Finance</span>
                          <h2>CCAlerts</h2>
                      </div>
                  </a>
                  <a class="item" [routerLink]="['/', 'work', 'talowiz']">
                      <div class="project-img">
                          <img src="assets/images/project-images/project-thumbnail/talowiz.webp" alt="Talowiz">
                      </div>
                      <div class="project-slider-info">
                          <span>Education</span>
                          <h2>Talowiz</h2>
                      </div>
                  </a>
                  <a class="item" [routerLink]="['/', 'work', 'percentology']">
                      <div class="project-img">
                          <img src="assets/images/project-images/project-thumbnail/percentology.webp" alt="Percentology">
                      </div>
                      <div class="project-slider-info">
                          <span>Account</span>
                          <h2>Percentology</h2>
                      </div>
                  </a>
                  <a class="item" [routerLink]="['/', 'work', 'facturazen']">
                    <div class="project-img">
                        <img src="assets/images/project-images/project-thumbnail/facturazen.webp" alt="Facturazen">
                    </div>
                    <div class="project-slider-info">
                        <span>Business</span>
                        <h2>Facturazen</h2>
                    </div>
                  </a>
                  <ng-container *ngIf="false">
                      <a class="item" [routerLink]="['/', 'work', 'stomerijcollectief']">
                          <div class="project-img">
                              <img src="assets/images/project-images/project-thumbnail/stomerij-collectief.webp" alt="Stomerij Collectief">
                          </div>
                          <div class="project-slider-info">
                              <span>Business</span>
                              <h2>Stomerij Collectief</h2>
                          </div>
                      </a>
                      <a class="item" [routerLink]="['/', 'work', 'food-trucks']">
                          <div class="project-img">
                              <img src="assets/images/project-images/project-thumbnail/food-truck.webp" alt="Food Truck">
                          </div>
                          <div class="project-slider-info">
                              <span>Food</span>
                              <h2>FoodTruck</h2>
                          </div>
                      </a>
                      <a class="item" [routerLink]="['/', 'work', 'local-grocery']">
                          <div class="project-img">
                              <img src="assets/images/project-images/project-thumbnail/local-grocery.webp" alt="Local Grocery">
                          </div>
                          <div class="project-slider-info">
                              <span>Utility</span>
                              <h2>Local Grocery</h2>
                          </div>
                      </a>
                      <a class="item" [routerLink]="['/', 'work', 'miniflix']">
                          <div class="project-img">
                              <img src="assets/images/project-images/project-thumbnail/miniflix.webp" alt="Miniflix">
                          </div>
                          <div class="project-slider-info">
                              <span>Entertainment</span>
                              <h2>Miniflix</h2>
                          </div>
                      </a>
                  </ng-container>
              </div>
          </div>
      </section>
  </div>
  <app-footer></app-footer>
</div>
