<app-header></app-header>
<app-header-menu *ngIf="isHeaderMenuOpen"></app-header-menu>
<div class="scroller">
  <div class="header-class"></div>
  <div class="page-grid-block">
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
  </div>
  <div class="grid-background-overlay"></div>
  <section class="testimonial-hero">
      <app-tech-logo></app-tech-logo>
      <div class="testimonial-hero-container">
          <div class="testimonial-hero-block">
              <div class="testimonial-detail-block">
                  <div class="page-title">
                      <h1>Our Testimonials </h1>
                  </div>
                  <div class="testimonial-info-detail page-info">
                      <p>It's very easy to promote yourself, however, the true reflection of your work is in the feedback from your customers and clients. Our passion for customer excellence is just one reason why we are the market leader for high quality IT services. But don't take our word for it, here's our testimonials... </p>
                  </div>
              </div>
          </div>
      </div>
  </section>

  <section class="tech-testimonial-wrapper">
      <div class="tech-testimonial-block">
          <div class="tech-testimonial-row">
            <div class="tech-testimonial-inner-block">
              <div class="tech-user-detail">
                <div class="userImg">
                  <!-- <img src="assets/images/smarter.webp" width="150" height="150" alt="Sam"> -->
                  <span>S</span>
                </div>
                <div class="user-name-block">
                  <h3>Sam</h3>
                  <span>CEO</span>
                </div>
              </div>
              <div class="testimonial-desc">
                <div class="testimonial-inner-desc">
                  <p> The Best team I could even not get from here in US or anywhere else. Stood up with me at every stage of product development. Coming from non-IT background, never thought it would be this much exciting to develop such a complex system delivering such a simplicity to the users. Product driven, skilled and happy team.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="tech-testimonial-row">
              <div class="tech-testimonial-inner-block">
                <div class="tech-user-detail">
                  <div class="userImg">
                    <img src="assets/images/testimonials/david-scholar.jpg" alt="David Scholar" width="150" height="150">
                  </div>
                  <div class="user-name-block">
                    <h3>David</h3>
                    <span>CEO</span>
                  </div>
                </div>
                <div class="testimonial-desc">
                  <div class="testimonial-inner-desc">
                    <p>They are very professional and skillful. They listen to my requirement. Suggest the best tools and technology for the job. They are committed to work.</p>
                  </div>
                </div>
              </div>
          </div>
          <div class="tech-testimonial-row">
            <div class="tech-testimonial-inner-block">
              <div class="tech-user-detail">
                <div class="userImg">
                  <!-- <img src="assets/images/smarter.webp" width="150" height="150" alt="Sam"> -->
                  <span>D</span>
                </div>
                <div class="user-name-block">
                  <h3>Danial</h3>
                  <span>Co-Founder and CEO</span>
                </div>
              </div>
              <div class="testimonial-desc">
                <div class="testimonial-inner-desc">
                  <p>Working with this team for more than 3 years now. Developers are different now but the delivery standards are same with even better productivity as they understand the application much better. Team is genuine to make sure we are not overbilled for the value being delivered. Strong engineering and creative team helped us achieve more than what is expected.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="tech-testimonial-row">
            <div class="tech-testimonial-inner-block">
                <div class="tech-user-detail">
                    <div class="userImg">
                        <img src="assets/images/testimonials/sona.jpg" alt="Sona">
                    </div>
                    <div class="user-name-block">
                        <h3>Sona</h3>
                        <span>CEO</span>
                    </div>
                </div>
                <div class="testimonial-desc">
                    <div class="testimonial-inner-desc">
                        <p>When I hired Techcompose, we started making big changes and I could see the impact right away. Highly recommend to all</p>
                    </div>
                </div>
            </div>
          </div>
          <div class="tech-testimonial-row">
            <div class="tech-testimonial-inner-block">
                <div class="tech-user-detail">
                    <div class="userImg">
                        <span>A</span>
                    </div>
                    <div class="user-name-block">
                        <h3>Abhay Singh</h3>
                        <span>Co-Founder and CEO</span>
                    </div>
                </div>
                <div class="testimonial-desc">
                    <div class="testimonial-inner-desc">
                        <p>The team at TechCompose loves taking on new projects and challenges that really excites them. They're not your average developers; they're forward-thinkers who value open dialogue about projects and offer insightful suggestions on the best course of action. When it came to developing our website, they not only brought our vision to life flawlessly but also exceeded our expectations.</p>
                    </div>
                </div>
            </div>
          </div>
          <div class="tech-testimonial-row">
            <div class="tech-testimonial-inner-block">
              <div class="tech-user-detail">
                <div class="userImg">
                  <!-- <img src="assets/images/smarter.webp" width="150" height="150" alt="Boris"> -->
                  <span>B</span>
                </div>
                <div class="user-name-block">
                  <h3>Boris</h3>
                  <span>CEO</span>
                </div>
              </div>
              <div class="testimonial-desc">
                <div class="testimonial-inner-desc">
                  <p>Close and productive collaboration with TechCompose team just like a team available in our US Office. Team is equipped with all the best practices we are following for Agile, Desinging, Programming, Demo and Testing. Another well thought product developed with this team and it's fun and exciting to work them again.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="tech-testimonial-row">
            <div class="tech-testimonial-inner-block">
              <div class="tech-user-detail">
                <div class="userImg">
                  <!-- <img src="assets/images/smarter.webp" width="150" height="150" alt="Dheeraj"> -->
                  <span>D</span>
                </div>
                <div class="user-name-block">
                  <h3>Dheeraj</h3>
                  <span>CEO</span>
                </div>
              </div>
              <div class="testimonial-desc">
                <div class="testimonial-inner-desc">
                  <p>We have worked with TechCompose team for other projects in the past. We knew their skills, potentials and dicipline. That was the reason to choose them again for this new solution we are planning to develop. I would recommend TechCompose team any day as my preferred team to work with.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="tech-testimonial-row">
            <div class="tech-testimonial-inner-block">
              <div class="tech-user-detail">
                <div class="userImg">
                  <!-- <img src="assets/images/smarter.webp" width="150" height="150" alt="Dheeraj"> -->
                  <span>J</span>
                </div>
                <div class="user-name-block">
                  <h3>Julio</h3>
                  <span>CEO</span>
                </div>
              </div>
              <div class="testimonial-desc">
                <div class="testimonial-inner-desc">
                  <p>Based on 10 lines of concept, TechCompose team picked up the soul and in 3 milestones with 3 demos in 3 weeks, I am running my platform’s phase 1. The platform is as clean and functional as any enterprise solution out there. This is my 3rd project with TechCompose and they always involve and listen which reflects in the solutions they develop.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="tech-testimonial-row">
              <div class="tech-testimonial-inner-block">
                  <div class="tech-user-detail">
                      <div class="userImg">
                          <img src="assets/images/testimonials/nichol-stark.jpg" alt="Nichol Stark">
                      </div>
                      <div class="user-name-block">
                          <h3>Nichol Stark</h3>
                          <span>FOUNDER</span>
                      </div>
                  </div>
                  <div class="testimonial-desc">
                      <div class="testimonial-inner-desc">
                          <p>From the first interaction, I knew the team had everything under control. Collaborative, concise, creative and Excellent communication. Nothing was too hard. Really good team to work with</p>
                      </div>
                  </div>
              </div>
          </div>
          <div class="tech-testimonial-row">
              <div class="tech-testimonial-inner-block">
                  <div class="tech-user-detail">
                      <div class="userImg">
                          <img src="assets/images/testimonials/marco.png" alt="Marco">
                      </div>
                      <div class="user-name-block">
                          <h3>Marco</h3>
                          <span>CEO</span>
                      </div>
                  </div>
                  <div class="testimonial-desc">
                      <div class="testimonial-inner-desc">
                          <p>Great company who are able to translate an idea for a webshop into reality. For me it’s a huge challenge, for Techcompose Solution it is ‘no big deal’. Very happy with the results and can recommend this company to everyone.</p>
                      </div>
                  </div>
              </div>
          </div>
          <div class="tech-testimonial-row">
              <div class="tech-testimonial-inner-block">
                  <div class="tech-user-detail">
                      <div class="userImg">
                          <img src="assets/images/testimonials/dinithi.png" alt="Dinithi">
                      </div>
                      <div class="user-name-block">
                          <h3>Dinithi</h3>
                          <span>CEO</span>
                      </div>
                  </div>
                  <div class="testimonial-desc">
                      <div class="testimonial-inner-desc">
                          <p>TechCompose has been a very productive company to work with. They assisted me in every way to create and host our company website. It has been a pleasure working with Techcompose team.</p>
                      </div>
                  </div>
              </div>
          </div>
          <div class="tech-testimonial-row">
              <div class="tech-testimonial-inner-block">
                  <div class="tech-user-detail">
                      <div class="userImg">
                          <img src="assets/images/testimonials/dike.png" alt="Dike">
                      </div>
                      <div class="user-name-block">
                          <h3>Dike</h3>
                          <span>CEO</span>
                      </div>
                  </div>
                  <div class="testimonial-desc">
                      <div class="testimonial-inner-desc">
                          <p>Excellent technical support and very very efficient in service delivery.</p>
                      </div>
                  </div>
              </div>
          </div>
          <div class="tech-testimonial-row">
              <div class="tech-testimonial-inner-block">
                  <div class="tech-user-detail">
                      <div class="userImg">
                          <img src="assets/images/testimonials/joel.jpg" alt="Joel">
                      </div>
                      <div class="user-name-block">
                          <h3>Joel</h3>
                          <span>CEO</span>
                      </div>
                  </div>
                  <div class="testimonial-desc">
                      <div class="testimonial-inner-desc">
                          <p>This is very professional company that takes their job seriously. They completed the job correctly and quickly. I have worked with all pleasures! I will work with them again.</p>
                      </div>
                  </div>
              </div>
          </div>
          <div class="tech-testimonial-row">
              <div class="tech-testimonial-inner-block">
                  <div class="tech-user-detail">
                      <div class="userImg">
                          <img src="assets/images/testimonials/david.jpg" alt="David">
                      </div>
                      <div class="user-name-block">
                          <h3>David</h3>
                          <span>CEO</span>
                      </div>
                  </div>
                  <div class="testimonial-desc">
                      <div class="testimonial-inner-desc">
                          <p>I would highly recommend Techcompose to any person. We had an incredibly difficult and complex issue regarding a setup on AWS using Ruby on Rails – Their team were able to look through all aspects of the setup until the issue was found and then resolved the issue in a timely manner, then they helped with upgrading our server as per our request which was also a large job.</p>
                      </div>
                  </div>
              </div>
          </div>
          <div class="tech-testimonial-row">
              <div class="tech-testimonial-inner-block">
                  <div class="tech-user-detail">
                      <div class="userImg">
                          <img src="assets/images/testimonials/darwin.jpg" alt="Darwin">
                      </div>
                      <div class="user-name-block">
                          <h3>Darwin</h3>
                          <span>CEO</span>
                      </div>
                  </div>
                  <div class="testimonial-desc">
                      <div class="testimonial-inner-desc">
                          <p>It’s been a pleasure working with entire TechCompose team on all our data infrastructure and development needs over the last two years. their commitment to excellence and integrity is reflected in the quality of work delivered by entire team. I highly recommend TechCompose for any development need.</p>
                      </div>
                  </div>
              </div>
          </div>
          <div class="tech-testimonial-row">
              <div class="tech-testimonial-inner-block">
                  <div class="tech-user-detail">
                      <div class="userImg">
                          <img src="assets/images/testimonials/javier.jpg" alt="Javier">
                      </div>
                      <div class="user-name-block">
                          <h3>Javier</h3>
                          <span>CEO</span>
                      </div>
                  </div>
                  <div class="testimonial-desc">
                      <div class="testimonial-inner-desc">
                          <p>TechCompose Solutions is an honest and decent group of people with highest work quality. Great company to work with. I get improved app with very good suggestions, and they are working with precision on the problem and as a result, quality is excellent. The team is affordable to hire and puts problem-solving at first. They are same as any other great development team from the US.</p>
                      </div>
                  </div>
              </div>
          </div>
          <div class="tech-testimonial-row">
              <div class="tech-testimonial-inner-block">
                  <div class="tech-user-detail">
                      <div class="userImg">
                          <img src="assets/images/testimonials/stacey.jpg" alt="Stacey">
                      </div>
                      <div class="user-name-block">
                          <h3>Stacey</h3>
                          <span>CEO</span>
                      </div>
                  </div>
                  <div class="testimonial-desc">
                      <div class="testimonial-inner-desc">
                          <p>This company has exemplified superior and professional ability to deliver very difficult tasks in web development. They are respectful and pleasant and patient all the time, and that is not common with developers. Their ability to perform this intrinsic database site was amazing. Hire them… you will be in good hands!</p>
                      </div>
                  </div>
              </div>
          </div>
          <div class="tech-testimonial-row">
              <div class="tech-testimonial-inner-block">
                  <div class="tech-user-detail">
                      <div class="userImg">
                          <img src="assets/images/testimonials/charles.png" alt="Charles">
                      </div>
                      <div class="user-name-block">
                          <h3>Charles</h3>
                          <span>CEO</span>
                      </div>
                  </div>
                  <div class="testimonial-desc">
                      <div class="testimonial-inner-desc">
                          <p>The handling of my web development project was done professionally with a great team of web developers on 24 hours standby to attend to our requests. I will be pleased to work more closely with Techcompose team in future web development projects. Your web development tools are also unique, friendly and dynamic</p>
                      </div>
                  </div>
              </div>
          </div>
          <div class="tech-testimonial-row">
              <div class="tech-testimonial-inner-block">
                  <div class="tech-user-detail">
                      <div class="userImg">
                          <img src="assets/images/testimonials/mm-david.png" alt="David">
                      </div>
                      <div class="user-name-block">
                          <h3>David</h3>
                          <span>CEO</span>
                      </div>
                  </div>
                  <div class="testimonial-desc">
                      <div class="testimonial-inner-desc">
                          <p>TechCompose Solutions assisted us in a very timely manner and completed the job with great success. They are very well in communication and would highly recommend them.</p>
                      </div>
                  </div>
              </div>
          </div>
          <div class="tech-testimonial-row">
              <div class="tech-testimonial-inner-block">
                  <div class="tech-user-detail">
                      <div class="userImg">
                          <img src="assets/images/testimonials/anthony.jpg" alt="Anthony">
                      </div>
                      <div class="user-name-block">
                          <h3>Anthony</h3>
                          <span>CEO</span>
                      </div>
                  </div>
                  <div class="testimonial-desc">
                      <div class="testimonial-inner-desc">
                          <p>TechCompose has been very instrumental to the project I am building. Technically adept at delivering tasks on time and within budget. Great team!</p>
                      </div>
                  </div>
              </div>
          </div>
          <div class="tech-testimonial-row">
              <div class="tech-testimonial-inner-block">
                  <div class="tech-user-detail">
                      <div class="userImg">
                          <img src="assets/images/testimonials/olakunle-oyetayo.jpg" alt="Olakunle-Oyetayo">
                      </div>
                      <div class="user-name-block">
                          <h3>Olakunle Oyetayo</h3>
                          <span>CEO</span>
                      </div>
                  </div>
                  <div class="testimonial-desc">
                      <div class="testimonial-inner-desc">
                          <p>Awesome bunch of developers!, have been very helpful helping us with the development of our platform!</p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
  <app-footer></app-footer>
</div>
