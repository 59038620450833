<div class="cursor">
    <!-- <i class="left-cursor">
        <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 9L1 5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M1 5L5 1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </i>
    <i class="right-cursor">
        <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L5 5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5 5L1 9" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </i> -->
</div>
<!-- <div class="cursor-follower"></div> -->

<!-- <div class="loading" *ngIf="loading">
    <app-loader></app-loader>
</div>
<div *ngIf="!loading">
    <app-header></app-header>
</div> -->
<app-project-drawer (closeDOM)="closeDOM()" *ngIf="openProjectDrawer"></app-project-drawer>
<!-- <app-social class="home-social-block" *ngIf="showSocialSymbol"></app-social> -->
<app-social class="home-social-block"></app-social>
<!-- <main class="viewport">
    <div class="scroller">
        <div class="scroll-container" id="scroll-container">
            <div class="" *ngIf="!loading">
                <div class="page-grid-block">
                    <div class="page-grid-column"></div>
                    <div class="page-grid-column"></div>
                    <div class="page-grid-column"></div>
                    <div class="page-grid-column"></div>
                    <div class="page-grid-column"></div>
                    <div class="page-grid-column"></div>
                    <div class="page-grid-column"></div>
                    <div class="page-grid-column"></div>
                </div>
                <router-outlet></router-outlet>
                <app-footer></app-footer>
            </div>
            <app-about></app-about>
        </div>
    </div>
</main> -->
<router-outlet></router-outlet>

<!-- <audio loop="loop" autoplay="autoplay">
    <source src="assets/images/audio/tech-audio.mp3" type="audio/mpeg">
    Your browser does not support the audio element.
</audio> -->
<!-- <button #button style="visibility: hidden" (click)="playAudio()"></button> -->
