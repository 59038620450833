import { getFirestore } from '@angular/fire/firestore';
import { AfterViewInit, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import SplitType from 'split-type';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { sharedService } from '../shared/shared.service';
import { ApiService } from '../api.service';

import { Title, Meta } from '@angular/platform-browser';

import Scrollbar, { ScrollbarPlugin } from 'smooth-scrollbar';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements AfterViewInit, OnDestroy, OnInit {

  bodyScrollBar: Scrollbar | undefined;
  public isHeaderMenuOpen: boolean = false;

  private listeners: any[] = [];
  /**
   * contactus form
   */
  public contactUsForm!: FormGroup;
  public successMsg: string = '';
  public currentUrl: string = window.location.href;
  public baseUrl: string = window.location.origin;
  constructor(private renderer: Renderer2,
    private fb: FormBuilder,
    private apiService: ApiService,
    private metaTagService: Meta, private titleService: Title,
    public sharedservice: sharedService,
    private db: AngularFirestore
    ) { }

  public ngAfterViewInit(): void {
    gsap.registerPlugin(ScrollTrigger);
    gsap.fromTo('.career-info-detail p', {
        opacity: 0,
        y: 30
    }, {
        y: 0,
        opacity: 1,
        duration: 0.5,
        ease: 'power1',
        delay: 0.8
    });
    const aboutpagetitle = new SplitType('.page-title h1',{
        types: 'chars, words',
        absolute: true
    });

    gsap.from(aboutpagetitle.chars, {
        opacity: 0,
        x: 20,
        duration: 0.7,
        delay: 0.7,
        stagger: 0.03,
    });
    // const contactInfo = gsap.utils.toArray('.connect-us-col');
    // gsap.set(contactInfo, {})
    // const t2 = gsap.timeline({scrollTrigger: {
    //     trigger: '.connect-us-col',
    //     start: 'top 80%',
    // }}).from(contactInfo, {opacity: 0, y: 50, duration: 0.5, ease: 'power1'});

    // indiaInfo
    // usInfo

    gsap.fromTo ("#indiaInfo",{
      opacity: 0, y: 50
    },{
      opacity: 1, y: 0, duration: 0.5, ease: 'power1',
      scrollTrigger: {
        trigger: '#indiaInfo',
        start: 'top 55%',
      }
    })
    gsap.fromTo ("#usInfo",{
      opacity: 0, y: 50
    },{
      opacity: 1, y: 0, duration: 0.5, ease: 'power1',
      scrollTrigger: {
        trigger: '#usInfo',
        start: 'top 55%',
      }
    })


    const contactTitleInfo: any = gsap.utils.toArray('.contact-form-title > *');
    gsap.set(contactTitleInfo, {autoAlpha: 0, y: 50});

    contactTitleInfo.forEach((contactTitle: any) => {
        const anim = gsap.to(contactTitle, { duration: 1, autoAlpha: 1, y: 0, paused: true });
        ScrollTrigger.create({
            trigger: contactTitle,
            start: 'top 80%',
            once: true,
            onEnter: self => {
                self.progress === 1 ? anim.progress(1) : anim.play();
            }
        });
    });

    const formRow: any = gsap.utils.toArray('.formField');
    gsap.set(formRow, {autoAlpha: 0, y: 50});

    formRow.forEach((formFieldBox: any) => {
        const anim = gsap.to(formFieldBox, { duration: 1, autoAlpha: 1, y: 0, paused: true });
        ScrollTrigger.create({
            trigger: formFieldBox,
            start: 'top 80%',
            once: true,
            onEnter: self => {
                self.progress === 1 ? anim.progress(1) : anim.play();
            }
        });
    });

    gsap.fromTo('.map-img-block', {
        autoAlpha: 0,
    }, {
        autoAlpha: 1,
        duration: 0.25,
        delay: 1,
    });

    // const listener: any = this.renderer.listen(
    //   window,
    //   'resize',
    //   () => {
    //     this.resize();
    // });
    // this.resize();
    // this.listeners.push(listener);

    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
    });

    ScrollTrigger.create({
      trigger: ".header-class",
      start: "+50px",
      end: "bottom -=1000%",
      toggleClass: { targets: ".header", className: "active" }
    });

  }

  public ngOnInit(): void {

    this.titleService.setTitle("Contact Us For Website development and Mobile App Development");

    this.metaTagService.updateTag({ name: 'keywords', content: 'Contact Us. Techcompose is a software development, website development and Mobile App development company in India and USA. Contact TechCompose Solutions to Hire Dedicated Developers for web design and development, android and ios app development, software testing and development.' });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Techcompose | Contact Us For Website development and Mobile App Development' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:url', content: `${this.currentUrl}` });
    this.metaTagService.updateTag({ property: 'og:image', content: `${this.baseUrl}/assets/images/thumb.png` });
    this.metaTagService.updateTag({ property: 'og:description', content: 'Contact Us. Techcompose is a software development, website development and Mobile App development company in India and USA. Contact TechCompose Solutions to Hire Dedicated Developers for web design and development, android and ios app development, software testing and development.' });
    this.metaTagService.removeTag("name='google-site-verification'");

    this.sharedservice.showHeaderMenu$.subscribe((data) => {
      this.isHeaderMenuOpen = data;
      if (this.bodyScrollBar) {
        this.bodyScrollBar.updatePluginOptions('modal', { open: this.isHeaderMenuOpen });
      }
    });

    this.contactUsForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', { validators: [Validators.required,
        Validators.pattern('^[A-Za-z0-9._+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$')]}],
      phone_number: [''],
      company: [''],
      message: ['', Validators.required],
    })

    gsap.registerPlugin(ScrollTrigger);
    const scroller = document.querySelector('.scroller')as HTMLElement;

    this.bodyScrollBar = Scrollbar.init(scroller, { damping: 0.1, delegateTo: document, alwaysShowTracks: true });

    const content = this;
    ScrollTrigger.scrollerProxy(".scroller", {
      scrollTop(value) {
        if (content.bodyScrollBar) {
          if (arguments.length) {
            content.bodyScrollBar.scrollTop = value || 0;
          }
          return content.bodyScrollBar.scrollTop;
        } else {
          return 0;
        }
      }
    });

    this.bodyScrollBar.addListener(ScrollTrigger.update);
    ScrollTrigger.defaults({ scroller: scroller });

  }

  public submit(): void {

    const emailBody: any = `
    TechCompose Contact Form:\n\n
    
    Name: ${this.contactUsForm?.value?.name}\n
    Email: ${this.contactUsForm?.value?.email}\n
    Phone Number: ${this.contactUsForm?.value?.phone_number}\n
    Company: ${this.contactUsForm?.value?.company}\n
    Message: ${this.contactUsForm?.value?.message}\n \n

    Thank you.
    `;

    const emailBodyHTML: any = `
    TechCompose Contact Form:<br><br>

    Name: ${this.contactUsForm?.value?.name}<br>
    Email: ${this.contactUsForm?.value?.email}<br> 
    Phone Number: ${this.contactUsForm?.value?.phone_number}<br>
    Company: ${this.contactUsForm?.value?.company}<br>
    Message: ${this.contactUsForm?.value?.message}<br><br>

    Thank you.
    `;

    const emailParams: any = {
      to: ["dharinrajgor@techcompose.com", "urjitrajgor@techcompose.com"],
      message: {
        subject: `Enquiry received from ${this.contactUsForm.value?.name}`,
        text: emailBody,
        html: emailBodyHTML,
      },
    };
 
    this.db.collection('mail').add(emailParams)
    .then((res: any) => {

      this.db.collection('contact_enquiry').add(this.contactUsForm?.value)
      .then((res: any) => {
        this.contactUsForm.reset();
        this.successMsg = 'Contact us form submitted successfully!';
      })
      .catch((error: any) => {
        this.contactUsForm.reset();
        this.successMsg = 'Contact us form submitted successfully!';
      });
    })
    .catch((error: any) => {
      console.error('Error adding data: ', error);
    });
  }

  // public resize(): void {
  //   if (window.matchMedia('(min-width: 1199px)').matches) {
  //       gsap.set('.address-block', {autoAlpha: 0});
  //       const btns = document.getElementsByClassName('addressBtn');
  //       let listener: any;
  //       for (let i = 0; i < btns.length; i++) {
  //         const id: string = btns[i].getAttribute('id') || '#indiaInfo';
  //         listener = this.renderer.listen(
  //             btns[i],
  //             'mouseenter',
  //             () => {
  //               gsap.to(id, {autoAlpha: 1, ease: Power2.easeIn, duration: 0.2});
  //         });
  //         this.listeners.push(listener);
  //         listener = this.renderer.listen(
  //             btns[i],
  //             'mouseleave',
  //             () => {
  //               gsap.to(document.getElementById(id.replace('#', '')),
  //                 {autoAlpha: 0, ease: Power2.easeIn, duration: 0.2});
  //         });
  //         this.listeners.push(listener);
  //       }
  //   } else {
  //       const contactInfo = gsap.utils.toArray('.address-block');
  //       const tl = gsap.timeline({scrollTrigger: {
  //           trigger: '.address-block',
  //           start: 'top 80%',
  //       }}).from(contactInfo, {opacity: 0, y: 50, stagger: 0.1, duration: 0.5, delay: 0.25, ease: 'power1'}, 0);
  //     }
  // }

  public ngOnDestroy(): void {
      this.listeners.forEach(listener => listener());
  }
}
class ModalPlugin extends ScrollbarPlugin {
  static pluginName = 'modal';

  static defaultOptions = {
    isHeaderMenuOpen: false
  };

  transformDelta(delta: any) {
      return this.options.open ? { x: 0, y: 0 } : delta;
  }
}

Scrollbar.use(ModalPlugin, /* OverscrollPlugin */);
