<app-header></app-header>
<app-header-menu *ngIf="isHeaderMenuOpen"></app-header-menu>
<div class="scroller">
  <div class="header-class"></div>
  <div class="page-grid-block">
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
  </div>
  <div class="portfolio-wrapper">
    <div class="portfolio-overlay"></div>
    <div class="portfolio-detail-wrapper">
        <div class="portfolio-detail-inner">
            <div class="back-btn-block">
              <a [routerLink]="['/work']" class="back-btn">
                <i>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.828 11.0007H22V13.0007H5.828L11.192 18.3647L9.778 19.7787L2 12.0007L9.778 4.22266L11.192 5.63666L5.828 11.0007Z" fill="black"/>
                  </svg>
                </i>
                Back to Portfolio
              </a>
            </div>
            <div class="project-logo">
                <img src="assets/images/project-images/facturazen/logo.png" alt="Facturazen">
            </div>
            <h2 class="probstatement">
                <span>Problem Statement </span>
                As a business or freelancer, need simple interface to generate invoices, maintain product catalog and customer details for everyone.
            </h2>
            <div class="challange-block">
                <div class="challange-list">
                    <ul>
                        <li>
                            <h3 class="section-title section-title-sub">Study</h3>
                            <ul>
                                <li class="work-info">Many invoice generation platforms</li>
                                <li class="work-info">Dealing with multiple currencies and languages</li>
                                <li class="work-info">Support for different taxes</li>
                                <li class="work-info">Many features related to Invoice</li>
                                <li class="work-info">Provides summary of the payment collection</li>
                            </ul>
                        </li>
                        <li>
                            <h3 class="section-title section-title-sub">Challenges</h3>
                            <ul>
                                <li class="work-info">Difficult and time consuming to set up business and customers</li>
                                <li class="work-info">Confusing user interface and unclear process flow</li>
                                <li class="work-info">Too many modules makes users confused</li>
                                <li class="work-info">User only understand complete features from Invoice page only</li>
                                <li class="work-info">Too much focus on various templates and customizations</li>
                                <li class="work-info">Time consuming and Re-work till generating an Invoice</li>
                                <li class="work-info">Missing Mobile-friendly User Interface</li>
                                <li class="work-info">Consistency of the information</li>
                                <li class="work-info">Performance</li>
                            </ul>
                        </li>
                        <li>
                            <h3 class="section-title section-title-sub">Approach</h3>
                            <ul>
                              <li class="work-info">Identify and Remove the Noice for better clarity</li>
                              <li class="work-info">Focused on simple UI/UX with Flowbite (tailwind css)</li>
                              <li class="work-info">Use i18n library for supporting multiple languages through out the platform</li>
                              <li class="work-info">Simple PDF template to conclude the invoice</li>
                              <li class="work-info">Maintaining Invoice status and Payment status</li>
                              <li class="work-info">Realtime Dashboard to understand Total/Due/Paid amount using Hotwire</li>
                            </ul>
                        </li>
                        <li>
                            <h3 class="section-title section-title-sub">Involvement</h3>
                            <ul>
                              <li class="work-info">10 lines of concept to actual customer ready platform in 3 weeks</li>
                              <li class="work-info">Database Design to cover core features</li>
                              <li class="work-info">User interface planning based on Flowbite</li>
                              <li class="work-info">Validations and Notifications</li>
                              <li class="work-info">PDF generation and Email attachments</li>
                              <li class="work-info">i18n supported Text content throughout the platform</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="project-img-block">
        <div class="portfolio-detail-wrapper pt-0">
            <div class="portfolio-detail-inner">
                <div class="project-img-block-inner">
                    <div class="main-img">
                      <img src="assets/images/project-images/facturazen/facturazen-1.webp" alt="Facturazen">
                    </div>
                    <div class="img-block-2">
                      <img src="assets/images/project-images/facturazen/facturazen-2.webp" alt="Facturazen">
                    </div>
                    <div class="img-block-3">
                      <img src="assets/images/project-images/facturazen/facturazen-3.webp" alt="Facturazen">
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="bg-img-block">
            <img src="assets/images/project-images/lmb/img-1-bg.webp" alt="">
        </div> -->
    </div>
    <div class="portfolio-detail-wrapper pt-0">
        <div class="portfolio-detail-inner">
            <div class="visule-guide pt-0">
                <h2>Visual Guide</h2>
                <div class="visule-guide-row">
                    <div class="visule-guide-col">
                        <h3 class="section-title section-title-sub">TYPE FACE</h3>
                        <label>Inter</label>
                        <p>a quick brown fox jumps over the lazy dog</p>
                    </div>
                    <div class="visule-guide-col">
                        <h3 class="section-title section-title-sub">COLOR</h3>
                        <ul class="color-list">
                            <li style="background: #3f3f46; color: #fff; border: none">
                              #3f3f46
                            </li>
                            <li style="background: #AEAEAE; color: #fff; border: none">
                              #AEAEAE
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="outcome-box-wrapper">
        <div class="outcome-box-inner">
            <div class="outcome-box-info">
                <div class="outcome-box-info-inner">
                    <h3 class="section-title section-title-sub">THE OUTCOME</h3>
                    <!-- <p>Front-end is very responsive and light weight to interact for our users. Smooth and uniform experience for users while interacting with the application.</p> -->
                    <ul>
                      <li>Concept to Deliver in 3 Weeks</li>
                      <li>Supporting multiple businesses by one user</li>
                      <li>2 mins – from Sign Up to an Invoice(Branding/PDF/Email/Taxes)</li>
                      <li>Create product catalog of 1000’s of items</li>
                      <li>Create Items and Customers from the Invoice</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="outcome-img-block">
            <img src="assets/images/project-images/facturazen/outcome-box.webp" alt="Facturazen">
        </div>
    </div>
    <div class="project-info">
        <div class="project-info-inner">
            <div class="portfolio-quote">
                <p>
                    <i class="quote-icon">
                        <img src="assets/images/quote-icon.svg" alt="Quote Icon">
                    </i>
                    Based on 10 lines of concept, TechCompose team picked up the soul and in 3 milestones with 3 demos in 3 weeks, I am running my platform’s phase 1. The platform is as clean and functional as any enterprise solution out there. This is my 3rd project with TechCompose and they always involve and listen which reflects in the solutions they develop. </p>
                <div class="client-quote-info">
                  <div class="client-img">
                    <!-- <img src="assets/images/smarter.webp" alt="LinkMyBooks"> -->
                    <span>J</span>
                  </div>
                  <div class="client-name">
                    <h3>Julio</h3>
                    <span>excepteur</span>
                  </div>
                </div>
            </div>
            <div class="work-detail-box">
                <div class="work-detail-box-inner">
                    <h5>Connect with us</h5>
                    <p class="work-info">If you have a concept/vision/product and you are looking for a right <span>design and developer team</span> OR you have right technical skills and looking for <span>exciting projects/environment to experience</span>, drop a message via contact us form. </p>
                    <div class="contact-btn-block">
                        <a [routerLink]="['/', 'contact-us']"><span>Contact Now</span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <section class="horizontal">
        <div class="more-project-ovrelay"></div>
        <div class="pin-wrap">
            <div class="projects-title-block">
                <h2>More Works</h2>
            </div>
            <div class="animation-wrap to-right">
                <a class="item" [routerLink]="['/', 'work', 'build-effective']">
                    <div class="project-img">
                        <img src="assets/images/project-images/project-thumbnail/build-effective.webp" alt="Build Effective">
                    </div>
                    <div class="project-slider-info">
                        <span>Real Estate</span>
                        <h2>Buildeffective</h2>
                    </div>
                </a>
                <a class="item" [routerLink]="['/', 'work', 'ar-workflow']">
                    <div class="project-img">
                        <img src="assets/images/project-images/project-thumbnail/ar-workflow.webp" alt="ARWorkflow">
                    </div>
                    <div class="project-slider-info">
                        <span>Accounting</span>
                        <h2>ARWorkflow</h2>
                    </div>
                </a>
                <a class="item" [routerLink]="['/', 'work', 'linkmybooks']">
                  <div class="project-img">
                      <img src="assets/images/project-images/project-thumbnail/link-my-books.webp" alt="LinkMyBooks">
                  </div>
                  <div class="project-slider-info">
                      <span>Business</span>
                      <h2>LinkMyBooks</h2>
                  </div>
                </a>
                <a class="item" [routerLink]="['/', 'work', 'ccalerts']">
                    <div class="project-img">
                        <img src="assets/images/project-images/project-thumbnail/ccalerts.webp" alt="CCAlerts">
                    </div>
                    <div class="project-slider-info">
                        <span>Finance</span>
                        <h2>CCAlerts</h2>
                    </div>
                </a>
                <a class="item" [routerLink]="['/', 'work', 'talowiz']">
                    <div class="project-img">
                        <img src="assets/images/project-images/project-thumbnail/talowiz.webp" alt="Talowiz">
                    </div>
                    <div class="project-slider-info">
                        <span>Education</span>
                        <h2>Talowiz</h2>
                    </div>
                </a>
                <a class="item" [routerLink]="['/', 'work', 'percentology']">
                    <div class="project-img">
                        <img src="assets/images/project-images/project-thumbnail/percentology.webp" alt="Percentology">
                    </div>
                    <div class="project-slider-info">
                        <span>Account</span>
                        <h2>Percentology</h2>
                    </div>
                </a>
            </div>
        </div>
    </section>
  </div>
  <app-footer></app-footer>
</div>
