import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

  
import { SharedModule } from '../shared/shared.module';
import { AboutRoutingModule } from './about-routing.module';
import { DigitalMarketingComponent } from './digital-marketing/digital-marketing.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { OurProcessComponent } from './our-process/our-process.component';


@NgModule({
  declarations: [ 
    DigitalMarketingComponent,
    AboutUsComponent,
    OurProcessComponent
  ],
  imports: [
    CommonModule,
    AboutRoutingModule,
    SharedModule
  ]
})
export class AboutModule { }
