import { AfterViewInit, Component, } from '@angular/core';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { sharedService } from '../shared/shared.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements AfterViewInit {

  constructor(public sharedService: sharedService) { }

  public ngAfterViewInit(): void {
    gsap.registerPlugin(ScrollTrigger);

    // const listones = gsap.utils.toArray('.footer-col-block');
    // const t2 = gsap.timeline({scrollTrigger: {
    //     trigger: '.FooterBlock',
    //     start: 'top 100%',
    //     end: 'bottom bottom',
    // }}).from(listones, {opacity: 0, y: 50, duration: 0.5, ease: 'power1'} , 0);

    // gsap.fromTo(".footer-title .sectionTitle",{y: 50, autoAlpha: 0},
    //   { autoAlpha: 1, y: 0, duration: 0.5, ease: 'power1', scrollTrigger: {
    //   trigger: '.FooterBlock',
    //   start: 'top 100%',
    //   end: 'bottom bottom',
    // }});
    gsap.config({
      nullTargetWarn: false,
    });
  }

  public startProject(): void {
    this.sharedService.projectDraw(true);
  }

}
