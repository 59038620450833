
<app-header></app-header>
<app-header-menu *ngIf="isHeaderMenuOpen"></app-header-menu>
<div class="scroller">
  <div class="header-class"></div>
  <div class="page-grid-block">
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
  </div>
  <div class="grid-background-overlay career-grid-background-overlay"></div>
  <section class="career-hero dark">
      <app-tech-logo></app-tech-logo>
      <div class="career-hero-container">
          <div class="career-hero-block">
              <div class="career-hero-inner-block">
                  <div class="page-title">
                      <h1>Careers</h1>
                  </div>
                  <div class="career-info-detail page-info">
                      <p>Are you looking for a career in IT Industry? At Techcompose Solutions, we are hiring. Check out this careers page for current job openings or vacancy in Ahmedabad for Ruby on Rails developer, WordPress developer, Laravel developer, Business development manager, Web and graphic designer, Ionic developer and many more. Contact us for more details.</p>
                  </div>
              </div>
          </div>
      </div>
  </section>

  <section class="work-info-wrapper light">
      <div class="work-info-top">
          <div class="reason-work-detail">
              <h2>Reasons To Work At TechCompose</h2>
              <p>Talking about us and how we work, we always practice proven agile development process with proactive approach specific to protect all complexity with simple and fully-fledged software, website, web application, mobile app development solutions.</p>
          </div>
      </div>
      <div class="benefits-info">
          <ul>
              <li>
                  <div class="benefits-info-inner">
                      <h3 class="title-letter-anim">Competitive Salary</h3>
                      <p class="careers-info">We offer best in the industry and the most competitive salary package based on the skill set of candidates for the different designations in a company.</p>
                  </div>
              </li>
              <li>
                  <div class="benefits-info-inner">
                      <h3 class="title-letter-anim">Growth & Learning</h3>
                      <p class="careers-info">We are constantly striving to create new development opportunities for our employees by conducting seminar and training.</p>
                  </div>
              </li>
              <li>
                  <div class="benefits-info-inner">
                      <h3 class="title-letter-anim">Values & Diversity</h3>
                      <p class="careers-info">Every employee of Techcompose abides by the following values commitment to the client’s success, creative and innovative solutions.</p>
                  </div>
              </li>
              <li>
                  <div class="benefits-info-inner">
                      <h3 class="title-letter-anim">Flexibility</h3>
                      <p class="careers-info">With our cheerful, friendly and supportive working environment, we encourage creativity and innovation as well as, professional growth.</p>
                  </div>
              </li>
          </ul>
      </div>
  </section>

  <section class="tc-life">
      <h2 class="title-letter-anim">Life At TechCompose</h2>
      <swiper [config]="config">
          <div class="swiper-wrapper">
              <div class="swiper-slide">
                  <div class="tc-life-img">
                      <img src="/assets/images/career/photo-1.webp" alt="TCLife">
                  </div>
              </div>
              <div class="swiper-slide">
                  <div class="tc-life-img">
                      <img src="/assets/images/career/photo-2.webp" alt="TCLife">
                  </div>
              </div>
              <div class="swiper-slide">
                  <div class="tc-life-img">
                      <img src="/assets/images/career/photo-3.webp" alt="TCLife">
                  </div>
              </div>
              <div class="swiper-slide">
                  <div class="tc-life-img">
                      <img src="/assets/images/career/photo-4.webp" alt="TCLife">
                  </div>
              </div>
              <div class="swiper-slide">
                  <div class="tc-life-img">
                      <img src="/assets/images/career/photo-5.webp" alt="TCLife">
                  </div>
              </div>
              <div class="swiper-slide">
                  <div class="tc-life-img">
                      <img src="/assets/images/career/photo-6.webp" alt="TCLife">
                  </div>
              </div>
          </div>
      </swiper>
      <swiper [config]="config" dir="rtl">
          <div class="swiper-wrapper">
              <div class="swiper-slide">
                  <div class="tc-life-img">
                      <img src="/assets/images/career/photo-7.webp" alt="TCLife">
                  </div>
              </div>
              <div class="swiper-slide">
                  <div class="tc-life-img">
                      <img src="/assets/images/career/photo-19.webp" alt="TCLife">
                  </div>
              </div>
              <div class="swiper-slide">
                  <div class="tc-life-img">
                      <img src="/assets/images/career/photo-9.webp" alt="TCLife">
                  </div>
              </div>
              <div class="swiper-slide">
                  <div class="tc-life-img">
                      <img src="/assets/images/career/photo-10.webp" alt="TCLife">
                  </div>
              </div>
              <div class="swiper-slide">
                  <div class="tc-life-img">
                      <img src="/assets/images/career/photo-11.webp" alt="TCLife">
                  </div>
              </div>
              <div class="swiper-slide">
                  <div class="tc-life-img">
                      <img src="/assets/images/career/photo-12.webp" alt="TCLife">
                  </div>
              </div>
          </div>
      </swiper>
      <swiper [config]="config">
          <div class="swiper-wrapper">
              <div class="swiper-slide">
                  <div class="tc-life-img">
                      <img src="/assets/images/career/photo-13.webp" alt="TCLife">
                  </div>
              </div>
              <div class="swiper-slide">
                  <div class="tc-life-img">
                      <img src="/assets/images/career/photo-14.webp" alt="TCLife">
                  </div>
              </div>
              <div class="swiper-slide">
                  <div class="tc-life-img">
                      <img src="/assets/images/career/photo-15.webp" alt="TCLife">
                  </div>
              </div>
              <div class="swiper-slide">
                  <div class="tc-life-img">
                      <img src="/assets/images/career/photo-16.webp" alt="TCLife">
                  </div>
              </div>
              <div class="swiper-slide">
                  <div class="tc-life-img">
                      <img src="/assets/images/career/photo-17.webp" alt="TCLife">
                  </div>
              </div>
              <div class="swiper-slide">
                  <div class="tc-life-img">
                      <img src="/assets/images/career/photo-18.webp" alt="TCLife">
                  </div>
              </div>
          </div>
      </swiper>
  </section>

  <section class="job-openingsblock">
      <div class="opening-title-block">
          <h2>Job Openings</h2>
      </div>
  </section>
  <section class="job-openinings-list-wrapper light">
      <div class="job-openinings-list">
          <ul class="job-list-block">
              <li *ngFor="let career of careerList">
                <button type="button"
                  class="btn opnening-btn"
                  (click)="redirectTojobOpening(career)">
                  {{ career['attributes']['job_title'] }}
                  <span>{{ career['attributes']['number_of_opening'] }}</span>
                </button>
              </li>
          </ul>
      </div>
  </section>
  <app-footer></app-footer>
</div>
