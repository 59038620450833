
<app-header></app-header>
<app-header-menu *ngIf="isHeaderMenuOpen"></app-header-menu>
<div class="scroller">
  <div class="header-class"></div>
  <div class="page-grid-block">
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
  </div>
  <div class="career-detail-overlay"></div>
  <div class="jop-apply-modal-box" *ngIf="selectedCareer">
    <app-tech-logo></app-tech-logo>
    <div class="job-info-box">
      <div class="job-info-box-inner">
        <div class="job-info-top">
          <div class="back-btn-block">
            <a [routerLink]="['/careers']" class="back-btn">
                <i>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.828 11.0007H22V13.0007H5.828L11.192 18.3647L9.778 19.7787L2 12.0007L9.778 4.22266L11.192 5.63666L5.828 11.0007Z" fill="black"/>
                    </svg>
                </i>
                Back to Career
            </a>
          </div>
          <div class="job-title-box">
            <div class="job-title-box-inner">
              <h1>Job Opening</h1>
              <h2>{{ selectedCareer['attributes']['job_title'] }}</h2>
              <span>- {{ selectedCareer['attributes']['number_of_opening'] }} position(s)</span>
            </div>
          </div>
          <div class="job-loc-block">
            <div class="job-loc-info">
              <div class="job-loc-col">
                <h3>Experience</h3>
                <span>{{ selectedCareer['attributes']['min_exp'] }}-{{ selectedCareer['attributes']['max_exp'] }} Yrs</span>
              </div>
              <div class="job-loc-col">
                <h3>Job Location</h3>
                <span>Ahmedabad</span>
              </div>
            </div>
            <div class="apply-btnblock">
              <button type="button" class="btn apply-btn" (click)="applyNow()"><span>Apply now!</span></button>
            </div>
          </div>
          <h4>Job Description</h4>
        </div>
        <div class="job-description-box" [innerHTML]="selectedCareer['attributes']['job_description']"></div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
