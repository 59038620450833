import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DelayResolver } from '../delay-resolver';
import { BlogListComponent } from './blog-list/blog-list.component';
import { BlogsComponent } from './blogs.component';
import { ContentVsDesignComponent } from './content-vs-design/content-vs-design.component';
import { ConvertHtmlToPdfComponent } from './convert-html-to-pdf/convert-html-to-pdf.component';
import { CreateGradientAndroidComponent } from './create-gradient-android/create-gradient-android.component';
import { ImportanceMethodologyComponent } from './importance-methodology/importance-methodology.component';
import { InternationalizationRorComponent } from './internationalization-ror/internationalization-ror.component';
import { RailsApiFastComponent } from './rails-api-fast/rails-api-fast.component';
import { ReasonsOfWhyRorComponent } from './reasons-of-why-ror/reasons-of-why-ror.component';
import { SlowWordpressWebsiteComponent } from './slow-wordpress-website/slow-wordpress-website.component';
import { UseOfRailsComponent } from './use-of-rails/use-of-rails.component';
import { TestcasesAngularComponent } from './testcases-angular/testcases-angular.component';

const routes: Routes = [
  {
    path: '',
    component: BlogsComponent,
    children: [
      {
        path: '',
        component: BlogListComponent,
      },
      {
        path: 'rails-6-api-fast_jsonapi-gem-with-devise-and-jwt-authentication',
        component: RailsApiFastComponent,
        resolve: {data: DelayResolver }
      },
      {
        path: 'use-of-rails-6-webpack-with-front-end-js-framework',
        component: UseOfRailsComponent,
        resolve: {data: DelayResolver }
      },
      {
        path: 'internationalization-i18n-in-ruby-on-rails',
        component: InternationalizationRorComponent,
        resolve: {data: DelayResolver }
      },
      {
        path: 'convert-html-to-pdf-in-codeigniter-using-dompdf',
        component: ConvertHtmlToPdfComponent,
        resolve: {data: DelayResolver }
      },
      {
        path: 'importance-of-agile-methodology-in-automation-testing',
        component: ImportanceMethodologyComponent,
        resolve: {data: DelayResolver }
      },
      {
        path: '14-reasons-of-why-ruby-on-rails-community-chooses-postgresql-over-mysql',
        component: ReasonsOfWhyRorComponent,
        resolve: {data: DelayResolver }
      },
      {
        path: 'how-to-create-gradient-background-in-android',
        component: CreateGradientAndroidComponent,
        resolve: {data: DelayResolver }
      },
      {
        path: 'top-5-reasons-for-your-slow-wordpress-website',
        component: SlowWordpressWebsiteComponent,
        resolve: {data: DelayResolver }
      },
      {
        path: 'content-vs-design-7-points-to-consider-by-web-designer-before-creating-a-website',
        component: ContentVsDesignComponent,
        resolve: {data: DelayResolver }
      },
      {
        path: 'testcases-in-angular-jest-for-local-storage-behavior-subject-with-get-methods',
        component: TestcasesAngularComponent,
        resolve: {data: DelayResolver }
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BlogsRoutingModule { }
