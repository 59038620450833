import { Component, AfterViewInit, OnInit } from '@angular/core';
import gsap from 'gsap/all';
import ScrollTrigger from 'gsap/ScrollTrigger';
import * as $ from 'jquery';

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.scss']
})
export class TestimonialComponent implements OnInit {
    constructor() { }
    public ngOnInit(): void {
        gsap.registerPlugin(ScrollTrigger);
        // Testimonial start
        const testimonialinfo: any = gsap.utils.toArray('.tech-testimonial-innerblock > *');
        gsap.set(testimonialinfo, {autoAlpha: 0, y: 50});

        testimonialinfo.forEach((testimonialLeft: any) => {
            const anim = gsap.to(testimonialLeft, { duration: 0.5, autoAlpha: 1, y: 0, paused: true });
            ScrollTrigger.create({
                trigger: testimonialLeft,
                start: 'top 80%',
                once: true,
                onEnter: self => {
                    self.progress === 1 ? anim.progress(1) : anim.play();
                }
            });
        });

        const testimonialrow: any = gsap.utils.toArray('.tech-testimonial-row');
        gsap.set(testimonialrow, {autoAlpha: 0, y: 50});
        testimonialrow.forEach((testimonial: any) => {
            const anim = gsap.to(testimonial, { duration: 0.5, autoAlpha: 1, y: 0, paused: true });
            ScrollTrigger.create({
                trigger: testimonial,
                start: 'top 80%',
                once: true,
                onEnter: self => {
                    self.progress === 1 ? anim.progress(1) : anim.play();
                }
            });
        });
        // Testimonial end

        gsap.config({
            nullTargetWarn: false,
        });
    }
}
