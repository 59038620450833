<app-header class="white-header"></app-header>
<app-header-menu *ngIf="isHeaderMenuOpen"></app-header-menu>
<div class="scroller">
  <div class="header-class"></div>
  <div class="page-grid-block">
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
  </div>
  <div class="grid-background-overlay"></div>
  <section class="about-hero">
      <app-tech-logo></app-tech-logo>
      <div class="about-hero-container">
          <div class="about-header-info">
              <div class="about-header-info-inner">
                  <div class="page-title">
                      <h1>
                          Solution Designing and Development
                      </h1>
                  </div>
                  <div class="about-banner-detail">
                      <p>
                          We are Designing and Developing effective solutions for Web, Mobile and Desktop. We empower businesses and drive results and revenues with our solutions. With expert and experienced team focusing towards problem solving using right processes, effective solutions are inevitable. We work with dedication, professionalism and business ethics and that reflects in our work and culture we developed.
                      </p>
                      <p> We offer a top-notch pervasive wide range of creative web/ mobile services, starting from developing code to design and development for a memorable customer experience. Our expertise in delivering secure, scalable, interactive, innovative & robust mobile apps has delighted 250+ customers in more than 15 countries across the globe. </p>
                  </div>
              </div>
          </div>
      </div>
  </section>

  <section class="service-list-wrapper">
        <div class="service-container">
            <div class="service-row">
                <div class="service-list-block">
                    <div class="service-img-block">
                        <img src="assets/images/services/team.webp" alt="Happy, Super & Effective Team" width="958" height="1460"/>
                    </div>
                </div>
                <div class="service-detail-block">
                    <h3>Happy, Super & Effective Team</h3>
                    <ul>
                        <li>Team of trained, skilled, dedicated, happy, genuine people.</li>
                        <li>Delivering super solutions which gives effectiveness to users.</li>
                        <li>Involvement and Contribution.</li>
                        <li>Brainstorming and Idea sharing activities at all levels.</li>
                        <li>Complete sense of responsibility, carefulness and sensibility while working.</li>
                        <li>Mindful efforts to cherish what we build.</li>
                        <li>Purposeful processes and no unnecessary hinderance.</li>
                        <li>Individuals with Individuality yet shared objective.</li>
                        <li>Working in Rhythm with Harmony unlike Assembly line in Factory.</li>
                    </ul>
                    <a [routerLink]="['happy-super-and-effective-team']">Read more
                        <i class="link-icon">
                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.5687 7.92313C14.3312 7.68545 13.946 7.68545 13.7081 7.92313C13.4704 8.16062 13.4704 8.5461 13.7081 8.78378L18.3174 13.3931H6.0705C5.73339 13.3931 5.45996 13.6666 5.45996 14.0037C5.45996 14.341 5.73339 14.6142 6.0705 14.6142H18.3113L13.702 19.2236C13.5876 19.3375 13.5234 19.4926 13.5234 19.654C13.5234 19.8156 13.5876 19.9703 13.702 20.0844C13.8163 20.1997 13.9729 20.2636 14.1354 20.2615C14.2974 20.2607 14.4529 20.1972 14.5687 20.0844L20.216 14.4373C20.3309 14.3222 20.395 14.166 20.3932 14.0037C20.3921 13.8417 20.3286 13.6868 20.216 13.5704L14.5687 7.92313Z" fill="currentColor"/>
                                <path d="M14.0072 0.877724C8.69816 0.876768 3.91155 4.0746 1.87993 8.97957C-0.151319 13.8847 0.972243 19.5305 4.72746 23.2834C9.8527 28.4086 18.162 28.4086 23.2871 23.2834C28.4121 18.1585 28.4121 9.84921 23.2871 4.72396C20.8313 2.25426 17.4899 0.86931 14.0072 0.877724ZM14.0072 25.9087C7.4442 25.9087 2.10231 20.5666 2.10231 14.0037C2.10231 7.4407 7.4442 2.0988 14.0072 2.0988C20.5701 2.0988 25.9122 7.4407 25.9122 14.0037C25.9122 20.5666 20.5701 25.9087 14.0072 25.9087Z" fill="currentColor"/>
                            </svg>
                        </i>
                    </a>
                </div>
            </div>
        </div>
    </section>

  <section class="about-who-wrapper">
      <div class="about-who-row">
          <div class="about-who-col">
              <span class="title-letter-anim">Who we are</span>
              <div class="about-who-img position-relative">
                  <img src="assets/images/about-who-img.webp" alt="Techcompose image" width="4032" height="2301">
              </div>
              <div class="about-who-detail">
                  <p class="about-info">Endowed with more than 8 years of positive change and driven by inspired teamwork, TechCompose is a full-scale integrated IT Solutions provider and a Solution Development and Design company having a presence in Ahmedabad(Development Centre) and US(Business Development). With teams working from 2 offices in Ahmedabad and individuals working Remotely from India and US, we are having distributed team but sharing the one objective of Building Effective Solutions. </p>
              </div>
          </div>
          <div class="about-who-col">
              <ul>
                  <li>
                      <h2 class="block-title title-letter-anim">Our Role</h2>
                      <p class="about-info">Our varied work area comprises of developing and delivering best quality IT solutions in an industry in varied areas, which comprised of designing, web development, web application, software development, mobile app development, and e-Commerce solutions. </p>
                  </li>
                  <li>
                      <h2 class="block-title title-letter-anim">Our Vision</h2>
                      <p class="about-info">Our vision is continue developing effective solutions where everyone working at different experience levels and performing different roles have the same proud feeling of valuable contribution to the solution development with highest deliverable standards that one could achieve. </p>
                  </li>
                  <li>
                      <h2 class="block-title title-letter-anim">Our Mission</h2>
                      <p class="about-info">Our mission is to enhance our client`s business growth with creative design and development to deliver market-defining high-quality solutions that create value and reliable competitive advantage for our clients globally. We always try to improve the quality of our work by exploring innovative ideas. </p>
                  </li>
              </ul>
          </div>
      </div>
  </section>

  <section class="about-solution-wrapper">
      <div class="solution-block">
          <h2 class="section-title-big best-it-title">Best IT Solutions Provider</h2>
          <div class="about-solution-block">
              <div class="about-solution-info">
                  <p class="about-info about-solution-info-first">TechCompose is the vibrant Solution Development and Design company which provides services like web application development, mobile app development, and software development outsourcing from India to our various clients from USA, UK, Canada, Australia, and the Middle East. TechCompose is premium and expert service provider for developing solutions with Ruby on Rails, Node, Angular, ReactJS, Python and Java. We don’t believe in “One Size Fits all” practice. So our professional team of creative designers, experienced developers, marketers, and business analysts analyze various business requirements to develop more effective solutions for our clients. </p>
                  <p class="about-info about-solution-info-second">TechCompose being renowned Web Development and Design company extends end-to-end different IT-enabled solutions and services to a broad spectrum of industry verticals ranging from Automobiles, Health & Fitness, Pharmacy & Medicines, Real Estate, News, Information & Technology, Law, Travel and Hospitality, Finance, Computers & Hardware, Business & Service and many more. We are leading Web Development and Design company and we are in business for over 8 years now with expertise having 15+ years of individual experience, we have gained rich experience and vast exposure to the overall Solution development outsourcing industry. We bring our strong team of highly qualified and experienced IT professionals and marketers, working from different locations as teams and individuals, having business domain knowledge, proven methodologies, and technical competence to render truly innovative and high-quality solutions that help any businesses boost their ROI faster. </p>
              </div>
          </div>
          <div class="solution-detail-row">
              <div class="solution-detail-col">
                  <h2 class="block-title title-letter-anim">How do we work?</h2>
                  <p class="about-info">Talking about us and how we work, we always practice proven agile development process with pro-active and problem solving approach specific to protect all complexity with simple and effective software, website, web application, mobile app development solutions. </p>
              </div>
              <div class="solution-detail-col">
                  <h2 class="block-title title-letter-anim">What makes us superior? </h2>
                  <p class="about-info">We apply our head and heart. We bring expertise in designing and engineering with best practices from the head and work selflessly with care for the product in the heart. We are sensible and happy while working as we love our work. We involve and encourage every individual to share their thoughts, ideas and experience while working on the solution development. We capture the vision of product owner, souls of different personas and the message conveyed between the words during the communication. We are humble, responsible and problem solving team that works. </p>
              </div>
          </div>
      </div>
  </section>
  <app-footer></app-footer>
</div>

 