import { Component, AfterViewInit, OnDestroy, Renderer2, OnInit } from '@angular/core';
import gsap from 'gsap/all';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { CSSRulePlugin } from 'gsap/CSSRulePlugin';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import Scrollbar, { ScrollbarPlugin } from 'smooth-scrollbar';
import { Title, Meta } from '@angular/platform-browser';
import { sharedService } from '../shared/shared.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnDestroy, AfterViewInit, OnInit {
    private listeners: any[] = [];
    public rollingTween = gsap.timeline();
    public firstRAFCycle: boolean = true;
    public mousepos = {x: 0, y: 0};
    // cache the mouse position
    public mousePosCache = this.mousepos;
    public direction = {x: this.mousePosCache.x - this.mousepos.x, y: this.mousePosCache.y - this.mousepos.y};
    public animatableProperties = {
      // translationX
      tx: {previous: 0, current: 0, amt: 0.08},
      // translationY
      ty: {previous: 0, current: 0, amt: 0.08},
      // skew value
      skew: {previous: 0, current: 0, amt: 0.04},
      // CSS filter (contrast) value
      contrast: {previous: 1, current: 1, amt: 0.08},
    };
    // calculate position/sizes the first time
    public bounds = {el: {left: 0, top: 0}, reveal: {width: 0, height: 0}};
    public requestId: any;
    public loadSections: boolean = false;

    bodyScrollBar: Scrollbar | undefined;

    selectedIndustry: any;
    open: boolean = false;
    // openProjectDrawer: boolean = false;
    public isHeaderMenuOpen: boolean = false;
    public currentUrl: string = window.location.href;
    public baseUrl: string = window.location.origin;

    constructor(
      private renderer: Renderer2,
      private metaTagService: Meta,
      private titleService: Title,
      public sharedservice: sharedService) {
      this.sharedservice._indusPreview.subscribe(res => {
        this.open = true;
        this.selectedIndustry = res;
        if (this.bodyScrollBar) {
          this.bodyScrollBar.updatePluginOptions('modal', { open: true });
        }
      });
      // this.sharedservice._projectDraw.subscribe(res => {
      //   this.openProjectDrawer = true;
      // });

     }

    closeDOM(): void {
      this.open = false;
      // this.openProjectDrawer = false;
      if (this.bodyScrollBar) {
        this.bodyScrollBar.updatePluginOptions('modal', { open: false });
      }
    }
    // closeMenuDOM(): void {
    //   this.isHeaderMenuOpen = false;
    //   // this.openProjectDrawer = false;
    //   if (this.bodyScrollBar) {
    //     this.bodyScrollBar.updatePluginOptions('modal', { isHeaderMenuOpen: false });
    //   }
    // }

    ngOnInit() {
      ScrollTrigger.getAll().forEach(t => t.kill());

      this.sharedservice.showHeaderMenu$.subscribe((data) => {
        this.isHeaderMenuOpen = data;
        if (this.bodyScrollBar) {
          this.bodyScrollBar.updatePluginOptions('modal', { open: this.isHeaderMenuOpen });
        }
      });

      this.titleService.setTitle("Custom Software, Web & Mobile App Development Company USA");
      
      this.metaTagService.updateTag({ name: 'keywords', content: 'Designing and developing effective solutions for Web and Mobile platforms. Dedicated, skilled and genuine team to build solutions.' });
      this.metaTagService.updateTag({ property: 'og:title', content: 'Custom Software, Web & Mobile App Development Company USA' });
      this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
      this.metaTagService.updateTag({ property: 'og:url', content: `${this.currentUrl}` });
      this.metaTagService.updateTag({ property: 'og:image', content: `${this.baseUrl}/assets/images/thumb.png` })
      this.metaTagService.updateTag({ property: 'og:description', content: 'TechCompose is a well-known software development and consulting company, that provides custom software, web, and mobile development services. Contact us now.' });
      // this.metaTagService.updateTag({ name: 'description', content: 'At Techcompose, we are Designing and Developing effective solutions for Web, Mobile and Desktop. Our solutions are effective and empower businesses to produce the results. With our expert and experienced team focusing towards problem solving using right processes, effective solutions are inevitable. We work with dedication, professionalism and business ethics which reflects in our work and culture we developed.' });
      this.metaTagService.updateTag({ name: 'google-site-verification', content: 'XIgKSGw7eFSWKKGY1eNzRmAMbGRMW7ySxphFsWP7aSg' });

      gsap.registerPlugin(ScrollTrigger);

      const scroller = document.querySelector('.scroller')as HTMLElement;

      this.bodyScrollBar = Scrollbar.init(scroller, { damping: 0.1, delegateTo: document, alwaysShowTracks: true });

      const content = this;
      ScrollTrigger.scrollerProxy(".scroller", {
        scrollTop(value) {
          if (content.bodyScrollBar) {
            if (arguments.length) {
              content.bodyScrollBar.scrollTop = value || 0;
            }
            return content.bodyScrollBar.scrollTop;
          } else {
            return 0;
          }
        }
      });

      this.bodyScrollBar.addListener(ScrollTrigger.update);
      ScrollTrigger.defaults({ scroller: scroller });

      if (document.querySelector('.gsap-marker-scroller-start')) {
        const markers = gsap.utils.toArray('[class *= "gsap-marker"]');

        this.bodyScrollBar.addListener(({ offset }) => {
          gsap.set(markers, { marginTop: -offset.y })
        });
      }
    }

    public ngAfterViewInit(): void{

      // gsap.fromTo('.text-inner-row', {y: '100%',opacity: 0,}, {y: 0,opacity: 1,stagger: 0.1,scrub: 1,duration: 0.8,delay: 0.2,ease: 'power3',});
      // gsap.fromTo('.text-row', {fontSize: '7vw',lineHeight: '7vw',marginBottom: '15px',}, {fontSize: '20px',lineHeight: '22px',duration: 1,delay: 1.3,marginBottom: '0px',ease: 'power3'});
      // gsap.fromTo('.loader-text', {position: 'absolute'}, {left: 0,right: 0,top: '110px',duration: 1,delay: 1.25});

      // gsap.fromTo('.serviceList', {autoAlpha: 0,}, {autoAlpha: 1,duration: 0.1,delay: 0.5});
      // gsap.fromTo('.serviceList li', {y: '30',autoAlpha: 0,}, {y: 0,autoAlpha: 1,stagger: 0.01,scrub: true,duration: 0.5,delay: 0.55,ease: 'power3'});
      // gsap.to('.sub-title', {y: 0,autoAlpha: 1, delay: 0.5});
      // gsap.to('.service-list-overlay', {autoAlpha: 0,duration: 0.1,delay: 1});

      setTimeout(() => {
        gsap.fromTo('.serviceList', {autoAlpha: 0,}, {autoAlpha: 1,duration: 0.1,delay: 0});
        gsap.fromTo('.serviceList li', {autoAlpha: 0,}, {autoAlpha: 1,stagger: 0.01,scrub: true,duration: 0.5,delay: 0.05,ease: 'power3'});
        gsap.to('.sub-title', {y: 0,autoAlpha: 1, delay: 0.05});
        gsap.to('.service-list-overlay', {autoAlpha: 0,duration: 0.1,delay: 0.5});
        gsap.fromTo('.header-main', {autoAlpha: 0,}, {autoAlpha: 1,duration: 0.1,delay: 0});
        gsap.fromTo('.scrollBlock', {autoAlpha: 0,}, {autoAlpha: 1,duration: 0.1,delay: 0});
        gsap.fromTo('.tech-logo-anim', {autoAlpha: 0,}, {autoAlpha: 1,duration: 0.1,delay: 0});
      }, 500);

      ScrollTrigger.create({
        trigger: '.new-Hero',
        start:"top 40%",
        onEnter: () => {
          gsap.to('.hero-bg', {duration: 1.0, backgroundColor: '#141414'})
          gsap.to('.hero-overlay', {duration: 1.0, backgroundColor: '#141414'})
          gsap.to('.serviceList ul li', {duration: 1, color: '#ffffff' })
          gsap.to('.tech-services-detail p', {duration: 1, color: '#ffffff'})
          gsap.to('.service-slider-item-inner', {duration: 1, backgroundColor: '#141414'})
          gsap.to('.service-detail-box', {duration: 1,color: '#ffffff'})
          gsap.to('.service-detail-box span', {duration: 1,borderColor: 'rgba(255,255,255,0.8)'})
          gsap.to('.text-row', {duration: 1,color: '#ffffff'})
          gsap.to('.service-info-box-inner h3', {duration: 1,color: '#ffffff'})
          gsap.to('.service-more-btn', {duration: 1,color: '#ffffff'})
          gsap.to('.framework-wrapper h2', {duration: 1.0, color: '#000000'})
          gsap.to('.framework-wrapper p', {duration: 1.0, color: '#000000'})
          gsap.to('.work-primary-img-block h4', { color: '#ffffff'})
        },
      })

      setTimeout(() => {
        this.loadSections = true;
        setTimeout(() => {
          gsap.registerPlugin(ScrollTrigger, CSSRulePlugin, ScrollToPlugin);
          gsap.to('.hero-bg', {duration: 1, opacity: 1, ease: 'none', delay: 1.4});
          gsap.to('.bannerImgBlock', {duration: 0.5, width: 'auto', ease: 'power1', delay: 1});
          gsap.to('.bannerImgOverlay', {duration: 1, width: '0', ease: 'power1', delay: 1.5});
          gsap.to('.grid-col-1', {duration: 1, scale: 1, ease: 'power1', delay: 1.5});
          gsap.to('.grid-col-2', {duration: 1, scale: 1, ease: 'power1', delay: 1.6});
          gsap.to('.grid-col-3', {duration: 1, scale: 1, ease: 'power1', delay: 1.7});
          gsap.to('.grid-col-4', {duration: 1, scale: 1, ease: 'power1', delay: 1.8});
          gsap.to('.grid-col-5', {duration: 1, scale: 1, ease: 'power1', delay: 1.9});
          gsap.to('.grid-col-6', {duration: 1, scale: 1, ease: 'power1', delay: 2});
          gsap.to('.grid-col-7', {duration: 1, scale: 1, ease: 'power1', delay: 2.1});
          gsap.to('.grid-col-8', {duration: 1, scale: 1, ease: 'power1', delay: 2.2});
          gsap.to('.grid-col-9', {duration: 1, scale: 1, ease: 'power1', delay: 2.3});
          gsap.to('.grid-col-10', {duration: 1, scale: 1, ease: 'power1', delay: 2.4});
          gsap.to('.grid-col-11', {duration: 1, scale: 1, ease: 'power1', delay: 2.5});

          this.resizeCheck();
          let listener: any = this.renderer.listen(
              window,
              'resize',
              () => {
                this.resizeCheck();
          });
          this.listeners.push(listener);

          listener = this.renderer.listen(
            window,
            'mousemove',
            (e) => {
              let posx = 0;
              let posy = 0;
              if (!e) e = window.event;
              if (e.pageX || e.pageY) {
                  posx = e.pageX;
                  posy = e.pageY;
              }
              else if (e.clientX || e.clientY)    {
                  posx = e.clientX + document.getElementsByTagName('body')[0].scrollLeft + document.documentElement.scrollLeft;
                  posy = e.clientY + document.getElementsByTagName('body')[0].scrollTop + document.documentElement.scrollTop;
              }
              this.mousepos = { x : posx, y : posy };
          });
          this.listeners.push(listener);

          const blockTitle: any = gsap.utils.toArray('.block-title');
          gsap.set(blockTitle, {autoAlpha: 0, y: 50});
          blockTitle.forEach((titleanimBlock: any) => {
              const titleAnim = gsap.to(titleanimBlock, { duration: 1, autoAlpha: 1, y: 0, paused: true });
              ScrollTrigger.create({
                  trigger: titleanimBlock,
                  start: 'top 80%',
                  once: true,
                  onEnter: self => {
                      self.progress === 1 ? titleAnim.progress(1) : titleAnim.play();
                  }
              });
          });

          const blockInfo: any = gsap.utils.toArray('.block-info');
          gsap.set(blockInfo, {autoAlpha: 0, y: 50});
          blockInfo.forEach((infoanimBlock: any) => {
              const infoAnim = gsap.to(infoanimBlock, { duration: 1, autoAlpha: 1, y: 0, paused: true,});
              ScrollTrigger.create({
                  trigger: infoanimBlock,
                  start: 'top 80%',
                  once: true,
                  onEnter: self => {
                      self.progress === 1 ? infoAnim.progress(1) : infoAnim.play();
                  }
              });
          });

          gsap.fromTo(".average-box", {autoAlpha: 0,},{autoAlpha: 1,scrollTrigger: {trigger: ".average-box",start: "top 70%"}})
          gsap.fromTo(".average-box h2", {autoAlpha: 0,y: 30,},{autoAlpha: 1,y: 0,delay: 0.5,scrollTrigger: {trigger: ".average-box",start: "top 70%"}})
          gsap.fromTo(".average-box ul li", {autoAlpha: 0,},{autoAlpha: 1,delay: 0.1,duration: 0.25,scrollTrigger: {trigger: ".average-box ul",start: "top 70%"}})

          const items = document.querySelectorAll('.average-box li span span');
          const avgcounter = gsap.from(items, {
            textContent: 0,
            duration: 1,
            delay: 0.35,
            ease: Power1.easeIn,
            snap: { textContent: 1 },
            // stagger: 0.35,
          });

          ScrollTrigger.create({
            trigger: '.average-box ul',
            start: 'top 70%',
            animation: avgcounter,
          });

          this.textHoverLayoutSetup();
          const listones11 = gsap.utils.toArray('.client-logo-inner li');
            const tl = gsap.timeline({scrollTrigger: {
            trigger: '.client-logo-inner ul',
            start: 'top 75%',
            end: 'bottom top',
          }}).fromTo(listones11, {opacity: 0, y: 50, stagger: 0.1, duration: 0.5, ease: 'power1'},{opacity: 1, y: 0, stagger: 0.1, duration: 0.5, ease: 'power1'}, 0);

          Scrollbar.get(document.querySelector('.scroller') as HTMLElement)?.scrollTo(0, 0, 600, {
            // callback: () => console.log('done!')
          });
        }, 0);
      }, 700);

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });

      gsap.config({
        nullTargetWarn: false,
      });

      ScrollTrigger.create({
        trigger: ".header-class",
        start: "+50px",
        end: "bottom -=1000%",
        toggleClass: { targets: ".header", className: "active" }
      });


    }

    public lerp(a: any, b: any, n: any): number {
      return (1 - n) * a + n * b;
    }

    // Map number x from range [a, b] to [c, d]
    public mapXFromRange(x: any, a: any, b: any, c: any, d: any): number {
      return (x - a) * (d - c) / (b - a) + c;
    }

    /** header banner animation methods */
    public textHoverLayoutSetup(): void {
      const elements = document.getElementsByClassName('textHoverSpan');
      for(let i = 0; i < elements.length; i++) {
        // this is the element that gets its position animated (and perhaps other properties like the skew etc..)
        const domElemReveal = document.createElement('div');
        domElemReveal.className = 'hover-reveal';
        // the next two elements could actually be only one, the image element
        // adding an extra wrapper (revealInner) around the image element with overflow hidden,
        // gives us the possibility to scale the image inside
        const domElemRevealInner = document.createElement('div');
        domElemRevealInner.className = 'hover-reveal__inner';
        const domElemRevealImage = document.createElement('div');
        domElemRevealImage.className = 'hover-reveal__img';
        const domElemRevealVideo = document.createElement('video');
        domElemRevealVideo.src = elements[i].getAttribute('data-img') || '';
        domElemRevealVideo.width = 480;
        domElemRevealVideo.height = 271;
        domElemRevealVideo.autoplay = true;
        domElemRevealVideo.loop = true;
        domElemRevealVideo.muted = true;
        domElemRevealImage.appendChild(domElemRevealVideo);
        domElemRevealInner.appendChild(domElemRevealImage);
        domElemReveal.appendChild(domElemRevealInner);
        elements[i].appendChild(domElemReveal);
      }
    }

    public hoverMouseEnter(target: any): void {
      this.showImage(target);
      this.animatableProperties = {
        // translationX
        tx: {previous: 0, current: 0, amt: 0.08},
        // translationY
        ty: {previous: 0, current: 0, amt: 0.08},
        // skew value
        skew: {previous: 0, current: 0, amt: 0.04},
        // CSS filter (contrast) value
        contrast: {previous: 1, current: 1, amt: 0.08},
      };
      this.bounds = {
        el: target.getBoundingClientRect(),
        reveal: target.lastChild.getBoundingClientRect()
      };
      this.loopRender(target);
    }

    public hoverMouseLeave(target: any): void {
      this.stopRendering();
      this.hideImage(target);
    }

    public showImage(target: any): void {
      const domElemRevealInner = target.lastChild?.firstChild as any;
      const domElemRevealImg = domElemRevealInner?.firstChild as any;
      // kill any current tweens
      gsap.killTweensOf(domElemRevealInner);
      gsap.killTweensOf(domElemRevealImg);

      gsap.timeline({
        onStart: () => {
          // show both image and its parent element
          target.lastChild.style.opacity = domElemRevealInner.style.opacity = 1;
          // set a high z-index value so image appears on top of other elements
          gsap.set(target, {zIndex: document.getElementsByClassName('textHoverSpan').length});
        }
      })
      // animate the image wrap
      .to(domElemRevealInner, 0.8, {
          ease: 'Expo.easeOut',
          startAt: {scaleX: 0, filter: 'contrast(10)'},
          scaleX: 1,
          filter: 'contrast(1)'
      })
      // animate the image element
      .to(domElemRevealImg, 1.2, {
          ease: 'Expo.easeOut',
          startAt: {scaleX: 2},
          scaleX: 1
      }, 0);
    }

    // hide the image element
    public hideImage(target: any): void {
      const domElemRevealInner = target.lastChild?.firstChild as any;
      const domElemRevealImg = domElemRevealInner?.firstChild as any;
      // kill any current tweens
      gsap.killTweensOf(domElemRevealInner);
      gsap.killTweensOf(domElemRevealImg);

      gsap.timeline({
          onStart: () => {
            gsap.set(target, {zIndex: 1});
          },
          onComplete: () => {
            gsap.set(target.lastChild, {opacity: 0});
          }
      })
      .to(domElemRevealInner, 0.6, {
          ease: 'Expo.easeOut',
          scaleX: 0,
          opacity: 0
      })
      .to(domElemRevealImg, 0.6, {
          ease: 'Expo.easeOut',
          scaleX: 2
      }, 0);
    }

    public loopRender(target: any): void {
      if (!this.requestId) {
        this.requestId = requestAnimationFrame(() => this.render(target));
      }
    }

    // stop the render loop animation (rAF)
    public stopRendering(): void {
      if (this.requestId){
        window.cancelAnimationFrame(this.requestId);
        this.requestId = undefined;
      }
    }

    public render(target: any): any {
      this.requestId = undefined;
      // calculate the mouse distance (current vs previous cycle)
      const mouseDistanceX = this.clamp(Math.abs(this.mousePosCache.x - this.mousepos.x), 0, 100);
      // direction where the mouse is moving
      this.direction = {x: this.mousePosCache.x - this.mousepos.x, y: this.mousePosCache.y - this.mousepos.y};
      // updated cache values
      this.mousePosCache = {x: this.mousepos.x, y: this.mousepos.y};

      // new translation values
      this.animatableProperties.tx.current = Math.abs(this.mousepos.x - this.bounds.el.left) - this.bounds.reveal.width / 2;
      this.animatableProperties.ty.current = Math.abs(this.mousepos.y - this.bounds.el.top) - this.bounds.reveal.height / 2;
      // new skew value
      this.animatableProperties.skew.current = this.firstRAFCycle ? 0 :
        this.mapXFromRange(mouseDistanceX, 0, 80, 0, this.direction.x < 0 ? 60 : -60);
      // new filter value
      this.animatableProperties.contrast.current = this.firstRAFCycle ? 1 :
        this.mapXFromRange(mouseDistanceX, 0, 100, 1, 10);
      // set up the interpolated values
      // for the first cycle, both the interpolated values need to be the same so
      // there's no 'lerped' animation between the previous and current state
      this.animatableProperties.tx.previous = this.firstRAFCycle ?
        this.animatableProperties.tx.current : this.lerp(this.animatableProperties.tx.previous,
            this.animatableProperties.tx.current, this.animatableProperties.tx.amt);
      this.animatableProperties.ty.previous = this.firstRAFCycle ?
        this.animatableProperties.ty.current : this.lerp(this.animatableProperties.ty.previous,
          this.animatableProperties.ty.current, this.animatableProperties.ty.amt);
      this.animatableProperties.skew.previous = this.firstRAFCycle ?
        this.animatableProperties.skew.current : this.lerp(this.animatableProperties.skew.previous,
          this.animatableProperties.skew.current, this.animatableProperties.skew.amt);
      this.animatableProperties.contrast.previous = this.firstRAFCycle ?
        this.animatableProperties.contrast.current : this.lerp(this.animatableProperties.contrast.previous,
          this.animatableProperties.contrast.current, this.animatableProperties.contrast.amt);
      // set styles
      gsap.set(target.lastChild, {
          x: this.animatableProperties.tx.previous,
          y: this.animatableProperties.ty.previous,
          skewX: this.animatableProperties.skew.previous,
          skewY: this.animatableProperties.skew.previous / 10,
          filter: `contrast(${this.animatableProperties.contrast.previous})`
      });
      this.firstRAFCycle = false;
      this.loopRender(target);
    }

    public clamp(num: any, min: any, max: any): any {
      return num <= min ? min : num >= max ? max : num;
    }
    public resizeCheck(): void {
    //   // record the progress so that we can match it with the new tween (jump to the same spot)
    //   const progress = this.rollingTween.progress();
    //   this.rollingTween.time(0).kill(); // rewind and kill the original tween.
    //   this.startRolling().progress(progress); // create a new tween based on the new size, and jump to the same progress value.
    }

    public ngOnDestroy(): void {
        this.listeners.forEach(listener => listener());
    }
}
class ModalPlugin extends ScrollbarPlugin {
  static pluginName = 'modal';

  static defaultOptions = {
    open: false,
    isHeaderMenuOpen: false
  };

  transformDelta(delta: any) {
      return this.options.open ? { x: 0, y: 0 } : delta;
  }
}

Scrollbar.use(ModalPlugin, /* OverscrollPlugin */);
