<app-header></app-header>
<app-header-menu *ngIf="isHeaderMenuOpen"></app-header-menu>
<div class="scroller">
  <div class="header-class"></div>
  <div class="page-grid-block">
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
  </div>
  <div class="grid-background-overlay"></div>
  <section class="page-not-found-wrapper">
    <div class="page-notfound-block">
        <div class="icon-box">
          <i class="icon-four icon-four-one">
            <svg width="284" height="319" viewBox="0 0 284 319" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g>
                <rect x="91.8242" y="221.562" width="14.285" height="14.285" transform="rotate(-135 91.8242 221.562)" fill="currentColor"/>
                <rect x="52.125" y="221.562" width="14.285" height="14.285" transform="rotate(-135 52.125 221.562)" fill="currentColor"/>
                <rect x="10.7539" y="221.563" width="14.285" height="14.285" transform="rotate(-135 10.7539 221.563)" fill="currentColor"/>
                <rect x="72.1289" y="201.864" width="14.285" height="14.285" transform="rotate(-135 72.1289 201.864)" fill="currentColor"/>
                <rect x="30.7617" y="201.864" width="14.285" height="14.285" transform="rotate(-135 30.7617 201.864)" fill="currentColor"/>
                <rect x="191.32" y="240.246" width="14.285" height="14.285" transform="rotate(-135 191.32 240.246)" fill="currentColor"/>
                <rect x="191.32" y="161.564" width="14.285" height="14.285" transform="rotate(-135 191.32 161.564)" fill="currentColor"/>
                <rect x="191.32" y="81.5684" width="14.285" height="14.285" transform="rotate(-135 191.32 81.5684)" fill="currentColor"/>
                <rect x="191.32" y="40.1396" width="14.285" height="14.285" transform="rotate(-135 191.32 40.1396)" fill="currentColor"/>
                <rect x="211.461" y="20.8545" width="14.285" height="14.285" transform="rotate(-135 211.461 20.8545)" fill="currentColor"/>
                <rect x="149.953" y="81.5693" width="14.285" height="14.285" transform="rotate(-135 149.953 81.5693)" fill="currentColor"/>
                <rect x="211.52" y="220.046" width="14.285" height="14.285" transform="rotate(-135 211.52 220.046)" fill="currentColor"/>
                <rect x="211.523" y="60.8535" width="14.285" height="14.285" transform="rotate(-135 211.523 60.8535)" fill="currentColor"/>
                <rect x="170.156" y="60.8535" width="14.285" height="14.285" transform="rotate(-135 170.156 60.8535)" fill="currentColor"/>
                <rect x="251.727" y="220.046" width="14.285" height="14.285" transform="rotate(-135 251.727 220.046)" fill="currentColor"/>
                <rect x="171.625" y="220.552" width="14.285" height="14.285" transform="rotate(-135 171.625 220.552)" fill="currentColor"/>
                <rect x="131.723" y="221.057" width="14.285" height="14.285" transform="rotate(-135 131.723 221.057)" fill="currentColor"/>
                <rect x="151.926" y="200.854" width="14.285" height="14.285" transform="rotate(-135 151.926 200.854)" fill="currentColor"/>
                <rect x="172.129" y="180.651" width="14.285" height="14.285" transform="rotate(-135 172.129 180.651)" fill="currentColor"/>
                <rect x="171.699" y="299.314" width="14.285" height="14.285" transform="rotate(-135 171.699 299.314)" fill="currentColor"/>
                <rect x="211.402" y="299.312" width="14.285" height="14.285" transform="rotate(-135 211.402 299.312)" fill="currentColor"/>
                <rect x="211.402" y="140.119" width="14.285" height="14.285" transform="rotate(-135 211.402 140.119)" fill="currentColor"/>
                <rect x="211.402" y="181.562" width="14.285" height="14.285" transform="rotate(-135 211.402 181.562)" fill="currentColor"/>
                <rect x="252.172" y="181.562" width="14.285" height="14.285" transform="rotate(-135 252.172 181.562)" fill="currentColor"/>
                <rect x="191.898" y="279.114" width="14.285" height="14.285" transform="rotate(-135 191.898 279.114)" fill="currentColor"/>
                <rect x="191.898" y="317.984" width="14.285" height="14.285" transform="rotate(-135 191.898 317.984)" fill="currentColor"/>
                <rect x="191.902" y="200.433" width="14.285" height="14.285" transform="rotate(-135 191.902 200.433)" fill="currentColor"/>
                <rect x="231.402" y="200.432" width="14.285" height="14.285" transform="rotate(-135 231.402 200.432)" fill="currentColor"/>
                <rect x="272.887" y="200.432" width="14.285" height="14.285" transform="rotate(-135 272.887 200.432)" fill="currentColor"/>
                <rect x="191.902" y="120.437" width="14.285" height="14.285" transform="rotate(-135 191.902 120.437)" fill="currentColor"/>
                <rect x="112.027" y="201.359" width="14.285" height="14.285" transform="rotate(-135 112.027 201.359)" fill="currentColor"/>
                <rect x="132.227" y="181.156" width="14.285" height="14.285" transform="rotate(-135 132.227 181.156)" fill="currentColor"/>
                <rect x="172.633" y="140.754" width="14.285" height="14.285" transform="rotate(-135 172.633 140.754)" fill="currentColor"/>
                <rect x="172.203" y="259.416" width="14.285" height="14.285" transform="rotate(-135 172.203 259.416)" fill="currentColor"/>
                <rect x="211.398" y="259.416" width="14.285" height="14.285" transform="rotate(-135 211.398 259.416)" fill="currentColor"/>
                <rect x="211.402" y="100.224" width="14.285" height="14.285" transform="rotate(-135 211.402 100.224)" fill="currentColor"/>
                <rect x="92.332" y="181.661" width="14.285" height="14.285" transform="rotate(-135 92.332 181.661)" fill="currentColor"/>
                <rect x="50.9609" y="181.661" width="14.285" height="14.285" transform="rotate(-135 50.9609 181.661)" fill="currentColor"/>
                <rect x="112.535" y="161.458" width="14.285" height="14.285" transform="rotate(-135 112.535 161.458)" fill="currentColor"/>
                <rect x="71.1641" y="161.458" width="14.285" height="14.285" transform="rotate(-135 71.1641 161.458)" fill="currentColor"/>
                <rect x="132.734" y="141.259" width="14.285" height="14.285" transform="rotate(-135 132.734 141.259)" fill="currentColor"/>
                <rect x="91.3672" y="141.26" width="14.285" height="14.285" transform="rotate(-135 91.3672 141.26)" fill="currentColor"/>
                <rect x="152.938" y="121.056" width="14.285" height="14.285" transform="rotate(-135 152.938 121.056)" fill="currentColor"/>
                <rect x="111.566" y="121.057" width="14.285" height="14.285" transform="rotate(-135 111.566 121.057)" fill="currentColor"/>
                <rect x="173.141" y="100.854" width="14.285" height="14.285" transform="rotate(-135 173.141 100.854)" fill="currentColor"/>
                <rect x="131.77" y="100.854" width="14.285" height="14.285" transform="rotate(-135 131.77 100.854)" fill="currentColor"/>
              </g>
            </svg>
          </i>
          <i class="icon-zero">
            <svg width="221" height="319" viewBox="0 0 221 319" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="171.203" y="79.0928" width="14.285" height="14.285" transform="rotate(45 171.203 79.0928)" fill="currentColor"/>
              <rect x="190.902" y="98.7881" width="14.285" height="14.285" transform="rotate(45 190.902 98.7881)" fill="currentColor"/>
              <rect x="210.598" y="118.485" width="14.285" height="14.285" transform="rotate(45 210.598 118.485)" fill="currentColor"/>
              <rect x="51.5078" y="80.6074" width="14.285" height="14.285" transform="rotate(45 51.5078 80.6074)" fill="currentColor"/>
              <rect x="31.3047" y="100.808" width="14.285" height="14.285" transform="rotate(45 31.3047 100.808)" fill="currentColor"/>
              <rect x="11.1016" y="121.011" width="14.285" height="14.285" transform="rotate(45 11.1016 121.011)" fill="currentColor"/>
              <rect x="91.4062" y="80.1035" width="14.285" height="14.285" transform="rotate(45 91.4062 80.1035)" fill="currentColor"/>
              <rect x="71.2031" y="100.303" width="14.285" height="14.285" transform="rotate(45 71.2031 100.303)" fill="currentColor"/>
              <rect x="51.0039" y="120.505" width="14.285" height="14.285" transform="rotate(45 51.0039 120.505)" fill="currentColor"/>
              <rect x="30.8008" y="140.708" width="14.285" height="14.285" transform="rotate(45 30.8008 140.708)" fill="currentColor"/>
              <rect x="131.305" y="79.5977" width="14.285" height="14.285" transform="rotate(45 131.305 79.5977)" fill="currentColor"/>
              <rect x="111.102" y="99.7969" width="14.285" height="14.285" transform="rotate(45 111.102 99.7969)" fill="currentColor"/>
              <rect x="90.9023" y="120" width="14.285" height="14.285" transform="rotate(45 90.9023 120)" fill="currentColor"/>
              <rect x="70.6992" y="140.203" width="14.285" height="14.285" transform="rotate(45 70.6992 140.203)" fill="currentColor"/>
              <rect x="50.4961" y="160.406" width="14.285" height="14.285" transform="rotate(45 50.4961 160.406)" fill="currentColor"/>
              <rect x="151" y="99.292" width="14.285" height="14.285" transform="rotate(45 151 99.292)" fill="currentColor"/>
              <rect x="130.801" y="119.495" width="14.285" height="14.285" transform="rotate(45 130.801 119.495)" fill="currentColor"/>
              <rect x="110.598" y="139.697" width="14.285" height="14.285" transform="rotate(45 110.598 139.697)" fill="currentColor"/>
              <rect x="90.3945" y="159.901" width="14.285" height="14.285" transform="rotate(45 90.3945 159.901)" fill="currentColor"/>
              <rect x="70.1914" y="180.101" width="14.285" height="14.285" transform="rotate(45 70.1914 180.101)" fill="currentColor"/>
              <rect x="170.699" y="118.99" width="14.285" height="14.285" transform="rotate(45 170.699 118.99)" fill="currentColor"/>
              <rect x="150.5" y="139.192" width="14.285" height="14.285" transform="rotate(45 150.5 139.192)" fill="currentColor"/>
              <rect x="130.297" y="159.396" width="14.285" height="14.285" transform="rotate(45 130.297 159.396)" fill="currentColor"/>
              <rect x="110.094" y="179.596" width="14.285" height="14.285" transform="rotate(45 110.094 179.596)" fill="currentColor"/>
              <rect x="89.8906" y="199.799" width="14.285" height="14.285" transform="rotate(45 89.8906 199.799)" fill="currentColor"/>
              <rect x="190.395" y="138.688" width="14.285" height="14.285" transform="rotate(45 190.395 138.688)" fill="currentColor"/>
              <rect x="170.195" y="158.892" width="14.285" height="14.285" transform="rotate(45 170.195 158.892)" fill="currentColor"/>
              <rect x="149.992" y="179.091" width="14.285" height="14.285" transform="rotate(45 149.992 179.091)" fill="currentColor"/>
              <rect x="129.789" y="199.294" width="14.285" height="14.285" transform="rotate(45 129.789 199.294)" fill="currentColor"/>
              <rect x="109.59" y="219.496" width="14.285" height="14.285" transform="rotate(45 109.59 219.496)" fill="currentColor"/>
            </svg>
          </i>
          <i class="icon-four icon-four-two">
            <svg width="284" height="319" viewBox="0 0 284 319" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g>
                <rect x="91.8242" y="221.562" width="14.285" height="14.285" transform="rotate(-135 91.8242 221.562)" fill="currentColor"/>
                <rect x="52.125" y="221.562" width="14.285" height="14.285" transform="rotate(-135 52.125 221.562)" fill="currentColor"/>
                <rect x="10.7539" y="221.563" width="14.285" height="14.285" transform="rotate(-135 10.7539 221.563)" fill="currentColor"/>
                <rect x="72.1289" y="201.864" width="14.285" height="14.285" transform="rotate(-135 72.1289 201.864)" fill="currentColor"/>
                <rect x="30.7617" y="201.864" width="14.285" height="14.285" transform="rotate(-135 30.7617 201.864)" fill="currentColor"/>
                <rect x="191.32" y="240.246" width="14.285" height="14.285" transform="rotate(-135 191.32 240.246)" fill="currentColor"/>
                <rect x="191.32" y="161.564" width="14.285" height="14.285" transform="rotate(-135 191.32 161.564)" fill="currentColor"/>
                <rect x="191.32" y="81.5684" width="14.285" height="14.285" transform="rotate(-135 191.32 81.5684)" fill="currentColor"/>
                <rect x="191.32" y="40.1396" width="14.285" height="14.285" transform="rotate(-135 191.32 40.1396)" fill="currentColor"/>
                <rect x="211.461" y="20.8545" width="14.285" height="14.285" transform="rotate(-135 211.461 20.8545)" fill="currentColor"/>
                <rect x="149.953" y="81.5693" width="14.285" height="14.285" transform="rotate(-135 149.953 81.5693)" fill="currentColor"/>
                <rect x="211.52" y="220.046" width="14.285" height="14.285" transform="rotate(-135 211.52 220.046)" fill="currentColor"/>
                <rect x="211.523" y="60.8535" width="14.285" height="14.285" transform="rotate(-135 211.523 60.8535)" fill="currentColor"/>
                <rect x="170.156" y="60.8535" width="14.285" height="14.285" transform="rotate(-135 170.156 60.8535)" fill="currentColor"/>
                <rect x="251.727" y="220.046" width="14.285" height="14.285" transform="rotate(-135 251.727 220.046)" fill="currentColor"/>
                <rect x="171.625" y="220.552" width="14.285" height="14.285" transform="rotate(-135 171.625 220.552)" fill="currentColor"/>
                <rect x="131.723" y="221.057" width="14.285" height="14.285" transform="rotate(-135 131.723 221.057)" fill="currentColor"/>
                <rect x="151.926" y="200.854" width="14.285" height="14.285" transform="rotate(-135 151.926 200.854)" fill="currentColor"/>
                <rect x="172.129" y="180.651" width="14.285" height="14.285" transform="rotate(-135 172.129 180.651)" fill="currentColor"/>
                <rect x="171.699" y="299.314" width="14.285" height="14.285" transform="rotate(-135 171.699 299.314)" fill="currentColor"/>
                <rect x="211.402" y="299.312" width="14.285" height="14.285" transform="rotate(-135 211.402 299.312)" fill="currentColor"/>
                <rect x="211.402" y="140.119" width="14.285" height="14.285" transform="rotate(-135 211.402 140.119)" fill="currentColor"/>
                <rect x="211.402" y="181.562" width="14.285" height="14.285" transform="rotate(-135 211.402 181.562)" fill="currentColor"/>
                <rect x="252.172" y="181.562" width="14.285" height="14.285" transform="rotate(-135 252.172 181.562)" fill="currentColor"/>
                <rect x="191.898" y="279.114" width="14.285" height="14.285" transform="rotate(-135 191.898 279.114)" fill="currentColor"/>
                <rect x="191.898" y="317.984" width="14.285" height="14.285" transform="rotate(-135 191.898 317.984)" fill="currentColor"/>
                <rect x="191.902" y="200.433" width="14.285" height="14.285" transform="rotate(-135 191.902 200.433)" fill="currentColor"/>
                <rect x="231.402" y="200.432" width="14.285" height="14.285" transform="rotate(-135 231.402 200.432)" fill="currentColor"/>
                <rect x="272.887" y="200.432" width="14.285" height="14.285" transform="rotate(-135 272.887 200.432)" fill="currentColor"/>
                <rect x="191.902" y="120.437" width="14.285" height="14.285" transform="rotate(-135 191.902 120.437)" fill="currentColor"/>
                <rect x="112.027" y="201.359" width="14.285" height="14.285" transform="rotate(-135 112.027 201.359)" fill="currentColor"/>
                <rect x="132.227" y="181.156" width="14.285" height="14.285" transform="rotate(-135 132.227 181.156)" fill="currentColor"/>
                <rect x="172.633" y="140.754" width="14.285" height="14.285" transform="rotate(-135 172.633 140.754)" fill="currentColor"/>
                <rect x="172.203" y="259.416" width="14.285" height="14.285" transform="rotate(-135 172.203 259.416)" fill="currentColor"/>
                <rect x="211.398" y="259.416" width="14.285" height="14.285" transform="rotate(-135 211.398 259.416)" fill="currentColor"/>
                <rect x="211.402" y="100.224" width="14.285" height="14.285" transform="rotate(-135 211.402 100.224)" fill="currentColor"/>
                <rect x="92.332" y="181.661" width="14.285" height="14.285" transform="rotate(-135 92.332 181.661)" fill="currentColor"/>
                <rect x="50.9609" y="181.661" width="14.285" height="14.285" transform="rotate(-135 50.9609 181.661)" fill="currentColor"/>
                <rect x="112.535" y="161.458" width="14.285" height="14.285" transform="rotate(-135 112.535 161.458)" fill="currentColor"/>
                <rect x="71.1641" y="161.458" width="14.285" height="14.285" transform="rotate(-135 71.1641 161.458)" fill="currentColor"/>
                <rect x="132.734" y="141.259" width="14.285" height="14.285" transform="rotate(-135 132.734 141.259)" fill="currentColor"/>
                <rect x="91.3672" y="141.26" width="14.285" height="14.285" transform="rotate(-135 91.3672 141.26)" fill="currentColor"/>
                <rect x="152.938" y="121.056" width="14.285" height="14.285" transform="rotate(-135 152.938 121.056)" fill="currentColor"/>
                <rect x="111.566" y="121.057" width="14.285" height="14.285" transform="rotate(-135 111.566 121.057)" fill="currentColor"/>
                <rect x="173.141" y="100.854" width="14.285" height="14.285" transform="rotate(-135 173.141 100.854)" fill="currentColor"/>
                <rect x="131.77" y="100.854" width="14.285" height="14.285" transform="rotate(-135 131.77 100.854)" fill="currentColor"/>
              </g>
            </svg>
          </i>
        </div>
        <h2>There’s NOTHING here....</h2>

        <span>...maybe the page you’re looking for is not found or never existed.</span>
        <a [routerLink]="['/']">
            Go Back to Home Page
        </a>
    </div>
  </section>
  <app-footer></app-footer>
</div>