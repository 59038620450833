<app-header></app-header>
<app-header-menu *ngIf="isHeaderMenuOpen"></app-header-menu>
<div class="scroller">
  <div class="header-class"></div>
  <div class="page-grid-block">
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
  </div>
  <div class="grid-background-overlay"></div>
  <section class="blog-hero">
      <app-tech-logo></app-tech-logo>
      <div class="blog-hero-container">
          <div class="blog-hero-block">
            <div class="back-btn-block">
                <a [routerLink]="['/blogs']" class="back-btn">
                    <i>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.828 11.0007H22V13.0007H5.828L11.192 18.3647L9.778 19.7787L2 12.0007L9.778 4.22266L11.192 5.63666L5.828 11.0007Z" fill="black"/>
                        </svg>
                    </i>
                    Back to Blogs
                </a>
            </div>
              <div class="blog-hero-inner-block">
                  <div class="page-title">
                      <h1>Rails 6 API fast_jsonapi gem with Devise and JWT authentication</h1>
                  </div>
              </div>
          </div>
      </div>
  </section>
  <section class="blog-wrapper">
      <div class="blog-detail-row">
          <div class="blog-detail-box">
              <figure>
                  <picture>
                      <img src="assets/images/blog/rails-6-api.webp" alt="rails 6 api">
                  </picture>
              </figure>
              <p>This article is all about authentication in rails 6 using devise and devise-jwt with fast_jsonapi response.</p>
              <h2>Fast_jsonapi</h2>
              <p>A lightning fast JSON:API serializer for Ruby Objects. It is better in performance compared to Active Model Serializer.</p>
              <h2>Devise and JWT</h2>
              <p>Devise-jwt is a devise extension which uses JSON Web Tokens(JWT) for user authentication. With JSON Web Tokens (JWT), rather than using a cookie, a token is added to the request headers themselves (rather than stored/retrieved as a cookie). This isn’t performed automatically by the browser (as with cookies), but typically will be handled by a front-end framework as part of an AJAX call.</p>
              <ol>
                  <li>
                      <h2>Create a new Rails API app</h2>
                      <p>In this step, We need to create a rails application with api_only mode with optional database params(If you want to change).</p>
                      <pre><code [highlight]="code1" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
                      <p>Here, I have created a rails 6 application using postgresql (Default SQLite).<br>
                          (Note: If you are using postgresql then you have to setup database.yml)</p>
                  </li>
                  <li>
                      <h2>Configure Rack Middleware</h2>
                      <p>As this is an API Only application, we have to handle ajax requests. So for that, we have to Rack Middleware for handling<a href="http://www.nczonline.net/blog/2010/05/25/cross-domain-ajax-with-cross-origin-resource-sharing/"> Cross-Origin Resource Sharing (CORS)</a></p>
                      <p>To do that, Just uncomment the <i>“gem ‘rack-cors’”</i> line from your generated <strong>Gemfile</strong>. And add the following lines to <strong>application.rb.</strong></p>
                      <h2>EDITOR</h2>
                      <pre><code [highlight]="code2" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
                      <p>Here, we can see that there should be an “Authorization” header exposed which will be used to dispatch and receive JWT tokens in Auth headers.</p>
                  </li>
                  <li>
                      <h2>Add the needed Gems</h2>
                      <p>Here, we are going to add gem like ‘devise’ and ‘devise-jwt’ for authentication and the dispatch and revocation of JWT tokens and ‘fast_jsonapi’ gem for json response.</p>
                      <pre><code [highlight]="code3" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
                      <p>Then, do <strong>‘bundle install’</strong></p>
                  </li>
                  <li>
                      <h2>Configure devise</h2>
                      <p>By running the following command to run a generator</p>
                      <pre><code [highlight]="code4" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
                      <p>It is important to set our navigational formats to empty in the generated <strong>devise.rb</strong> by adding the following line since it’s an api only app.</p>
                      <pre><code [highlight]="code5" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
                      <p>Also, add the following line to <strong><i>config/environments/development.rb</i></strong></p>
                      <pre><code [highlight]="code6" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
                  </li>
                  <li>
                      <h2>Create User model</h2>
                      <p>You can create a devise model to represent a user. It can be named as anything. So, I’m gonna be going ahead with User. Run the following command to create User model.</p>
                      <pre><code [highlight]="code7" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
                      <p>Then run migrations using,</p>
                      <pre><code [highlight]="code8" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
                      <p>or by,</p>
                      <pre><code [highlight]="code9" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
                      <pre><code [highlight]="code10" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
                  </li>
                  <li>
                      <h2><strong><b>Create devise controllers and routes</b></strong></h2>
                      <p>We need to create two controllers (sessions, registrations) to handle sign ups and sign ins. By,</p>
                      <pre><code [highlight]="code11" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
                      <p>specify that they will be responding to JSON requests. The files will looks like,</p>
                      <pre><code [highlight]="code12" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
                      <pre><code [highlight]="code13" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
                      <p>Then, add the routes aliases to override default routes provided by devise in the <strong>routes.rb</strong></p>
                      <pre><code [highlight]="code14" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
                  </li>
                  <li>
                      <h2><strong>Configure devise-jwt</strong></h2>
                      <p>Create a rake secret by running the following command.</p>
                      <pre><code [highlight]="code15" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
                      <p>Add the following lines to <strong><i>devise.rb</i></strong></p>
                      <pre><code [highlight]="code16" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
                      <p>Here, we are just specifying that on every post request to login call, append JWT token to Authorization header as “Bearer” + token when there’s a successful response sent back and on a delete call to logout endpoint, the token should be revoked.</p>
                      <p>The <i>jwt.expiration_time </i>sets the expiration time for the generated token. In this example, it’s 30 minutes.</p>
                  </li>
                  <li>
                      <h2><b>Set up a revocation strategy</b></h2>
                      <p>Revocation of token is conflicting with the main purpose of JWT token. Still devise-jwt comes with three revocation strategies out of the box. Some of them are implementations of what is discussed in the blog post<a href="http://waiting-for-dev.github.io/blog/2017/01/24/jwt_revocation_strategies"> JWT Revocation Strategies</a></p>
                      <p>Here, for the revocation of tokens, we will be using one of the 3 strategies.</p>
                      <p>Create a jwt_blacklist model by the following command</p>
                      <pre><code [highlight]="code17" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
                      <p>Add these two lines to the “<i><strong>jwt_blacklist.rb</strong>”</i></p>
                      <pre><code [highlight]="code18" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
                      <p>Add these two options to your devise User model to specify that the model will be jwt authenticatable and will be using the blacklist model we just created for revocation.</p>
                      <pre><code [highlight]="code19" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
                      <p>The final user model will look like this</p>
                      <pre><code [highlight]="code20" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
                      <p>Now run migrations using<strong> “rails db:migrate”</strong></p>
                  </li>
                  <li>
                      <h2><b>Add respond_with using fast_jsonapi method</b></h2>
                      <p>As we already added a fast_jsonapi gem. For json response for user data, we have to create a user serializer. By following command,</p>
                      <pre><code [highlight]="code21" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
                      <p>It will create a serializer with predefined structure.Now, we have to add the attributes which we have to set as a user response. So I have added user’s id, email and created_at.So the final version of <strong>user_serializer.rb</strong></p>
                      <pre><code [highlight]="code22" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
                      <p>We can access serializer data for single record by,</p>
                      <p><strong>UserSerializer.new(resource).serializable_hash[:data][:attributes]</strong><br>And multiple records by,<strong><br>UserSerializer.new(resource).serializable_hash[:data].map&#123;|data| data[:attributes]&#125;</strong></p>
                      <p>Now, we have to tell devise to communicate through JSON by adding these methods in the <strong>RegistrationsController </strong>and <strong>SessionsController<br></strong></p>
                      <pre><code [highlight]="code23" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
                      <pre><code [highlight]="code24" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
                      <p>You can modify the column name and data format by overwrite attribute:</p>
                      <p><strong>attribute :created_date do |user|</strong><br><strong> user.created_at.strftime(‘%d/%m/%Y’)</strong><br><strong>end</strong></p>
                      <p>Here, I have changed created_at attribute’s column name and its format.</p>
                      <p><a href="https://github.com/Netflix/fast_jsonapi">Here</a> you can get detailed information on fast_jsonapi.</p>
                  </li>
                  <li>
                      <h2><b>Finally, it’s done</b></h2>
                      <p>Now you can add the following line in any controller to authenticate your user.</p>
                      <p><strong>before_action :authenticate_user!</strong></p>
                      <p>If you are looking to develop any project on Ruby on Rails then choose us as we are one of the leading Ruby on Rails Development Company that provides quality Ruby on Rails development services. Contact us to hire Ruby on Rails developers for your Ruby on Rails requirement or you can reach us at <a class="markup--anchor markup--p-anchor" href="mailto:info@techcompose.com" target="_blank" rel="noopener noreferrer" data-href="mailto:info@techcompose.com">info@techcompose.com</a></p>
                  </li>
              </ol>
          </div>
          <app-share-blog-buttons
              [currentBlogUrl]="currentBlogUrl"
              [currentBlogTitle]="'Rails 6 API fast_jsonapi gem with Devise and JWT authentication - TechCompose'">
          </app-share-blog-buttons>
      </div>
  </section>
  <app-footer></app-footer>
</div>
