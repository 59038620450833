import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

/**
 * Shared Service
 */
@Injectable({
  providedIn: 'root'
})

export class sharedService {
  /**
     * url
     */
  public url: string = environment.baseAPIUrl;
  constructor(
    private http: HttpClient
  ) {}

  /** set industries preview value */
  public _indusPreview: Subject<Object> = new Subject<Object>();

  /** observable used for set industries preview value */
  public indusPreview$: Observable<Object> = this._indusPreview.asObservable();

  /** set project draw value */
  public _projectDraw: Subject<boolean> = new Subject<boolean>();

  /** observable used for set project draw value */
  public projectDraw$: Observable<boolean> = this._projectDraw.asObservable();

  private isHeaderMenuOpen: boolean = false;

  /** set show header menu */
  public _showHeaderMenu: Subject<boolean> = new Subject<boolean>();

  /** observable used for set show header menu value */
  public showHeaderMenu$: Observable<boolean> = this._showHeaderMenu.asObservable();

  /** call careers preview function */
  public indusPreview(indusPreview: Object): void {
    this._indusPreview.next(indusPreview);
  }

  /** call project draw function */
  public projectDraw(projectDraw: boolean): void {
    this._projectDraw.next(projectDraw);
  }

  public toggleShowHeaderMenu(toggleValue: boolean): void {
    this.isHeaderMenuOpen = toggleValue;
    this._showHeaderMenu.next(this.isHeaderMenuOpen);
  }

}
