<app-header class="black-header header-main"></app-header>
<app-header-menu *ngIf="isHeaderMenuOpen"></app-header-menu>
<app-industries-preview [selectedIndustry]="selectedIndustry" (closeDOM)="closeDOM()" *ngIf="open"></app-industries-preview>
<div class="scroller">
  <div class="header-class"></div>
  <div class="page-grid-block">
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
  </div>
  <div class="centerLIne"></div>
  <div class="hero-bg"></div>
  <div class="position-relative">
      <section class="techHero tech-black-section new-Hero panel">
          <div class="tech-logo-anim">
              <div class="tech-logo-grid box1 grid-col-1"><div class="tech-logo-box"></div></div>
              <div class="tech-logo-grid box2 grid-col-2"><div class="tech-logo-box"></div></div>
              <div class="tech-logo-grid box3 grid-col-2"><div class="tech-logo-box"></div></div>
              <div class="tech-logo-grid box4 grid-col-3"><div class="tech-logo-box"></div></div>
              <div class="tech-logo-grid box5 grid-col-3"><div class="tech-logo-box"></div></div>
              <div class="tech-logo-grid box6 grid-col-3"><div class="tech-logo-box"></div></div>
              <div class="tech-logo-grid box7 grid-col-4"><div class="tech-logo-box"></div></div>
              <div class="tech-logo-grid box8 grid-col-4"><div class="tech-logo-box"></div></div>
              <div class="tech-logo-grid box9 grid-col-4"><div class="tech-logo-box"></div></div>
              <div class="tech-logo-grid box10 grid-col-5"><div class="tech-logo-box"></div></div>
              <div class="tech-logo-grid box11 grid-col-5"><div class="tech-logo-box"></div></div>
              <div class="tech-logo-grid box12 grid-col-5"><div class="tech-logo-box"></div></div>
              <div class="tech-logo-grid box13 grid-col-5"><div class="tech-logo-box"></div></div>
              <div class="tech-logo-grid box14 grid-col-6"><div class="tech-logo-box"></div></div>
              <div class="tech-logo-grid box15 grid-col-6"><div class="tech-logo-box"></div></div>
              <div class="tech-logo-grid box16 grid-col-6"><div class="tech-logo-box"></div></div>
              <div class="tech-logo-grid box17 grid-col-6"><div class="tech-logo-box"></div></div>
              <div class="tech-logo-grid box18 grid-col-7"><div class="tech-logo-box"></div></div>
              <div class="tech-logo-grid box19 grid-col-7"><div class="tech-logo-box"></div></div>
              <div class="tech-logo-grid box20 grid-col-7"><div class="tech-logo-box"></div></div>
              <div class="tech-logo-grid box21 grid-col-7"><div class="tech-logo-box"></div></div>
              <div class="tech-logo-grid box22 grid-col-8"><div class="tech-logo-box"></div></div>
              <div class="tech-logo-grid box23 grid-col-8"><div class="tech-logo-box"></div></div>
              <div class="tech-logo-grid box24 grid-col-8"><div class="tech-logo-box"></div></div>
              <div class="tech-logo-grid box25 grid-col-9"><div class="tech-logo-box"></div></div>
              <div class="tech-logo-grid box26 grid-col-9"><div class="tech-logo-box"></div></div>
              <div class="tech-logo-grid box27 grid-col-9"><div class="tech-logo-box"></div></div>
              <div class="tech-logo-grid box28 grid-col-10"><div class="tech-logo-box"></div></div>
              <div class="tech-logo-grid box29 grid-col-10"><div class="tech-logo-box"></div></div>
              <div class="tech-logo-grid box30 grid-col-11"><div class="tech-logo-box"></div></div>
          </div>
         
          <div class="serviceList">
              <ul>
                  <li>
                      <span
                      class="textHoverSpan"
                      data-img="assets/videos/lets.mp4"
                      (mouseenter)="hoverMouseEnter($event.target)"
                      (mouseleave)="hoverMouseLeave($event.target)">
                      <span>
                          <span>Let’s Make</span>
                      </span>
                      </span>
                  </li> <li>
                      <span>
                          <span>Things</span>
                      </span>
                      <span
                      class="textHoverSpan"
                      data-img="assets/videos/smart.mp4"
                      (mouseenter)="hoverMouseEnter($event.target)"
                      (mouseleave)="hoverMouseLeave($event.target)">
                      <span>
                          <span>Smarter</span>
                      </span>
                      </span>
                  </li> <li>
                      <span><span>with</span></span>
                  </li> <li>
                      <span
                      class="textHoverSpan"
                      data-img="assets/videos/team.mp4"
                      (mouseenter)="hoverMouseEnter($event.target)"
                      (mouseleave)="hoverMouseLeave($event.target)">
                      <span>
                          <span>Superior Team</span>
                      </span>
                      </span>
                  </li>
              </ul>
              <div class="service-list-overlay"></div>
          </div>
            <!-- <div class="loader-text">
                <h1>
                    <span class="text-row">
                        <span class="text-inner-row" style="opacity: 0;">Bringing solution</span>
                    </span>
                    <span class="text-row">
                        <span class="text-inner-row" style="opacity: 0;">forward with</span>
                    </span>
                    <span class="text-row">
                        <span class="text-inner-row" style="opacity: 0;">Possibilities</span>
                    </span>
                </h1>
            </div> -->
            <div class="sub-title">
                <span>Bringing solution forward with possibilities</span>
            </div>
          <div class="scrollBlock">
              <span>
                <div class="mouse"></div>
                Scroll Down
              </span>
          </div>
      </section>
      <div class="hero-overlay"></div>
      <app-portfolio class="home-portfolio"></app-portfolio>
      <ng-container *ngIf="loadSections">
        <app-our-services></app-our-services>
      </ng-container>
  </div>
  <ng-container *ngIf="loadSections">
      <!-- <div class="service-info-block-home"> -->
          <div class="client-logo-block">
              <div class="section-title-block">
                  <div class="section-title-inner">
                      <h2 class="block-title">Solutions we develop for</h2>
                      <p class="block-info">Our solution portfolio crosses different industry verticals and borders.</p>
                  </div>
              </div>
              <div class="client-logo-inner">
                  <div class="client-logo">
                      <ul>
                        <li>
                          <span><img src="assets/images/work/volkswagen.webp" alt="Volkswagen" width="400" height="120"></span>
                        </li>
                        <li>
                          <span><img src="assets/images/work/pharmeasy.webp" alt="Pharmeasy" width="400" height="120"></span>
                        </li>
                        <li>
                          <span><img src="assets/images/work/coin-dcx.webp" alt="Coin Dcx" width="400" height="120"></span>
                        </li>
                        <li>
                          <span><img src="assets/images/work/scriptdoor.webp" alt="Scriptdoor" width="400" height="120"></span>
                        </li>
                        <li>
                          <span><img src="assets/images/work/arworkflow.webp" alt="ARWorkflow" width="400" height="120"></span>
                        </li>
                        <li>
                          <span><img src="assets/images/work/build-effective.webp" alt="BuildEffective" width="400" height="120"></span>
                        </li>
                        <li>
                          <span><img src="assets/images/work/linkmybooks.webp" alt="LinkMyBooks" width="400" height="120"></span>
                        </li>
                        <li>
                          <span span><img src="assets/images/work/percentology.webp" alt="Percentology" width="400" height="120"></span>
                        </li>
                        <li>
                          <span><img src="assets/images/work/ccalerts.webp" alt="CCAlerts" width="400" height="120"></span>
                        </li>
                        <li>
                          <span><img src="assets/images/work/talscale.webp" alt="Talscale" width="400" height="120"></span>
                        </li>
                        <li>
                          <span><img src="assets/images/work/eyeli.webp" alt="Eyeli" width="400" height="120"></span>
                        </li>
                        <li>
                          <span><img src="assets/images/work/verto.webp" alt="Verto" width="400" height="120"></span>
                        </li>
                        <li>
                          <span><img src="assets/images/work/eekifoods.webp" alt="Eekifoods" width="400" height="120"></span>
                        </li>
                        <li>
                          <span><img src="assets/images/work/stomerij-collectief.webp" alt="Stomerij-Collectief" width="400" height="120"></span>
                        </li>
                        <li>
                          <span><img src="assets/images/work/talowiz.webp" alt="Talowiz" width="400" height="120"></span>
                        </li>
                      </ul>
                  </div>
              </div>
          </div>
          <app-framework></app-framework>
      <!-- </div> -->
      <!-- <app-process></app-process> -->
      <app-industries></app-industries>
      <div class="average-wrapper">
          <div class="average-box">
              <div class="average-info">
                  <h2>We build solutions that engage your users and generate results for your business. </h2>
              </div>
              <ul>
                  <li>
                      <i>
                          <img src="assets/images/api-icon.png" alt="API icon" width="80" height="80">
                      </i>
                      <div class="avg-detail">
                          <span><span>10500</span>+</span>
                          <label>APIs</label>
                          <h4>Written</h4>
                      </div>
                  </li>
                  <li>
                      <i>
                          <img src="assets/images/seconds-icon.png" alt="Seconds icon" width="80" height="80">
                      </i>
                      <div class="avg-detail">
                          <span>0.<span>15</span></span>
                          <label>Seconds</label>
                          <h4>Avg. API Response time</h4>
                      </div>
                  </li>
                  <li>
                      <i>
                          <img src="assets/images/mili-seconds-icon.png" alt="Miliseconds Icon" width="80" height="80">
                      </i>
                      <div class="avg-detail">
                          <span><span>800</span></span>
                          <label>Miliseconds</label>
                          <h4>Avg. Page Load time</h4>
                      </div>
                  </li>
                  <li>
                      <i>
                          <img src="assets/images/kilobytes-icon.png" alt="Kilobytes Icon" width="80" height="80">
                      </i>
                      <div class="avg-detail">
                          <span><span>95</span></span>
                          <label>Kilobytes</label>
                          <h4>Avg. Payload Size</h4>
                      </div>
                  </li>
              </ul>
          </div>
      </div>
      <app-testimonial></app-testimonial>
      <app-footer></app-footer>
  </ng-container>
</div>
