import { Component, AfterViewInit } from '@angular/core';
import gsap from 'gsap';
@Component({
  selector: 'app-tech-logo',
  templateUrl: './tech-logo.component.html',
  styleUrls: ['./tech-logo.component.scss']
})
export class TechLogoComponent implements AfterViewInit {

  constructor() { }

  public ngAfterViewInit(): void {
    gsap.to('.grid-col-1', {duration: 1, scale: 1, ease: 'power1', delay: 1.5});
    gsap.to('.grid-col-2', {duration: 1, scale: 1, ease: 'power1', delay: 1.6});
    gsap.to('.grid-col-3', {duration: 1, scale: 1, ease: 'power1', delay: 1.7});
    gsap.to('.grid-col-4', {duration: 1, scale: 1, ease: 'power1', delay: 1.8});
    gsap.to('.grid-col-5', {duration: 1, scale: 1, ease: 'power1', delay: 1.9});
    gsap.to('.grid-col-6', {duration: 1, scale: 1, ease: 'power1', delay: 2});
    gsap.to('.grid-col-7', {duration: 1, scale: 1, ease: 'power1', delay: 2.1});
    gsap.to('.grid-col-8', {duration: 1, scale: 1, ease: 'power1', delay: 2.2});
    gsap.to('.grid-col-9', {duration: 1, scale: 1, ease: 'power1', delay: 2.3});
    gsap.to('.grid-col-10', {duration: 1, scale: 1, ease: 'power1', delay: 2.4});
    gsap.to('.grid-col-11', {duration: 1, scale: 1, ease: 'power1', delay: 2.5});
  }
}
