import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DelayResolver } from '../delay-resolver'; 
import { AboutComponent } from './about.component';
import { DigitalMarketingComponent } from './digital-marketing/digital-marketing.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { OurProcessComponent } from './our-process/our-process.component';

const routes: Routes = [
  {
    path: '',
    component: AboutComponent,
    children: [
        {
            path: '',
            component: AboutUsComponent,
            resolve: {data: DelayResolver }
        },
        {
            path: 'happy-super-and-effective-team',
            component: DigitalMarketingComponent,
            resolve: {data: DelayResolver }
        },
        {
          path: 'our-process',
          component: OurProcessComponent,
          resolve: {data: DelayResolver }
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AboutRoutingModule { }
