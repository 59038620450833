<app-header></app-header>
<app-header-menu *ngIf="isHeaderMenuOpen"></app-header-menu>
<div class="scroller">
  <div class="header-class"></div>
  <div class="page-grid-block">
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
  </div>
  <div class="main-service-wrapper">
      <div class="background-overlay bg-services"></div>
      <section class="service-in-hero">
          <app-tech-logo></app-tech-logo>
          <div class="service-in-hero-container">
              <div class="service-in-hero-block">
                  <div class="service-in-deail-block">
                      <div class="page-title">
                          <h1>IT Services</h1>
                      </div>
                      <!-- <div class="service-in-info-detail page-info">
                          <p> We offer a top-notch pervasive wide range of creative web/ mobile services, starting from developing code to design and development for a memorable customer experience. Our expertise in delivering secure, scalable, interactive, innovative & robust mobile apps has delighted 250+ customers in more than 15 countries across the globe. </p>
                      </div> -->
                  </div>
              </div>
          </div>
      </section>
      <section class="service-list-wrapper">
          <div class="service-container">
              <div class="service-row">
                <div class="service-list-block">
                    <div class="service-img-block">
                        <img src="assets/images/services/remote-work.webp" alt="Remote Work" width="958" height="1460">
                    </div>
                </div>
                <div class="service-detail-block">
                    <h3>Start-Up / Remote Work / RDC </h3>
                    <ul>
                        <li>From Concept to Marketshare</li>
                        <li>Happy and Motivated</li>
                        <li>Engineers + Creators + Performers</li>
                        <li>Effective communication with Clarity</li>
                        <li>Fully Equipped</li>
                        <li>Sound Infrastructure</li>
                        <li>Solution oriented mindset</li>
                        <li>Genuine and Dedicated</li>
                    </ul>
                    <a [routerLink]="['start-up-remote-work-rdc']">Read more
                        <i class="link-icon">
                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.5687 7.92313C14.3312 7.68545 13.946 7.68545 13.7081 7.92313C13.4704 8.16062 13.4704 8.5461 13.7081 8.78378L18.3174 13.3931H6.0705C5.73339 13.3931 5.45996 13.6666 5.45996 14.0037C5.45996 14.341 5.73339 14.6142 6.0705 14.6142H18.3113L13.702 19.2236C13.5876 19.3375 13.5234 19.4926 13.5234 19.654C13.5234 19.8156 13.5876 19.9703 13.702 20.0844C13.8163 20.1997 13.9729 20.2636 14.1354 20.2615C14.2974 20.2607 14.4529 20.1972 14.5687 20.0844L20.216 14.4373C20.3309 14.3222 20.395 14.166 20.3932 14.0037C20.3921 13.8417 20.3286 13.6868 20.216 13.5704L14.5687 7.92313Z" fill="currentColor"/>
                                <path d="M14.0072 0.877724C8.69816 0.876768 3.91155 4.0746 1.87993 8.97957C-0.151319 13.8847 0.972243 19.5305 4.72746 23.2834C9.8527 28.4086 18.162 28.4086 23.2871 23.2834C28.4121 18.1585 28.4121 9.84921 23.2871 4.72396C20.8313 2.25426 17.4899 0.86931 14.0072 0.877724ZM14.0072 25.9087C7.4442 25.9087 2.10231 20.5666 2.10231 14.0037C2.10231 7.4407 7.4442 2.0988 14.0072 2.0988C20.5701 2.0988 25.9122 7.4407 25.9122 14.0037C25.9122 20.5666 20.5701 25.9087 14.0072 25.9087Z" fill="currentColor"/>
                            </svg>
                        </i>
                    </a>
                </div>
              </div>
              <div class="service-row">
                  <div class="service-list-block">
                      <div class="service-img-block">
                          <img src="assets/images/services/web-app.webp" alt="Web Applications" width="958" height="1460"/>
                      </div>
                  </div>
                  <div class="service-detail-block">
                      <h3>Web Applications</h3>
                      <ul>
                          <li>Delivering Business Solutions</li>
                          <li>Manual Processes into Effective Digitalize System</li>
                          <li>Automation for Business Processes</li>
                          <li>Modern Technical Stack</li>
                          <li>Reporting & Data Processing / E-commerce apps / Online Shopping</li>
                          <li>Saas Applications</li>
                          <li>Governance, Privacy and Easy to operate</li>
                          <li>Scalable and Modularize</li>
                      </ul>
                      <a [routerLink]="['web-applications']">Read more
                          <i class="link-icon">
                              <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M14.5687 7.92313C14.3312 7.68545 13.946 7.68545 13.7081 7.92313C13.4704 8.16062 13.4704 8.5461 13.7081 8.78378L18.3174 13.3931H6.0705C5.73339 13.3931 5.45996 13.6666 5.45996 14.0037C5.45996 14.341 5.73339 14.6142 6.0705 14.6142H18.3113L13.702 19.2236C13.5876 19.3375 13.5234 19.4926 13.5234 19.654C13.5234 19.8156 13.5876 19.9703 13.702 20.0844C13.8163 20.1997 13.9729 20.2636 14.1354 20.2615C14.2974 20.2607 14.4529 20.1972 14.5687 20.0844L20.216 14.4373C20.3309 14.3222 20.395 14.166 20.3932 14.0037C20.3921 13.8417 20.3286 13.6868 20.216 13.5704L14.5687 7.92313Z" fill="currentColor"/>
                                  <path d="M14.0072 0.877724C8.69816 0.876768 3.91155 4.0746 1.87993 8.97957C-0.151319 13.8847 0.972243 19.5305 4.72746 23.2834C9.8527 28.4086 18.162 28.4086 23.2871 23.2834C28.4121 18.1585 28.4121 9.84921 23.2871 4.72396C20.8313 2.25426 17.4899 0.86931 14.0072 0.877724ZM14.0072 25.9087C7.4442 25.9087 2.10231 20.5666 2.10231 14.0037C2.10231 7.4407 7.4442 2.0988 14.0072 2.0988C20.5701 2.0988 25.9122 7.4407 25.9122 14.0037C25.9122 20.5666 20.5701 25.9087 14.0072 25.9087Z" fill="currentColor"/>
                              </svg>
                          </i>
                      </a>
                  </div>
              </div>
              <div class="service-row">
                  <div class="service-list-block">
                      <div class="service-img-block">
                          <img src="assets/images/services/mobile-app.webp" alt="Mobile Apps" width="958" height="1460" />
                      </div>
                  </div>
                  <div class="service-detail-block">
                      <h3>Mobile Apps</h3>
                      <ul>
                          <li>Simple User Interface</li>
                          <li>Easy to operate and Fast</li>
                          <li>For iOS, Android and Tablets</li>
                          <li>Hybrid Frameworks like Flutter, ReactNative</li>
                          <li>Availability of information on hand</li>
                          <li>Live modules, Collaborative features</li>
                          <li>Offline / Online Sync / Sharing</li>
                          <li>Multi-language support</li>
                          <li>Privacy and Data Security</li>
                      </ul>
                      <a [routerLink]="['mobile-apps']">Read more
                          <i class="link-icon">
                              <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M14.5687 7.92313C14.3312 7.68545 13.946 7.68545 13.7081 7.92313C13.4704 8.16062 13.4704 8.5461 13.7081 8.78378L18.3174 13.3931H6.0705C5.73339 13.3931 5.45996 13.6666 5.45996 14.0037C5.45996 14.341 5.73339 14.6142 6.0705 14.6142H18.3113L13.702 19.2236C13.5876 19.3375 13.5234 19.4926 13.5234 19.654C13.5234 19.8156 13.5876 19.9703 13.702 20.0844C13.8163 20.1997 13.9729 20.2636 14.1354 20.2615C14.2974 20.2607 14.4529 20.1972 14.5687 20.0844L20.216 14.4373C20.3309 14.3222 20.395 14.166 20.3932 14.0037C20.3921 13.8417 20.3286 13.6868 20.216 13.5704L14.5687 7.92313Z" fill="currentColor"/>
                                  <path d="M14.0072 0.877724C8.69816 0.876768 3.91155 4.0746 1.87993 8.97957C-0.151319 13.8847 0.972243 19.5305 4.72746 23.2834C9.8527 28.4086 18.162 28.4086 23.2871 23.2834C28.4121 18.1585 28.4121 9.84921 23.2871 4.72396C20.8313 2.25426 17.4899 0.86931 14.0072 0.877724ZM14.0072 25.9087C7.4442 25.9087 2.10231 20.5666 2.10231 14.0037C2.10231 7.4407 7.4442 2.0988 14.0072 2.0988C20.5701 2.0988 25.9122 7.4407 25.9122 14.0037C25.9122 20.5666 20.5701 25.9087 14.0072 25.9087Z" fill="currentColor"/>
                              </svg>
                          </i>
                      </a>
                  </div>
              </div>
              <div class="service-row">
                  <div class="service-list-block">
                      <div class="service-img-block">
                          <img src="assets/images/services/design.webp" alt="Web Design" width="958" height="1460">
                      </div>
                  </div>
                  <div class="service-detail-block">
                      <h3>Creative & Functional Design</h3>
                      <ul>
                          <li>Appealing to people</li>
                          <li>Impressive – Creatively and Technically</li>
                          <li>Pixel perfect and open to expand</li>
                          <li>User experience and Wireframing</li>
                          <li>Self-explaining Designs</li>
                          <li>Clear objective for Viewers</li>
                          <li>Easy to read and understand</li>
                          <li>Modern tools and technologies</li>
                          <li>Transmit Concept/Vision to working design</li>
                          <li>Expertise in Design Processes</li>
                      </ul>
                      <a [routerLink]="['creative-and-functional-design']">Read more
                          <i class="link-icon">
                              <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M14.5687 7.92313C14.3312 7.68545 13.946 7.68545 13.7081 7.92313C13.4704 8.16062 13.4704 8.5461 13.7081 8.78378L18.3174 13.3931H6.0705C5.73339 13.3931 5.45996 13.6666 5.45996 14.0037C5.45996 14.341 5.73339 14.6142 6.0705 14.6142H18.3113L13.702 19.2236C13.5876 19.3375 13.5234 19.4926 13.5234 19.654C13.5234 19.8156 13.5876 19.9703 13.702 20.0844C13.8163 20.1997 13.9729 20.2636 14.1354 20.2615C14.2974 20.2607 14.4529 20.1972 14.5687 20.0844L20.216 14.4373C20.3309 14.3222 20.395 14.166 20.3932 14.0037C20.3921 13.8417 20.3286 13.6868 20.216 13.5704L14.5687 7.92313Z" fill="currentColor"/>
                                  <path d="M14.0072 0.877724C8.69816 0.876768 3.91155 4.0746 1.87993 8.97957C-0.151319 13.8847 0.972243 19.5305 4.72746 23.2834C9.8527 28.4086 18.162 28.4086 23.2871 23.2834C28.4121 18.1585 28.4121 9.84921 23.2871 4.72396C20.8313 2.25426 17.4899 0.86931 14.0072 0.877724ZM14.0072 25.9087C7.4442 25.9087 2.10231 20.5666 2.10231 14.0037C2.10231 7.4407 7.4442 2.0988 14.0072 2.0988C20.5701 2.0988 25.9122 7.4407 25.9122 14.0037C25.9122 20.5666 20.5701 25.9087 14.0072 25.9087Z" fill="currentColor"/>
                              </svg>
                          </i>
                      </a>
                  </div>
              </div>
              <div class="service-row">
                  <div class="service-list-block">
                      <div class="service-img-block">
                          <img src="assets/images/services/website.webp" alt="Websites" width="958" height="1460">
                      </div>
                  </div>
                  <div class="service-detail-block">
                      <h3>Websites</h3>
                      <ul>
                          <li>Engaging and Modern</li>
                          <li>Webflow / Wordpress / Wix</li>
                          <li>On-page SEO</li>
                          <li>Page loading performance</li>
                          <li>Tracking and Analytics</li>
                          <li>Secured and updated</li>
                          <li>Regular Backups</li>
                          <li>Multi-language support</li>
                      </ul>
                      <a [routerLink]="['websites']">Read more
                          <i class="link-icon">
                              <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M14.5687 7.92313C14.3312 7.68545 13.946 7.68545 13.7081 7.92313C13.4704 8.16062 13.4704 8.5461 13.7081 8.78378L18.3174 13.3931H6.0705C5.73339 13.3931 5.45996 13.6666 5.45996 14.0037C5.45996 14.341 5.73339 14.6142 6.0705 14.6142H18.3113L13.702 19.2236C13.5876 19.3375 13.5234 19.4926 13.5234 19.654C13.5234 19.8156 13.5876 19.9703 13.702 20.0844C13.8163 20.1997 13.9729 20.2636 14.1354 20.2615C14.2974 20.2607 14.4529 20.1972 14.5687 20.0844L20.216 14.4373C20.3309 14.3222 20.395 14.166 20.3932 14.0037C20.3921 13.8417 20.3286 13.6868 20.216 13.5704L14.5687 7.92313Z" fill="currentColor"/>
                                  <path d="M14.0072 0.877724C8.69816 0.876768 3.91155 4.0746 1.87993 8.97957C-0.151319 13.8847 0.972243 19.5305 4.72746 23.2834C9.8527 28.4086 18.162 28.4086 23.2871 23.2834C28.4121 18.1585 28.4121 9.84921 23.2871 4.72396C20.8313 2.25426 17.4899 0.86931 14.0072 0.877724ZM14.0072 25.9087C7.4442 25.9087 2.10231 20.5666 2.10231 14.0037C2.10231 7.4407 7.4442 2.0988 14.0072 2.0988C20.5701 2.0988 25.9122 7.4407 25.9122 14.0037C25.9122 20.5666 20.5701 25.9087 14.0072 25.9087Z" fill="currentColor"/>
                              </svg>
                          </i>
                      </a>
                  </div>
              </div>
              
          </div>
      </section>
  </div>
  <app-footer></app-footer>
</div>
