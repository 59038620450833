<app-header></app-header>
<app-header-menu *ngIf="isHeaderMenuOpen"></app-header-menu>
<div class="scroller">
  <div class="header-class"></div>
  <div class="page-grid-block">
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
  </div>
  <div class="grid-background-overlay"></div>
  <section class="blog-hero">
      <app-tech-logo></app-tech-logo>
      <div class="blog-hero-container">
          <div class="blog-hero-block">
            <div class="back-btn-block">
                <a [routerLink]="['/blogs']" class="back-btn">
                    <i>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.828 11.0007H22V13.0007H5.828L11.192 18.3647L9.778 19.7787L2 12.0007L9.778 4.22266L11.192 5.63666L5.828 11.0007Z" fill="black"/>
                        </svg>
                    </i>
                    Back to Blogs
                </a>
            </div>
              <div class="blog-hero-inner-block">
                  <div class="page-title">
                      <h1>Angular + Jest<br>Test cases specific to Local Storage & Behavior Subject with GET method</h1>
                      <span>By: Sagar Panchal</span>
                  </div>
              </div>
          </div>
      </div>
  </section>
  <section class="blog-wrapper">
      <div class="blog-detail-row">
          <div class="blog-detail-box">            
                <figure>
                    <picture>
                        <img src="assets/images/blog/testcases-angular.webp" alt="TestCases Angular">
                    </picture>
                </figure>
                <div class="blog-group">
                    <p>At, TechCompose Solutions, we have been following a practice of writing test cases for all front-end and back-end development.</p>
                    <p class="mb-10">This practice of writing test cases can help in</p>
                    <ul class="dash-list">
                        <li>Understanding the requirements much better</li>
                        <li>Uncovers some use cases and underlying complexities for engineers</li>
                        <li>Motivates developers to write refactored and optimized code</li>
                    </ul>
                    <p>We use the Jest testing framework for Angular, NodeJS, and ReactJS.</p>
                </div>
                <div class="blog-group">
                    <div class="blog-intro-block">
                        <h3>Introduction to Jest:</h3>
                        <p>Jest is currently the most popular JavaScript Testing Framework in the industry due to its simple syntax, minimum configuration & high speed, and extensive features such as easy mocking, code coverage, etc.</p>
                        <p><a class="markup--anchor markup--p-anchor" href="https://jestjs.io/" target="_blank">Click here</a> to learn more about Jest.</p>
                        <h4>Reference links to configure Jest in Angular.</h4>
                        <span>Here are some reference links which can help you to configure Jest in Angular.</span>
                        <ul>
                            <li><a class="markup--anchor markup--p-anchor" href="https://jnpiyush.medium.com/how-to-set-up-jest-in-angular-app-961ddcbab8af" target="blank">This</a> is an article for Step by step-by-step guide to add jest into Angular.</li>
                            <li>For more understanding, you can also follow this video to set up jest with Angular. <a class="markup--anchor markup--p-anchor" href="https://www.youtube.com/watch?v=Dt8RtykEglo" target="_blank">Video tutorial to set up jest with Angular.</a></li>
                        </ul>
                    </div>
                </div>
                <div class="blog-group">
                    <p class="mb-10">This blog covers topics related to</p>
                    <ul class="order-list-small">
                        <li>Pre-requisites for starting to write custom test cases.</li>
                        <li><b>Local storage</b> test cases.</li>
                        <li>Test cases for <b>GET</b> method with <b>Behavior subject variables.</b></li>
                    </ul>
                </div>
                <div class="blog-group">
                    <ol>
                        <li>
                            <h2>Pre-requisites that can make your process to write custom test cases hassle-free</h2>
                            <div class="blog-group">
                                <div class="protip-block">
                                    <span class="protip-icon">
                                        <img src="assets/images/tip-icon.png" alt="protip-icon">
                                    </span>
                                    <h3><b>Protip</b> - While writing test cases for any file(components, services, etc.), make sure to write it in isolation.</h3>
                                    <ul>
                                        <li>
                                            <b>For example</b> - If the file, for which you are writing the test case, has any functions or variables defined in another file(services or components), you must create mocks first.
                                        </li>
                                        <li>
                                            <b>Why?</b> - It is because we should not make a call to actual functions or variables(at another file) for isolation while writing the test cases. <u><i>i.e. considered to be the best practice while writing test cases.</i></u>
                                        </li>
                                    </ul>
                                </div>
                                <div class="protip-block">
                                    <span class="protip-icon">
                                        <img src="assets/images/tip-icon.png" alt="protip-icon">
                                    </span>
                                    <h3><b>Protip</b> - Always pass the default test case first. </h3>
                                    <ul>
                                        <li><b>Why?</b> -This will allow you to resolve all required dependencies in order to write test cases for that particular file. </li>
                                        <li><b>Results</b> - It will help avoid any dependency-related issues in the future while writing your custom test cases. Again one of the best approaches 🤓.</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="blog-group">
                                <div class="code-info-block">
                                    <p>Any additional <u><i>modules</i></u> used will be mentioned in the <b>imports</b> of the <b>configureTestingModule</b> method of the test cases as shown below.</p>
                                    <pre><code [highlight]="code1" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
                                </div>
                                <div class="code-info-block">
                                    <p>Any additional <u><i>components</i></u> used will be mentioned in the <b>declaration</b> of the <b>configureTestingModule</b> method of the test cases as shown below.</p>
                                    <pre><code [highlight]="code2" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
                                </div>
                                <div class="code-info-block">
                                    <p>Any additional <u><i>services</i></u> used, will be mentioned in the <b>providers array</b> of the <b>configureTestingModule</b> method of the test cases as shown below.</p>
                                    <pre class="mb-0"><code [highlight]="code3" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
                                    <p class="notes-info">Note: Here we’ve used useValue while providing a mock for the user-service. You can also use useClass.</p>
                                </div>
                                <div class="code-info-block">
                                    <p>Now I believe we have enough base so let’s dive straight into the Test cases 🚀:</p>
                                    <p class="notes-info">Note: we’ve attached the <a class="markup--anchor markup--p-anchor" href="https://github.com/sagarTc125/angular-with-jest">Repository</a> consisting code mentioned below for reference.</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <h2>How to mock LocalStorage</h2>
                            <div class="blog-group">
                                <p class="mb-10">To write test cases related to local storage, the basic strategy would be to</p>
                                <ul class="dash-list">
                                    <li>Create a fake <b>localstorage</b></li>
                                    <li>Assign it to the window object as a replacement to the actual <b>localstorage</b></li>
                                </ul>
                            </div>
                            <div class="blog-group">
                                <div class="code-info-block">
                                    <p>It is because, by using our mocked localstorage, we can avoid disturbing actual localstorage while executing the code during test cases.</p>
                                    <p>Let’s write a test case for the below function where</p>
                                    <p>Here, We’re signing out a user by removing user data from local storage.</p>
                                    <pre><code [highlight]="code4" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
                                </div>
                                <div class="code-info-block">
                                    <p>Now, first, we’ll mock the localstorage in the spec file as shown below</p>
                                    <pre class="mb-0"><code [highlight]="code5" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
                                    <p class="notes-info">Note: In the last line, We've also added a fake userData for the actual function to remove.</p>
                                </div>
                                <div class="code-info-block">
                                    <p>Now, you can just add the setUpLocalStorageMock function to your spec file and call it in beforeEach method.</p>
                                    <pre class="mb-0"><code [highlight]="code6" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
                                </div>
                                <div class="code-info-block">
                                    <p>And finally, we’re all set to write an actual test case for the function.</p>
                                    <pre class="mb-0"><code [highlight]="code7" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
                                </div>
                            </div>
                        </li>
                        <li>
                            <h2>Test case for GET method with Behavior subject variables</h2>
                            <div class="blog-group">
                                <p>This particular test case is related to the <b>Behavior subject</b> and the <b>GET</b> method to access the value of the Behavior subject.</p>
                                <p class="mb-10">Following is the example of the service file that has</p>
                                <ul class="dash-list">
                                    <li><b>isUserLoggedIn</b> as a <b>Behavior subject</b> with a boolean value</li>
                                    <li><b>userLoginValue</b> as a <b>GET</b> method of that particular behavior subject</li>
                                </ul>
                                <pre class="mb-0"><code [highlight]="code8" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
                            </div>
                            <div class="blog-group">
                                <div class="code-info-block">
                                    <p>Here’s how we’ll write a test case for the userLoginValue with the GET method.</p>
                                    <pre class="mb-0"><code [highlight]="code9" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
                                </div>
                                <div class="code-info-block">
                                    <p>Now, let’s see how we can write a test case for the component, using the userLoginValue with the GET method. </p>
                                    <p>Below is the code snippet of the component accessing it,</p>
                                    <pre class="mb-0"><code [highlight]="code10" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
                                </div>
                                <div class="code-info-block">
                                    <p>We’ve to mock user service first for writing test cases for the component so that, no variables or functions of the actual service will be used & the testing for the component could be performed in isolation.</p>
                                    <div class="protip-block">
                                        <span class="protip-icon">
                                            <img src="assets/images/tip-icon.png" alt="protip-icon">
                                        </span>
                                        <h3 class="mb-0"><b>Pro-tip:</b> - In the following code snippet, we’ve mocked only necessary variables and functions that are used in the component and not everything from the service file mentioned above. This way you can avoid spending extra effort 😎.</h3>
                                    </div>
                                    <pre class="mb-0"><code [highlight]="code11" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
                                </div>
                                <div class="code-info-block">
                                    <p>And so here’s how we can write a test case for calling the component function.</p>
                                    <pre class="mb-0"><code [highlight]="code12" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
                                </div>
                            </div>
                        </li>
                    </ol>
                </div>
          </div>
          <app-share-blog-buttons
              [currentBlogUrl]="currentBlogUrl"
              [currentBlogTitle]="'TestCases in Angular + Jest for Local Storage & Behavior Subject with get methods'">
          </app-share-blog-buttons>
      </div>
  </section>
  <app-footer></app-footer>
</div>
