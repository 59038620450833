import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ServicesRoutingModule } from './services-routing.module';
import { WebApplicationDevelopmentComponent } from './web-application-development/web-application-development.component';
import { WebsiteDevelopmentComponent } from './website-development/website-development.component';
import { WebDesignComponent } from './web-design/web-design.component';
import { MobileApplicationDevelopmentComponent } from './mobile-application-development/mobile-application-development.component';
import { DigitalMarketingComponent } from './digital-marketing/digital-marketing.component';
import { OffshoreDevelopmentCenterComponent } from './offshore-development-center/offshore-development-center.component';
import { ItServicesComponent } from './it-services/it-services.component';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [
    WebApplicationDevelopmentComponent,
    WebsiteDevelopmentComponent,
    WebDesignComponent,
    MobileApplicationDevelopmentComponent,
    DigitalMarketingComponent,
    OffshoreDevelopmentCenterComponent,
    ItServicesComponent
  ],
  imports: [
    CommonModule,
    ServicesRoutingModule,
    SharedModule
  ]
})
export class ServicesModule { }
