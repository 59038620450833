import { Component, OnInit } from '@angular/core';

import { Title, Meta } from '@angular/platform-browser';
import gsap from 'gsap';
import Scrollbar, { ScrollbarPlugin } from 'smooth-scrollbar';
import { sharedService } from 'src/app/shared/shared.service';

import {HighlightLoader,} from 'ngx-highlightjs';

@Component({
  selector: 'app-use-of-rails',
  templateUrl: './use-of-rails.component.html',
  styleUrls: ['./use-of-rails.component.scss']
})
export class UseOfRailsComponent implements OnInit {

  bodyScrollBar: Scrollbar | undefined;
  public isHeaderMenuOpen: boolean = false;
  public currentBlogUrl: string = window.location.href;
  public baseUrl: string = window.location.origin;

public code1 =`rails new Mywebpacker --webpack`;

public code2 =`#Gemfile
gem ‘webpacker’, ‘~> 5.1’`;

public code3 =`bundle exec rails webpacker:install`;

public code4 =`require("rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")`;

public code5 =`rails new testWebpacker --webpack=react`;

public code6 =`import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

const Hello = props => (
  <div>Hello {props.name}!</div>
)

Hello.defaultProps = {
  name: 'David'
}

Hello.propTypes = {
  name: PropTypes.string
}

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <Hello name="React" />,
    document.body.appendChild(document.createElement('div')),
  )
})`;

public code7 =`<%= javascript_pack_tag 'hello_react' %>`;

public code8 =`class DashboardController < ActionController::Base  
  def index
  end
end  `;

public code9 =`Rails.application.routes.draw do
  root 'dashboard#index' 
end`;

public code10 =`Rails s`;

  constructor(private metaTagService: Meta, private titleService: Title, public sharedservice: sharedService, private hljsLoader: HighlightLoader) { }

  ngOnInit(): void {

    this.titleService.setTitle("How to use Rails 6 webpack with front-end JS framework - TechCompose");
    this.metaTagService.updateTag({ name: 'keywords', content: 'Rails 6 comes with exciting new features like Parallel Testing, Multiple database Support and Webpacker. Lets understand the Webpacker because it is now used as the default javascript compiler in Rails 6. In the previous rails versions, we are using Sprockets as JS compiler. To understand Webpacker we first need to understand how it’s done … ' }),
    this.metaTagService.updateTag({ property: 'og:title', content: 'Techcompose | How to use Rails 6 webpack with front-end JS framework' }),
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' }),
    this.metaTagService.updateTag({ property: 'og:url', content: `${this.currentBlogUrl}` }),
    this.metaTagService.updateTag({ property: 'og:image', content: `${this.baseUrl}/assets/images/blog/how-to-use-rails.png` });
    this.metaTagService.updateTag({ property: 'og:description', content: 'Rails 6 comes with exciting new features like Parallel Testing, Multiple database Support and Webpacker. Lets understand the Webpacker because it is now used as the default javascript compiler in Rails 6. In the previous rails versions, we are using Sprockets as JS compiler. To understand Webpacker we first need to understand how it’s done …' })
    this.metaTagService.removeTag("name='google-site-verification'");

    this.sharedservice.showHeaderMenu$.subscribe((data) => {
      this.isHeaderMenuOpen = data;
      if (this.bodyScrollBar) {
        this.bodyScrollBar.updatePluginOptions('modal', { open: this.isHeaderMenuOpen });
      }
    });

    gsap.registerPlugin(ScrollTrigger);
    const scroller = document.querySelector('.scroller')as HTMLElement;

    this.bodyScrollBar = Scrollbar.init(scroller, { damping: 0.1, delegateTo: document, alwaysShowTracks: true });

    const content = this;
    ScrollTrigger.scrollerProxy(".scroller", {
      scrollTop(value) {
        if (content.bodyScrollBar) {
          if (arguments.length) {
            content.bodyScrollBar.scrollTop = value || 0;
          }
          return content.bodyScrollBar.scrollTop;
        } else {
          return 0;
        }
      }
    });

    this.bodyScrollBar.addListener(ScrollTrigger.update);
    ScrollTrigger.defaults({ scroller: scroller });

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    ScrollTrigger.create({
      trigger: ".header-class",
      start: "+50px",
      end: "bottom -=1000%",
      toggleClass: { targets: ".header", className: "active" }
    });

  }

}
class ModalPlugin extends ScrollbarPlugin {
  static pluginName = 'modal';

  static defaultOptions = {
    isHeaderMenuOpen: false
  };

  transformDelta(delta: any) {
      return this.options.open ? { x: 0, y: 0 } : delta;
  }
}

Scrollbar.use(ModalPlugin, /* OverscrollPlugin */);
