import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LoaderComponent } from './loader/loader.component';
import { AboutComponent } from './about/about.component';
import { CareersComponent } from './careers/careers.component';
import { ContactComponent } from './contact/contact.component';
import { ServicesComponent } from './services/services.component';
import { OurTestimonialsComponent } from './our-testimonials/our-testimonials.component';
import { HttpClientModule } from '@angular/common/http';
import { BuildeffectiveComponent } from './work/buildeffective/buildeffective.component';
import { OurServicesComponent } from './home/our-services/our-services.component';
import { TestimonialComponent } from './home/testimonial/testimonial.component';
import { IndustriesComponent } from './home/industries/industries.component';
import { FrameworkComponent } from './home/framework/framework.component';
import { SharedModule } from './shared/shared.module';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { ProcessComponent } from './home/process/process.component';
import { IndustriesPreviewComponent } from './industries-preview/industries-preview.component';
import { ProjectDrawerComponent } from './project-drawer/project-drawer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { CareerDetailsComponent } from './career-details/career-details.component';
import {
  HighlightAutoResult,
  HighlightLoader,
  HighlightModule,
  HighlightOptions,
  HIGHLIGHT_OPTIONS,
} from 'ngx-highlightjs';
import { PrivaryPolicyComponent } from './privary-policy/privary-policy.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

import { environment } from 'src/environments/environment';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore/';
import { AngularFireModule } from '@angular/fire/compat';
import { PhoneInputDirective } from './directives/phone-input.directive';


export const options: Partial<IConfig> = {
  thousandSeparator: "'"
};
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    IndustriesPreviewComponent,
    ProjectDrawerComponent,
    LoaderComponent,
    AboutComponent,
    CareersComponent,
    ContactComponent,
    ServicesComponent,
    OurTestimonialsComponent,
    BuildeffectiveComponent,
    OurServicesComponent,
    TestimonialComponent,
    IndustriesComponent,
    FrameworkComponent,
    ProcessComponent,
    CareerDetailsComponent,
    PrivaryPolicyComponent,
    PageNotFoundComponent,
    PhoneInputDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxUsefulSwiperModule,
    HttpClientModule,
    SharedModule,
    ScullyLibModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(options),
    HighlightModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule
  ],
  providers: [
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: <HighlightOptions>{
        lineNumbers: true,
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          typescript: () => import('highlight.js/lib/languages/typescript'),
          css: () => import('highlight.js/lib/languages/css'),
          xml: () => import('highlight.js/lib/languages/xml'),
        },
      },
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
