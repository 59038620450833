<app-header></app-header>
<app-header-menu *ngIf="isHeaderMenuOpen"></app-header-menu>
<div class="scroller">
  <div class="header-class"></div>
  <div class="page-grid-block">
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
  </div>
  <div class="grid-background-overlay"></div>
  <section class="blog-hero">
      <app-tech-logo></app-tech-logo>
      <div class="blog-hero-container">
          <div class="blog-hero-block">
            <div class="back-btn-block">
                <a [routerLink]="['/blogs']" class="back-btn">
                    <i>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.828 11.0007H22V13.0007H5.828L11.192 18.3647L9.778 19.7787L2 12.0007L9.778 4.22266L11.192 5.63666L5.828 11.0007Z" fill="black"/>
                        </svg>
                    </i>
                    Back to Blogs
                </a>
            </div>
              <div class="blog-hero-inner-block">
                  <div class="page-title">
                      <h1>Convert HTML to PDF in CodeIgniter using Dompdf</h1>
                  </div>
              </div>
          </div>
      </div>
  </section>
  <section class="blog-wrapper">
      <div class="blog-detail-row">
          <div class="blog-detail-box">
              <figure>
                  <picture>
                      <img src="assets/images/blog/convert-html-to-pdf.webp" alt="convert html to pdf">
                  </picture>
              </figure>
              <p>Convert HTML to PDF in CodeIgniter using Dompdf</p>
              <p>Nowadays PDF is the most used format in various web applications. There are multiple libraries like TCPDF, <a href="https://google.github.io/snappy/">Snappy</a>, mPDF etc can be used to create PDF in a web application. DomPDF is one of the most popular libraries to convert your data into PDF. Here are very easy steps to create HTML to PDF:</p>
              <p>We are using default <code>views/welcome_message.php</code> with <code>controller/welcome.php</code> to generate pdf that is easy to understand. You can add your model &amp; include it into the controller.</p>
              <p>Step 1: Download DOMPDF from <a href="assets/data/dompdf-1.zip">here</a>. Paste it into application\libraries folder<br>Step 2: Create a file name named Pdf.php into application\libraries folder and paste below code.</p>
              <pre><code [highlight]="code1" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
              <p>Step 3 : Now create an controller that renders html view to PDF.</p>
              <pre><code [highlight]="code2" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
              <p>Step 4 :In above controller we have used default welcome.php, you can replace with YOUR_VIEW.php file. Create a model if you are using custom view in step4 and load it into controller.</p>
              <pre><code [highlight]="code3" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
              <h2>Editor</h2>
              <figure>
                  <picture>
                      <img src="assets/images/blog/blog-detail/convert-html-to-pdf-1.png" alt="convert html to pdf">
                  </picture>
              </figure>
              <p>That’s it your rendered html in view file will looks like above image. Check it by http://YOUR_SITE_URL/welcome/generatepdf</p>
              <p>So this is How to Convert HTML to PDF in CodeIgniter using Dompdf, If you are looking to create a unique, feature-rich and error-free web application development then you need to hire a dedicated developer from Techcompose Solutions who work solely on your project who will make sure your project is developed within the time frame and it meets your business requirement. Our Developers are experienced and had executed several small to complex development projects. We strictly believe in proper communication at the beginning which makes sure that your requirement is totally understood and we both are in the right track. Our developers know very well what your business may require to enable you to stand out in competition. Contact us to hire dedicated developers for developing a project on Codeigniter or you can reach us at <a href="mailto:inquiry@techcompose.com">inquiry@techcompose.com</a></p>
          </div>
          <app-share-blog-buttons
              [currentBlogUrl]="currentBlogUrl"
              [currentBlogTitle]="'Convert HTML to PDF in CodeIgniter using Dompdf - TechCompose'">
          </app-share-blog-buttons>
      </div>
  </section>
  <app-footer></app-footer>
</div>
