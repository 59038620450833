<app-header></app-header>
<app-header-menu *ngIf="isHeaderMenuOpen"></app-header-menu>
<div class="scroller">
  <div class="header-class"></div>
  <div class="page-grid-block">
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
  </div>
  <div class="grid-background-overlay"></div>
  <section class="blog-hero">
      <app-tech-logo></app-tech-logo>
      <div class="blog-hero-container">
          <div class="blog-hero-block">
            <div class="back-btn-block">
                <a [routerLink]="['/blogs']" class="back-btn">
                    <i>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.828 11.0007H22V13.0007H5.828L11.192 18.3647L9.778 19.7787L2 12.0007L9.778 4.22266L11.192 5.63666L5.828 11.0007Z" fill="black"/>
                        </svg>
                    </i>
                    Back to Blogs
                </a>
            </div>
              <div class="blog-hero-inner-block">
                  <div class="page-title">
                      <h1>How to use Rails 6 webpack with front-end JS framework</h1>
                  </div>
              </div>
          </div>
      </div>
  </section>
  <section class="blog-wrapper">
      <div class="blog-detail-row">
          <div class="blog-detail-box">
              <figure>
                  <picture>
                      <img src="assets/images/blog/how-to-use-rails.webp" alt="how to use rails">
                  </picture>
              </figure>
              <p>Rails 6 comes with exciting new features like Parallel Testing, Multiple database Support and Webpacker.</p>
              <p>Lets understand the Webpacker because it is now used as the default javascript compiler in Rails 6. In the previous rails versions, we are using Sprockets as JS compiler. To understand Webpacker we first need to understand how it’s done in application before rails 6.</p>
              <h2>Before Rails 6</h2>
              <p>JavaScript code is compiled in Sprockets by default and stored inside the app/assets/javascript directory.</p>
              <p>In previous applications of Rails 6 we can use the Webpacker.</p>
              <p>With Rails 5.1+ Application, we need to provide an option to use Webpacker as compiler while creating a new application.</p>
              <pre><code [highlight]="code1" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
              <p>Or we can use as Gem in existing rails app</p>
              <pre><code [highlight]="code2" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
              <p>Install in the rails application</p>
              <pre><code [highlight]="code3" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
              <h2>In Rails 6</h2>
              <p>When we create a new rails application, it will be installed with the application and webpacker:install will be run by the rails application generator.</p>
              <p>In the Rails 6 application directory structure there will be a default directory of app/JavaScript that will host the JavaScript files . The JavaScript code of the Active Storage, Action Cable, Turbolinks and Rails-Ujs will be loaded in Application.js in app/JavaScript directory</p>
              <h2>What is Webpacker?</h2>
              <p>Webpacker is a gem, which allows easy integration of javascript pre-processor and bundlers in rails. It provides various helpers and configuration options to use webpack easily with Rails.</p>
              <h2>Webpack Directory structure</h2>
              <figure>
                <picture>
                    <img src="assets/images/blog/blog-detail/use-of-rails-1.png" alt="use of rails">
                </picture>
              </figure>
              <p>Here, Packs directory is considered to be an entry point of webpack. All the files in the packs directory are compiled by the webpacker. By default application.js file has the following JS code.</p>
              <pre><code [highlight]="code4" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
              <h2>Benefits of Webpacker</h2>
              <ul>
                  <li>Webpack can allow us to write the javascript source code within a module and which can help us take the advantage of module scope within each file.</li>
                  <li>This will help us manage dependencies in a better way. We can replace our asset gems with Node Package Manager.</li>
                  <li>With this we can stop using jquery plugins if we want.</li>
                  <li>We can use TypeScript with this which opens endless posibilities.</li>
                  <li>We can unlock many powerful new tools and new libraries that we can use now due to the webpack integration.</li>
              </ul>
              <p><strong>Now, let me create a simple application to use the React front-end JS framework with Rails 6 Webpacker.</strong></p>
              <p>In this tutorial I am using Ruby 2.6.4 and Rails 6.0.2.2</p>
              <p>1. Create a new rails app with below command line</p>
              <pre><code [highlight]="code5" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
              <p>2. This will create a new Rails app with webpacker configured and a new directory with new files as shown below:</p>
              <figure>
                <picture>
                    <img src="assets/images/blog/blog-detail/use-of-rails-2.png" alt="use of rails">
                </picture>
              </figure>
              <p>3. Here all files in the new app/javascript/packs directory are going to be compiled by webpack.</p>
              <p>4. Here as we can see there is one sample react file hello_react.jsx which shows the functional React component called Hello which will display the words “Hello React!” on a page. We can also take a prop value name to display it instead of the word “React”.</p>
              <pre><code [highlight]="code6" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
              <p>5. We can use this component on any page by linking it with the javascript_pack_tag helper method.</p>
              <p>6. Let’s create a new dashboard index view file app/views/dashboard/index.html.erb and use the javascript_pack_tag method inside it to display the default example Hello component:</p>
              <pre><code [highlight]="code7" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
              <p>7. Let’s create the controller and index action for dashboard:</p>
              <pre><code [highlight]="code8" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
              <p>8. Create the routes for the same in config/routes.rb</p>
              <pre><code [highlight]="code9" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
              <p>9. Let’s start the rails server:</p>
              <pre><code [highlight]="code10" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
              <p>10. Go to http://localhost:3000/ to see the result:</p>
              <figure>
                <picture>
                    <img src="assets/images/blog/blog-detail/use-of-rails-3.png" alt="use of rails">
                </picture>
              </figure>
              <p>That’s all</p>
              <p>As a developer we should know where to use the Webpack, as there are some side effects of using the Webpacker</p>
              <ul>
                  <li>If your application doesn’t use much JavaScript, it’s probably not worth adding the it as webpacker is a very heavy tool.</li>
                  <li>You will need time to adopt the webpacker for the first time, it works differently than the sprockets. You might need to invest time in learning that.</li>
                  <li>Webpack is complex which can make the process of adopting the webpack quite frustrating.</li>
              </ul>
              <p>If you are looking to develop any project on Ruby on Rails then choose us as we are one of the leading Ruby on Rails Development Company that provides quality Ruby on Rails development services. Contact us to hire Ruby on Rails developers for your Ruby on Rails requirement or you can reach us at <a class="markup--anchor markup--p-anchor" href="mailto:inquiry@techcompose.com" target="_blank" rel="noopener noreferrer" data-href="mailto:inquiry@techcompose.com">inquiry@techcompose.com</a></p>
          </div>
          <app-share-blog-buttons
              [currentBlogUrl]="currentBlogUrl"
              [currentBlogTitle]="'How to use Rails 6 webpack with front-end JS framework - TechCompose'">
          </app-share-blog-buttons>
      </div>
  </section>
  <app-footer></app-footer>
</div>
