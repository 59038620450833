<app-header></app-header>
<app-header-menu *ngIf="isHeaderMenuOpen"></app-header-menu>
<div class="scroller">
  <div class="header-class"></div>
  <div class="page-grid-block">
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
  </div>
  <div class="portfolio-wrapper">
      <div class="portfolio-overlay"></div>
      <div class="portfolio-detail-wrapper">
          <div class="portfolio-detail-inner">
              <div class="back-btn-block">
                  <a [routerLink]="['/work']" class="back-btn">
                      <i>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M5.828 11.0007H22V13.0007H5.828L11.192 18.3647L9.778 19.7787L2 12.0007L9.778 4.22266L11.192 5.63666L5.828 11.0007Z" fill="black"/>
                          </svg>
                      </i>
                      Back to Portfolio
                  </a>
              </div>
              <div class="project-logo">
                  <img src="assets/images/project-images/percentology/logo.svg" alt="Percentology">
              </div>
              <h2 class="probstatement">
                  <span>Problem Statement</span>
                  As a medical practitioner like Dentists need to be well aware about the cash flows and areas of cash flows, it's impacts on profits and summary of accounting for different time intervals.
              </h2>
              <div class="challange-block">
                  <div class="challange-list">
                      <ul>
                          <li>
                              <h3 class="section-title section-title-sub">Study</h3>
                              <ul>
                                  <li class="work-info">Analysis of Quickbooks accounts</li>
                                  <li class="work-info">Study the routine for medical practitioners</li>
                                  <li class="work-info">Understanding of Incomes and Expenses</li>
                                  <li class="work-info">Understanding of Capital investments and depreciations</li>
                              </ul>
                          </li>
                          <li>
                              <h3 class="section-title section-title-sub">Challenges</h3>
                              <ul>
                                  <li class="work-info">Many heads for cash flows(In/Out) </li>
                                  <li class="work-info">Quickbooks API integration </li>
                                  <li class="work-info">Processing information </li>
                                  <li class="work-info">Authentication and authorization </li>
                                  <li class="work-info">Effective UI/UX </li>
                                  <li class="work-info">Charts - Customization and Interactive </li>
                                  <li class="work-info">Preparing Benchmarking mechanism </li>
                                  <li class="work-info">Consistency of the information </li>
                                  <li class="work-info">Performance </li>
                              </ul>
                          </li>
                          <li>
                              <h3 class="section-title section-title-sub">Approach</h3>
                              <ul>
                                  <li class="work-info">Prepare a mapping protocol to tag appropriate head  </li>
                                  <li class="work-info">Processing Transactional information </li>
                                  <li class="work-info">Preparing Staging information to use for Charts and Reports </li>
                                  <li class="work-info">Quickbooks Webhooks and Sync </li>
                                  <li class="work-info">Background Authentication for continuous access </li>
                              </ul>
                          </li>
                          <li>
                              <h3 class="section-title section-title-sub">Involvement</h3>
                              <ul>
                                  <li class="work-info">Interaction with Account team managing Quickbooks accounts </li>
                                  <li class="work-info">Convert functional requirements to algorithm </li>
                                  <li class="work-info">Design UI/UX </li>
                                  <li class="work-info">Customizing the Chart library for effective presentation of data </li>
                              </ul>
                          </li>
                      </ul>
                  </div>
              </div>

              <div class="project-img-block">
                  <div class="percentology-img-1">
                      <img src="assets/images/project-images/percentology/main-img.webp" alt="percentology">
                  </div>
                  <!-- <div class="percentology-img-7">
                      <img src="assets/images/project-images/percentology/img-7.webp">
                  </div>
                  <div class="percentology-img-2">
                      <img src="assets/images/project-images/percentology/img-2.webp">
                  </div>
                  <div class="percentology-img-3">
                      <img src="assets/images/project-images/percentology/img-3.webp">
                  </div>
                  <div class="percentology-img-4">
                      <img src="assets/images/project-images/percentology/img-4.webp">
                  </div>
                  <div class="percentology-img-5">
                      <img src="assets/images/project-images/percentology/img-5.webp">
                  </div>
                  <div class="percentology-img-6">
                      <img src="assets/images/project-images/percentology/img-6.webp">
                  </div>-->
              </div>

              <div class="work-process" *ngIf="false">
                  <ul>
                      <li>
                          <h3 class="section-title">STRATEGY</h3>
                          <span>Design thinking</span>
                          <span>Product Roadmap</span>
                      </li>
                      <li>
                          <h3 class="section-title">DESIGN</h3>
                          <span>User Experience</span>
                          <span>User Interface</span>
                          <span>Illustrations & Icons</span>
                      </li>
                      <li>
                          <h3 class="section-title">FRONT END DEVELOPEMENT</h3>
                          <span>HTML</span>
                          <span>CSS</span>
                          <span>Posenet</span>
                      </li>
                  </ul>
              </div>

              <div class="visule-guide pt-0">
                  <h2>Visual Guide</h2>
                  <div class="visule-guide-row">
                      <div class="visule-guide-col">
                          <h3 class="section-title section-title-sub">TYPE FACE</h3>
                          <label>Nunito</label>
                          <p>- Sans-Serif</p>
                      </div>
                      <div class="visule-guide-col">
                          <h3 class="section-title section-title-sub">COLOR</h3>
                          <ul class="color-list">
                              <li style="background: #344459; color: #fff; border: none">#344459</li>
                              <li style="background: #6F6287; color: #fff; border: none">#6F6287</li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="outcome-box-wrapper">
          <div class="outcome-box-inner">
              <div class="outcome-box-info">
                  <div class="outcome-box-info-inner">
                      <h3 class="section-title section-title-sub">THE OUTCOME</h3>
                      <ul>
                          <li>Faster Benchmarking at different time range </li>
                          <li>Trends based on industry average </li>
                          <li>Better access to processed information </li>
                          <li>Faster and confident decision making </li>
                      </ul>
                  </div>
              </div>
          </div>
          <div class="outcome-img-block">
              <img src="assets/images/project-images/percentology/outcome-box.webp" alt="Percentology">
          </div>
      </div>
      <div class="project-info">
          <div class="project-info-inner">
              <div class="portfolio-quote">
                  <p>
                      <i class="quote-icon">
                          <img src="assets/images/quote-icon.svg" alt="Quote Icon">
                      </i>
                      The product has generated a 40% increase in clients and can now accommodate a broader audience of users for additional income. TechCompose Solutions designed a user-friendly product with a wide range of functionalities. They are organized, deliver high-quality coding, and communicate effectively. </p>
                  <div class="client-quote-info">
                      <div class="client-img">
                          <img src="assets/images/testimonials/sona.jpg" alt="Sona">
                      </div>
                      <div class="client-name">
                          <h3>Sona</h3>
                          <span>CEO</span>
                      </div>
                  </div>
              </div>
              <div class="work-detail-box">
                  <div class="work-detail-box-inner">
                      <h5>Connect with us</h5>
                      <p class="work-info">If you have a concept/vision/product and you are looking for a right <span>design and developer team</span> OR you have right technical skills and looking for <span>exciting projects/environment to experience</span>, drop a message via contact us form. </p>
                      <div class="contact-btn-block">
                          <a [routerLink]="['/', 'contact-us']"><span>Contact Now</span></a>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <section class="horizontal">
          <div class="more-project-ovrelay"></div>
          <div class="pin-wrap">
              <div class="projects-title-block">
                  <h2>More Works</h2>
              </div>
              <div class="animation-wrap to-right">
                  <a class="item" [routerLink]="['/', 'work', 'build-effective']">
                      <div class="project-img">
                          <img src="assets/images/project-images/project-thumbnail/build-effective.webp" alt="Build Effective">
                      </div>
                      <div class="project-slider-info">
                          <span>Real Estate</span>
                          <h2>Buildeffective</h2>
                      </div>
                  </a>
                  <a class="item" [routerLink]="['/', 'work', 'ar-workflow']">
                      <div class="project-img">
                          <img src="assets/images/project-images/project-thumbnail/ar-workflow.webp" alt="ARWorkflow">
                      </div>
                      <div class="project-slider-info">
                          <span>Accounting</span>
                          <h2>ARWorkflow</h2>
                      </div>
                  </a>
                  <a class="item" [routerLink]="['/', 'work', 'linkmybooks']">
                      <div class="project-img">
                          <img src="assets/images/project-images/project-thumbnail/link-my-books.webp" alt="LinkMyBooks">
                      </div>
                      <div class="project-slider-info">
                          <span>Business</span>
                          <h2>LinkMyBooks</h2>
                      </div>
                  </a>
                  <a class="item" [routerLink]="['/', 'work', 'ccalerts']">
                      <div class="project-img">
                          <img src="assets/images/project-images/project-thumbnail/ccalerts.webp" alt="CCAlerts">
                      </div>
                      <div class="project-slider-info">
                          <span>Finance</span>
                          <h2>CCAlerts</h2>
                      </div>
                  </a>
                  <a class="item" [routerLink]="['/', 'work', 'talowiz']">
                      <div class="project-img">
                          <img src="assets/images/project-images/project-thumbnail/talowiz.webp" alt="Talowiz">
                      </div>
                      <div class="project-slider-info">
                          <span>Education</span>
                          <h2>Talowiz</h2>
                      </div>
                  </a>
                  <a class="item" [routerLink]="['/', 'work', 'facturazen']">
                    <div class="project-img">
                        <img src="assets/images/project-images/project-thumbnail/facturazen.webp" alt="Facturazen">
                    </div>
                    <div class="project-slider-info">
                        <span>Business</span>
                        <h2>Facturazen</h2>
                    </div>
                  </a>
                  <ng-container *ngIf="false">
                      <a class="item" [routerLink]="['/', 'work', 'stomerijcollectief']">
                          <div class="project-img">
                              <img src="assets/images/project-images/project-thumbnail/stomerij-collectief.webp" alt="Stomerij Collectief">
                          </div>
                          <div class="project-slider-info">
                              <span>Business</span>
                              <h2>Stomerij Collectief</h2>
                          </div>
                      </a>
                      <a class="item" [routerLink]="['/', 'work', 'food-trucks']">
                          <div class="project-img">
                              <img src="assets/images/project-images/project-thumbnail/food-truck.webp" alt="Food Truck">
                          </div>
                          <div class="project-slider-info">
                              <span>Food</span>
                              <h2>FoodTruck</h2>
                          </div>
                      </a>
                      <a class="item" [routerLink]="['/', 'work', 'percentology']">
                          <div class="project-img">
                              <img src="assets/images/project-images/project-thumbnail/percentology.webp" alt="Percentology">
                          </div>
                          <div class="project-slider-info">
                              <span>Account</span>
                              <h2>Percentology</h2>
                          </div>
                      </a>
                      <a class="item" [routerLink]="['/', 'work', 'local-grocery']">
                          <div class="project-img">
                              <img src="assets/images/project-images/project-thumbnail/local-grocery.webp" alt="Local Grocery">
                          </div>
                          <div class="project-slider-info">
                              <span>Utility</span>
                              <h2>Local Grocery</h2>
                          </div>
                      </a>
                      <a class="item" [routerLink]="['/', 'work', 'miniflix']">
                          <div class="project-img">
                              <img src="assets/images/project-images/project-thumbnail/miniflix.webp" alt="Miniflix">
                          </div>
                          <div class="project-slider-info">
                              <span>Entertainment</span>
                              <h2>Miniflix</h2>
                          </div>
                      </a>
                  </ng-container>
              </div>
          </div>
      </section>
  </div>
  <app-footer></app-footer>
</div>
