import { Component, OnInit } from '@angular/core';

import { Title, Meta } from '@angular/platform-browser';
import gsap from 'gsap';
import Scrollbar, { ScrollbarPlugin } from 'smooth-scrollbar';
import { sharedService } from 'src/app/shared/shared.service';

import { HighlightLoader } from 'ngx-highlightjs';

@Component({
  selector: 'app-convert-html-to-pdf',
  templateUrl: './convert-html-to-pdf.component.html',
  styleUrls: ['./convert-html-to-pdf.component.scss']
})
export class ConvertHtmlToPdfComponent implements OnInit {

  bodyScrollBar: Scrollbar | undefined;
  public isHeaderMenuOpen: boolean = false;
  public currentBlogUrl: string = window.location.href;
  public baseUrl: string = window.location.origin;

public code1 =`<?php
if (!defined('BASEPATH'))
  exit('No direct script access allowed'); 
require_once 'dompdf/autoload.inc.php';
use Dompdf\Dompdf;

class Pdf extends Dompdf { 
    public function __construct() { 
        parent::__construct();
    } 
} 
?>`;

public code2 =`<?php

if (!defined('BASEPATH'))
        exit('No direct script access allowed');
class Welcome extends CI_Controller {
	function GeneratePdf(){
		$this->load->view('welcome_message');
		$html = $this->output->get_output();
        		// Load pdf library
		$this->load->library('pdf');
		$this->pdf->loadHtml($html);
		$this->pdf->setPaper('A4', 'landscape');
		$this->pdf->render();
		// Output the generated PDF (1 = download and 0 = preview)
		$this->pdf->stream("html_contents.pdf", array("Attachment"=> 0));		
	}
}`;

public code3 =`<?php
defined('BASEPATH') OR exit('No direct script access allowed');
?><!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="utf-8">
  <title>Welcome to CodeIgniter</title>
  <style type="text/css">
  ::selection { background-color: #E13300; color: white; }
  ::-moz-selection { background-color: #E13300; color: white; }
  body {
    background-color: #fff;
    margin: 40px;
    font: 13px/20px normal Helvetica, Arial, sans-serif;
    color: #4F5155;
  }
  a {
    color: #003399;
    background-color: transparent;
    font-weight: normal;
  }
  h1 {
    color: #444;
    background-color: transparent;
    border-bottom: 1px solid #D0D0D0;
    font-size: 19px;
    font-weight: normal;
    margin: 0 0 14px 0;
    padding: 14px 15px 10px 15px;
  }
  code {
    font-family: Consolas, Monaco, Courier New, Courier, monospace;
    font-size: 12px;
    background-color: #f9f9f9;
    border: 1px solid #D0D0D0;
    color: #002166;
    display: block;
    margin: 14px 0 14px 0;
    padding: 12px 10px 12px 10px;
  }
  #body {
    margin: 0 15px 0 15px;
  }
  p.footer {
    text-align: right;
    font-size: 11px;
    border-top: 1px solid #D0D0D0;
    line-height: 32px;
    padding: 0 10px 0 10px;
    margin: 20px 0 0 0;
  }
  #container {
    margin: 10px;
    border: 1px solid #D0D0D0;
    box-shadow: 0 0 8px #D0D0D0;
  }
  </style>
</head>
<body>

  <div id="container">
    <h1>Welcome to CodeIgniter!</h1>
    <div id="body">
      <p>The page you are looking at is being generated dynamically by CodeIgniter.</p>
      <p>If you would like to edit this page you'll find it located at:</p>
      <code>application/views/welcome_message.php</code>
      <p>The corresponding controller for this page is found at:</p>
      <code>application/controllers/Welcome.php</code>
      <p>If you are exploring CodeIgniter for the very first time, you should start by reading the <a href="user_guide/">User Guide</a>.</p>
    </div>
    <p class="footer">Page rendered in <strong>{elapsed_time}</strong> seconds. <?php echo  (ENVIRONMENT === 'development') ?  'CodeIgniter Version <strong>' . CI_VERSION . '</strong>' : '' ?></p>
  </div>
</body>
</html>`;

  constructor(private metaTagService: Meta, private titleService: Title, public sharedservice: sharedService, private hljsLoader: HighlightLoader) { }

  ngOnInit(): void {

    this.titleService.setTitle("Convert HTML to PDF in CodeIgniter using Dompdf - TechCompose");

    this.metaTagService.updateTag({ name: 'keywords', content: 'Convert HTML to PDF in CodeIgniter using Dompdf. Read How you can Convert HTML to PDF in CodeIgniter using Dompdf during web application development.'});
    this.metaTagService.updateTag({ property: 'og:title', content: 'Techcompose | Convert HTML to PDF in CodeIgniter using Dompdf' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:url', content: `${this.currentBlogUrl}` });
    this.metaTagService.updateTag({ property: 'og:image', content: `${this.baseUrl}/assets/images/blog/convert-html-to-pdf.png` });
    this.metaTagService.updateTag({ property: 'og:description', content: 'Convert HTML to PDF in CodeIgniter using Dompdf. Read How you can Convert HTML to PDF in CodeIgniter using Dompdf during web application development.'});
    this.metaTagService.removeTag("name='google-site-verification'");

    this.sharedservice.showHeaderMenu$.subscribe((data) => {
      this.isHeaderMenuOpen = data;
      if (this.bodyScrollBar) {
        this.bodyScrollBar.updatePluginOptions('modal', { open: this.isHeaderMenuOpen });
      }
    });

    gsap.registerPlugin(ScrollTrigger);
    const scroller = document.querySelector('.scroller')as HTMLElement;

    this.bodyScrollBar = Scrollbar.init(scroller, { damping: 0.1, delegateTo: document, alwaysShowTracks: true });

    const content = this;
    ScrollTrigger.scrollerProxy(".scroller", {
      scrollTop(value) {
        if (content.bodyScrollBar) {
          if (arguments.length) {
            content.bodyScrollBar.scrollTop = value || 0;
          }
          return content.bodyScrollBar.scrollTop;
        } else {
          return 0;
        }
      }
    });

    this.bodyScrollBar.addListener(ScrollTrigger.update);
    ScrollTrigger.defaults({ scroller: scroller });

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    ScrollTrigger.create({
      trigger: ".header-class",
      start: "+50px",
      end: "bottom -=1000%",
      toggleClass: { targets: ".header", className: "active" }
    });
  }

}

class ModalPlugin extends ScrollbarPlugin {
  static pluginName = 'modal';

  static defaultOptions = {
    isHeaderMenuOpen: false
  };

  transformDelta(delta: any) {
      return this.options.open ? { x: 0, y: 0 } : delta;
  }
}

Scrollbar.use(ModalPlugin, /* OverscrollPlugin */);
