<app-header></app-header>
<app-header-menu *ngIf="isHeaderMenuOpen"></app-header-menu>
<div class="scroller">
  <div class="header-class"></div>
  <div class="page-grid-block">
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
  </div>
  <div class="grid-background-overlay"></div>
  <section class="career-hero">
      <!-- <div class="hero-bg"></div> -->
      <app-tech-logo></app-tech-logo>
      <div class="career-hero-container">
          <div class="career-hero-block">
              <div class="page-title">
                  <h1>Privacy Policy</h1>
              </div>
              <div class="career-info-detail page-info">
                  <p>Last updated on January 1, 2024</p>
              </div>
          </div>
      </div>
  </section>
  <section class="privary-info-wrapper">
    <div class="privary-info">
        <p><b>Techcompose solutions pvt. ltd</b>, a provider of IT services and web solutions, is committed to protecting the privacy of its users. This Privacy Policy outlines the types of information we collect, how we use it, and the choices you have regarding your information. By accessing or using our website, you consent to the practices described in this Privacy Policy.</p>
        <h2 class="block-title">Information We Collect</h2>
        <ol>
            <li>
                <h3>Personal Information</h3>
                <ul>
                    <li>We only collect following personal information requested such as your name, email address, phone number, and company name when you voluntarily provide it through our website forms or other communication channels.</li>
                </ul>
            </li>
            <li>
                <h3>Non-Personal Information:</h3>
                <ul>
                    <li>We may collect non-personal information, such as browser type, device type, and IP address, to enhance the user experience and improve the quality of our website. These are collected by Google Analytics following standard practice followed by Google team.</li>
                </ul>
            </li>
        </ol>
        <h2 class="block-title">How We Use Your Information</h2>
        <ol>
            <li>
                <h3>Communication</h3>
                <ul>
                    <li>We may use your contact information to respond to your inquiries, provide information about our services, and send relevant updates.</li>
                </ul>
            </li>
            <li>
                <h3>Analytics</h3>
                <ul>
                    <li>Non-personal information may be used for analytics and statistical purposes to understand how users interact with our website and improve its performance.</li>
                </ul>
            </li>
            <li>
                <h3>Marketing</h3>
                <ul>
                    <li>With your consent, we may use your contact information to send you promotional materials, newsletters, or information about new services and features.
                    </li>
                </ul>
            </li>
        </ol>
        <h2 class="block-title">Information Sharing</h2>
        <p>We do not sell, trade, or otherwise transfer your personal information to third parties without your consent.</p>

        <h2 class="block-title">Cookies</h2>
        <p>We may use cookies to enhance your experience on our website. You can set your browser to refuse all or some browser cookies, but this may affect the functionality of the website.</p>
        
        <h2 class="block-title">Security</h2>
        <p>We take high measures to protect the security and confidentiality of your information. We have been encrypting the transmission over the internet and encrypt electronic storage to provide high security of the information submitted manually.</p>

        <h2 class="block-title">Updates to Privacy Policy:</h2>
        <p>We take reasonable measures to protect the security and confidentiality of your information. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>
        <p>We may update this Privacy Policy from time to time, and the latest version will be posted on this page with the effective date.</p>
        <p>By using our website, you agree to the terms of this Privacy Policy.</p>

        <p>Effective Date: January 1, 2024</p>
        
    </div>
  </section>
  <app-footer></app-footer>
</div>