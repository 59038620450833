import { Component, AfterViewInit, OnInit, Renderer2 } from '@angular/core';
import SplitType from 'split-type';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

import { Title, Meta } from '@angular/platform-browser';

import Scrollbar, { ScrollbarPlugin } from 'smooth-scrollbar';
import { sharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  bodyScrollBar: Scrollbar | undefined;
  public isHeaderMenuOpen: boolean = false;
  public currentUrl: string = window.location.href;
  public baseUrl: string = window.location.origin;

  private listeners: any[] = [];
  constructor(private renderer: Renderer2, private metaTagService: Meta, private titleService: Title, public sharedservice: sharedService) { }

  public ngOnInit(): void {

    this.titleService.setTitle("Outsource Accounting & Bookkeeping services | Quickbooks accountant");
    this.metaTagService.updateTag({ name: 'keywords', content: 'Enhance your financial management with powerful financial accounting software. Our solutions empower businesses to maintain accurate records, track expenses, and optimize financial performance.' }),
    this.metaTagService.updateTag({ property: 'og:title', content: 'Outsource Accounting & Bookkeeping services | Quickbooks accountant' }),
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' }),
    this.metaTagService.updateTag({ property: 'og:url', content: `${this.currentUrl}` }),
    this.metaTagService.updateTag({ property: 'og:image', content: `${this.baseUrl}/assets/images/thumb.png` }),
    this.metaTagService.updateTag({ property: 'og:description', content: 'Enhance your financial management with powerful financial accounting software. Our solutions empower businesses to maintain accurate records, track expenses, and optimize financial performance.' }),
    this.metaTagService.removeTag("name='google-site-verification'");
    
    this.sharedservice.showHeaderMenu$.subscribe((data) => {
      this.isHeaderMenuOpen = data;
      if (this.bodyScrollBar) {
        this.bodyScrollBar.updatePluginOptions('modal', { open: this.isHeaderMenuOpen });
      }
    });

    gsap.registerPlugin(ScrollTrigger);

    const scroller = document.querySelector('.scroller')as HTMLElement;

    this.bodyScrollBar = Scrollbar.init(scroller, { damping: 0.1, delegateTo: document, alwaysShowTracks: true });

    const content = this;
    ScrollTrigger.scrollerProxy(".scroller", {
      scrollTop(value) {
        if (content.bodyScrollBar) {
          if (arguments.length) {
            content.bodyScrollBar.scrollTop = value || 0;
          }
          return content.bodyScrollBar.scrollTop;
        } else {
          return 0;
        }
      }
    });

    this.bodyScrollBar.addListener(ScrollTrigger.update);
    ScrollTrigger.defaults({ scroller: scroller });

    // gsap.to('.bannerImgBlock', {duration: 0.5, width: 'auto', ease: 'power1', delay: 1});
    // gsap.to('.bannerImgOverlay', {duration: 1, width: '0', ease: 'power1', delay: 1.5});

    const workpageTitle = new SplitType('.page-title h1', {
      types: 'chars, words',
      absolute: true
    });

    gsap.from(workpageTitle.chars, {
      opacity: 0,
      x: 20,
      duration: 0.7,
      delay: 0.2,
      stagger: 0.03,
    });
    gsap.fromTo('.mainWorkInfo p', {
      opacity: 0,
      y: 30,
    }, {
      opacity: 1,
      y: 0,
      duration: 0.5,
      delay: 0.3,
    });

    const info: any = gsap.utils.toArray('.work-card-block');

    // Set things up
    gsap.set(info, {scale: 1.15, autoAlpha: 0, rotationX: -20});
    info.forEach((box: any) => {
      // Set up your animation
      const anim = gsap.to(box, { duration: 1, autoAlpha: 1, scale: 1, rotationX: 0, paused: true });

      // Use callbacks to control the state of the animation
      ScrollTrigger.create({
        trigger: box,
        start: 'top 70%',
        once: true,
        onEnter: self => {
          // If it's scrolled past, set the state
          // If it's scrolled to, play it
          self.progress === 1 ? anim.progress(1) : anim.play();
        }
      });
    });

    // const revealContainers = document.querySelectorAll(".project-img-block picture");

    // revealContainers.forEach((container) => {
    //   const image = container.querySelector(".project-img-block img");
    //   const picanim = gsap.timeline({
    //     scrollTrigger: {
    //       trigger: container,
    //       toggleActions: "restart none none reset"
    //     }
    //   });

    //   picanim.set(container, { autoAlpha: 1 })
    //   .from(container, 1, {
    //     xPercent: -100,
    //     ease: 'Power2.out'
    //   })
    //   .from(image, 1, {
    //     xPercent: 100,
    //     scale: 1.3,
    //     delay: -1.5,
    //     ease: 'Power2.out'
    //   })
    // });

    // const ProjectList: any = gsap.utils.toArray('.project-list');

    // // Set things up
    // gsap.set(ProjectList, {autoAlpha: 0, y:"30px"});
    // ProjectList.forEach((box: any) => {
    //   // Set up your animation
    //   const anim = gsap.to(box, { duration: 1,  y:"0px", autoAlpha: 1, paused: true });

    //   // Use callbacks to control the state of the animation
    //   ScrollTrigger.create({
    //     trigger: box,
    //     start: 'top 70%',
    //     once: true,
    //     onEnter: self => {
    //       // If it's scrolled past, set the state
    //       // If it's scrolled to, play it
    //       self.progress === 1 ? anim.progress(1) : anim.play();
    //     }
    //   });
    // });

    const listener: any = this.renderer.listen(
      window,
      'resize',
      () => {
        this.resize();
    });
    this.resize();
    this.listeners.push(listener);


    document.querySelectorAll("img").forEach(img => {
      if (img.complete) {
        ScrollTrigger.refresh();
      } else {
        img.addEventListener('load', _imgLoaded => ScrollTrigger.refresh());
      }
    });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    ScrollTrigger.create({
      trigger: ".header-class",
      start: "+50px",
      end: "bottom -=1000%",
      toggleClass: { targets: ".header", className: "active" }
    });

  }

  public resize(): void {
    if (window.matchMedia('(min-width: 768px)').matches) {
      var boxes = gsap.utils.toArray(".project-list:nth-child(2n)");

      gsap.set(boxes, {y: '100'})

      boxes.forEach((box : any, i) => {

        gsap.timeline({
          scrollTrigger: {
            trigger: box,
            start: "top 100%",
            end:"bottom 50%",
            scrub:0.3,
            // markers: true,
          },
          defaults:{duration: 1, ease:'power1'}
        })
        .to(box, {y: '-100'});
      });
    } else {
      // const info: any = gsap.utils.toArray('.project-list');

      // // Set things up
      // gsap.set(info, {autoAlpha: 0, y: 100});
      // info.forEach((box: any) => {
      //   // Set up your animation
      //   const anim = gsap.to(box, { duration: 1, autoAlpha: 1, y: 0, paused: true });

      //   // Use callbacks to control the state of the animation
      //   ScrollTrigger.create({
      //     trigger: box,
      //     start: 'top 70%',
      //     once: true,
      //     onEnter: self => {
      //       // If it's scrolled past, set the state
      //       // If it's scrolled to, play it
      //       self.progress === 1 ? anim.progress(1) : anim.play();
      //     }
      //   });
      // });


      const scmobileAnim: any = gsap.utils.toArray('.project-list');

      gsap.set(scmobileAnim, {autoAlpha: 0, y: 50});
      scmobileAnim.forEach((mobile: any) => {
        const anim = gsap.to(mobile, { duration: 1, autoAlpha: 1, delay: 0.25, y: 0, paused: true });

        ScrollTrigger.create({
          trigger: mobile,
          start: 'top 70%',
          once: true,
          onEnter: self => {
            self.progress === 1 ? anim.progress(1) : anim.play();
          }
        });
      });
    }
  }
}
class ModalPlugin extends ScrollbarPlugin {
  static pluginName = 'modal';

  static defaultOptions = {
    isHeaderMenuOpen: false
  };

  transformDelta(delta: any) {
      return this.options.open ? { x: 0, y: 0 } : delta;
  }
}

Scrollbar.use(ModalPlugin, /* OverscrollPlugin */);
