<app-header></app-header>
<app-header-menu *ngIf="isHeaderMenuOpen"></app-header-menu>
<div class="scroller">
  <div class="header-class"></div>
  <div class="page-grid-block">
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
  </div>
  <div class="grid-background-overlay"></div>
  <section class="blog-hero">
      <app-tech-logo></app-tech-logo>
      <div class="blog-hero-container">
          <div class="blog-hero-block">
            <div class="back-btn-block">
                <a [routerLink]="['/blogs']" class="back-btn">
                    <i>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.828 11.0007H22V13.0007H5.828L11.192 18.3647L9.778 19.7787L2 12.0007L9.778 4.22266L11.192 5.63666L5.828 11.0007Z" fill="black"/>
                        </svg>
                    </i>
                    Back to Blogss
                </a>
            </div>
              <div class="blog-hero-inner-block">
                  <div class="page-title">
                      <h1>Content Vs Design: 7 points to consider by web designer before creating a website</h1>
                  </div>
              </div>
          </div>
      </div>
  </section>
  <section class="blog-wrapper">
      <div class="blog-detail-row">
          <div class="blog-detail-box">
              <figure>
                  <picture>
                      <img src="assets/images/blog/content-vs-design.webp" alt="content vs design">
                  </picture>
              </figure>
              <p>Content Vs Design: 7 points to consider by web designer before creating a website</p>
              <p>The internet has grown and changed a lot in the past many years: we’ve seen the reign and evolution of mobile, the introduction of AR, VR, AI, AMP, and many other acronyms. In some years, website design trends have pushed towards wide rampant creativity.</p>
              <h2>1. Serifs on screen</h2>
              <p>With its clean readability and visibility, it is always the go-to for longer bouts of website copy, more brands and companies are turning towards bold serifs in other aspects of their designs such as headers and callouts. There’s a very good reason for this: serifs were always designed to be decorative, making them perfect for emphasis.</p>
              <h2>2. Black-and-white palettes</h2>
              <p>White is clean and reserved whereas black is strong and assertive. Combine these both and you will get an altogether striking look. Color is literally how we see the world by light particles being absorbed. When color is missing, we see the world differently: textures, shapes, and other elements become clearer, and the world seems noticeably slower.</p>
              <h2>3. Natural, organic shapes</h2>
              <p>Talking about Content Vs Design: 7 points to consider by web designer before creating a website, Organic shapes are naturally imperfect and also asymmetrical; they provide depth to any web design which makes page elements stand out. They are free-drawn elements which capture the spontaneity of man-made accidents such as paint splatter.</p>
              <h2>4. Glitch Art</h2>
              <p>Glitches Art are very significant in our modern times when computers are so pervasive. The breakdown of technology makes for appealing subject matter both as an idea and in its design execution, where it can draw the user’s eye to those areas of the website that are warped, double exposed and glitchy.</p>
              <h2>5. Micro-interactions</h2>
              <p>Micro-interactions are events occur with one purpose: to surprise the user and create an event that is inviting. Every time the user takes a small action on a website or app and there is a specific response to it, this is a micro-interaction. For Example, when you refresh a Twitter page and hear a beep, this is known as micro-interaction.</p>
              <h2>6. Chatbots evolution</h2>
              <p>Chatbots in the website have been seen up-and-coming for a while now but will finally move into the spotlight in 2019. This is mostly due to the advancements in AI and machine learning, making them more intelligent and efficient enough to communicate with the user visiting a website.</p>
              <h2>7. Even more video content than before</h2>
              <p>You don’t need an explainer video to tell you that the video content for the website is nothing new. Video not only diversifies any web pages but caters to an on-the-go user who generally doesn’t have time to scan through a lot of text on the website.</p>
              <p>So this is Content Vs Design: 7 points to consider by web designer before creating a website, web design trends are changing so you require a reputed Wed design company that can create an error-free and unique design website that suits your business. <a href="https://www.techcompose.com/contact-us/">Contact us</a> to hire a dedicated web designer or you can reach us at <a href="mailto:inquiry@techcompose.com">inquiry@techcompose.com</a> to discuss your requirement.</p>
          </div>
          <app-share-blog-buttons
              [currentBlogUrl]="currentBlogUrl"
              [currentBlogTitle]="'Content Vs Design: 7 points to consider by web designer before creating a website'">
          </app-share-blog-buttons>
      </div>
  </section>
  <app-footer></app-footer>
</div>
