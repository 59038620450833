<section class="industries-wrapper">
  <div class="industries-contain">
      <div class="industries-content-block">
          <div class="industries-title-desc">
              <h2 id="industries-title" class="block-title">Industries Solutions</h2>
              <p class="block-info">
                Develop today and transform for the future with solutions that help you maximize your investments, scale effectively, connect your workforce and optimize your business. <span>"Making life easy and business effective"</span> is our main focus as a solution development company. We focus on communication, quality services and responsiveness for our clients’ technology needs. 
                </p>
          </div>
      </div>
      <div class="industries-listing-block">
          <div class="industries-listing-row">
                <div class="industries-listing"
                    *ngFor="let article of industrySolution; let i = index"
                    [attr.article]="'article-' + i"
                    (click)="enlargeIndustry($event, article)">
                    <div class="industries-listing-image">
                        <img [src]="article['imgUrl']"
                            [alt]="article['title']" width="880" height="1440">
                    </div>
                    <span>{{ article['title'] }}</span>
                </div>
          </div>
      </div>
  </div>
</section>
