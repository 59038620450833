// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseAPIUrl: 'https://techlogin.techcompose.com/',
  firebase: {
    apiKey: 'AIzaSyAiLSQvq0c_GGV7k00JjcjllHgYh0a7Nrg',
    authDomain: 'techcompose-site-api.firebaseapp.com',
    projectId: 'techcompose-site-api',
    storageBucket: 'techcompose-site-api.appspot.com',
    messagingSenderId: '1017295214808',
    appId: '1:1017295214808:web:79b2ea01446b65e901dfad',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
