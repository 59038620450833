import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { DelayResolver } from './delay-resolver';
import { CareersComponent } from './careers/careers.component';
import { ContactComponent } from './contact/contact.component';
import { ServicesModule } from './services/services.module';
import { OurTestimonialsComponent } from './our-testimonials/our-testimonials.component';
import { WorkModule } from './work/work.module';
import { BlogsModule } from './blogs/blogs.module';
import { CareerDetailsComponent } from './career-details/career-details.component';
import { PrivaryPolicyComponent } from './privary-policy/privary-policy.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AboutModule } from './about/about.module';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
    resolve: {data: DelayResolver }
  },
  {
    path: 'about-us',
    loadChildren: () => AboutModule,
    resolve: {data: DelayResolver }
  },
  {
    path: 'careers',
    component: CareersComponent,
    resolve: {data: DelayResolver }
  },
  {
    path: 'careers/:slug',
    component: CareerDetailsComponent,
    resolve: {data: DelayResolver }
  },
  {
    path: 'it-services',
    loadChildren: () => ServicesModule,
    resolve: {data: DelayResolver }
  },
  {
    path: 'work',
    loadChildren: () => WorkModule,
    resolve: {data: DelayResolver }
  },
  {
    path: 'contact-us',
    component: ContactComponent,
    resolve: {data: DelayResolver }
  },
  {
    path: 'our-testimonials',
    component: OurTestimonialsComponent,
    resolve: {data: DelayResolver }
  },
  {
    path: 'privacy-policy',
    component: PrivaryPolicyComponent,
    resolve: {data: DelayResolver }
  },
  {
    path: 'blogs',
    loadChildren: () => BlogsModule,
    resolve: {data: DelayResolver }
  },
  { path: 'technologies', loadChildren: () => import('./technologies/technologies.module').then(m => m.TechnologiesModule) },
  {
    path: 'career-details',
    component: CareerDetailsComponent,
    resolve: {data: DelayResolver }
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    resolve: {data: DelayResolver }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
