import { AfterViewInit, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import gsap from 'gsap/all';
import ScrollTrigger from 'gsap/ScrollTrigger';
import * as $ from 'jquery';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-our-services',
  templateUrl: './our-services.component.html',
  styleUrls: ['./our-services.component.scss']
})
export class OurServicesComponent implements OnInit, OnDestroy {
  private listeners: any[] = [];
  public showSwipper: boolean = false

  public config: SwiperOptions = {
    loop: false,
    breakpoints: {
      1200: {
        slidesPerView: 3
      },
      768: {
        slidesPerView: 2
      },
      580: {
        slidesPerView: 1
      }
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  };

  constructor(private renderer: Renderer2) {}
  public ngOnInit():void{
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.create({
      trigger: '.tech-services-wrapper',
      start:"top 60%",
      end:"bottom 0%",
      onEnter: () => {
        gsap.to('.hero-bg', {duration: 1, backgroundColor: '#ffffff'})
        gsap.to('.hero-overlay', {duration: 1, backgroundColor: '#ffffff'})
        gsap.to('.serviceList ul li', {duration: 1, color: '#282828' })
        gsap.to('.tech-services-detail p', {duration: 1, color: '#282828'})
        gsap.to('.service-slider-item-inner', {duration: 1,backgroundColor: '#ffffff'})
        gsap.to('.service-detail-box', {duration: 1,color: '#000000'})
        gsap.to('.service-detail-box span', {duration: 1,borderColor: 'rgba(0,0,0,0.8)'})
        gsap.to('.text-row', {duration: 1,color: '#000000'})
        gsap.to('.service-info-box-inner h3', {duration: 1,color: '#000000'})
        gsap.to('.service-more-btn', {duration: 1,color: '#000000'})
        gsap.to('.tech-services-slider-btn .swiper-nav-btn', {duration: 1,color: '#ffffff', backgroundColor: '#141414'})
        gsap.to('.framework-wrapper h2', {duration: 1.0, color: '#000000'})
        gsap.to('.framework-wrapper p', {duration: 1.0, color: '#000000'})
        gsap.to('.work-primary-img-block h4', { color: '#000000'})
      },
      onLeaveBack: () => {
        gsap.to('.hero-bg', {duration: 1.0, backgroundColor: '#141414'})
        gsap.to('.hero-overlay', {duration: 1.0, backgroundColor: '#141414'})
        gsap.to('.serviceList ul li', {duration: 1, color: '#ffffff' })
        gsap.to('.tech-services-detail p', {duration: 1, color: '#ffffff'})
        gsap.to('.service-slider-item-inner', {duration: 1, backgroundColor: '#141414'})
        gsap.to('.service-detail-box', {duration: 1,color: '#ffffff'})
        gsap.to('.service-detail-box span', {duration: 1,borderColor: 'rgba(255,255,255,0.8)'})
        gsap.to('.text-row', {duration: 1,color: '#ffffff'})
        gsap.to('.service-info-box-inner h3', {duration: 1,color: '#ffffff'})
        gsap.to('.service-more-btn', {duration: 1,color: '#ffffff'})
        gsap.to('.tech-services-slider-btn .swiper-nav-btn', {duration: 1,color: '#282828', backgroundColor: '#ffffff'})
        gsap.to('.work-primary-img-block h4', { color: '#ffffff'})
      },
    })
    this.showSwipper = true;
    const cursor = $('.cursor');
    // const follower = $('.cursor-follower');
    const mouseInElms = document.querySelectorAll('.tech-services-slider-box-inner');
    mouseInElms.forEach(elm => {
      let  listener = this.renderer.listen(
        elm,
        'mouseenter',
        (e) => {
          cursor.addClass('slider-hover');
          // follower.addClass('slider-hover');
      });
      this.listeners.push(listener);
      listener = this.renderer.listen(
        elm,
        'mouseleave',
        (e) => {
          cursor.removeClass('slider-hover');
          // follower.removeClass('slider-hover');
      });
      this.listeners.push(listener);
    });

    gsap.config({
      nullTargetWarn: false,
    });
  }

  public ngOnDestroy(): void {
    if (this.listeners && this.listeners.length > 0) {
      this.listeners.forEach(listener => listener());
    }
  }
}
