import { AfterViewInit, Component, OnInit, Renderer2 } from '@angular/core';
import { sharedService } from '../shared/shared.service';
import * as $ from 'jquery';
import gsap from 'gsap';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
})
export class HeaderMenuComponent implements OnInit, AfterViewInit {
  private listeners: any[] = [];

  private isHeaderMenuOpen: boolean = false;
  // renderer: any;

  constructor(private sharedService: sharedService,
    private renderer: Renderer2,
  ) {
    setTimeout(() => {
      gsap.fromTo('.techMenu', {y: '-100%'},{y: 0});
      gsap.to( '.MenuItem', {duration: 0.3, delay: 0.5, y: 0, opacity: 1, visibility: 'visible', ease: 'power1', stagger: 0.05})
    }, 0);
  }

  public ngOnInit(): void {
    this.sharedService.showHeaderMenu$.subscribe((data) => {
      this.isHeaderMenuOpen = data;
    });
  }

  public toggleMenu(): void {
    this.isHeaderMenuOpen = !this.isHeaderMenuOpen;
    this.sharedService._showHeaderMenu.next(this.isHeaderMenuOpen);
  }

  public ngAfterViewInit(): void {
    let listener: any = this.renderer.listen(
        window,
        'resize',
        () => {
          this.resize();
    });
    this.listeners.push(listener);
    this.resize();

    const menu = gsap.timeline({paused: true, reversed: true});

    // menu
    // .fromTo( '.techMenu' , {y: '-100%'}, {duration: 0.2, ease: 'power1', y: 0})
    // .fromTo('.toggleIconfirst', {rotation: 0, top: 0}, {rotation: -45, top: 7, duration: 0.1}, '-=0.5')
    // .fromTo('.toggleIconSec', {opacity: 1}, {opacity: 0, duration: 0.1}, '-=0.5')
    // .fromTo('.toggleIconThird', {rotation: 0, top: 0}, {rotation: 45, top: -7, duration: 0.1}, '-=0.5')
    // .to( '.MenuItem', {duration: 0.3, y: 0, opacity: 1, visibility: 'visible', ease: 'power1', stagger: 0.025})
    // .fromTo('body', {overflow: 'visible'},{overflow: 'hidden'})

    // listener = this.renderer.listen(
    //     document.querySelector('.MenutoggleButton'),
    //     'click',
    //     () => {
    //       menu.reversed() ? menu.play() : menu.reverse();
    //       subMobileMenu.reverse();
    // });
    // this.listeners.push(listener);

    document.querySelectorAll('.MenuItem a').forEach((e) => {
        listener = this.renderer.listen(e, 'click',() => {
          gsap.fromTo('.techMenu', {y: 0},{y: '-100%'});
            // menu.reversed() ? menu.play() : menu.reverse();
            // subMobileMenu.reverse();
        });
        this.listeners.push(listener);
    });

    const subServicesMobileMenu = gsap.timeline({paused: true, reversed: true});
    subServicesMobileMenu
        .fromTo('.services-sub-menu', {height: '0'}, {height: 'auto', duration: 0.25, autoAlpha: 1, delay: 0.05})
        .fromTo('.services-sub-menu > li', {y: '50%', opacity: 0},
          {delay: 0.05, y: '0', duration: 0.1, opacity: 1, ease: Sine.easeInOut, stagger: 0.025})
        .fromTo('.submenuBtn i', {rotation: -90}, {rotation: 0, duration: 0.1}, '-=0.5');

    listener = this.renderer.listen(
      document.querySelector('.submenuBtn'), 'click', () => {
        subServicesMobileMenu.reversed() ? subServicesMobileMenu.play() : subServicesMobileMenu.reverse();
      }
    );

    const subAboutMobileMenu = gsap.timeline({paused: true, reversed: true});
    subAboutMobileMenu
        .fromTo('.about-sub-menu', {height: '0'}, {height: 'auto', duration: 0.25, autoAlpha: 1, delay: 0.05})
        .fromTo('.about-sub-menu > li', {y: '50%', opacity: 0},
          {delay: 0.05, y: '0', duration: 0.1, opacity: 1, ease: Sine.easeInOut, stagger: 0.025})
        .fromTo('.about-sub-menu-btn i', {rotation: -90}, {rotation: 0, duration: 0.1}, '-=0.5');

    listener = this.renderer.listen(
      document.querySelector('.about-sub-menu-btn'),'click',() => {
        subAboutMobileMenu.reversed() ? subAboutMobileMenu.play() : subAboutMobileMenu.reverse();
      }
    );

    this.listeners.push(listener);

    // yellow circle
    const cursor = $('.cursor');
    const follower = $('.cursor-follower');
    const mouseInElms = document.querySelectorAll('.mouseIn');
    mouseInElms.forEach(elm => {
      listener = this.renderer.listen(
        elm,
        'mouseenter',
        (e) => {
          cursor.addClass('active');
          follower.addClass('active');
      });
      this.listeners.push(listener);
      listener = this.renderer.listen(
        elm,
        'mouseleave',
        (e) => {
          cursor.removeClass('active');
          follower.removeClass('active');
      });
      this.listeners.push(listener);
    });
    gsap.config({
      nullTargetWarn: false,
    });
  }

  public resize(): void {
      if (window.matchMedia('(min-width: 991px)').matches) {
          // The view port is at least 991 pixels wide
          //  console.log('Desktop');
          $('.MenuListBlock > li.MenuItem').each((i, el) => {
              const tl = gsap.timeline({
                paused: true
              });
              tl.from($(el).find('.submenu'), {
                    autoAlpha: 0,
                    duration: 0.25
                }, 'Sametime')
                .from($(el).find('.submenu > li'), {
                    y: '50%',
                    opacity: 0,
                    duration: 0.25,
                    stagger: 0.05,
                    ease: Sine.easeInOut
                });

              const animation = tl;
              $(el).hover(() => {
                  animation.play();
              }, () => {
                  animation.reverse();
              });
          });
      } else {
        // The view port is less than 991 pixels wide
        // console.log('mobile');
      }
  }

  public ngOnDestroy(): void {
    this.listeners.forEach((listener) => listener());
  }


}
