import { Component, AfterViewInit, OnInit, Input } from '@angular/core';
import { SwiperOptions } from 'swiper';
import gsap from 'gsap/all';
import ScrollTrigger from 'gsap/ScrollTrigger';
import gsapCore from 'gsap/gsap-core';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements AfterViewInit {
  @Input() projectsFor: string = 'all';
    public projectLists = [
        { label: "<span>BuildEffective</span>", url: 'build-effective',
        imgUrl: "assets/images/project-images/project-thumbnail/build-effective.webp",
        altText: "BuildEffective",
        technologies: ['all', 'android', 'angular', 'figma', 'flutter', 'ios', 'node', 'ror', 'wordpress'] },
        { label: "<span>ARWorkflow</span>", url: 'ar-workflow',
        imgUrl: "assets/images/project-images/project-thumbnail/ar-workflow.webp",
        altText: "ARWorkflow",
        technologies: ['all', 'angular', 'figma', 'node', 'ror', 'wordpress'] },
        { label: "<span>CCAlerts</span>", url: 'ccalerts',
        imgUrl: "assets/images/project-images/project-thumbnail/ccalerts.webp",
        altText: "CCAlerts",
        technologies: ['all', 'react', 'ios','android', 'figma', 'node'] },
        { label: "<span>LinkMyBooks</span>", url: 'linkmybooks',
        imgUrl: "assets/images/project-images/project-thumbnail/link-my-books.webp",
        altText: "LinkMyBooks",
        technologies: ['all', 'angular', 'figma', 'webflow', 'wordpress'] },
        { label: "<span>Talowiz</span>", url: 'talowiz',
        imgUrl: "assets/images/project-images/project-thumbnail/talowiz.webp",
        altText: "Talowiz",
        technologies: ['all', 'angular', 'figma', 'webflow', 'wordpress'] },
        { label: "<span>Percentology</span>", url: 'percentology',
        imgUrl: "assets/images/project-images/project-thumbnail/percentology.webp",
        altText: "Percentology",
        technologies: ['all', 'react', 'figma'] },
        { label: "<span>Facturazen</span>", url: 'facturazen',
        imgUrl: "assets/images/project-images/project-thumbnail/facturazen.webp",
        technologies: ['all', 'ror'] },
      // { label: "<span>Food</span><span>Truck</span>", url: 'food-trucks',
      //   imgUrl: "assets/images/project-images/project-thumbnail/food-truck.webp",
      //   technologies: ['all', 'figma', 'ror'] },
      // { label: "<span>Local</span><span>Grocery</span>", url: 'local-grocery',
      //   imgUrl: "assets/images/project-images/project-thumbnail/local-grocery.webp",
      //   technologies: ['all', 'android', 'figma', 'ror', 'ios'] },
      // { label: "<span>Miniflix</span>", url: 'miniflix',
      //   imgUrl: "assets/images/project-images/project-thumbnail/miniflix.webp",
      //   technologies: ['all', 'figma', 'react', 'ror'] },
      // { label: "<span>Stomerij</span><span>Collectief</span>", url: 'stomerijcollectief',
      //   imgUrl: "assets/images/project-images/project-thumbnail/stomerij-collectief.webp",
      //   technologies: ['all', 'figma', 'node', 'react', 'ror'] },
    ]
    public config: SwiperOptions = {
        loop: true,
        autoplay: {
          delay: 2000,
          disableOnInteraction: false
        },
        // slidesPerView: 'auto',
        speed: 2500,
        // initialSlide: 1,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        grabCursor: false,
        centeredSlides: true,
        breakpoints: {
          900: {
            slidesPerView: 2
          },
        },
    };

    constructor() { }

    public ngAfterViewInit(): void {
      gsap.registerPlugin(ScrollTrigger);
      gsap.fromTo('.portfolio-slider-wrapper .section-title-inner h2',{
        autoAlpha: 0, y: 50
        },{
          duration: 1, autoAlpha: 1, y: 0,
          scrollTrigger: {
            trigger: '.portfolio-slider-wrapper .section-title-inner h2',
            start: 'top 90%',
            // scrub: 1,
          }
        }
      )
      gsap.fromTo('.portfolio-slider-wrapper .section-title-inner p',{
        autoAlpha: 0, y: 50
        },{
          duration: 1, autoAlpha: 1, y: 0,
          scrollTrigger: {
            trigger: '.portfolio-slider-wrapper .section-title-inner p',
            start: 'top 90%',
          }
        }
      )
    }
}
