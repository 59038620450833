import { Component, OnInit } from '@angular/core';
import gsap from 'gsap';

@Component({
  selector: 'app-social',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.scss']
})
export class SocialComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    gsap.to('.SocialList li', {
      duration: 0.3, x: 0, opacity: 1, ease: 'power2', delay: 0.5, stagger: 0.1
    });
  }
}
