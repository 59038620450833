import { Component, OnInit, Renderer2 } from '@angular/core';
import Scrollbar, { ScrollbarPlugin } from 'smooth-scrollbar';
import { sharedService } from 'src/app/shared/shared.service';
import SplitType from 'split-type';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger'; 

@Component({
  selector: 'app-our-process',
  templateUrl: './our-process.component.html',
  styleUrls: ['./our-process.component.scss']
})
export class OurProcessComponent implements OnInit {

  bodyScrollBar: Scrollbar | undefined;
  public isHeaderMenuOpen: boolean = false;
  private listeners: any[] = [];

  constructor(public sharedservice: sharedService, private renderer: Renderer2 ) { }

  public ngOnInit(): void {

    this.sharedservice.showHeaderMenu$.subscribe((data) => {
      this.isHeaderMenuOpen = data;
      if (this.bodyScrollBar) {
        this.bodyScrollBar.updatePluginOptions('modal', { open: this.isHeaderMenuOpen });
      }
    });

    gsap.registerPlugin(ScrollTrigger);
    const scroller = document.querySelector('.scroller')as HTMLElement;

    this.bodyScrollBar = Scrollbar.init(scroller, { damping: 0.1, delegateTo: document, alwaysShowTracks: true });

    const content = this;
    ScrollTrigger.scrollerProxy(".scroller", {
      scrollTop(value) {
        if (content.bodyScrollBar) {
          if (arguments.length) {
            content.bodyScrollBar.scrollTop = value || 0;
          }
          return content.bodyScrollBar.scrollTop;
        } else {
          return 0;
        }
      }
    });

    this.bodyScrollBar.addListener(ScrollTrigger.update);
    ScrollTrigger.defaults({ scroller: scroller });


    gsap.to(".point-icon-inner", {scale: '0.2'});
    gsap.to(".point-icon-inner i", {autoAlpha: 0});
    gsap.to(".process-circle", {rotate: '75deg'});
    gsap.to(".process-circle ul li", {rotation: '-75deg'});
    gsap.to( ".process-circle-box", {top: '0vh'});

    const info: any = gsap.utils.toArray('.process-detail ul li > *');
    // Set things up

    gsap.set(info, {autoAlpha: 0, y: 50});
    info.forEach((box: any) => {
      const anim = gsap.to(box, { duration: 1, autoAlpha: 1, y: 0, paused: true });
      ScrollTrigger.create({
        trigger: box,
        start: 'top 90%',
        once: true,
        onEnter: self => {
          self.progress === 1 ? anim.progress(1) : anim.play();
        },

      });
    });
    document.querySelectorAll("img").forEach(img => {
      if (img.complete) {
        ScrollTrigger.refresh();
      } else {
        img.addEventListener('load', _imgLoaded => ScrollTrigger.refresh());
      }
    });

    const listener: any = this.renderer.listen(
      window,
      'resize',
      () => {
        this.resize();
    });
    this.resize();
    this.listeners.push(listener);

    document.querySelectorAll("img").forEach(img => {
      if (img.complete) {
        ScrollTrigger.refresh();
      } else {
        img.addEventListener('load', _imgLoaded => ScrollTrigger.refresh());
      }
    });

    gsap.config({
      nullTargetWarn: false,
    });

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

  }

  public ngAfterViewInit(): void {
    gsap.registerPlugin(ScrollTrigger);
    gsap.fromTo('.about-banner-detail p', {
      opacity: 0,
      y: 30
    }, {
      y: 0,
      opacity: 1,
      duration: 0.5,
      ease: 'power1',
      delay: 0.5
    });

    const aboutpagetitle = new SplitType('.about-header-info h1', {
      types: 'chars, words',
      absolute: true
    });

    gsap.from(aboutpagetitle.chars, {
      opacity: 0,
      x: 20,
      duration: 0.7,
      delay: 0.2,
      stagger: 0.03
    });
  }

  public resize(): void {
    if (window.matchMedia('(min-width: 768px)').matches) { 


      const mainCircle = gsap.timeline({scrollTrigger: {
        trigger: '.process-circle-main-block',
        start: '-=800s',
        end: '+=750s',
        // markers: true,
        toggleActions: 'play none none reverse',
        scrub: 1,
      }});
      mainCircle.fromTo( ".process-circle-main-block" ,{rotate: '270deg'}, {rotate: '0deg'})


      ScrollTrigger.create({
        trigger: '.process-first',
        start: '0s',
        end: '+=600s',
        // toggleActions: 'play reverse play reverse',
        onEnter: () => {
          gsap.fromTo( ".process-circle" ,{rotate: '75deg'}, {rotate: '0deg'})
          gsap.fromTo( ".first .point-icon-inner", {scale: '0.2'},{scale: '1', stagger:0.5})
          gsap.fromTo( ".first .point-icon-inner i" ,{autoAlpha: 0}, {autoAlpha: 1, stagger:0.5})
          gsap.fromTo( ".process-circle ul li.first", {rotation: '-75deg'},{rotation: '0deg', stagger:0.5})
          gsap.fromTo(".process-first", {autoAlpha: 0, y:50}, {autoAlpha: 1, y:0})
        },
        onEnterBack: () => {
          gsap.fromTo( ".process-circle" ,{rotate: '-60deg'}, {rotate: '0deg'})
          gsap.fromTo( ".first .point-icon-inner", {scale: '0.2'},{scale: '1', stagger:0.5})
          gsap.fromTo( ".first .point-icon-inner i" ,{autoAlpha: 0}, {autoAlpha: 1, stagger:0.5})
          gsap.fromTo( ".process-circle ul li.first", {rotation: '60deg'},{rotation: '0deg', stagger:0.5})
          gsap.fromTo(".process-first", {autoAlpha: 0, y:-50}, {autoAlpha: 1, y:0})
        },
        onLeaveBack: () => {
          gsap.fromTo( ".process-circle" ,{rotate: '0deg'}, {rotate: '75deg'})
          gsap.fromTo( ".first .point-icon-inner", {scale: '1', stagger:0.5},{scale: '0.2'})
          gsap.fromTo( ".first .point-icon-inner i" , {autoAlpha: 1, stagger:0.5},{autoAlpha: 0})
          gsap.fromTo( ".process-circle ul li.first", {rotation: '0deg', stagger:0.5},{rotation: '-75deg'})
          gsap.fromTo(".process-first", {autoAlpha: 1, y:0}, {autoAlpha: 0, y:50})
        }
      })

      ScrollTrigger.create({
        trigger: '.process-sec',
        start: '+=600s',
        end: '+=600s',
        onEnter: () => {
          gsap.fromTo( ".process-circle" ,{rotate: '0deg'}, {rotate: '-60deg'})
          gsap.fromTo( ".second .point-icon-inner", {scale: '0.2'},{scale: '1', stagger:0.5})
          gsap.fromTo( ".first .point-icon-inner", {scale: '1'},{scale: '0.2', stagger:0.5})
          gsap.fromTo( ".second .point-icon-inner i" ,{autoAlpha: 0}, {autoAlpha: 1, stagger:0.5})
          gsap.fromTo( ".first .point-icon-inner i" ,{autoAlpha: 1}, {autoAlpha: 0, stagger:0.5})
          gsap.fromTo( ".process-circle ul li.second", {rotation: '0deg'},{rotation: '60deg', stagger:0.5})
          gsap.fromTo( ".process-circle ul li.first", {rotation: '0deg'},{rotation: '60deg', stagger:0.5})
          gsap.fromTo(".process-sec", {autoAlpha: 0, y:50}, {autoAlpha: 1, y:0})
          gsap.fromTo(".process-first", {autoAlpha: 1, y:0}, {autoAlpha: 0, y:-50})
        },
        onEnterBack: () => {
          gsap.fromTo( ".process-circle" ,{rotate: '-120deg'}, {rotate: '-60deg'})
          gsap.fromTo( ".second .point-icon-inner", {scale: '0.2'},{scale: '1', stagger:0.5})
          gsap.fromTo( ".second .point-icon-inner i" ,{autoAlpha: 0}, {autoAlpha: 1, stagger:0.5})
          gsap.fromTo( ".process-circle ul li.second", {rotation: '120deg'},{rotation: '60deg', stagger:0.5})
          gsap.fromTo(".process-sec", {autoAlpha: 0, y:-50}, {autoAlpha: 1, y:0})
        },
        onLeaveBack: () => {
          gsap.fromTo( ".process-circle" ,{rotate: '-60deg'}, {rotate: '0deg'})
          gsap.fromTo( ".second .point-icon-inner", {scale: '1'},{scale: '0.2', stagger:0.5})
          gsap.fromTo( ".second .point-icon-inner i" ,{autoAlpha: 1}, {autoAlpha: 0, stagger:0.5})
          gsap.fromTo( ".process-circle ul li.second", {rotation: '60deg'},{rotation: '0deg', stagger:0.5})
          gsap.fromTo(".process-sec", {autoAlpha: 1, y:0}, {autoAlpha: 0, y:50})
        }
      })

      ScrollTrigger.create({
        trigger: '.process-third',
        start: '+=1200s',
        end: '+=600s',
        onEnter: () => {
          gsap.fromTo( ".process-circle" ,{rotate: '-60deg'}, {rotate: '-120deg'})
          gsap.fromTo( ".third .point-icon-inner", {scale: '0.2'},{scale: '1', stagger:0.5})
          gsap.fromTo( ".second .point-icon-inner", {scale: '1'},{scale: '0.2', stagger:0.5})
          gsap.fromTo( ".third .point-icon-inner i" ,{autoAlpha: 0}, {autoAlpha: 1, stagger:0.5})
          gsap.fromTo( ".second .point-icon-inner i" ,{autoAlpha: 1}, {autoAlpha: 0, stagger:0.5})
          gsap.fromTo( ".process-circle ul li.third", {rotation: '60deg'},{rotation: '120deg', stagger:0.5})
          gsap.fromTo( ".process-circle ul li.second", {rotation: '60deg'},{rotation: '120deg', stagger:0.5})
          gsap.fromTo(".process-third", {autoAlpha: 0, y:50}, {autoAlpha: 1, y:0})
          gsap.fromTo(".process-sec", {autoAlpha: 1, y:0}, {autoAlpha: 0, y:-50})
        },
        onEnterBack: () => {
          gsap.fromTo( ".process-circle" ,{rotate: '-180deg'}, {rotate: '-120deg'})
          gsap.fromTo( ".third .point-icon-inner", {scale: '0.2'},{scale: '1', stagger:0.5})
          gsap.fromTo( ".third .point-icon-inner i" ,{autoAlpha: 0}, {autoAlpha: 1, stagger:0.5})
          gsap.fromTo( ".process-circle ul li.third", {rotation: '180deg'},{rotation: '120deg', stagger:0.5})
          gsap.fromTo(".process-third", {autoAlpha: 0, y:-50}, {autoAlpha: 1, y:0})
        },
        onLeaveBack: () => {
          gsap.fromTo( ".process-circle" ,{rotate: '-120deg'}, {rotate: '-60deg'})
          gsap.fromTo( ".third .point-icon-inner", {scale: '1'},{scale: '0.2', stagger:0.5})
          gsap.fromTo( ".third .point-icon-inner i" ,{autoAlpha: 1}, {autoAlpha: 0, stagger:0.5})
          gsap.fromTo( ".process-circle ul li.third", {rotation: '120deg'},{rotation: '60deg', stagger:0.5})
          gsap.fromTo(".process-third", {autoAlpha: 1, y:0}, {autoAlpha: 0, y:50})
        }
      })
      ScrollTrigger.create({
        trigger: '.process-fourth',
        start: '+=1800s',
        end: '+=600s',
        onEnter: () => {
          gsap.fromTo( ".process-circle" ,{rotate: '-120deg'}, {rotate: '-180deg'})
          gsap.fromTo( ".fourth .point-icon-inner", {scale: '0.2'},{scale: '1',stagger:0.5})
          gsap.fromTo( ".third .point-icon-inner", {scale: '1'},{scale: '0.2',stagger:0.5})
          gsap.fromTo( ".fourth .point-icon-inner i" ,{autoAlpha: 0}, {autoAlpha: 1,stagger:0.5})
          gsap.fromTo( ".third .point-icon-inner i" ,{autoAlpha: 1}, {autoAlpha: 0,stagger:0.5})
          gsap.fromTo( ".process-circle ul li.fourth", {rotation: '120deg'},{rotation: '180deg',stagger:0.5})
          gsap.fromTo( ".process-circle ul li.third", {rotation: '120deg'},{rotation: '180deg',stagger:0.5})
          gsap.fromTo(".process-fourth", {autoAlpha: 0, y:50}, {autoAlpha: 1, y:0})
          gsap.fromTo(".process-third", {autoAlpha: 1, y:0}, {autoAlpha: 0, y:-50})
        },
        onEnterBack: () => {
          gsap.fromTo( ".process-circle" ,{rotate: '-240deg'}, {rotate: '-180deg'})
          gsap.fromTo( ".fourth .point-icon-inner", {scale: '0.2'},{scale: '1',stagger:0.5})
          gsap.fromTo( ".fourth .point-icon-inner i" ,{autoAlpha: 0}, {autoAlpha: 1,stagger:0.5})
          gsap.fromTo( ".process-circle ul li.fourth", {rotation: '240deg'},{rotation: '180deg',stagger:0.5})
          gsap.fromTo(".process-fourth", {autoAlpha: 0, y:-50}, {autoAlpha: 1, y:0})
        },
        onLeaveBack: () => {
          gsap.fromTo( ".process-circle" ,{rotate: '-180deg'}, {rotate: '-120deg'})
          gsap.fromTo( ".fourth .point-icon-inner", {scale: '1'},{scale: '0.2',stagger:0.5})
          gsap.fromTo( ".fourth .point-icon-inner i" ,{autoAlpha: 1}, {autoAlpha: 0,stagger:0.5})
          gsap.fromTo( ".process-circle ul li.fourth", {rotation: '180deg'},{rotation: '120deg',stagger:0.5})
          gsap.fromTo(".process-fourth", {autoAlpha: 1, y:0}, {autoAlpha: 0, y:50})
        }
      })
      ScrollTrigger.create({
        trigger: '.process-fifth',
        start: '+=2400s',
        end: '+=600s',
        onEnter: () => {
          gsap.fromTo( ".process-circle" ,{rotate: '-180deg'}, {rotate: '-240deg'})
          gsap.fromTo( ".fifth .point-icon-inner", {scale: '0.2'},{scale: '1',stagger:0.5})
          gsap.fromTo( ".fourth .point-icon-inner", {scale: '1'},{scale: '0.2',stagger:0.5})
          gsap.fromTo( ".fifth .point-icon-inner i" ,{autoAlpha: 0}, {autoAlpha: 1,stagger:0.5})
          gsap.fromTo( ".fourth .point-icon-inner i" ,{autoAlpha: 1}, {autoAlpha: 0,stagger:0.5})
          gsap.fromTo( ".process-circle ul li.fifth", {rotation: '180deg'},{rotation: '240deg',stagger:0.5})
          gsap.fromTo( ".process-circle ul li.fourth", {rotation: '180deg'},{rotation: '240deg',stagger:0.5})
          gsap.fromTo(".process-fifth", {autoAlpha: 0, y:50}, {autoAlpha: 1, y:0})
          gsap.fromTo(".process-fourth", {autoAlpha: 1, y:0}, {autoAlpha: 0, y:-50})
        },
        onEnterBack: () => {
          gsap.fromTo( ".process-circle" ,{rotate: '-300deg'}, {rotate: '-240deg'})
          gsap.fromTo( ".fifth .point-icon-inner", {scale: '0.2'},{scale: '1',stagger:0.5})
          gsap.fromTo( ".fifth .point-icon-inner i" ,{autoAlpha: 0}, {autoAlpha: 1,stagger:0.5})
          gsap.fromTo( ".process-circle ul li.fifth", {rotation: '300deg'},{rotation: '240deg',stagger:0.5})
          gsap.fromTo(".process-fifth", {autoAlpha: 0, y:-50}, {autoAlpha: 1, y:0})
        },
        onLeaveBack: () => {
          gsap.fromTo( ".process-circle" ,{rotate: '-240deg'}, {rotate: '-180deg'})
          gsap.fromTo( ".fifth .point-icon-inner", {scale: '1'},{scale: '0.2',stagger:0.5})
          gsap.fromTo( ".fifth .point-icon-inner i" ,{autoAlpha: 1}, {autoAlpha: 0,stagger:0.5})
          gsap.fromTo( ".process-circle ul li.fifth", {rotation: '240deg'},{rotation: '180deg',stagger:0.5})
          gsap.fromTo(".process-fifth", {autoAlpha: 1, y:0}, {autoAlpha: 0, y:50})
        }
      })
      ScrollTrigger.create({
        trigger: '.process-six',
        start: '+=3000s',
        end: '+=600s',
        onEnter: () => {
          gsap.fromTo( ".process-circle" ,{rotate: '-240deg'}, {rotate: '-300deg'})
          gsap.fromTo( ".six .point-icon-inner", {scale: '0.2'},{scale: '1',stagger:0.5})
          gsap.fromTo( ".fifth .point-icon-inner", {scale: '1'},{scale: '0.2',stagger:0.5})
          gsap.fromTo( ".six .point-icon-inner i" ,{autoAlpha: 0}, {autoAlpha: 1,stagger:0.5})
          gsap.fromTo( ".fifth .point-icon-inner i" ,{autoAlpha: 1}, {autoAlpha: 0,stagger:0.5})
          gsap.fromTo( ".process-circle ul li.six", {rotation: '240deg'},{rotation: '300deg',stagger:0.5})
          gsap.fromTo( ".process-circle ul li.fifth", {rotation: '240deg'},{rotation: '300deg',stagger:0.5})
          gsap.fromTo(".process-six", {autoAlpha: 0, y:50}, {autoAlpha: 1, y:0})
          gsap.fromTo(".process-fifth", {autoAlpha: 1, y:0}, {autoAlpha: 0, y:-50})
        },
        onEnterBack: () => {

        },
        onLeaveBack: () => {
          gsap.fromTo( ".process-circle" ,{rotate: '-300deg'}, {rotate: '-240deg'})
          gsap.fromTo( ".six .point-icon-inner", {scale: '1'},{scale: '0.2',stagger:0.5})
          gsap.fromTo( ".six .point-icon-inner i" ,{autoAlpha: 1}, {autoAlpha: 0,stagger:0.5})
          gsap.fromTo( ".process-circle ul li.six", {rotation: '300deg'},{rotation: '240deg',stagger:0.5})
          gsap.fromTo(".process-six", {autoAlpha: 1, y:0}, {autoAlpha: 0, y:50})
        }
      })

      ScrollTrigger.create({
        trigger: '.process-inner-box',
        pin: '.process-inner-box',
        end: '+=3600s',
        pinSpacing: true
      });

    } else {
      gsap.to('.process-first', {
        scrollTrigger: {
          trigger: '.process-first',
          start: 'top 85%',
          end: 'bottom 85%',
        },
        autoAlpha: 1, y:0
      });
      gsap.to('.process-sec', {
        scrollTrigger: {
          trigger: '.process-sec',
          start: 'top 85%',
          end: 'bottom 85%',
        },
        autoAlpha: 1, y:0
      });
      gsap.to('.process-third', {
        scrollTrigger: {
          trigger: '.process-third',
          start: 'top 85%',
          end: 'bottom 85%',
        },
        autoAlpha: 1, y:0
      });
      gsap.to('.process-fourth', {
        scrollTrigger: {
          trigger: '.process-fourth',
          start: 'top 85%',
          end: 'bottom 85%',
        },
        autoAlpha: 1, y:0
      });
      gsap.to('.process-fifth', {
        scrollTrigger: {
          trigger: '.process-fifth',
          start: 'top 85%',
          end: 'bottom 85%',
        },
        autoAlpha: 1, y:0
      });
      gsap.to('.process-six', {
        scrollTrigger: {
          trigger: '.process-six',
          start: 'top 85%',
          end: 'bottom 85%',
        },
        autoAlpha: 1, y:0
      });
    }
  }


}

class ModalPlugin extends ScrollbarPlugin {
  static pluginName = 'modal';

  static defaultOptions = {
    isHeaderMenuOpen: false
  };

  transformDelta(delta: any) {
      return this.options.open ? { x: 0, y: 0 } : delta;
  }
}

Scrollbar.use(ModalPlugin, /* OverscrollPlugin */);
