import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import SplitType from 'split-type';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { ApiService } from '../api.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SwiperOptions } from 'swiper';

import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import Scrollbar, { ScrollbarPlugin } from 'smooth-scrollbar';
import { sharedService } from '../shared/shared.service';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements AfterViewInit, OnDestroy, OnInit {
  public preview: any;
  public careerList: any;
  public selectedCareer: any;
  public componentDestroyed$: Subject<boolean> = new Subject();

  public config: SwiperOptions = {
    loop: true,
    autoplay: {
      delay: 0.5,
      disableOnInteraction: false
    },
    allowTouchMove: false,
    slidesPerView: 'auto',
    speed: 8000,
  };

  bodyScrollBar: Scrollbar | undefined;
  public isHeaderMenuOpen: boolean = false;
  public currentUrl: string = window.location.href;
  public baseUrl: string = window.location.origin;

  constructor(
    private apiService: ApiService,
    private metaTagService: Meta,
    private titleService: Title,
    private router: Router,
    private activedRoute: ActivatedRoute,
    public sharedservice: sharedService
  ) {
   this.apiService.getCareers()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data: any) => {
        this.careerList = data['data'];
        if (this.careerList > 0) {
          this.selectedCareer = this.careerList[0]
        }
        console.log('careerList', this.careerList);
    });
  }

  ngOnInit() {
    this.titleService.setTitle("Careers | Job Vacancies in Ahmedabad | We Are Hiring @ TechcomposeA");

    this.metaTagService.updateTag({ name: 'keywords', content: 'Careers. Are you looking for Career in IT Industry, At TechCompose solutions we are Hiring. check our careers page for current job openings in Ahmedabad or vacancy in Ahmedabad for Ruby on rails developer, wordpress developer, laravel developer, Business development manager, web and graphic designer, ionic developer etc' }),
    this.metaTagService.updateTag({ charset: 'UTF-8' }),
    this.metaTagService.updateTag({ property: 'og:title', content: 'Techcompose | Careers | Job Vacancies in Ahmedabad | We Are Hiring @ TechcomposeA' }),
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' }),
    this.metaTagService.updateTag({ property: 'og:url', content: `${this.currentUrl}` }),
    this.metaTagService.updateTag({ property: 'og:image', content: `${this.baseUrl}/assets/images/thumb.png` }),
    this.metaTagService.updateTag({ property: 'og:description', content: 'Careers. Are you looking for Career in IT Industry, At TechCompose solutions we are Hiring. check our careers page for current job openings in Ahmedabad or vacancy in Ahmedabad for Ruby on rails developer, wordpress developer, laravel developer, Business development manager, web and graphic designer, ionic developer etc' })
    this.metaTagService.removeTag("name='google-site-verification'");

    this.sharedservice.showHeaderMenu$.subscribe((data) => {
      this.isHeaderMenuOpen = data;
      if (this.bodyScrollBar) {
        this.bodyScrollBar.updatePluginOptions('modal', { open: this.isHeaderMenuOpen });
      }
    });

    gsap.registerPlugin(ScrollTrigger);
      const scroller = document.querySelector('.scroller')as HTMLElement;

      this.bodyScrollBar = Scrollbar.init(scroller, { damping: 0.1, delegateTo: document, alwaysShowTracks: true });

      const content = this;
      ScrollTrigger.scrollerProxy(".scroller", {
        scrollTop(value) {
          if (content.bodyScrollBar) {
            if (arguments.length) {
              content.bodyScrollBar.scrollTop = value || 0;
            }
            return content.bodyScrollBar.scrollTop;
          } else {
            return 0;
          }
        }
      });

      this.bodyScrollBar.addListener(ScrollTrigger.update);
      ScrollTrigger.defaults({ scroller: scroller });

  }

  public ngAfterViewInit(): void {
    gsap.registerPlugin(ScrollTrigger);
    const aboutpagetitle = new SplitType('.page-title h1', {
        types: 'chars, words',
        absolute: true
    });
    gsap.from(aboutpagetitle.chars, {
        opacity: 0,
        x: 20,
        duration: 0.7,
        stagger: 0.03,
    });
    gsap.fromTo('.career-info-detail p', {
        opacity: 0,
        y: 30,
    }, {
        opacity: 1,
        y: 0,
        duration: 0.5,
        delay: 0.5
    });

    const titleAnim = gsap.to(".opening-title-block", {
      ease: 'power1',
      opacity: 1,
    });
    ScrollTrigger.create({
      trigger: document.getElementsByClassName('job-openingsblock')[0],
      start: 'top 40%',
      end: 'bottom 10%',
      scrub: true,
      pin: true,
      anticipatePin: 1,
      animation: titleAnim,
    });


    // Title animation start
    const abouttitles = new SplitType('.title-letter-anim', {
      types: 'words chars',
      absolute: true
    });

    const letteranim: any = gsap.utils.toArray('.title-letter-anim .char');

    // Set things up
    gsap.set(letteranim, {autoAlpha: 0, x: 20});

    letteranim.forEach((textanim: any) => {
      // Set up your animation
      const anim = gsap.to(textanim, { duration: 1, autoAlpha: 1, stagger: 0.5, x: 0, paused: true });

      // Use callbacks to control the state of the animation
      ScrollTrigger.create({
        trigger: textanim,
        start: 'top 85%',
        once: true,
        onEnter: self => {
          // If it's scrolled past, set the state
          // If it's scrolled to, play it
          self.progress === 1 ? anim.progress(1) : anim.play();
        }
      });
    });

    const info: any = gsap.utils.toArray('.careers-info');

    // Set things up
    gsap.set(info, {autoAlpha: 0, y: 50});
    info.forEach((box: any) => {
      // Set up your animation
      const anim = gsap.to(box, { duration: 1, autoAlpha: 1, y: 0, paused: true });

      // Use callbacks to control the state of the animation
      ScrollTrigger.create({
        trigger: box,
        start: 'top 90%',
        once: true,
        onEnter: self => {
          // If it's scrolled past, set the state
          // If it's scrolled to, play it
          self.progress === 1 ? anim.progress(1) : anim.play();
        }
      });
    });


    ScrollTrigger.refresh();

    const openiningslist: any = gsap.utils.toArray('.job-list-block li');
    // Set things up
    gsap.set(openiningslist, {autoAlpha: 0, y: 50});
    openiningslist.forEach((JobListBlock: any) => {
        // Set up your animation
        const anim = gsap.to(JobListBlock, { duration: 1, autoAlpha: 1, y: 0, paused: true });

        // Use callbacks to control the state of the animation
        ScrollTrigger.create({
            trigger: JobListBlock,
            start: 'top 80%',
            once: true,
            onEnter: self => {
                self.progress === 1 ? anim.progress(1) : anim.play();
            }
        });
    });

    const workblock = gsap.fromTo('.reason-work-detail > *', {
      opacity: 0,
      y: 30,
    },{
      opacity: 1,
      y: 0,
      duration: 0.5,
      stagger: 0.3,
      delay: 0.5,
    });
    ScrollTrigger.create({
      trigger: '.reason-work-detail',
      start: 'top 80%',
      end: 'bottom 80%',
      animation: workblock,
    });

    ScrollTrigger.create({
      trigger: '.career-hero',
      start:"top 80%",
      onEnter: () => {
        gsap.to('.benefits-info-inner h3', {duration: 1.0, color: '#000000'})
        gsap.to('.benefits-info-inner p', {duration: 1.0, color: '#6D6D6D'})
      }
    })

    ScrollTrigger.create({
      trigger: '.tc-life',
      start:"top 50%",
      onEnter: () => {
        gsap.to('.career-grid-background-overlay', {duration: 1.0, backgroundColor: '#282828'})
        gsap.to('.tc-life h2', {duration: 1.0, color: '#ffffff'})
        gsap.to('.benefits-info-inner h3', {duration: 1.0, color: '#ffffff'})
        gsap.to('.benefits-info-inner p', {duration: 1.0, color: '#ffffff'})
      },
      onLeaveBack: () => {
        gsap.to('.career-grid-background-overlay', {duration: 1.0, backgroundColor: '#ffffff'})
        gsap.to('.tc-life h2', {duration: 1.0, color: '#282828'})
        gsap.to('.benefits-info-inner h3', {duration: 1.0, color: '#000000'})
        gsap.to('.benefits-info-inner p', {duration: 1.0, color: '#6D6D6D'})
      }
    })

    ScrollTrigger.create({
      trigger: '.job-openingsblock',
      start:"top 50%",
      onEnter: () => {
        gsap.to('.career-grid-background-overlay', {duration: 1.0, backgroundColor: '#ffffff'})
        gsap.to('.tc-life h2', {duration: 1.0, color: '#282828'})
      },
      onLeaveBack: () => {
        gsap.to('.career-grid-background-overlay', {duration: 1.0, backgroundColor: '#282828'})
        gsap.to('.tc-life h2', {duration: 1.0, color: '#ffffff'})
      }
    })

    gsap.to(".career-grid-background-overlay", {backgroundColor: '#ffffff'});
    document.querySelectorAll("img").forEach(img => {
      if (img.complete) {
        ScrollTrigger.refresh();
      } else {
        img.addEventListener('load', _imgLoaded => ScrollTrigger.refresh());
      }
    });
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
    });

    ScrollTrigger.create({
      trigger: ".header-class",
      start: "+50px",
      end: "bottom -=1000%",
      toggleClass: { targets: ".header", className: "active" }
    });

  }

  public redirectTojobOpening(career: any): void {
    this.router.navigate([career['attributes']['slug']], {relativeTo: this.activedRoute});
  }

  public closeJobOpening(): void {
    this.selectedCareer = null;
    gsap.to('.jop-apply-modal-box', {autoAlpha: 0, ease: 'linear', duration: 0.25, delay : 0.5});
    gsap.to('.job-info-box', {x: '100%',autoAlpha: 0, ease: 'linear', duration: 0.25, delay: 0.25});
    gsap.to('.back-overlay', {autoAlpha: 0, ease: 'linear', duration: 0.25, delay : 0.5});
    gsap.to('body', {overflow: 'visible'});
  }

  public applyNow(): void {
    window.location.href = `http://recruit.techcompose.com/?apply=${this.selectedCareer['attributes']['slug']}`;
  }

  public ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
class ModalPlugin extends ScrollbarPlugin {
  static pluginName = 'modal';

  static defaultOptions = {
    isHeaderMenuOpen: false
  };

  transformDelta(delta: any) {
      return this.options.open ? { x: 0, y: 0 } : delta;
  }
}

Scrollbar.use(ModalPlugin, /* OverscrollPlugin */);
