import { ActivatedRoute } from '@angular/router';
import { Component, AfterViewInit, OnDestroy, OnInit } from '@angular/core';

import SplitType from 'split-type';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { ApiService } from './../api.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import Scrollbar, { ScrollbarPlugin } from 'smooth-scrollbar';
import { sharedService } from '../shared/shared.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-career-details',
  templateUrl: './career-details.component.html',
  styleUrls: ['./career-details.component.scss']
})
export class CareerDetailsComponent implements AfterViewInit, OnDestroy, OnInit {
  public selectedCareer: any;
  public componentDestroyed$: Subject<boolean> = new Subject();

  bodyScrollBar: Scrollbar | undefined;
  public isHeaderMenuOpen: boolean = false;
  public currentUrl: string = window.location.href;
  public baseUrl: string = window.location.origin;

  constructor(private apiService: ApiService, public sharedservice: sharedService,private metaTagService: Meta, private titleService: Title,
    private route: ActivatedRoute) {
    this.route.params.subscribe(param => {
      const slug = param['slug'];
      this.apiService.getCareers()
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((data: any) => {
          if (data['data'] && data['data'].length > 0) {
            this.selectedCareer = data['data'].find((career: any) => career['attributes']['slug'] === slug);
            this.titleService.setTitle("Careers | Job Vacancies in Ahmedabad | We Are Hiring @ TechcomposeA");
            this.metaTagService.updateTag({ name: 'keywords', content: 'Careers. Are you looking for Career in IT Industry, At TechCompose solutions we are Hiring. check our careers page for current job openings in Ahmedabad or vacancy in Ahmedabad for Ruby on rails developer, wordpress developer, laravel developer, Business development manager, web and graphic designer, ionic developer etc' }),
            this.metaTagService.updateTag({ charset: 'UTF-8' });
            this.metaTagService.updateTag({ property: 'og:title', content: 'Techcompose | Careers | Job Vacancies in Ahmedabad | We Are Hiring @ TechcomposeA' });
            this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
            // this.metaTagService.updateTag({ property: 'og:url', content: 'https://www.techcompose.com/careers/'+param['slug'] });
            this.metaTagService.updateTag({ property: 'og:url', content: `${this.currentUrl}` });
            this.metaTagService.updateTag({ property: 'og:image', content: `${this.baseUrl}/assets/images/thumb.png` });
            this.metaTagService.updateTag({ property: 'og:description', content: 'Careers. Are you looking for Career in IT Industry, At TechCompose solutions we are Hiring. check our careers page for current job openings in Ahmedabad or vacancy in Ahmedabad for Ruby on rails developer, wordpress developer, laravel developer, Business development manager, web and graphic designer, ionic developer etc' });
            this.metaTagService.removeTag("name='google-site-verification'");

            setTimeout(() => {
              gsap.registerPlugin(ScrollTrigger);
              const aboutpagetitle = new SplitType('.job-title-box-inner h1', {
                types: 'chars, words',
                absolute: true
              });
              gsap.from(aboutpagetitle.chars, {
                  opacity: 0,
                  x: 20,
                  duration: 0.7,
                  stagger: 0.03,
              });
              gsap.fromTo(".back-btn",{autoAlpha: 0},{autoAlpha: 1, duration: 0.7,})
              gsap.fromTo(".job-title-box-inner h2", {
                autoAlpha: 0, y: 30,
              },{
                autoAlpha: 1,
                y: 0,
              })
              gsap.fromTo(".job-title-box-inner span", {
                autoAlpha: 0, y: 30,
              },{
                autoAlpha: 1,
                y: 0,
                delay: 0.1,
              })
              gsap.fromTo(".job-loc-info", {
                autoAlpha: 0, y: 30,
              },{
                autoAlpha: 1,
                y: 0,
              })
              gsap.fromTo(".job-info-top h4", {
                autoAlpha: 0, y: 30,
              },{
                autoAlpha: 1,
                y: 0,
              })
              gsap.fromTo(".job-description-box", {
                autoAlpha: 0, y: 30,
              },{
                autoAlpha: 1,
                y: 0,
                scrollTrigger: {
                  trigger: '.job-description-box',
                  start: "top 85%",
                }
              })
            }, 0)
          }
      });
    })
  }

  ngOnInit() {


    this.sharedservice.showHeaderMenu$.subscribe((data) => {
      this.isHeaderMenuOpen = data;
      if (this.bodyScrollBar) {
        this.bodyScrollBar.updatePluginOptions('modal', { open: this.isHeaderMenuOpen });
      }
    });

    gsap.registerPlugin(ScrollTrigger);
      const scroller = document.querySelector('.scroller')as HTMLElement;

      this.bodyScrollBar = Scrollbar.init(scroller, { damping: 0.1, delegateTo: document, alwaysShowTracks: true });

      const content = this;
      ScrollTrigger.scrollerProxy(".scroller", {
        scrollTop(value) {
          if (content.bodyScrollBar) {
            if (arguments.length) {
              content.bodyScrollBar.scrollTop = value || 0;
            }
            return content.bodyScrollBar.scrollTop;
          } else {
            return 0;
          }
        }
      });

      this.bodyScrollBar.addListener(ScrollTrigger.update);
      ScrollTrigger.defaults({ scroller: scroller });
  }

  ngAfterViewInit(): void {
    ScrollTrigger.create({
      trigger: ".header-class",
      start: "+50px",
      end: "bottom -=1000%",
      toggleClass: { targets: ".header", className: "active" }
    });
  }

  public applyNow(): void {
    window.location.href = `http://recruit.techcompose.com/?apply=${this.selectedCareer['attributes']['slug']}`;
  }

  public ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
class ModalPlugin extends ScrollbarPlugin {
  static pluginName = 'modal';

  static defaultOptions = {
    isHeaderMenuOpen: false
  };

  transformDelta(delta: any) {
      return this.options.open ? { x: 0, y: 0 } : delta;
  }
}

Scrollbar.use(ModalPlugin, /* OverscrollPlugin */);
