import { Component, OnInit, Renderer2 } from '@angular/core';
import gsap from 'gsap/all';
import ScrollTrigger from 'gsap/ScrollTrigger';
import * as $ from 'jquery';

@Component({
  selector: 'app-process',
  templateUrl: './process.component.html',
  styleUrls: ['./process.component.scss']
})
export class ProcessComponent implements OnInit {

  private listeners: any[] = [];
  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {

    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.create({
      trigger: '.process-inner-box',
      start:"top 50%",
      end:"bottom 0%",
      // pin: '.process-inner-box',
      // end: '+=3000s',
      // pinSpacing: true,
      onEnter: () => {
        gsap.to('.hero-bg', {duration: 1, backgroundColor: '#141414'})
        gsap.to('.hero-overlay', {duration: 1, backgroundColor: '#141414'})
        gsap.to('.tech-services-detail p', {duration: 1, color: '#ffffff'})
        gsap.to('.service-slider-item-inner', {duration: 1,backgroundColor: '#141414'})
        gsap.to('.service-detail-box', {duration: 1,color: '#ffffff'})
        gsap.to('.service-detail-box span', {duration: 1,borderColor: 'rgba(255,255,255,0.8)'})
        gsap.to('.service-info-box-inner h3', {duration: 1,color: '#ffffff'})
        gsap.to('.service-more-btn', {duration: 1,color: '#ffffff'})
        gsap.to('.process-detail ul li *', {color: '#ffffff'})
        gsap.to('.process-circle ul li .point-icon-inner', { background: '#ffffff'})
        gsap.to('.tc-logo span', { color: '#ffffff'})
        gsap.to('.process-circle ul li .point-icon-inner i', {color: '#000000'})
        gsap.to('.framework-wrapper h2', {duration: 1.0, color: '#ffffff'})
        gsap.to('.framework-wrapper p', {duration: 1.0, color: '#ffffff'})
        gsap.to('.work-primary-img-block h4',{duration: 1.0, color: '#ffffff'})
        gsap.to('.tech-services-slider-btn .swiper-nav-btn', {duration: 1,color: '#282828', backgroundColor: '#ffffff'})
        gsap.to('.service-detail-box span', {duration: 1,borderColor: 'rgba(255,255,255,0.8)'})
        gsap.to('.process-circle', {borderColor: 'rgba(255,255,255,0.1)'})
      },
      onLeaveBack: () => {
        gsap.to('.hero-bg', {duration: 1.0, backgroundColor: '#ffffff'})
        gsap.to('.hero-overlay', {duration: 1.0, backgroundColor: '#ffffff'})
        gsap.to('.serviceList ul li', {duration: 1, color: '#ffffff' })
        gsap.to('.text-row', {duration: 1,color: '#ffffff'})
        gsap.to('.tech-services-detail p', {duration: 1, color: '#282828'})
        gsap.to('.service-slider-item-inner', {duration: 1, backgroundColor: '#ffffff'})
        gsap.to('.service-detail-box', {duration: 1,color: '#000000'})
        gsap.to('.service-detail-box span', {duration: 1,borderColor: 'rgba(255,255,255,0.8)'})
        gsap.to('.service-info-box-inner h3', {duration: 1,color: '#000000'})
        gsap.to('.service-more-btn', {duration: 1,color: '#6d6d6d'})
        gsap.to('.process-detail ul li *', {color: '#282828'})
        gsap.to( ".process-circle ul li .point-icon-inner", { background: '#141414'})
        gsap.to('.tc-logo span', { color: '#141414'})
        gsap.to('.process-circle ul li .point-icon-inner i', {color: '#ffffff'})
        gsap.to('.tech-services-slider-btn .swiper-nav-btn', {duration: 1,color: '#ffffff', backgroundColor: '#141414'})
        gsap.to('.service-detail-box span', {duration: 1,borderColor: 'rgba(0,0,0,0.8)'})
        gsap.to('.framework-wrapper h2', {duration: 1.0, color: '#000000'})
        gsap.to('.framework-wrapper p', {duration: 1.0, color: '#000000'})
        gsap.to('.process-circle', {borderColor: '#ebebeb'})
      },
    })

    // const p1 = gsap.timeline({scrollTrigger: {
    //   trigger: '.process-first',
    //   start: '-=750s',
    //   end: '+=500s',
    //   // markers: true,
    //   toggleActions: 'play reverse play reverse',
    //   scrub: 1,
    // }});
    // p1.fromTo( ".first .point-icon-inner", {scale: '0.2'},{scale: '1'}, '-=0.5')
    //   .fromTo( ".first .point-icon-inner i" ,{autoAlpha: 0}, {autoAlpha: 1,}, '-=0.5')
    //   .fromTo( ".process-circle ul li.first", {rotation: '-75deg'},{rotation: '0deg'}, '-=0.5')

    // const p1Circle = gsap.timeline({scrollTrigger: {
    //   trigger: '.process-first',
    //   start: '-=750s',
    //   end: '+=500s',
    //   toggleActions: 'play none none reverse',
    //   scrub: 1,
    // }});
    // p1Circle.fromTo( ".process-circle" ,{rotate: '75deg'}, {rotate: '0deg'})


    // const p2 = gsap.timeline({scrollTrigger: {
    //   trigger: '.process-sec',
    //   start: '+=250s',
    //   end: '+=500s',
    //   // markers: true,
    //   toggleActions: 'play reverse play reverse',
    //   scrub: 1,
    // }});
    // p2.fromTo( ".second .point-icon-inner", {scale: '0.2'},{scale: '1'}, '-=0.5')
    //   .fromTo( ".first .point-icon-inner", {scale: '1'},{scale: '0.2'}, '-=0.5')
    //   .fromTo( ".second .point-icon-inner i" ,{autoAlpha: 0}, {autoAlpha: 1,}, '-=0.5')
    //   .fromTo( ".first .point-icon-inner i" ,{autoAlpha: 1}, {autoAlpha: 0,}, '-=0.5')
    //   .fromTo( ".process-circle ul li.second", {rotation: '0deg'},{rotation: '60deg'}, '-=0.5')
    //   .fromTo( ".process-circle ul li.first", {rotation: '0deg'},{rotation: '60deg'}, '-=0.5')

    // const p2Circle = gsap.timeline({scrollTrigger: {
    //   trigger: '.process-sec',
    //   start: '+=250s',
    //   end: '+=500s',
    //   // markers: true,
    //   toggleActions: 'play none none reverse',
    //   scrub: 1,
    // }});
    // p2Circle.fromTo( ".process-circle" ,{rotate: '0deg'}, {rotate: '-60deg', })

    // const p3 = gsap.timeline({scrollTrigger: {
    //   trigger: '.process-third',
    //   start: '+=750s',
    //   end: '+=500s',
    //   // markers: true,
    //   toggleActions: 'play reverse play reverse',
    //   scrub: 1,
    // }});
    // p3.fromTo( ".third .point-icon-inner", {scale: '0.2'},{scale: '1'}, '-=0.5')
    //   .fromTo( ".second .point-icon-inner", {scale: '1'},{scale: '0.2'}, '-=0.5')
    //   .fromTo( ".third .point-icon-inner i" ,{autoAlpha: 0}, {autoAlpha: 1,}, '-=0.5')
    //   .fromTo( ".second .point-icon-inner i" ,{autoAlpha: 1}, {autoAlpha: 0,}, '-=0.5')
    //   .fromTo( ".process-circle ul li.third", {rotation: '60deg'},{rotation: '120deg'}, '-=0.5')
    //   .fromTo( ".process-circle ul li.second", {rotation: '60deg'},{rotation: '120deg'}, '-=0.5')

    // const p3Circle = gsap.timeline({scrollTrigger: {
    //   trigger: '.process-third',
    //   start: '+=750s',
    //   end: '+=500s',
    //   // markers: true,
    //   toggleActions: 'play none none reverse',
    //   scrub: 1,
    // }});
    // p3Circle.fromTo( ".process-circle" ,{rotate: '-60deg'}, {rotate: '-120deg'})

    //   const p4 = gsap.timeline({scrollTrigger: {
    //     trigger: '.process-fourth',
    //     start: '+=1250s',
    //     end: '+=500s',
    //     // markers: true,
    //     toggleActions: 'play reverse play reverse',
    //     scrub: 1,
    //   }});
    //   p4.fromTo( ".fourth .point-icon-inner", {scale: '0.2'},{scale: '1'}, '-=0.5')
    //     .fromTo( ".third .point-icon-inner", {scale: '1'},{scale: '0.2'}, '-=0.5')
    //     .fromTo( ".fourth .point-icon-inner i" ,{autoAlpha: 0}, {autoAlpha: 1,}, '-=0.5')
    //     .fromTo( ".third .point-icon-inner i" ,{autoAlpha: 1}, {autoAlpha: 0,}, '-=0.5')
    //     .fromTo( ".process-circle ul li.fourth", {rotation: '120deg'},{rotation: '180deg'}, '-=0.5')
    //     .fromTo( ".process-circle ul li.third", {rotation: '120deg'},{rotation: '180deg'}, '-=0.5')

    //   const p4Circle = gsap.timeline({scrollTrigger: {
    //     trigger: '.process-fourth',
    //     start: '+=1250s',
    //     end: '+=500s',
    //     // markers: true,
    //     toggleActions: 'play none none reverse',
    //     scrub: 1,
    //   }});
    //   p4Circle.fromTo( ".process-circle" ,{rotate: '-120deg'}, {rotate: '-180deg'})

    //   const p5 = gsap.timeline({scrollTrigger: {
    //     trigger: '.process-fifth',
    //     start: '+=1750s',
    //     end: '+=500s',
    //     // markers: true,
    //     toggleActions: 'play reverse play reverse',
    //     scrub: 1,
    //   }});
    //   p5.fromTo( ".fifth .point-icon-inner", {scale: '0.2'},{scale: '1'}, '-=0.5')
    //     .fromTo( ".fourth .point-icon-inner", {scale: '1'},{scale: '0.2'}, '-=0.5')
    //     .fromTo( ".fifth .point-icon-inner i" ,{autoAlpha: 0}, {autoAlpha: 1,}, '-=0.5')
    //     .fromTo( ".fourth .point-icon-inner i" ,{autoAlpha: 1}, {autoAlpha: 0,}, '-=0.5')
    //     .fromTo( ".process-circle ul li.fifth", {rotation: '180deg'},{rotation: '240deg'}, '-=0.5')
    //     .fromTo( ".process-circle ul li.fourth", {rotation: '180deg'},{rotation: '240deg'}, '-=0.5')

    //   const p5Circle = gsap.timeline({scrollTrigger: {
    //     trigger: '.process-fifth',
    //     start: '+=1750s',
    //     end: '+=500s',
    //     // markers: true,
    //     toggleActions: 'play none none reverse',
    //     scrub: 1,
    //   }});
    //   p5Circle.fromTo( ".process-circle" ,{rotate: '-180deg'}, {rotate: '-240deg'})

    //   const p6 = gsap.timeline({scrollTrigger: {
    //     trigger: '.process-six',
    //     start: '+=2250s',
    //     end: '+=500s',
    //     // markers: true,
    //     toggleActions: 'play reverse play reverse',
    //     scrub: 1,
    //   }});
    //   p6.fromTo( ".six .point-icon-inner", {scale: '0.2'},{scale: '1'}, '-=0.5')
    //     .fromTo( ".fifth .point-icon-inner", {scale: '1'},{scale: '0.2'}, '-=0.5')
    //     .fromTo( ".six .point-icon-inner i" ,{autoAlpha: 0}, {autoAlpha: 1,}, '-=0.5')
    //     .fromTo( ".fifth .point-icon-inner i" ,{autoAlpha: 1}, {autoAlpha: 0,}, '-=0.5')
    //     .fromTo( ".process-circle ul li.six", {rotation: '240deg'},{rotation: '300deg'}, '-=0.5')
    //     .fromTo( ".process-circle ul li.fifth", {rotation: '240deg'},{rotation: '300deg'}, '-=0.5')

    //     const p6Circle = gsap.timeline({scrollTrigger: {
    //       trigger: '.process-six',
    //       start: '+=2250s',
    //       end: '+=500s',
    //       // markers: true,
    //       toggleActions: 'play none none reverse',
    //       scrub: 1,
    //     }});
    //     p6Circle.fromTo( ".process-circle" ,{rotate: '-240deg'}, {rotate: '-300deg'})

    gsap.to(".point-icon-inner", {scale: '0.2'});
    gsap.to(".point-icon-inner i", {autoAlpha: 0});
    gsap.to(".process-circle", {rotate: '75deg'});
    gsap.to(".process-circle ul li", {rotation: '-75deg'});
    gsap.to( ".process-circle-box", {top: '0vh'});

    const info: any = gsap.utils.toArray('.process-detail ul li > *');
    // Set things up

    gsap.set(info, {autoAlpha: 0, y: 50});
    info.forEach((box: any) => {
      const anim = gsap.to(box, { duration: 1, autoAlpha: 1, y: 0, paused: true });
      ScrollTrigger.create({
        trigger: box,
        start: 'top 90%',
        once: true,
        onEnter: self => {
          self.progress === 1 ? anim.progress(1) : anim.play();
        },

      });
    });
    document.querySelectorAll("img").forEach(img => {
      if (img.complete) {
        ScrollTrigger.refresh();
      } else {
        img.addEventListener('load', _imgLoaded => ScrollTrigger.refresh());
      }
    });

    const listener: any = this.renderer.listen(
      window,
      'resize',
      () => {
        this.resize();
    });
    this.resize();
    this.listeners.push(listener);

    document.querySelectorAll("img").forEach(img => {
      if (img.complete) {
        ScrollTrigger.refresh();
      } else {
        img.addEventListener('load', _imgLoaded => ScrollTrigger.refresh());
      }
    });

    gsap.config({
      nullTargetWarn: false,
    });

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

  }

  public resize(): void {
    if (window.matchMedia('(min-width: 768px)').matches) {
      // let sections = gsap.utils.toArray(".process-detail ul li");

      // let scrollTween = gsap.to(sections, {
      //   yPercent: -100 * (sections.length - 1),
      //   ease: "none",
      //   scrollTrigger: {
      //     trigger: ".process-wrapper",
      //     pin: true,
      //     scrub: 1,
      //     // start: "top top",
      //     // invalidateOnRefresh: true,
      //     pinSpacing: false,
      //     // base vertical scrolling on how wide the container is so it feels more natural.
      //     // end: () => "+=" + document.querySelector(".process-wrapper").offsetWidth
      //     // end: "+=6000"
      //     end: "bottom bottom"
      //   }
      // });

      // gsap.to('.process-first', {
      //   scrollTrigger: {
      //     trigger: '.process-first',
      //     toggleActions: 'play reverse play reverse',
      //     start: '-=250s',
      //     end: '+=500s',
      //   },
      //   autoAlpha: 1, y:0
      // });
      // gsap.to('.process-sec', {
      //   scrollTrigger: {
      //     trigger: '.process-sec',
      //     toggleActions: 'play reverse play reverse',
      //     start: '+=500s',
      //     end: '+=500s',
      //   },
      //   autoAlpha: 1, y:0
      // });
      // gsap.to('.process-third', {
      //   scrollTrigger: {
      //     trigger: '.process-third',
      //     toggleActions: 'play reverse play reverse',
      //     start: '+=1000s',
      //     end: '+=500s',
      //   },
      //   autoAlpha: 1, y:0
      // });
      // gsap.to('.process-fourth', {
      //   scrollTrigger: {
      //     trigger: '.process-fourth',
      //     toggleActions: 'play reverse play reverse',
      //     start: '+=1500s',
      //     end: '+=500s',
      //   },
      //   autoAlpha: 1, y:0
      // });
      // gsap.to('.process-fifth', {
      //   scrollTrigger: {
      //     trigger: '.process-fifth',
      //     toggleActions: 'play reverse play reverse',
      //     start: '+=2000s',
      //     end: '+=500s',
      //   },
      //   autoAlpha: 1, y:0
      // });
      // gsap.to('.process-six', {
      //   scrollTrigger: {
      //     trigger: '.process-six',
      //     toggleActions: 'play none play reverse',
      //     start: '+=2500s',
      //     end: '+=500s',
      //   },
      //   autoAlpha: 1, y:0
      // });


      // ScrollTrigger.create({
      //   trigger: '.process-circle-main-block',
      //   start: '-=600s',
      //   end: '+=3600s',
      //   onEnter: () => {
      //     gsap.fromTo( ".process-circle-main-block" ,{rotate: '300deg'}, {rotate: '0deg'})
      //   },
      //   onLeaveBack: () => {
      //     gsap.fromTo( ".process-circle-main-block" ,{rotate: '0deg'}, {rotate: '300deg'})
      //   }
      // })


      const mainCircle = gsap.timeline({scrollTrigger: {
        trigger: '.process-circle-main-block',
        start: '-=800s',
        end: '+=750s',
        // markers: true,
        toggleActions: 'play none none reverse',
        scrub: 1,
      }});
      mainCircle.fromTo( ".process-circle-main-block" ,{rotate: '270deg'}, {rotate: '0deg'})


      ScrollTrigger.create({
        trigger: '.process-first',
        start: '0s',
        end: '+=600s',
        // toggleActions: 'play reverse play reverse',
        onEnter: () => {
          gsap.fromTo( ".process-circle" ,{rotate: '75deg'}, {rotate: '0deg'})
          gsap.fromTo( ".first .point-icon-inner", {scale: '0.2'},{scale: '1', stagger:0.5})
          gsap.fromTo( ".first .point-icon-inner i" ,{autoAlpha: 0}, {autoAlpha: 1, stagger:0.5})
          gsap.fromTo( ".process-circle ul li.first", {rotation: '-75deg'},{rotation: '0deg', stagger:0.5})
          gsap.fromTo(".process-first", {autoAlpha: 0, y:50}, {autoAlpha: 1, y:0})
        },
        onEnterBack: () => {
          gsap.fromTo( ".process-circle" ,{rotate: '-60deg'}, {rotate: '0deg'})
          gsap.fromTo( ".first .point-icon-inner", {scale: '0.2'},{scale: '1', stagger:0.5})
          gsap.fromTo( ".first .point-icon-inner i" ,{autoAlpha: 0}, {autoAlpha: 1, stagger:0.5})
          gsap.fromTo( ".process-circle ul li.first", {rotation: '60deg'},{rotation: '0deg', stagger:0.5})
          gsap.fromTo(".process-first", {autoAlpha: 0, y:-50}, {autoAlpha: 1, y:0})
        },
        onLeaveBack: () => {
          gsap.fromTo( ".process-circle" ,{rotate: '0deg'}, {rotate: '75deg'})
          gsap.fromTo( ".first .point-icon-inner", {scale: '1', stagger:0.5},{scale: '0.2'})
          gsap.fromTo( ".first .point-icon-inner i" , {autoAlpha: 1, stagger:0.5},{autoAlpha: 0})
          gsap.fromTo( ".process-circle ul li.first", {rotation: '0deg', stagger:0.5},{rotation: '-75deg'})
          gsap.fromTo(".process-first", {autoAlpha: 1, y:0}, {autoAlpha: 0, y:50})
        }
      })

      ScrollTrigger.create({
        trigger: '.process-sec',
        start: '+=600s',
        end: '+=600s',
        onEnter: () => {
          gsap.fromTo( ".process-circle" ,{rotate: '0deg'}, {rotate: '-60deg'})
          gsap.fromTo( ".second .point-icon-inner", {scale: '0.2'},{scale: '1', stagger:0.5})
          gsap.fromTo( ".first .point-icon-inner", {scale: '1'},{scale: '0.2', stagger:0.5})
          gsap.fromTo( ".second .point-icon-inner i" ,{autoAlpha: 0}, {autoAlpha: 1, stagger:0.5})
          gsap.fromTo( ".first .point-icon-inner i" ,{autoAlpha: 1}, {autoAlpha: 0, stagger:0.5})
          gsap.fromTo( ".process-circle ul li.second", {rotation: '0deg'},{rotation: '60deg', stagger:0.5})
          gsap.fromTo( ".process-circle ul li.first", {rotation: '0deg'},{rotation: '60deg', stagger:0.5})
          gsap.fromTo(".process-sec", {autoAlpha: 0, y:50}, {autoAlpha: 1, y:0})
          gsap.fromTo(".process-first", {autoAlpha: 1, y:0}, {autoAlpha: 0, y:-50})
        },
        onEnterBack: () => {
          gsap.fromTo( ".process-circle" ,{rotate: '-120deg'}, {rotate: '-60deg'})
          gsap.fromTo( ".second .point-icon-inner", {scale: '0.2'},{scale: '1', stagger:0.5})
          gsap.fromTo( ".second .point-icon-inner i" ,{autoAlpha: 0}, {autoAlpha: 1, stagger:0.5})
          gsap.fromTo( ".process-circle ul li.second", {rotation: '120deg'},{rotation: '60deg', stagger:0.5})
          gsap.fromTo(".process-sec", {autoAlpha: 0, y:-50}, {autoAlpha: 1, y:0})
        },
        onLeaveBack: () => {
          gsap.fromTo( ".process-circle" ,{rotate: '-60deg'}, {rotate: '0deg'})
          gsap.fromTo( ".second .point-icon-inner", {scale: '1'},{scale: '0.2', stagger:0.5})
          gsap.fromTo( ".second .point-icon-inner i" ,{autoAlpha: 1}, {autoAlpha: 0, stagger:0.5})
          gsap.fromTo( ".process-circle ul li.second", {rotation: '60deg'},{rotation: '0deg', stagger:0.5})
          gsap.fromTo(".process-sec", {autoAlpha: 1, y:0}, {autoAlpha: 0, y:50})
        }
      })

      ScrollTrigger.create({
        trigger: '.process-third',
        start: '+=1200s',
        end: '+=600s',
        onEnter: () => {
          gsap.fromTo( ".process-circle" ,{rotate: '-60deg'}, {rotate: '-120deg'})
          gsap.fromTo( ".third .point-icon-inner", {scale: '0.2'},{scale: '1', stagger:0.5})
          gsap.fromTo( ".second .point-icon-inner", {scale: '1'},{scale: '0.2', stagger:0.5})
          gsap.fromTo( ".third .point-icon-inner i" ,{autoAlpha: 0}, {autoAlpha: 1, stagger:0.5})
          gsap.fromTo( ".second .point-icon-inner i" ,{autoAlpha: 1}, {autoAlpha: 0, stagger:0.5})
          gsap.fromTo( ".process-circle ul li.third", {rotation: '60deg'},{rotation: '120deg', stagger:0.5})
          gsap.fromTo( ".process-circle ul li.second", {rotation: '60deg'},{rotation: '120deg', stagger:0.5})
          gsap.fromTo(".process-third", {autoAlpha: 0, y:50}, {autoAlpha: 1, y:0})
          gsap.fromTo(".process-sec", {autoAlpha: 1, y:0}, {autoAlpha: 0, y:-50})
        },
        onEnterBack: () => {
          gsap.fromTo( ".process-circle" ,{rotate: '-180deg'}, {rotate: '-120deg'})
          gsap.fromTo( ".third .point-icon-inner", {scale: '0.2'},{scale: '1', stagger:0.5})
          gsap.fromTo( ".third .point-icon-inner i" ,{autoAlpha: 0}, {autoAlpha: 1, stagger:0.5})
          gsap.fromTo( ".process-circle ul li.third", {rotation: '180deg'},{rotation: '120deg', stagger:0.5})
          gsap.fromTo(".process-third", {autoAlpha: 0, y:-50}, {autoAlpha: 1, y:0})
        },
        onLeaveBack: () => {
          gsap.fromTo( ".process-circle" ,{rotate: '-120deg'}, {rotate: '-60deg'})
          gsap.fromTo( ".third .point-icon-inner", {scale: '1'},{scale: '0.2', stagger:0.5})
          gsap.fromTo( ".third .point-icon-inner i" ,{autoAlpha: 1}, {autoAlpha: 0, stagger:0.5})
          gsap.fromTo( ".process-circle ul li.third", {rotation: '120deg'},{rotation: '60deg', stagger:0.5})
          gsap.fromTo(".process-third", {autoAlpha: 1, y:0}, {autoAlpha: 0, y:50})
        }
      })
      ScrollTrigger.create({
        trigger: '.process-fourth',
        start: '+=1800s',
        end: '+=600s',
        onEnter: () => {
          gsap.fromTo( ".process-circle" ,{rotate: '-120deg'}, {rotate: '-180deg'})
          gsap.fromTo( ".fourth .point-icon-inner", {scale: '0.2'},{scale: '1',stagger:0.5})
          gsap.fromTo( ".third .point-icon-inner", {scale: '1'},{scale: '0.2',stagger:0.5})
          gsap.fromTo( ".fourth .point-icon-inner i" ,{autoAlpha: 0}, {autoAlpha: 1,stagger:0.5})
          gsap.fromTo( ".third .point-icon-inner i" ,{autoAlpha: 1}, {autoAlpha: 0,stagger:0.5})
          gsap.fromTo( ".process-circle ul li.fourth", {rotation: '120deg'},{rotation: '180deg',stagger:0.5})
          gsap.fromTo( ".process-circle ul li.third", {rotation: '120deg'},{rotation: '180deg',stagger:0.5})
          gsap.fromTo(".process-fourth", {autoAlpha: 0, y:50}, {autoAlpha: 1, y:0})
          gsap.fromTo(".process-third", {autoAlpha: 1, y:0}, {autoAlpha: 0, y:-50})
        },
        onEnterBack: () => {
          gsap.fromTo( ".process-circle" ,{rotate: '-240deg'}, {rotate: '-180deg'})
          gsap.fromTo( ".fourth .point-icon-inner", {scale: '0.2'},{scale: '1',stagger:0.5})
          gsap.fromTo( ".fourth .point-icon-inner i" ,{autoAlpha: 0}, {autoAlpha: 1,stagger:0.5})
          gsap.fromTo( ".process-circle ul li.fourth", {rotation: '240deg'},{rotation: '180deg',stagger:0.5})
          gsap.fromTo(".process-fourth", {autoAlpha: 0, y:-50}, {autoAlpha: 1, y:0})
        },
        onLeaveBack: () => {
          gsap.fromTo( ".process-circle" ,{rotate: '-180deg'}, {rotate: '-120deg'})
          gsap.fromTo( ".fourth .point-icon-inner", {scale: '1'},{scale: '0.2',stagger:0.5})
          gsap.fromTo( ".fourth .point-icon-inner i" ,{autoAlpha: 1}, {autoAlpha: 0,stagger:0.5})
          gsap.fromTo( ".process-circle ul li.fourth", {rotation: '180deg'},{rotation: '120deg',stagger:0.5})
          gsap.fromTo(".process-fourth", {autoAlpha: 1, y:0}, {autoAlpha: 0, y:50})
        }
      })
      ScrollTrigger.create({
        trigger: '.process-fifth',
        start: '+=2400s',
        end: '+=600s',
        onEnter: () => {
          gsap.fromTo( ".process-circle" ,{rotate: '-180deg'}, {rotate: '-240deg'})
          gsap.fromTo( ".fifth .point-icon-inner", {scale: '0.2'},{scale: '1',stagger:0.5})
          gsap.fromTo( ".fourth .point-icon-inner", {scale: '1'},{scale: '0.2',stagger:0.5})
          gsap.fromTo( ".fifth .point-icon-inner i" ,{autoAlpha: 0}, {autoAlpha: 1,stagger:0.5})
          gsap.fromTo( ".fourth .point-icon-inner i" ,{autoAlpha: 1}, {autoAlpha: 0,stagger:0.5})
          gsap.fromTo( ".process-circle ul li.fifth", {rotation: '180deg'},{rotation: '240deg',stagger:0.5})
          gsap.fromTo( ".process-circle ul li.fourth", {rotation: '180deg'},{rotation: '240deg',stagger:0.5})
          gsap.fromTo(".process-fifth", {autoAlpha: 0, y:50}, {autoAlpha: 1, y:0})
          gsap.fromTo(".process-fourth", {autoAlpha: 1, y:0}, {autoAlpha: 0, y:-50})
        },
        onEnterBack: () => {
          gsap.fromTo( ".process-circle" ,{rotate: '-300deg'}, {rotate: '-240deg'})
          gsap.fromTo( ".fifth .point-icon-inner", {scale: '0.2'},{scale: '1',stagger:0.5})
          gsap.fromTo( ".fifth .point-icon-inner i" ,{autoAlpha: 0}, {autoAlpha: 1,stagger:0.5})
          gsap.fromTo( ".process-circle ul li.fifth", {rotation: '300deg'},{rotation: '240deg',stagger:0.5})
          gsap.fromTo(".process-fifth", {autoAlpha: 0, y:-50}, {autoAlpha: 1, y:0})
        },
        onLeaveBack: () => {
          gsap.fromTo( ".process-circle" ,{rotate: '-240deg'}, {rotate: '-180deg'})
          gsap.fromTo( ".fifth .point-icon-inner", {scale: '1'},{scale: '0.2',stagger:0.5})
          gsap.fromTo( ".fifth .point-icon-inner i" ,{autoAlpha: 1}, {autoAlpha: 0,stagger:0.5})
          gsap.fromTo( ".process-circle ul li.fifth", {rotation: '240deg'},{rotation: '180deg',stagger:0.5})
          gsap.fromTo(".process-fifth", {autoAlpha: 1, y:0}, {autoAlpha: 0, y:50})
        }
      })
      ScrollTrigger.create({
        trigger: '.process-six',
        start: '+=3000s',
        end: '+=600s',
        onEnter: () => {
          gsap.fromTo( ".process-circle" ,{rotate: '-240deg'}, {rotate: '-300deg'})
          gsap.fromTo( ".six .point-icon-inner", {scale: '0.2'},{scale: '1',stagger:0.5})
          gsap.fromTo( ".fifth .point-icon-inner", {scale: '1'},{scale: '0.2',stagger:0.5})
          gsap.fromTo( ".six .point-icon-inner i" ,{autoAlpha: 0}, {autoAlpha: 1,stagger:0.5})
          gsap.fromTo( ".fifth .point-icon-inner i" ,{autoAlpha: 1}, {autoAlpha: 0,stagger:0.5})
          gsap.fromTo( ".process-circle ul li.six", {rotation: '240deg'},{rotation: '300deg',stagger:0.5})
          gsap.fromTo( ".process-circle ul li.fifth", {rotation: '240deg'},{rotation: '300deg',stagger:0.5})
          gsap.fromTo(".process-six", {autoAlpha: 0, y:50}, {autoAlpha: 1, y:0})
          gsap.fromTo(".process-fifth", {autoAlpha: 1, y:0}, {autoAlpha: 0, y:-50})
        },
        onEnterBack: () => {

        },
        onLeaveBack: () => {
          gsap.fromTo( ".process-circle" ,{rotate: '-300deg'}, {rotate: '-240deg'})
          gsap.fromTo( ".six .point-icon-inner", {scale: '1'},{scale: '0.2',stagger:0.5})
          gsap.fromTo( ".six .point-icon-inner i" ,{autoAlpha: 1}, {autoAlpha: 0,stagger:0.5})
          gsap.fromTo( ".process-circle ul li.six", {rotation: '300deg'},{rotation: '240deg',stagger:0.5})
          gsap.fromTo(".process-six", {autoAlpha: 1, y:0}, {autoAlpha: 0, y:50})
        }
      })

      ScrollTrigger.create({
        trigger: '.process-inner-box',
        pin: '.process-inner-box',
        end: '+=3600s',
        pinSpacing: true
      });

    } else {
      gsap.to('.process-first', {
        scrollTrigger: {
          trigger: '.process-first',
          start: 'top 85%',
          end: 'bottom 85%',
        },
        autoAlpha: 1, y:0
      });
      gsap.to('.process-sec', {
        scrollTrigger: {
          trigger: '.process-sec',
          start: 'top 85%',
          end: 'bottom 85%',
        },
        autoAlpha: 1, y:0
      });
      gsap.to('.process-third', {
        scrollTrigger: {
          trigger: '.process-third',
          start: 'top 85%',
          end: 'bottom 85%',
        },
        autoAlpha: 1, y:0
      });
      gsap.to('.process-fourth', {
        scrollTrigger: {
          trigger: '.process-fourth',
          start: 'top 85%',
          end: 'bottom 85%',
        },
        autoAlpha: 1, y:0
      });
      gsap.to('.process-fifth', {
        scrollTrigger: {
          trigger: '.process-fifth',
          start: 'top 85%',
          end: 'bottom 85%',
        },
        autoAlpha: 1, y:0
      });
      gsap.to('.process-six', {
        scrollTrigger: {
          trigger: '.process-six',
          start: 'top 85%',
          end: 'bottom 85%',
        },
        autoAlpha: 1, y:0
      });
    }
  }

}
