import { Component, OnInit } from '@angular/core';

import { Title, Meta } from '@angular/platform-browser';
import gsap from 'gsap';
import Scrollbar, { ScrollbarPlugin } from 'smooth-scrollbar';
import { sharedService } from 'src/app/shared/shared.service';
import { HighlightLoader } from 'ngx-highlightjs';

@Component({
  selector: 'app-internationalization-ror',
  templateUrl: './internationalization-ror.component.html',
  styleUrls: ['./internationalization-ror.component.scss']
})
export class InternationalizationRorComponent implements OnInit {

  bodyScrollBar: Scrollbar | undefined;
  public isHeaderMenuOpen: boolean = false;
  public currentBlogUrl: string = window.location.href;
  public baseUrl: string = window.location.origin;

public code1 =`rails new tech-internationalization`;

public code2 =`cd tech-internationalization`;

public code3 =`bundle install`;

public code4 =`rails generate controller Welcome index`;

public code5 =`rails g scaffold User name email`;

public code6 =`rails db:migrate`;

public code7 =`rails s`;

public code8 =`config.i18n.available_locales = [:en, :ru, :hi, :es, :nl, :gu]
config.i18n.default_locale = :en`;

public code9 =`# Rails internationalization
gem 'rails-i18n'

# Third party api
gem 'httparty'`;

public code10 =`# English
en:
  header:
    menu:
      language: 'Language'
      home: 'Home'
      about_us: 'About Us'
      service: 'Service'
      search: 'Search'
  content:
    demo_text: 'Internationalization Demo here'
  user:
    user_text: 'User'`;

public code11 =`# Russian
ru:
  header:
    menu:
      language: 'язык'
      home: 'Главная'
      about_us: 'Насчет нас'
      service: 'обслуживание'
      search: 'Поиск'
  content:
    demo_text: 'Интернационализация Демо здесь'
  user:
    user_text: 'пользователей'`;

public code12 =`<nav class="navbar navbar-expand-lg navbar-light bg-light" style="margin:24px 0;">
  <a class="navbar-brand" href="/"><%= image_tag 'techcompose.png', width: 50, height: 50%></a>
  <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navb">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navb">
    <ul class="navbar-nav mr-auto">
    <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <%= t 'header.menu.language'%>
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <%= link_to 'English', url_for(locale: :en), class: "dropdown-item" %>
            <%= link_to 'Russisch', url_for(locale: :ru), class: "dropdown-item" %>
            <%= link_to 'हिंदी', url_for(locale: :hi), class: "dropdown-item" %>
            <%= link_to 'Español', url_for(locale: :es), class: "dropdown-item" %>
            <%= link_to 'Nederlands', url_for(locale: :nl), class: "dropdown-item" %>
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="javascript:void(0)"><%= t 'header.menu.home'%></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="javascript:void(0)"><%= t 'header.menu.about_us'%></a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="javascript:void(0)"><%= t 'header.menu.service'%></a>
      </li>
      <li class="nav-item">
        <%= link_to (t 'user.user_text'), users_path, class: "nav-link" %>
      </li>
    </ul>
    <form class="form-inline my-2 my-lg-0">
      <input class="form-control mr-sm-2" type="text" placeholder="<%= t 'header.menu.search'%>">
      <button class="btn btn-success my-2 my-sm-0" type="button"><%= t 'header.menu.search'%></button>
    </form>
  </div>
</nav>`;

public code13 =`en:
header:
  menu:
    language: 'Language'
    home: 'Home'
    about_us: 'About Us'
    service: 'Service from %{my_name}'`;

public code14 =`Rails.application.routes.draw do
scope "/:locale" do
  resources :users
  resources :welcome, only: [:index]
end
root 'welcome#index'
# For details on the DSL available within this file, see http://guides.rubyonrails.org/routing.html
end`;

public code15 =`class ApplicationController < ActionController::Base
before_action :set_locale
private
  def set_locale
    I18n.locale = extract_locale || I18n.default_locale
  end

  def extract_locale
    parsed_locale = params[:locale]
    I18n.available_locales.map(&:to_s).include?(parsed_locale) ? parsed_locale : nil
  end

  def default_url_options
    { locale: I18n.locale }
  end
end`;

  constructor(private metaTagService: Meta, private titleService: Title, public sharedservice: sharedService, private hljsLoader: HighlightLoader) { }

  ngOnInit(): void {

    this.titleService.setTitle("Internationalization I18n in Ruby on Rails - TechCompose");

    this.metaTagService.updateTag({ name: 'keywords', content: 'In this Article we are going to built simple rails simple application to demonstrate the features of Rails Internationalization (I18n). We will cover very basic aspect of Internationalization.' });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Techcompose | Internationalization I18n in Ruby on Rails' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:url', content: `${this.currentBlogUrl}` });
    this.metaTagService.updateTag({ property: 'og:image', content: `${this.baseUrl}/assets/images/blog/international-ror.png` });
    this.metaTagService.updateTag({ property: 'og:description', content: 'In this Article we are going to built simple rails simple application to demonstrate the features of Rails Internationalization (I18n). We will cover very basic aspect of Internationalization.' });
    this.metaTagService.removeTag("name='google-site-verification'");

    this.sharedservice.showHeaderMenu$.subscribe((data) => {
      this.isHeaderMenuOpen = data;
      if (this.bodyScrollBar) {
        this.bodyScrollBar.updatePluginOptions('modal', { open: this.isHeaderMenuOpen });
      }
    });

    gsap.registerPlugin(ScrollTrigger);
    const scroller = document.querySelector('.scroller')as HTMLElement;

    this.bodyScrollBar = Scrollbar.init(scroller, { damping: 0.1, delegateTo: document, alwaysShowTracks: true });

    const content = this;
    ScrollTrigger.scrollerProxy(".scroller", {
      scrollTop(value) {
        if (content.bodyScrollBar) {
          if (arguments.length) {
            content.bodyScrollBar.scrollTop = value || 0;
          }
          return content.bodyScrollBar.scrollTop;
        } else {
          return 0;
        }
      }
    });

    this.bodyScrollBar.addListener(ScrollTrigger.update);
    ScrollTrigger.defaults({ scroller: scroller });

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    ScrollTrigger.create({
      trigger: ".header-class",
      start: "+50px",
      end: "bottom -=1000%",
      toggleClass: { targets: ".header", className: "active" }
    });
  }

}

class ModalPlugin extends ScrollbarPlugin {
  static pluginName = 'modal';

  static defaultOptions = {
    isHeaderMenuOpen: false
  };

  transformDelta(delta: any) {
      return this.options.open ? { x: 0, y: 0 } : delta;
  }
}

Scrollbar.use(ModalPlugin, /* OverscrollPlugin */);
