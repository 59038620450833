import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-share-blog-buttons',
  templateUrl: './share-blog-buttons.component.html',
  styleUrls: ['./share-blog-buttons.component.scss']
})
export class ShareBlogButtonsComponent implements OnInit {

  @Input() public currentBlogUrl: string = '';

  @Input() public currentBlogTitle: string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
