<div class="loader">
    <i class="d-none">
        <svg width="676" height="676" viewBox="0 0 676 676" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <!-- <path fill-rule="evenodd" clip-rule="evenodd" d="M385.421 275.73L428.218 318.241L385.421 360.752L370.786 346.215L398.949 318.241L370.786 290.267L385.421 275.73Z" fill="#3F5C76"/> -->
                <path class="code-left" d="M259.79 283.026L224.39 318.238L318.996 412.088L363.469 368.046" stroke="#3F5C76" stroke-width="20.6"/>
                <!-- <path fill-rule="evenodd" clip-rule="evenodd" d="M252.583 275.73L209.785 318.241L252.583 360.752L267.218 346.215L239.055 318.241L267.218 290.267L252.583 275.73Z" fill="#12B495"/> -->
                <path class="Tech" fill-rule="evenodd" clip-rule="evenodd" d="M369.822 263.91L349.236 284.468L329.309 284.468L329.314 358.642L318.922 368.858L308.626 358.639V284.468L288.661 284.468L268.075 263.91H369.822Z" fill="#12B495"/>
                <path class="code-right" d="M378.207 283.077L413.64 318.317L378.167 353.518" stroke="#3F5C76" stroke-width="20.6"/>
                <!-- <path fill-rule="evenodd" clip-rule="evenodd" d="M625.386 275.353L668.184 318.151L625.386 360.949L610.751 346.314L638.914 318.151L610.751 289.988L625.386 275.353Z" fill="#3F5C76"/> -->
                <path class="l-shape" d="M89.6978 354.651H138.845V338.742H109.442V281.924H89.6978V354.651Z" fill="#3F5C76"/>
                <path class="o-shape" d="M173.389 355.646C191.145 355.646 201.656 344.282 201.656 327.521C201.656 310.759 191.145 299.396 173.389 299.396C155.633 299.396 145.122 310.759 145.122 327.521C145.122 344.282 155.633 355.646 173.389 355.646ZM173.531 341.157C168.276 341.157 165.151 335.937 165.151 327.379C165.151 318.82 168.276 313.6 173.531 313.6C178.503 313.6 181.628 318.82 181.628 327.379C181.628 335.937 178.503 341.157 173.531 341.157Z" fill="#3F5C76"/>
                <path class="a-shape" d="M224.81 355.504C231.912 355.504 237.274 353.089 240.577 346.981H241.003V354.651H259.327V317.293C259.327 307.208 249.774 299.396 234.185 299.396C217.885 299.396 209.859 307.918 209.185 317.861H227.224C227.686 314.488 230.172 312.89 233.901 312.89C237.31 312.89 239.724 314.452 239.724 317.293V317.435C239.724 320.596 236.244 321.59 227.082 322.265C215.825 323.082 207.054 327.627 207.054 339.594C207.054 350.461 214.369 355.504 224.81 355.504ZM231.202 343.146C227.864 343.146 225.52 341.512 225.52 338.458C225.52 335.653 227.508 333.486 232.054 332.776C235.179 332.279 237.807 331.64 239.866 330.788V335.333C239.866 340.305 235.641 343.146 231.202 343.146Z" fill="#3F5C76"/>
                <path class="d-shape" d="M288.437 355.361C296.108 355.361 301.363 351.242 303.636 345.702H304.062V354.651H323.522V281.924H303.92V309.623H303.636C301.505 304.083 296.392 299.396 288.295 299.396C277.5 299.396 266.988 307.634 266.988 327.379C266.988 346.271 276.789 355.361 288.437 355.361ZM295.681 340.305C290.284 340.305 287.159 335.475 287.159 327.379C287.159 319.282 290.284 314.452 295.681 314.452C301.079 314.452 304.346 319.282 304.346 327.379C304.346 335.333 301.079 340.305 295.681 340.305Z" fill="#3F5C76"/>
                <path class="i-shape" d="M333.146 354.651H352.748V300.106H333.146V354.651ZM342.947 294.424C348.274 294.424 352.606 290.411 352.606 285.475C352.606 280.539 348.274 276.526 342.947 276.526C337.62 276.526 333.288 280.539 333.288 285.475C333.288 290.411 337.62 294.424 342.947 294.424Z" fill="#3F5C76"/>
                <path class="n-shape" d="M382.338 323.969C382.374 318.572 385.428 315.305 390.293 315.305C395.229 315.305 398.141 318.572 398.105 323.969V354.651H417.707V319.85C417.743 307.989 410.25 299.396 398.673 299.396C390.648 299.396 384.291 303.586 381.912 310.475H381.344V300.106H362.736V354.651H382.338V323.969Z" fill="#3F5C76"/>
                <path class="g-shape" d="M453.663 376.242C470.921 376.242 482.356 368.359 482.356 354.509V300.106H462.754V309.623H462.327C460.197 304.083 455.083 299.396 446.986 299.396C436.191 299.396 425.68 307.634 425.68 327.379C425.68 346.271 435.481 353.941 447.129 353.941C454.515 353.941 460.339 350.816 462.469 345.276H463.038V354.509C463.038 360.937 459.309 363.032 454.231 363.032C449.721 363.032 446.418 361.682 445.85 358.202H426.958C427.704 368.714 437.256 376.242 453.663 376.242ZM454.373 340.021C448.975 340.021 445.85 335.475 445.85 327.379C445.85 319.282 448.975 314.452 454.373 314.452C459.771 314.452 463.038 319.282 463.038 327.379C463.038 335.333 459.771 340.021 454.373 340.021Z" fill="#3F5C76"/>
                <g class="dot1-group"><path class="dot1" d="M502.979 355.788C508.199 355.788 512.851 351.384 512.922 345.844C512.851 340.447 508.199 336.043 502.979 336.043C497.475 336.043 492.965 340.447 493.036 345.844C492.965 351.384 497.475 355.788 502.979 355.788Z" fill="#3F5C76"/></g>
                <g class="dot2-group"><path class="dot2" d="M534.229 355.788C539.449 355.788 544.101 351.384 544.172 345.844C544.101 340.447 539.449 336.043 534.229 336.043C528.725 336.043 524.215 340.447 524.286 345.844C524.215 351.384 528.725 355.788 534.229 355.788Z" fill="#3F5C76"/></g>
                <g class="dot3-group"><path class="dot3" d="M565.479 355.788C570.699 355.788 575.351 351.384 575.422 345.844C575.351 340.447 570.699 336.043 565.479 336.043C559.975 336.043 555.465 340.447 555.536 345.844C555.465 351.384 559.975 355.788 565.479 355.788Z" fill="#3F5C76"/></g>
                <!-- <path fill-rule="evenodd" clip-rule="evenodd" d="M42.5438 275.353L-0.254013 318.151L42.5438 360.949L57.1787 346.314L29.0158 318.151L57.1787 289.988L42.5438 275.353Z" fill="#12B495"/> -->
            </g>
        </svg>
    </i>

    <div class="loader-overlay"></div>

    <div class="tech-logo-anim">
        <div class="tech-logo-grid box1 grid-col-1"><div class="tech-logo-box"></div></div>
        <div class="tech-logo-grid box2 grid-col-2"><div class="tech-logo-box"></div></div>
        <div class="tech-logo-grid box3 grid-col-2"><div class="tech-logo-box"></div></div>
        <div class="tech-logo-grid box4 grid-col-3"><div class="tech-logo-box"></div></div>
        <div class="tech-logo-grid box5 grid-col-3"><div class="tech-logo-box"></div></div>
        <div class="tech-logo-grid box6 grid-col-3"><div class="tech-logo-box"></div></div>
        <div class="tech-logo-grid box7 grid-col-4"><div class="tech-logo-box"></div></div>
        <div class="tech-logo-grid box8 grid-col-4"><div class="tech-logo-box"></div></div>
        <div class="tech-logo-grid box9 grid-col-4"><div class="tech-logo-box"></div></div>
        <div class="tech-logo-grid box10 grid-col-5"><div class="tech-logo-box"></div></div>
        <div class="tech-logo-grid box11 grid-col-5"><div class="tech-logo-box"></div></div>
        <div class="tech-logo-grid box12 grid-col-5"><div class="tech-logo-box"></div></div>
        <div class="tech-logo-grid box13 grid-col-5"><div class="tech-logo-box"></div></div>
        <div class="tech-logo-grid box14 grid-col-6"><div class="tech-logo-box"></div></div>
        <div class="tech-logo-grid box15 grid-col-6"><div class="tech-logo-box"></div></div>
        <div class="tech-logo-grid box16 grid-col-6"><div class="tech-logo-box"></div></div>
        <div class="tech-logo-grid box17 grid-col-6"><div class="tech-logo-box"></div></div>
        <div class="tech-logo-grid box18 grid-col-7"><div class="tech-logo-box"></div></div>
        <div class="tech-logo-grid box19 grid-col-7"><div class="tech-logo-box"></div></div>
        <div class="tech-logo-grid box20 grid-col-7"><div class="tech-logo-box"></div></div>
        <div class="tech-logo-grid box21 grid-col-7"><div class="tech-logo-box"></div></div>
        <div class="tech-logo-grid box22 grid-col-8"><div class="tech-logo-box"></div></div>
        <div class="tech-logo-grid box23 grid-col-8"><div class="tech-logo-box"></div></div>
        <div class="tech-logo-grid box24 grid-col-8"><div class="tech-logo-box"></div></div>
        <div class="tech-logo-grid box25 grid-col-9"><div class="tech-logo-box"></div></div>
        <div class="tech-logo-grid box26 grid-col-9"><div class="tech-logo-box"></div></div>
        <div class="tech-logo-grid box27 grid-col-9"><div class="tech-logo-box"></div></div>
        <div class="tech-logo-grid box28 grid-col-10"><div class="tech-logo-box"></div></div>
        <div class="tech-logo-grid box29 grid-col-10"><div class="tech-logo-box"></div></div>
        <div class="tech-logo-grid box30 grid-col-11"><div class="tech-logo-box"></div></div>
    </div>

</div>