import { Component, OnInit } from '@angular/core';

import { Title, Meta } from '@angular/platform-browser';
import gsap from 'gsap';
import Scrollbar, { ScrollbarPlugin } from 'smooth-scrollbar';
import { sharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-content-vs-design',
  templateUrl: './content-vs-design.component.html',
  styleUrls: ['./content-vs-design.component.scss']
})
export class ContentVsDesignComponent implements OnInit {

  bodyScrollBar: Scrollbar | undefined;
  public isHeaderMenuOpen: boolean = false;
  public currentBlogUrl: string = window.location.href;
  public baseUrl: string = window.location.origin;

  constructor(private metaTagService: Meta, private titleService: Title, public sharedservice: sharedService) { }

  ngOnInit(): void {

    this.titleService.setTitle("Content Vs Design: 7 points to consider by web designer before creating a website");

    this.metaTagService.updateTag({ name: 'keywords', content: ' Content Vs Design: 7 points to consider by web designer before creating a website. Read this blog to know more about latest web design trends for website. ' });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Techcompose | Content Vs Design: 7 points to consider by web designer before creating a website' });
    this.metaTagService.updateTag( { property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:url', content: `${this.currentBlogUrl}` });
    this.metaTagService.updateTag({ property: 'og:image', content: `${this.baseUrl}/assets/images/blog/content-vs-design.png` });
    this.metaTagService.updateTag({ property: 'og:description', content: 'Content Vs Design: 7 points to consider by web designer before creating a website. Read this blog to know more about latest web design trends for website.' })
    this.metaTagService.removeTag("name='google-site-verification'");

    this.sharedservice.showHeaderMenu$.subscribe((data) => {
      this.isHeaderMenuOpen = data;
      if (this.bodyScrollBar) {
        this.bodyScrollBar.updatePluginOptions('modal', { open: this.isHeaderMenuOpen });
      }
    });

    gsap.registerPlugin(ScrollTrigger);
    const scroller = document.querySelector('.scroller')as HTMLElement;

    this.bodyScrollBar = Scrollbar.init(scroller, { damping: 0.1, delegateTo: document, alwaysShowTracks: true });

    const content = this;
    ScrollTrigger.scrollerProxy(".scroller", {
      scrollTop(value) {
        if (content.bodyScrollBar) {
          if (arguments.length) {
            content.bodyScrollBar.scrollTop = value || 0;
          }
          return content.bodyScrollBar.scrollTop;
        } else {
          return 0;
        }
      }
    });

    this.bodyScrollBar.addListener(ScrollTrigger.update);
    ScrollTrigger.defaults({ scroller: scroller });

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    ScrollTrigger.create({
      trigger: ".header-class",
      start: "+50px",
      end: "bottom -=1000%",
      toggleClass: { targets: ".header", className: "active" }
    });

  }

}
class ModalPlugin extends ScrollbarPlugin {
  static pluginName = 'modal';

  static defaultOptions = {
    isHeaderMenuOpen: false
  };

  transformDelta(delta: any) {
      return this.options.open ? { x: 0, y: 0 } : delta;
  }
}

Scrollbar.use(ModalPlugin, /* OverscrollPlugin */);
