<app-header></app-header>
<app-header-menu *ngIf="isHeaderMenuOpen"></app-header-menu>
<div class="scroller">
  <div class="header-class"></div>
  <div class="page-grid-block">
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
  </div>
  <div class="grid-background-overlay"></div>
  <section class="blog-hero">
      <app-tech-logo></app-tech-logo>
      <div class="blog-hero-container">
          <div class="blog-hero-block">
            <div class="back-btn-block">
                <a [routerLink]="['/blogs']" class="back-btn">
                    <i>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.828 11.0007H22V13.0007H5.828L11.192 18.3647L9.778 19.7787L2 12.0007L9.778 4.22266L11.192 5.63666L5.828 11.0007Z" fill="black"/>
                        </svg>
                    </i>
                    Back to Blogs
                </a>
            </div>
              <div class="blog-hero-inner-block">
                  <div class="page-title">
                      <h1>Internationalization I18n in Ruby on Rails</h1>
                  </div>
              </div>
          </div>
      </div>
  </section>
  <section class="blog-wrapper">
      <div class="blog-detail-row">
          <div class="blog-detail-box">
              <figure>
                  <picture>
                      <img src="assets/images/blog/international-ror.webp" alt="international ror">
                  </picture>
              </figure>
              <p>In this Article we are going to built simple rails simple application to demonstrate the features of Rails Internationalization (I18n). We will cover very basic aspect of Internationalization.</p>
              <h2>What is Internationalization?</h2>
              <p>Internationalization means developing the application that can adapt any language easily without making any complex changes. Internationalization can be apply on string, date and currency format of the rails application web page.</p>
              <h2>Let’s build a demo Rails App with I18n</h2>
              <p>We are using I18 since the early version rails 2.2. It provides the framework that makes so easy to translate the App in various languages. Let’s create one demo. Apply the following steps as written and you will get the nice demo app at the end of this article.</p>
              <p>Step:1 Create rails application using below command and I am using rails 5.2 version</p>
              <pre><code [highlight]="code1" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
              <p>Step2: Go inside directory and bundle install</p>
              <pre><code [highlight]="code2" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
              <pre><code [highlight]="code3" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
              <p>Step3: Generate controller</p>
              <pre><code [highlight]="code4" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
              <p>Step4: Create the CRUD using following command</p>
              <pre><code [highlight]="code5" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
              <p>Step5: Migrate database and start rails server</p>
              <pre><code [highlight]="code6" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
              <pre><code [highlight]="code7" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
              <p>Step6: Configuration for I18</p>
              <pre><code [highlight]="code8" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
              <pre><code [highlight]="code9" [languages]="['typescript']" [lineNumbers]="true"></code></pre>

              <p>Gem “rails-i18n” use for internationalization</p>
              <p>Gem “httparty” use for third party API</p>
              <p>Step7: Install the bundle and restart the server</p>
              <h2>How to store the Translations?</h2>
              <p>Inside config/locales</p>
              <p>There is one default file en.yml and you may create other file for each language</p>
              <figure>
                  <picture>
                      <img src="assets/images/blog/blog-detail/international-ror-1.png" alt="international ror">
                  </picture>
              </figure>
              <pre><code [highlight]="code10" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
              <pre><code [highlight]="code11" [languages]="['typescript']" [lineNumbers]="true"></code></pre>

              <h2>How to use this string in header html file?</h2>
              <pre><code [highlight]="code12" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
              <h2>t ‘header.menu.home’</h2>
              <p>Create custom message with your text</p>
              <h2>t ‘header.menu.service’my-name:’techcompose’</h2>
              <pre><code [highlight]="code13" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
              <p>Change the language</p>
              <p><a class="markup--anchor markup--p-anchor" href="#" target="_blank" rel="noopener noreferrer" data-href="http://localhost:3000/en/welcomes">http://localhost:3000/en/welcomes</a></p>
              <p><a class="markup--anchor markup--p-anchor" href="#" target="_blank" rel="noopener noreferrer" data-href="http://localhost:3000/hi/welcomes">http://localhost:3000/hi/welcomes</a></p>
              <pre><code [highlight]="code14" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
              <p>And inside application controller set locale param</p>
              <pre><code [highlight]="code15" [languages]="['typescript']" [lineNumbers]="true"></code></pre>
              <h2>How to switch language from html?</h2>
              <p>_header.html.erb</p>
              <p>&lt;%= link_to ‘English’, url_for(locale: :en), class: “dropdown-item” %&gt;<br>&lt;%= link_to ‘Russisch’, url_for(locale: :ru), class: “dropdown-item” %&gt;<br>&lt;%= link_to ‘हिंदी’, url_for(locale: :hi), class: “dropdown-item” %&gt;<br>&lt;%= link_to ‘Español’, url_for(locale: :es), class: “dropdown-item” %&gt;<br>&lt;%= link_to ‘Nederlands’, url_for(locale: :nl), class: “dropdown-item” %&gt;</p>
              <p>Contact Ruby on Rails Development Company to develop your Business mobile app or web application with elegant design. Contact us to Hire dedicated ROR developer today or reach us at <a class="markup--anchor markup--p-anchor" href="mailto:inquiry@techcompose.com" target="_blank" rel="noopener noreferrer" data-href="mailto:inquiry@techcompose.com">inquiry@techcompose.com</a> for any assistance regarding ROR development requirement.</p>
          </div>
          <app-share-blog-buttons
              [currentBlogUrl]="currentBlogUrl"
              [currentBlogTitle]="'Internationalization I18n in Ruby on Rails - TechCompose'">
          </app-share-blog-buttons>
      </div>
  </section>
  <app-footer></app-footer>
</div>
