import { AfterViewInit, Component, OnInit } from '@angular/core';
import SplitType from 'split-type';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { Title, Meta } from '@angular/platform-browser';
import Scrollbar, { ScrollbarPlugin } from 'smooth-scrollbar';
import { sharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-it-services',
  templateUrl: './it-services.component.html',
  styleUrls: ['./it-services.component.scss']
})
export class ItServicesComponent implements AfterViewInit, OnInit {

  bodyScrollBar: Scrollbar | undefined;
  public isHeaderMenuOpen: boolean = false;
  public currentUrl: string = window.location.href;
  public baseUrl: string = window.location.origin;

  constructor(private metaTagService: Meta, private titleService: Title, public sharedservice: sharedService) { }

  ngOnInit() {
    this.titleService.setTitle("Hire Software Developers | Top Software Development Company");

    this.metaTagService.updateTag({ name: 'keywords', content: 'Hire top software developers from our renowned software development company. With a proven track record of success, we offer unparalleled expertise and innovation to drive your projects forward.' }),
    this.metaTagService.updateTag({ property: 'og:title', content: 'Hire Software Developers | Top Software Development Company' }),
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' }),
    this.metaTagService.updateTag({ property: 'og:url', content: `${this.currentUrl}` }),
    this.metaTagService.updateTag({ property: 'og:image', content: `${this.baseUrl}/assets/images/thumb.png` });
    this.metaTagService.updateTag({ property: 'og:description', content: 'Hire top software developers from our renowned software development company. With a proven track record of success, we offer unparalleled expertise and innovation to drive your projects forward.' });
    this.metaTagService.removeTag("name='google-site-verification'");

    this.sharedservice.showHeaderMenu$.subscribe((data) => {
      this.isHeaderMenuOpen = data;
      if (this.bodyScrollBar) {
        this.bodyScrollBar.updatePluginOptions('modal', { open: this.isHeaderMenuOpen });
      }
    });

    gsap.registerPlugin(ScrollTrigger);
    const scroller = document.querySelector('.scroller')as HTMLElement;

    this.bodyScrollBar = Scrollbar.init(scroller, { damping: 0.1, delegateTo: document, alwaysShowTracks: true });

    const content = this;
    ScrollTrigger.scrollerProxy(".scroller", {
      scrollTop(value) {
        if (content.bodyScrollBar) {
          if (arguments.length) {
            content.bodyScrollBar.scrollTop = value || 0;
          }
          return content.bodyScrollBar.scrollTop;
        } else {
          return 0;
        }
      }
    });

    this.bodyScrollBar.addListener(ScrollTrigger.update);
    ScrollTrigger.defaults({ scroller: scroller });
  }

  public ngAfterViewInit(): void {
    gsap.registerPlugin(ScrollTrigger);
    gsap.to('.bannerImgBlock', {duration: 0.5, width: 'auto', ease: 'power1', delay: 1});
    gsap.to('.bannerImgOverlay', {duration: 1, width: '0', ease: 'power1', delay: 0.7});
    gsap.to('.service-in-info-detail', { opacity: 1, y: 0, duration: 0.5, delay: 0.7, ease: 'power1'});
    const aboutpagetitle = new SplitType('.page-title h1', {
      types: 'chars, words',
      absolute: true
    });
    gsap.from(aboutpagetitle.chars, {
      opacity: 0,
      x: 20,
      duration: 0.7,
      delay: 0.6,
      stagger: 0.03,
    });

    const servicesImg: any = gsap.utils.toArray('.service-row');

    // Set things up
    gsap.set(servicesImg, {autoAlpha: 0, y: 50});
    servicesImg.forEach((box: any) => {
      // Set up your animation
      const anim = gsap.to(box, { duration: 0.5, autoAlpha: 1, y: 0, delay: 0.25, paused: true });

      // Use callbacks to control the state of the animation
      ScrollTrigger.create({
        trigger: box,
        start: 'top 90%',
        once: true,
        onEnter: self => {
          // If it's scrolled past, set the state
          // If it's scrolled to, play it
          self.progress === 1 ? anim.progress(1) : anim.play();
        }
      });
    });
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
    });

    ScrollTrigger.create({
      trigger: ".header-class",
      start: "+50px",
      end: "bottom -=1000%",
      toggleClass: { targets: ".header", className: "active" }
    });

  }
}
class ModalPlugin extends ScrollbarPlugin {
  static pluginName = 'modal';

  static defaultOptions = {
    isHeaderMenuOpen: false
  };

  transformDelta(delta: any) {
      return this.options.open ? { x: 0, y: 0 } : delta;
  }
}

Scrollbar.use(ModalPlugin, /* OverscrollPlugin */);
