import { AfterViewInit, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import SplitType from 'split-type';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger'; 
import { Title, Meta } from '@angular/platform-browser';
import Scrollbar, { ScrollbarPlugin } from 'smooth-scrollbar';
import { sharedService } from '../../shared/shared.service';
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements AfterViewInit, OnDestroy, OnInit {
  private listeners: any[] = [];

  bodyScrollBar: Scrollbar | undefined;
  public isHeaderMenuOpen: boolean = false;
  public currentUrl: string = window.location.href;
  public baseUrl: string = window.location.origin;

  constructor(private renderer: Renderer2, private metaTagService: Meta, private titleService: Title, public sharedservice: sharedService ) { }

  ngOnInit() {
    this.titleService.setTitle("Web Development and Design Company India | IT Solutions USA");

    this.metaTagService.updateTag({ name: 'keywords', content: 'Techcompose is a Top Web Development and Design Company in India and USA that offers IT Solutions like Ruby on Rails, PHP, Wordpress, Angular, Android, iOS.' });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Techcompose | Web Development and Design Company India | IT Solutions USA' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:url', content: `${this.currentUrl}` });
    this.metaTagService.updateTag({ property: 'og:image', content: `${this.baseUrl}/assets/images/thumb.png` });
    this.metaTagService.updateTag({ property: 'og:description', content: 'Techcompose is a Top Web Development and Design Company in India and USA that offers IT Solutions like Ruby on Rails, PHP, Wordpress, Angular, Android, iOS.' });
    this.metaTagService.removeTag("name='google-site-verification'");

    this.sharedservice.showHeaderMenu$.subscribe((data) => {
      this.isHeaderMenuOpen = data;
      if (this.bodyScrollBar) {
        this.bodyScrollBar.updatePluginOptions('modal', { open: this.isHeaderMenuOpen });
      }
    });

    gsap.registerPlugin(ScrollTrigger);
      const scroller = document.querySelector('.scroller')as HTMLElement;

      this.bodyScrollBar = Scrollbar.init(scroller, { damping: 0.1, delegateTo: document, alwaysShowTracks: true });

      const content = this;
      ScrollTrigger.scrollerProxy(".scroller", {
        scrollTop(value) {
          if (content.bodyScrollBar) {
            if (arguments.length) {
              content.bodyScrollBar.scrollTop = value || 0;
            }
            return content.bodyScrollBar.scrollTop;
          } else {
            return 0;
          }
        }
      });

      this.bodyScrollBar.addListener(ScrollTrigger.update);
      ScrollTrigger.defaults({ scroller: scroller });
  }

  public ngAfterViewInit(): void {
    gsap.registerPlugin(ScrollTrigger);
    gsap.fromTo('.about-banner-detail p', {
      opacity: 0,
      y: 30
    }, {
      y: 0,
      opacity: 1,
      duration: 0.5,
      ease: 'power1',
      delay: 0.5
    });

    const aboutpagetitle = new SplitType('.about-header-info h1', {
      types: 'chars, words',
      absolute: true
    });

    gsap.from(aboutpagetitle.chars, {
      opacity: 0,
      x: 20,
      duration: 0.7,
      delay: 0.2,
      stagger: 0.03
    });

    /* counter start */
    const items = document.querySelectorAll('.counter span');

    const counteranim = gsap.from(items, {
      textContent: 0,
      duration: 1,
      delay: 0.5,
      ease: Power1.easeIn,
      snap: { textContent: 1 },
    });

    ScrollTrigger.create({
      trigger: '.exp-counter-block',
      start: 'top 50%',
      animation: counteranim,
    });
    const counterShow = gsap.from(".exp-counter-block li", {
      duration: 1,
      opacity: 0,
    });

    ScrollTrigger.create({
      trigger: '.exp-counter-block',
      start: 'top 50%',
      animation: counterShow,
    });

    /* counter end */

    const info: any = gsap.utils.toArray('.about-info');

    // Set things up
    gsap.set(info, {autoAlpha: 0, y: 50});
    info.forEach((box: any) => {
      // Set up your animation
      const anim = gsap.to(box, { duration: 1, autoAlpha: 1, y: 0, paused: true });

      // Use callbacks to control the state of the animation
      ScrollTrigger.create({
        trigger: box,
        start: 'top 90%',
        once: true,
        onEnter: self => {
          // If it's scrolled past, set the state
          // If it's scrolled to, play it
          self.progress === 1 ? anim.progress(1) : anim.play();
        }
      });
    });

    // Title animation start
    const abouttitles = new SplitType('.title-letter-anim', {
      types: 'words chars',
      absolute: true
    });

    const letteranim: any = gsap.utils.toArray('.title-letter-anim .char');

    // Set things up
    gsap.set(letteranim, {autoAlpha: 0, x: 20});

    letteranim.forEach((textanim: any) => {
      // Set up your animation
      const anim = gsap.to(textanim, { duration: 1, autoAlpha: 1, stagger: 0.5, x: 0, paused: true });

      // Use callbacks to control the state of the animation
      ScrollTrigger.create({
        trigger: textanim,
        start: 'top 85%',
        once: true,
        onEnter: self => {
          // If it's scrolled past, set the state
          // If it's scrolled to, play it
          self.progress === 1 ? anim.progress(1) : anim.play();
        }
      });
    });

    const aboutBigtitles = new SplitType('.section-title-big', {
      types: 'words chars',
      absolute: true
    });

    const letterBiganim: any = gsap.utils.toArray('.section-title-big .char');

    // Set things up
    gsap.set(letterBiganim, {autoAlpha: 0, x: 20});

    letterBiganim.forEach((textBiganim: any) => {
      // Set up your animation
      const anim = gsap.to(textBiganim, { duration: 1, autoAlpha: 1, stagger: 0.5, x: 0, paused: true });

      // Use callbacks to control the state of the animation
      ScrollTrigger.create({
        trigger: textBiganim,
        start: 'top 85%',
        once: true,
        onEnter: self => {
          // If it's scrolled past, set the state
          // If it's scrolled to, play it
          self.progress === 1 ? anim.progress(1) : anim.play();
        }
      });
    });

    gsap.to('.best-it-title .char',{duration: 0.1, autoAlpha: 1, stagger: 0.05, x: 0, delay: 1, scrollTrigger: {trigger: '.best-it-title .char', start: 'top 95%'}})

    gsap.fromTo(".about-who-img",{autoAlpha: 0,},{autoAlpha: 1, duration: 1, scrollTrigger :{trigger: '.about-who-img',start: 'top 90%'}})

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    ScrollTrigger.create({
      trigger: ".header-class",
      start: "+50px",
      end: "bottom -=1000%",
      toggleClass: { targets: ".header", className: "active" }
    });

    gsap.fromTo('.service-row', {
      autoAlpha: 0, y: 50
    },{
      duration: 0.5, autoAlpha: 1, y: 0,
      scrollTrigger:{
        trigger: '.service-row',
        start: 'top 80%'
      }
    });

  }

  public ngOnDestroy(): void {
    this.listeners.forEach(listener => listener());
  }
}

class ModalPlugin extends ScrollbarPlugin {
  static pluginName = 'modal';

  static defaultOptions = {
    isHeaderMenuOpen: false
  };

  transformDelta(delta: any) {
      return this.options.open ? { x: 0, y: 0 } : delta;
  }
}

Scrollbar.use(ModalPlugin, /* OverscrollPlugin */);
