<div class="techMenu">
    <div class="techMenuInnerBlock">
        <div class="MenuBlock">
            <ul class="MenuListBlock">
                <li class="MenuItem">
                    <div class="submentBtnBlock">
                        <a [routerLink]="['/about-us']" routerLinkActive="active">About</a>
                        <button type="button" class="about-sub-menu-btn">
                            <i class="downarrow">
                                <svg width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.78787 3.78787L0.512132 0.512132C0.323143 0.323143 0.456993 0 0.724264 0H7.27574C7.54301 0 7.67686 0.323142 7.48787 0.512131L4.21213 3.78787C4.09497 3.90503 3.90503 3.90503 3.78787 3.78787Z" fill="currentColor"/>
                                </svg>
                            </i>
                        </button>
                    </div>
                    <ul class="submenu about-sub-menu">
                        <li class="submenu-item"><a [routerLink]="['/about-us', 'happy-super-and-effective-team']" routerLinkActive="active">Happy, Super & Effective Team</a></li>
                        <!-- <li class="submenu-item"><a [routerLink]="['/about-us', 'our-process']" routerLinkActive="active">Our Process</a></li> -->
                        <li class="submenu-item"><a [routerLink]="['/our-testimonials']" routerLinkActive="active">Testimonial</a></li>
                    </ul>
                </li>
                <li class="MenuItem">
                    <div class="submentBtnBlock">
                        <a [routerLink]="['/it-services']" routerLinkActive="active">Services</a>
                        <button type="button" class="submenuBtn">
                            <i class="downarrow">
                                <svg width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.78787 3.78787L0.512132 0.512132C0.323143 0.323143 0.456993 0 0.724264 0H7.27574C7.54301 0 7.67686 0.323142 7.48787 0.512131L4.21213 3.78787C4.09497 3.90503 3.90503 3.90503 3.78787 3.78787Z" fill="currentColor"/>
                                </svg>
                            </i>
                        </button>
                    </div>
                    <ul class="submenu services-sub-menu">                        
                        <li class="submenu-item"><a [routerLink]="['/it-services', 'start-up-remote-work-rdc']" routerLinkActive="active">Start-Up Remote Work RDC</a></li>
                        <li class="submenu-item"><a [routerLink]="['/it-services', 'web-applications']" routerLinkActive="active">Web Applications</a></li>
                        <li class="submenu-item"><a [routerLink]="['/it-services', 'mobile-apps']" routerLinkActive="active">Mobile Apps</a></li>
                        <li class="submenu-item"><a [routerLink]="['/it-services', 'creative-and-functional-design']" routerLinkActive="active">Creative & Functional Design</a></li>
                        <li class="submenu-item"><a [routerLink]="['/it-services', 'websites']" routerLinkActive="active">Websites</a></li>
                    </ul>
                </li>
                <li class="MenuItem"><a [routerLink]="['/work']" routerLinkActive="active">Work</a></li>
                <li class="MenuItem"><a [routerLink]="['/careers']" routerLinkActive="active">Careers</a></li>
                <li class="MenuItem"><a [routerLink]="['/blogs']" routerLinkActive="active">Blogs</a></li>
                <li class="MenuItem"><a [routerLink]="['/contact-us']" routerLinkActive="active">Contact</a></li>
            </ul>
        </div>
    </div>
    <div class="social-menu">
        <app-social></app-social>
    </div>
</div>
