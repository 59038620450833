<div class="tech-logo-anim">
  <div class="tech-logo-grid box1 grid-col-1"><div class="tech-logo-box"></div></div>
  <div class="tech-logo-grid box2 grid-col-2"><div class="tech-logo-box"></div></div>
  <div class="tech-logo-grid box3 grid-col-2"><div class="tech-logo-box"></div></div>
  <div class="tech-logo-grid box4 grid-col-3"><div class="tech-logo-box"></div></div>
  <div class="tech-logo-grid box5 grid-col-3"><div class="tech-logo-box"></div></div>
  <div class="tech-logo-grid box6 grid-col-3"><div class="tech-logo-box"></div></div>
  <div class="tech-logo-grid box7 grid-col-4"><div class="tech-logo-box"></div></div>
  <div class="tech-logo-grid box8 grid-col-4"><div class="tech-logo-box"></div></div>
  <div class="tech-logo-grid box9 grid-col-4"><div class="tech-logo-box"></div></div>
  <div class="tech-logo-grid box10 grid-col-5"><div class="tech-logo-box"></div></div>
  <div class="tech-logo-grid box11 grid-col-5"><div class="tech-logo-box"></div></div>
  <div class="tech-logo-grid box12 grid-col-5"><div class="tech-logo-box"></div></div>
  <div class="tech-logo-grid box13 grid-col-5"><div class="tech-logo-box"></div></div>
  <div class="tech-logo-grid box14 grid-col-6"><div class="tech-logo-box"></div></div>
  <div class="tech-logo-grid box15 grid-col-6"><div class="tech-logo-box"></div></div>
  <div class="tech-logo-grid box16 grid-col-6"><div class="tech-logo-box"></div></div>
  <div class="tech-logo-grid box17 grid-col-6"><div class="tech-logo-box"></div></div>
  <div class="tech-logo-grid box18 grid-col-7"><div class="tech-logo-box"></div></div>
  <div class="tech-logo-grid box19 grid-col-7"><div class="tech-logo-box"></div></div>
  <div class="tech-logo-grid box20 grid-col-7"><div class="tech-logo-box"></div></div>
  <div class="tech-logo-grid box21 grid-col-7"><div class="tech-logo-box"></div></div>
  <div class="tech-logo-grid box22 grid-col-8"><div class="tech-logo-box"></div></div>
  <div class="tech-logo-grid box23 grid-col-8"><div class="tech-logo-box"></div></div>
  <div class="tech-logo-grid box24 grid-col-8"><div class="tech-logo-box"></div></div>
  <div class="tech-logo-grid box25 grid-col-9"><div class="tech-logo-box"></div></div>
  <div class="tech-logo-grid box26 grid-col-9"><div class="tech-logo-box"></div></div>
  <div class="tech-logo-grid box27 grid-col-9"><div class="tech-logo-box"></div></div>
  <div class="tech-logo-grid box28 grid-col-10"><div class="tech-logo-box"></div></div>
  <div class="tech-logo-grid box29 grid-col-10"><div class="tech-logo-box"></div></div>
  <div class="tech-logo-grid box30 grid-col-11"><div class="tech-logo-box"></div></div>
</div>
