<app-header></app-header>
<app-header-menu *ngIf="isHeaderMenuOpen"></app-header-menu>
<div class="scroller">
  <div class="header-class"></div>
  <div class="page-grid-block">
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
  </div>
  <div class="main-service-wrapper">
      <div class="background-overlay bg-services"></div>
      <section class="service-in-hero">
          <app-tech-logo></app-tech-logo>
          <div class="service-in-hero-container">
              <div class="service-in-hero-block">
                  <div class="service-in-deail-block">
                      <div class="page-title">
                          <h1>Websites</h1>
                      </div>
                      <div class="service-in-info-detail page-info">
                          <!-- <p>Techcompose is a Best Website Development Company in India and the USA that offers web design and development services matching customer’s business strategies and requirements. Through our extremely qualified and experienced IT developers, we can serve our clients with valuable website development services across all major technologies like WordPress, Magento, Joomla, Drupal, Shopify, PHP, Yii, Codeigniter etc.</p> -->
                          <ul>
                              <li>Engaging and Modern</li>
                              <li>Webflow / Wordpress / Wix</li>
                              <li>On-page SEO</li>
                              <li>Page loading performance</li>
                              <li>Tracking and Analytics</li>
                              <li>Secured and updated</li>
                              <li>Regular Backups</li>
                              <li>Multi-language support</li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      <section class="philosophie">
          <div class="wrapper">
              <div class="point point-1">
                  <article>
                      <div class="article-inner">
                          <h3>WordPress</h3>
                          <div class="article-desc-block">
                              <p>We totally understand that each client has his own specific requirements. We follow a process to understand the purpose the site, audience for the site and offer custom solution according to client's business needs. We, at TechCompose, offer various solutions considering client's budget as we always have more than one solutions and we always suggest effective solutions with time and cost associated with each of them.</p>
                          </div>
                          <a [routerLink]="[ '/', 'technologies', 'wordpress']">Read more
                              <i class="link-icon">
                                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M14.5687 7.92313C14.3312 7.68545 13.946 7.68545 13.7081 7.92313C13.4704 8.16062 13.4704 8.5461 13.7081 8.78378L18.3174 13.3931H6.0705C5.73339 13.3931 5.45996 13.6666 5.45996 14.0037C5.45996 14.341 5.73339 14.6142 6.0705 14.6142H18.3113L13.702 19.2236C13.5876 19.3375 13.5234 19.4926 13.5234 19.654C13.5234 19.8156 13.5876 19.9703 13.702 20.0844C13.8163 20.1997 13.9729 20.2636 14.1354 20.2615C14.2974 20.2607 14.4529 20.1972 14.5687 20.0844L20.216 14.4373C20.3309 14.3222 20.395 14.166 20.3932 14.0037C20.3921 13.8417 20.3286 13.6868 20.216 13.5704L14.5687 7.92313Z" fill="currentColor"/>
                                      <path d="M14.0072 0.877724C8.69816 0.876768 3.91155 4.0746 1.87993 8.97957C-0.151319 13.8847 0.972243 19.5305 4.72746 23.2834C9.8527 28.4086 18.162 28.4086 23.2871 23.2834C28.4121 18.1585 28.4121 9.84921 23.2871 4.72396C20.8313 2.25426 17.4899 0.86931 14.0072 0.877724ZM14.0072 25.9087C7.4442 25.9087 2.10231 20.5666 2.10231 14.0037C2.10231 7.4407 7.4442 2.0988 14.0072 2.0988C20.5701 2.0988 25.9122 7.4407 25.9122 14.0037C25.9122 20.5666 20.5701 25.9087 14.0072 25.9087Z" fill="currentColor"/>
                                  </svg>
                              </i>
                          </a>
                      </div>
                  </article>
                  <div class="services-img-block">
                      <img src="assets/images/services/sub-page/wordpress.webp" alt="Wordpress" width="1920" height="1275">
                  </div>
              </div>
              <!-- <div class="point">
                  <article>
                      <div class="article-inner">
                          <h3>Webflow</h3>
                          <div class="article-desc-block">
                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                          </div>
                          <a [routerLink]="[ '/', 'technologies', 'wordpress']">Read more
                              <i class="link-icon">
                                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M14.5687 7.92313C14.3312 7.68545 13.946 7.68545 13.7081 7.92313C13.4704 8.16062 13.4704 8.5461 13.7081 8.78378L18.3174 13.3931H6.0705C5.73339 13.3931 5.45996 13.6666 5.45996 14.0037C5.45996 14.341 5.73339 14.6142 6.0705 14.6142H18.3113L13.702 19.2236C13.5876 19.3375 13.5234 19.4926 13.5234 19.654C13.5234 19.8156 13.5876 19.9703 13.702 20.0844C13.8163 20.1997 13.9729 20.2636 14.1354 20.2615C14.2974 20.2607 14.4529 20.1972 14.5687 20.0844L20.216 14.4373C20.3309 14.3222 20.395 14.166 20.3932 14.0037C20.3921 13.8417 20.3286 13.6868 20.216 13.5704L14.5687 7.92313Z" fill="currentColor"/>
                                      <path d="M14.0072 0.877724C8.69816 0.876768 3.91155 4.0746 1.87993 8.97957C-0.151319 13.8847 0.972243 19.5305 4.72746 23.2834C9.8527 28.4086 18.162 28.4086 23.2871 23.2834C28.4121 18.1585 28.4121 9.84921 23.2871 4.72396C20.8313 2.25426 17.4899 0.86931 14.0072 0.877724ZM14.0072 25.9087C7.4442 25.9087 2.10231 20.5666 2.10231 14.0037C2.10231 7.4407 7.4442 2.0988 14.0072 2.0988C20.5701 2.0988 25.9122 7.4407 25.9122 14.0037C25.9122 20.5666 20.5701 25.9087 14.0072 25.9087Z" fill="currentColor"/>
                                  </svg>
                              </i>
                          </a>
                      </div>
                  </article>
                  <div class="services-img-block">
                      <img src="assets/images/services/sub-page/webflow.webp" alt="webflow">
                  </div>
              </div> -->

              <!-- <div class="point">
                  <article>
                      <div class="article-inner">
                          <h3>Maintenance</h3>
                          <div class="article-desc-block">
                              <p>Our website maintenance programs ensure that your website is always current, error-free, accessible and fully functional for your customers and your project stays within budget. Our standard program includes content and images update, and support via call and e-mail. We include all of the services you require in order to ensure that both short and long-term success of your online marketing. Apart from website maintenance contract Techcompose being website development company offers services like web deployment, server maintenance, and mobile app maintenance to make sure your business is uninterrupted.</p>
                          </div>
                      </div>
                  </article>
                  <div class="services-img-block">
                      <img src="assets/images/services/sub-page/services-sub-6.jpg">
                  </div>
              </div> -->
          </div>
      </section>
  </div>
  <app-footer></app-footer>
</div>
