import { Component, AfterViewInit } from '@angular/core';
import gsap from 'gsap/all';
import ScrollTrigger from 'gsap/ScrollTrigger';
import * as $ from 'jquery';

@Component({
  selector: 'app-framework',
  templateUrl: './framework.component.html',
  styleUrls: ['./framework.component.scss']
})
export class FrameworkComponent implements AfterViewInit {

  constructor() { }

  public ngAfterViewInit(): void {
    gsap.registerPlugin(ScrollTrigger);
      this.layoutForFramework();
      const items = document.querySelectorAll('.framework-info');
      items.forEach((el) => {
          const image = el.querySelector('.framework-img-inner');
          el.addEventListener('mouseenter', (e) => {
              gsap.to(image, { width: '100%', autoAlpha: 1 });
          });
          el.addEventListener('mouseleave', (e) => {
              gsap.to(image, { width: 0, autoAlpha: 0 });
          });
      });

      const framework: any = gsap.utils.toArray('.framework-list-block .framework-list .framework-info');
      gsap.set(framework, {autoAlpha: 0, y: 50, scale: 1.3});
      framework.forEach((frameworkIcon: any) => {
          const frameWorkAnim = gsap.to(frameworkIcon, { duration: 1, autoAlpha: 1, y: 0, scale: 1, paused: true });
          ScrollTrigger.create({
              trigger: '.framework-list-block .framework-list',
              start: 'top 60%',
              once: true,
              onEnter: self => {
                  self.progress === 1 ? frameWorkAnim.progress(1) : frameWorkAnim.play();
              }
          });
      });

    const listones = gsap.utils.toArray('.framework-icon-wrapper li');
        const tl = gsap.timeline({scrollTrigger: {
        trigger: '.framework-icon-wrapper ul',
        start: 'top 75%',
        end: 'bottom top',
    }}).fromTo(listones, {opacity: 0, y: 50, stagger: 0.1, duration: 0.5, ease: 'power1'},{opacity: 1, y: 0, stagger: 0.1, duration: 0.5, ease: 'power1'}, 0);

    gsap.config({
      nullTargetWarn: false,
    });
    const blockTitle: any = gsap.utils.toArray('.block-title');
    gsap.set(blockTitle, {autoAlpha: 0, y: 50});
    blockTitle.forEach((titleanimBlock: any) => {
        const titleAnim = gsap.to(titleanimBlock, { duration: 1, autoAlpha: 1, y: 0, paused: true });
        ScrollTrigger.create({
            trigger: titleanimBlock,
            start: 'top 80%',
            once: true,
            onEnter: self => {
                self.progress === 1 ? titleAnim.progress(1) : titleAnim.play();
            }
        });
    });

    const blockInfo: any = gsap.utils.toArray('.block-info');
    gsap.set(blockInfo, {autoAlpha: 0, y: 50});
    blockInfo.forEach((infoanimBlock: any) => {
        const infoAnim = gsap.to(infoanimBlock, { duration: 1, autoAlpha: 1, y: 0, paused: true,});
        ScrollTrigger.create({
            trigger: infoanimBlock,
            start: 'top 80%',
            once: true,
            onEnter: self => {
                self.progress === 1 ? infoAnim.progress(1) : infoAnim.play();
            }
        });
    });
  }

  // translate/rotate the grid items as we move the mouse
  public move(event: any): void {
      for (let i = 0; i < document.getElementsByClassName('framework-list').length; i++) {
          const frameworkListObj = document.getElementsByClassName('framework-list')[i];
          // Track the mouse position
          const mousepos = {x: event.clientX, y: event.clientY};
          const translationVals = {x: parseFloat(frameworkListObj.getAttribute('x-value') || '0'),
                                  y: parseFloat(frameworkListObj.getAttribute('y-value') || '0')};
          const rotationVals = {x: parseFloat(frameworkListObj.getAttribute('rx-value') || '0'),
                                y: parseFloat(frameworkListObj.getAttribute('ry-value') || '0')};
          // get random start and end translation/rotation boundaries
          // translation:
          const xstart = this.getRandomNumber(70, 100);
          const ystart = this.getRandomNumber(40, 65);
          // rotation:
          const rxstart = 5;
          const rystart = 10;
          // Calculate the amount to move.
          // Using linear interpolation to smooth things out.
          // Translation values will be in the range of [-start, start] for a cursor movement from 0 to the window's width/height
          translationVals.x = this.lerp(translationVals.x,
              this.mapXFromRange(mousepos.x, 0, window.innerWidth, -xstart, xstart), 0.04);
          translationVals.y = this.lerp(translationVals.y,
              this.mapXFromRange(mousepos.y, 0, window.innerHeight, -ystart, ystart), 0.04);
          frameworkListObj.setAttribute('x-value', translationVals.x.toString());
          frameworkListObj.setAttribute('y-value', translationVals.y.toString());
          const isTop = frameworkListObj.getAttribute('isTop-value');
          const isLeft = frameworkListObj.getAttribute('isLeft-value');
          // // same for the rotations
          const rX = parseFloat(frameworkListObj.getAttribute('rx-value') || '0');
          const rY = parseFloat(frameworkListObj.getAttribute('ry-value') || '0');
          rotationVals.x = (isTop && isTop === 'true') ? this.lerp(rotationVals.x,
              this.mapXFromRange(mousepos.y, 0, window.innerHeight / 2, rxstart, 0), 0.04) :
                this.lerp(rotationVals.x, this.mapXFromRange(mousepos.y, window.innerHeight / 2, window.innerHeight, 0, -rxstart), 0.04);
          rotationVals.y = isLeft ? this.lerp(rotationVals.y,
              this.mapXFromRange(mousepos.x, 0, window.innerWidth / 2, -rystart, 0), 0.04) :
                this.lerp(rotationVals.y, this.mapXFromRange(mousepos.x, window.innerWidth / 2, window.innerWidth, 0, rystart), 0.04);
          frameworkListObj.setAttribute('rx-value', rotationVals.x.toString());
          frameworkListObj.setAttribute('ry-value', rotationVals.y.toString());
          gsap.set(frameworkListObj, {
            x: -translationVals.x,
            y: -translationVals.y,
            z: parseFloat(frameworkListObj.getAttribute('z-value') || '0'),
            rotationX: -rX - rotationVals.x,
            rotationY: -rY - rotationVals.y
          });
      }
  }

  public layoutForFramework(): void {
      for (let i = 0; i < document.getElementsByClassName('framework-list').length; i++) {
          const frameworkListObj = document.getElementsByClassName('framework-list')[i];
          // Track the mouse position
          const rect = document.getElementsByClassName('framework-list')[i].getBoundingClientRect();

          // // check if the element is position on the left/top side of the viewport
          const isLeft = rect.left + rect.width / 2 < window.innerWidth / 2;
          const isTop = rect.top + rect.height / 2 < window.innerHeight / 2;
          const rxstart = 5;
          const rystart = 10;

          const rY = isLeft ?
                          this.mapXFromRange(rect.left + rect.width / 2, 0, window.innerWidth / 2, rystart, 0) :
                          this.mapXFromRange(rect.left + rect.width / 2, window.innerWidth / 2, window.innerWidth, 0, -rystart);
          const rX = isTop ?
                          this.mapXFromRange(rect.top + rect.height / 2, 0, window.innerHeight / 2, -rxstart, 0) :
                          this.mapXFromRange(rect.top + rect.height / 2, window.innerHeight / 2, window.innerHeight, 0, rxstart);
          const tZ = isLeft ?
                          this.mapXFromRange(rect.left + rect.width / 2, 0, window.innerWidth / 2, -200, -600) :
                          this.mapXFromRange(rect.left + rect.width / 2, window.innerWidth / 2, window.innerWidth, -600, -200);

          frameworkListObj.setAttribute('z-value', tZ.toString());
          frameworkListObj.setAttribute('x-value', '0');
          frameworkListObj.setAttribute('y-value', '0');
          frameworkListObj.setAttribute('rx-value', rX.toString());
          frameworkListObj.setAttribute('ry-value', rY.toString());
          frameworkListObj.setAttribute('isLeft-value', isLeft.toString());
          frameworkListObj.setAttribute('isTop-value', isTop.toString());
          gsap.set(frameworkListObj, {
              x: 0,
              y: 0,
              z: tZ,
              rotationX: rX,
              rotationY: rY,
          });
      }
  }

  public lerp(a: any, b: any, n: any): number {
      return (1 - n) * a + n * b;
  }

  // Map number x from range [a, b] to [c, d]
  public mapXFromRange(x: any, a: any, b: any, c: any, d: any): number {
      return (x - a) * (d - c) / (b - a) + c;
  }

  public getRandomNumber(min: number, max: number): number {
      return Math.floor(Math.random() * (max - min + 1) + min);
  }
}
