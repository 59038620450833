<app-header></app-header>
<app-header-menu *ngIf="isHeaderMenuOpen"></app-header-menu>
<div class="scroller">
  <div class="header-class"></div>
  <div class="page-grid-block">
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
  </div>
  <div class="portfolio-wrapper">
      <div class="portfolio-overlay"></div>
      <div class="portfolio-detail-wrapper">
          <div class="portfolio-detail-inner">
              <div class="back-btn-block">
                  <a [routerLink]="['/work']" class="back-btn">
                      <i>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M5.828 11.0007H22V13.0007H5.828L11.192 18.3647L9.778 19.7787L2 12.0007L9.778 4.22266L11.192 5.63666L5.828 11.0007Z" fill="black"/>
                          </svg>
                      </i>
                      Back to Portfolio
                  </a>
              </div>
              <div class="project-logo">
                  <img src="assets/images/project-images/ccalerts/ccalerts.svg" alt="CCAlerts">
              </div>
              <h2 class="probstatement">
                  <span>Problem Statement</span>
                  Late payment charges on due liabilities like Creditcard, Mortgage loan and Student loan and lack of financial planning
              </h2>
              <div class="challange-block">
                  <div class="challange-list">
                      <ul>
                          <li>
                              <h3 class="section-title section-title-sub">Study</h3>
                              <ul>
                                  <li class="work-info">Huge amount of credit card late payment charges </li>
                                  <li class="work-info">Most people have multiple credit cards and other liabilities </li>
                              </ul>
                          </li>
                          <li>
                              <h3 class="section-title section-title-sub">Challenges</h3>
                              <ul>
                                  <li class="work-info">Data dependency on external service </li>
                                  <li class="work-info">Consistency of data and process with real time updates </li>
                                  <li class="work-info">Security and Privacy </li>
                                  <li class="work-info">Background processes and Scheduled tasks for Reminders </li>
                                  <li class="work-info">Simple UI/UX for Web and Mobile app  </li>
                              </ul>
                          </li>
                          <li>
                              <h3 class="section-title section-title-sub">Approach</h3>
                              <ul>
                                  <li class="work-info">Study of concept shared by Product Owner </li>
                                  <li class="work-info">Ask right questions to understand industry</li>
                                  <li class="work-info">Study the external APIs and Responses </li>
                                  <li class="work-info">Email, SMS and In-app notifications </li>
                                  <li class="work-info">Simple yet productive UI/UX </li>
                                  <li class="work-info">Mobile first </li>
                              </ul>
                          </li>
                          <li>
                              <h3 class="section-title section-title-sub">Involvement</h3>
                              <ul>
                                  <li class="work-info">From concept to conversations to launch to market share </li>
                                  <li class="work-info">From paper drawings to wireframes to UI/UX </li>
                                  <li class="work-info">From Database Design to Application development </li>
                              </ul>
                          </li>
                      </ul>
                  </div>
              </div>
              <div class="project-img-block">
                  <img class="mac-img" src="assets/images/project-images/ccalerts/macbook.webp" alt="CCAlerts">
                  <img class="mobile-img" src="assets/images/project-images/ccalerts/mobile-img.webp" alt="CCAlerts">
                  <img class="card-img-1" src="assets/images/project-images/ccalerts/card-img-1.webp" alt="CCAlerts">
                  <img class="card-img-2" src="assets/images/project-images/ccalerts/card-img-2.webp" alt="CCAlerts">
                  <img class="dot-img" src="assets/images/project-images/ccalerts/dots.webp" alt="CCAlerts">
              </div>
              <div class="work-process" *ngIf="false">
                  <ul>
                      <li>
                          <h3 class="section-title">STRATEGY</h3>
                          <div>
                              <span>Design thinking</span>
                              <span>Product Roadmap</span>
                          </div>
                      </li>
                      <li>
                          <h3 class="section-title">DESIGN</h3>
                          <div>
                              <span>User Experience</span>
                              <span>User Interface</span>
                              <span>Illustrations & Icons</span>
                          </div>
                      </li>
                      <li>
                          <h3 class="section-title">FRONT END DEVELOPEMENT</h3>
                          <div>
                              <span>HTML</span>
                              <span>CSS</span>
                              <span>Posenet</span>
                          </div>
                      </li>
                  </ul>
              </div>
              <div class="visule-guide pt-0">
                  <h2>Visual Guide</h2>
                  <div class="visule-guide-row">
                      <div class="visule-guide-col">
                          <h3 class="section-title section-title-sub">TYPE FACE</h3>
                          <label>Inter</label>
                          <p>- Sans-Serif</p>
                      </div>
                      <div class="visule-guide-col">
                          <h3 class="section-title section-title-sub">COLOR</h3>
                          <ul class="color-list">
                              <li style="background: #2F6FBA; color: #fff; border: none">
                                  #2F6FBA
                              </li>
                              <li style="background: #F06D1F; color: #fff;border: none">
                                  #F06D1F
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="outcome-box-wrapper">
          <div class="outcome-box-inner">
              <div class="outcome-box-info">
                  <div class="outcome-box-info-inner">
                      <h3 class="section-title section-title-sub">THE OUTCOME</h3>
                      <ul>
                          <li>Schedule Auto-reminders via Email, SMS, In-app notification</li>
                          <li>Saved approx $ 8K late payment charges for users within 2 months since intial launch</li>
                      </ul>
                      <!-- <p>Schedule Auto-reminders via Email, SMS, In-app notification and saved approx $ 8K late payment charges for users within 2 months since intial launch</p> -->
                  </div>
              </div>
          </div>
          <div class="outcome-img-block">
              <img src="assets/images/project-images/ccalerts/outcome-box.webp" alt="CCAlerts">
          </div>
      </div>
      <div class="project-info">
          <div class="project-info-inner">
              <div class="portfolio-quote">
                  <p>
                      <i class="quote-icon">
                          <img src="assets/images/quote-icon.svg" alt="Quote Icon">
                      </i>
                      Close and productive collaboration with TechCompose team just like a team available in our US Office. Team is equipped with all the best practices we are following for Agile, Desinging, Programming, Demo and Testing. Another well thought product developed with this team and it's fun and exciting to work them again.</p>
                  <div class="client-quote-info">
                      <div class="client-img">
                          <!-- <img src="assets/images/smarter.webp" alt="CCAlerts"> -->
                          <span>B</span>
                      </div>
                      <div class="client-name">
                          <h3>Boris</h3>
                          <span>CEO</span>
                      </div>
                  </div>
              </div>
              <div class="work-detail-box">
                  <div class="work-detail-box-inner">
                      <h5>Connect with us</h5>
                      <p class="work-info">If you have a concept/vision/product and you are looking for a right <span>design and developer team</span> OR you have right technical skills and looking for <span>exciting projects/environment to experience</span>, drop a message via contact us form. </p>
                      <div class="contact-btn-block">
                          <a [routerLink]="['/', 'contact-us']"><span>Contact Now</span></a>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <section class="horizontal">
          <div class="more-project-ovrelay"></div>
          <div class="pin-wrap">
              <div class="projects-title-block">
                  <h2>More Works</h2>
              </div>
              <div class="animation-wrap to-right">
                  <a class="item" [routerLink]="['/', 'work', 'build-effective']">
                      <div class="project-img">
                          <img src="assets/images/project-images/project-thumbnail/build-effective.webp" alt="Build Effective">
                      </div>
                      <div class="project-slider-info">
                          <span>Real Estate</span>
                          <h2>Buildeffective</h2>
                      </div>
                  </a>
                  <a class="item" [routerLink]="['/', 'work', 'ar-workflow']">
                      <div class="project-img">
                          <img src="assets/images/project-images/project-thumbnail/ar-workflow.webp" alt="ARWorkflow">
                      </div>
                      <div class="project-slider-info">
                          <span>Accounting</span>
                          <h2>ARWorkflow</h2>
                      </div>
                  </a>
                  <a class="item" [routerLink]="['/', 'work', 'linkmybooks']">
                      <div class="project-img">
                          <img src="assets/images/project-images/project-thumbnail/link-my-books.webp" alt="LinkMyBooks">
                      </div>
                      <div class="project-slider-info">
                          <span>Business</span>
                          <h2>LinkMyBooks</h2>
                      </div>
                  </a>
                  <a class="item" [routerLink]="['/', 'work', 'talowiz']">
                      <div class="project-img">
                          <img src="assets/images/project-images/project-thumbnail/talowiz.webp" alt="Talowiz">
                      </div>
                      <div class="project-slider-info">
                          <span>Education</span>
                          <h2>Talowiz</h2>
                      </div>
                  </a>
                  <a class="item" [routerLink]="['/', 'work', 'percentology']">
                      <div class="project-img">
                          <img src="assets/images/project-images/project-thumbnail/percentology.webp" alt="Percentology">
                      </div>
                      <div class="project-slider-info">
                          <span>Account</span>
                          <h2>Percentology</h2>
                      </div>
                  </a>
                  <a class="item" [routerLink]="['/', 'work', 'facturazen']">
                    <div class="project-img">
                        <img src="assets/images/project-images/project-thumbnail/facturazen.webp" alt="Facturazen">
                    </div>
                    <div class="project-slider-info">
                        <span>Business</span>
                        <h2>Facturazen</h2>
                    </div>
                  </a>
                  <ng-container *ngIf="false">
                      <a class="item" [routerLink]="['/', 'work', 'stomerijcollectief']">
                          <div class="project-img">
                              <img src="assets/images/project-images/project-thumbnail/stomerij-collectief.webp" alt="Stomerij Collectief">
                          </div>
                          <div class="project-slider-info">
                              <span>Business</span>
                              <h2>Stomerij Collectief</h2>
                          </div>
                      </a>
                      <a class="item" [routerLink]="['/', 'work', 'food-trucks']">
                          <div class="project-img">
                              <img src="assets/images/project-images/project-thumbnail/food-truck.webp" alt="Food Truck">
                          </div>
                          <div class="project-slider-info">
                              <span>Food</span>
                              <h2>FoodTruck</h2>
                          </div>
                      </a>
                      <a class="item" [routerLink]="['/', 'work', 'local-grocery']">
                          <div class="project-img">
                              <img src="assets/images/project-images/project-thumbnail/local-grocery.webp" alt="Local Grocery">
                          </div>
                          <div class="project-slider-info">
                              <span>Utility</span>
                              <h2>Local Grocery</h2>
                          </div>
                      </a>
                      <a class="item" [routerLink]="['/', 'work', 'miniflix']">
                          <div class="project-img">
                              <img src="assets/images/project-images/project-thumbnail/miniflix.webp" alt="Miniflix">
                          </div>
                          <div class="project-slider-info">
                              <span>Entertainment</span>
                              <h2>Miniflix</h2>
                          </div>
                      </a>
                  </ng-container>
              </div>
          </div>
      </section>
  </div>
  <app-footer></app-footer>
</div>
