<div class="start-project-drawer">
  <div class="start-project-overlay" (click)="closeProjectDrawer()">
  </div>
  <div class="start-project-box">
      <div class="start-project-title">
          <h3>Start project</h3>
          <button type="button" class="close-project-drawer" (click)="closeProjectDrawer()">
              <i>
                  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 1L31 31" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M31 1L1 31" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
              </i>
          </button>
      </div>
      <div class="contact-form">
        <form [formGroup]="startProjectForm">
          <div class="form-row">
              <div class="form-group formField">
                  <input type="text" placeholder="Your Name*" formControlName="name" autofocus>
              </div>
          </div>
          <div class="form-row">
              <div class="form-group formField">
                  <input type="text" placeholder="Email Address*" formControlName="email">
              </div>
          </div>
          <div class="form-row tech-row">
              <h5>Technologies</h5>
              <ul>
                  <li *ngFor="let item of userFormGroups['controls']; let i = index" formArrayName="technologies">
                    <div [formGroupName]="i">
                      <input type="checkbox" id="{{item.value.name}}" name="technologisArray" formControlName="isSelected">
                      <label for="{{item.value.name}}">
                          <span>
                              <i>
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M20 6L9 17L4 12" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                  </svg>
                              </i>
                          </span>{{item.value.name}}
                      </label>
                    </div>
                  </li>
              </ul>
          </div>
          <div class="form-row">
              <div class="form-group formField">
                  <input type="text" placeholder="Phone Number" formControlName="phone_number" appPhoneInput>
              </div>
          </div>
          <div class="form-row">
              <div class="form-group formField">
                  <input type="text" placeholder="Company" formControlName="company">
              </div>
          </div>
          <div class="form-row">
              <div class="form-group messageblock formField">
                  <textarea name="" id="" placeholder="Message*" formControlName="message"></textarea>
              </div>
          </div>
          <div class="form-row justify-content-center formField">
              <button class="btn submit-btn" [disabled]="!startProjectForm.valid" (click)="submit()">
                  <span>Submit</span>
              </button>
          </div>
        </form>
      </div>
  </div>
</div>
