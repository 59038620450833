import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WorkRoutingModule } from './work-routing.module';
import { WorkComponent } from './work.component';
import { ProjectsComponent } from './projects/projects.component';
import { ArWorkflowComponent } from './ar-workflow/ar-workflow.component';
import { LinkMyBooksComponent } from './link-my-books/link-my-books.component';
import { StomerijCollectiefComponent } from './stomerij-collectief/stomerij-collectief.component';
import { FoodTrucksComponent } from './food-trucks/food-trucks.component';
import { SharedModule } from '../shared/shared.module';
import { LocalGroceryComponent } from './local-grocery/local-grocery.component';
import { MiniflixComponent } from './miniflix/miniflix.component';
import { PercentologyComponent } from './percentology/percentology.component';
import { CcalertsComponent } from './ccalerts/ccalerts.component';
import { TalowizComponent } from './talowiz/talowiz.component';
import { InvoxiComponent } from './invoxi/invoxi.component';

@NgModule({
  declarations: [
    WorkComponent,
    ProjectsComponent,
    ArWorkflowComponent,
    LinkMyBooksComponent,
    StomerijCollectiefComponent,
    FoodTrucksComponent,
    LocalGroceryComponent,
    MiniflixComponent,
    PercentologyComponent,
    CcalertsComponent,
    TalowizComponent,
    InvoxiComponent
  ],
  imports: [
    CommonModule,
    WorkRoutingModule,
    SharedModule
  ]
})
export class WorkModule { }
