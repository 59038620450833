<div class="industires-item-preview-block">
  <div class="industires-item-preview-overlay"></div>
  <div class="industires-detail-block" id="article-1">
    <div class="industires-preview-detail">
      <div class="industires-title-block">
        <button type="button" class="close-artical" (click)="closeArtical()">
          <i>
            <svg width="30" height="10" viewBox="0 0 30 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g>
                <path d="M29 5L1 5" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5 9L1 5L5 1" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
            </svg>
          </i>
        </button>
        <span id="industryTitle" *ngIf="selectedIndustry && selectedIndustry['title']">{{ selectedIndustry['title'] }}</span>
      </div>
      <div class="industires-info">
        <div class="industires-info-box">
          <div class="industires-info-box-inner">
            <p *ngIf="selectedIndustry && selectedIndustry['description']" [innerHtml]="selectedIndustry['description']"></p>
          </div>
        </div>
      </div>
    </div>
    <div class="ind-preview-imgblock" *ngIf="selectedIndustry && selectedIndustry['imgUrl']">
      <div class="ind-preview-imgInner"
        [style.background-image]="'url(' + selectedIndustry['imgUrl'] +')'">
          <!-- <img src="" alt="Food"> -->
      </div>
    </div>
  </div>
</div>
