import { Component, AfterViewInit, OnInit } from '@angular/core';
import SplitType from 'split-type';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-food-trucks',
  templateUrl: './food-trucks.component.html',
  styleUrls: ['./food-trucks.component.scss']
})
export class FoodTrucksComponent implements OnInit {

  public currentUrl: string = window.location.href;
  public baseUrl: string = window.location.origin;

  constructor(private metaTagService: Meta, private titleService: Title,) { }

  public ngOnInit(): void {

    this.titleService.setTitle("Food trucks - TechCompose");
      this.metaTagService.updateTag({ name: 'keywords', content: 'FoodTrucks.com is revolutionizing the way you dine at Food Trucks. It is an innovative platform lets you know where your favorite food trucks are, so you can order from them and pick up your meal quickly. Order in advance, pick up your food and get on with your day' }),
      this.metaTagService.updateTag({ property: 'og:title', content: 'Techcompose | Food trucks' }),
      this.metaTagService.updateTag({ property: 'og:type', content: 'website' }),
      this.metaTagService.updateTag({ property: 'og:url', content: '' }),
      this.metaTagService.updateTag({ property: 'og:image', content: `${this.baseUrl}/assets/images/thumb.pn` }),
      this.metaTagService.updateTag({ property: 'og:description', content: 'FoodTrucks.com is revolutionizing the way you dine at Food Trucks. It is an innovative platform lets you know where your favorite food trucks are, so you can order from them and pick up your meal quickly. Order in advance, pick up your food and get on with your day' }),
      this.metaTagService.removeTag("name='google-site-verification'");
      
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.refresh();

    gsap.fromTo(".back-btn",{autoAlpha: 0},{autoAlpha: 1, duration: 0.7,})
    gsap.fromTo(".project-logo",{autoAlpha: 0},{autoAlpha: 1, duration: 0.5, delay: 0.8})
    gsap.fromTo(".portfolio-detail-inner .probstatement",{autoAlpha: 0},{autoAlpha: 1, duration: 0.5, delay: 0.9})

    gsap.fromTo(".work-process span", {
      y: 30,autoAlpha: 0
    },{
      y: 0,autoAlpha: 1, duration: 0.5,
      scrollTrigger: {
        trigger: ".work-process span",
        start: "top 85%"
      }
    })

    const abouttitles = new SplitType('.section-title', {
      types: 'words chars',
      absolute: true
    });

    const portfoliosubTitle: any = gsap.utils.toArray('.section-title-sub .char');

    gsap.set(portfoliosubTitle, {autoAlpha: 0, x: 20});

    portfoliosubTitle.forEach((pdtitle: any) => {

      const anim = gsap.to(pdtitle, { duration: 1, autoAlpha: 1, stagger: 0.5, x: 0, delay: 0.2, paused: true });

      ScrollTrigger.create({
        trigger: pdtitle,
        start: 'top 70%',
        once: true,
        onEnter: self => {
          self.progress === 1 ? anim.progress(1) : anim.play();
        }
      });
    });

    gsap.fromTo(".work-process .section-title .char", {
      autoAlpha: 0, x: 20
    },{
      autoAlpha: 1, x: 0,
      scrollTrigger: {
        trigger: ".work-process .section-title .char",
        start: "top 80%"
      }
    })

    const info: any = gsap.utils.toArray('.portfolio-wrapper p');

    gsap.set(info, {autoAlpha: 0, y: 50});
    info.forEach((box: any) => {
      const anim = gsap.to(box, { duration: 0.5, autoAlpha: 1, delay: 0.25, y: 0, paused: true });

      ScrollTrigger.create({
        trigger: box,
        start: 'top 70%',
        once: true,
        onEnter: self => {
          self.progress === 1 ? anim.progress(1) : anim.play();
        }
      });
    });

    gsap.fromTo(".color-list li",{
      autoAlpha: 0,
    },{
      autoAlpha: 1,
      duration: 0.5,
      scrollTrigger: {
        trigger: ".color-list li",
        start: 'top 70%',
      }
    })
    gsap.fromTo(".visule-guide-col label",{autoAlpha: 0,},{autoAlpha: 1,duration: 0.5, delay: 0.5,
      scrollTrigger: {
        trigger: ".visule-guide-col",
        start: 'top 70%',
      }
    })

    gsap.fromTo(".visule-guide h2",{y: 30, autoAlpha: 0},{y: 0, autoAlpha: 1,
      scrollTrigger: {
        trigger: ".visule-guide h2",
        start: 'top 80%',
      }
    })

    gsap.fromTo(".client-quote-info",{
      autoAlpha: 0,
      y: 30,
    },{
      autoAlpha: 1,
      y: 0,
      duration: 0.5,
      scrollTrigger: {
        trigger: '.client-quote-info',
        start: 'top 85%',
      }
    })
    const outcomeimg = gsap.timeline({scrollTrigger: {
      trigger: '.outcome-box-wrapper',
      start: 'top 50%',
      end: 'bottom -100%',
      // markers: true,
      toggleActions: 'play none none none',
      scrub:0.3,
    }});
    outcomeimg.fromTo( ".outcome-img-block img" ,{scale: 1.5}, {scale: 1})


    gsap.fromTo(".project-main-img-block", {autoAlpha: 0, y: 30},{
      autoAlpha: 1,
      duration: 0.5,
      y: 0,
      scrollTrigger: {
        trigger: ".project-main-img-block",
        start: 'top 70%',
      }
    })

    gsap.fromTo(".ft-floating-img-main", {autoAlpha: 0, x: 30},{
      autoAlpha: 1,
      duration: 0.5,
      x: 0,
      scrollTrigger: {
        trigger: ".ft-floating-img-main",
        start: 'top 70%',
      }
    })

    gsap.fromTo(".ft-floating-mobile", {autoAlpha: 0, scale: 0},{
      autoAlpha: 1,
      duration: 0.5,
      scale: 1,
      scrollTrigger: {
        trigger: ".ft-floating-mobile",
        start: 'top 65%',
      }
    })

    document.querySelectorAll("img").forEach(img => {
      if (img.complete) {
        ScrollTrigger.refresh();
      } else {
        img.addEventListener('load', _imgLoaded => ScrollTrigger.refresh());
      }
    });

    const horizontalSections = gsap.utils.toArray('section.horizontal')

    horizontalSections.forEach(function (sec : any, i) {

      var thisPinWrap = sec.querySelector('.pin-wrap');
      var thisAnimWrap = thisPinWrap.querySelector('.animation-wrap');

      var getToValue = () => -(thisAnimWrap.scrollWidth - window.innerWidth);
      ScrollTrigger.create({
        trigger: sec,
        start: "top top",
        end: () => "+=" + (thisAnimWrap.scrollWidth - window.innerWidth) + "-=100%",
        pin: thisPinWrap,
        invalidateOnRefresh: true,
        anticipatePin: 1,
        scrub: true,
      });

      const h1 = gsap.timeline({scrollTrigger: {
        trigger: sec,
        start: "top 100%",
        end: () => "+=" + (thisAnimWrap.scrollWidth - window.innerWidth),
        // pin: thisPinWrap,
        invalidateOnRefresh: true,
        //anticipatePin: 1,
        scrub: true,
      }});
      h1.fromTo( thisAnimWrap, {
          x: () => thisAnimWrap.classList.contains('to-right') ? 0 : getToValue()
        }, {
          x: () => thisAnimWrap.classList.contains('to-right') ? getToValue() : 0,
          ease: "none"}
      );

    });
    ScrollTrigger.create({
      trigger: '.portfolio-wrapper',
      start:"top",
      onEnter: () => {
        gsap.to(".portfolio-quote p, .client-name h3, .client-name span, .work-detail-box p", {color: '#000000'})
        gsap.to(".work-detail-box", {backgroundColor: 'rgba(0,0,0,0.02)'})
      }
    });
    ScrollTrigger.create({
      trigger: '.more-project-ovrelay',
      start:"top 50%",
      end:"bottom 0%",
      onEnter: () => {
        gsap.to( ".portfolio-overlay, top-rated-wrapper ul li", {backgroundColor: '#141414'})
        gsap.to( ".portfolio-quote p, .client-name h3, .client-name span, .top-rated-wrapper h3, .projects-title-block h2, .top-rated-block li", {color: '#ffffff'})
      },
      onLeaveBack: () => {
        gsap.to( ".portfolio-overlay, top-rated-wrapper ul li", {backgroundColor: '#ffffff'})
        gsap.to( ".portfolio-quote p, .client-name h3, .client-name span, .top-rated-wrapper h3, .projects-title-block h2, .top-rated-block li", {color: '#000000'})
      },
    })
    ScrollTrigger.create({
      trigger: '.top-rated-wrapper',
      start:"top 50%",
      end:"bottom 0%",
      onEnter: () => {
        gsap.to( ".portfolio-overlay, .top-rated-wrapper ul li" , {backgroundColor: '#141414'})
        gsap.to( ".project-slider-info span, .project-slider-info h2, .top-rated-wrapper h3, .projects-title-block h2, .top-rated-block li",{color: '#ffffff'})
      },
      onLeaveBack: () => {
        gsap.to( ".portfolio-overlay, .top-rated-wrapper ul li" , {backgroundColor: '#ffffff'})
        gsap.to( ".project-slider-info span, .project-slider-info h2, .top-rated-wrapper h3, .projects-title-block h2, .top-rated-block li",{color: '#141414'})
      },
    })
    gsap.to(".portfolio-overlay", {backgroundColor: '#ffffff'})
    gsap.to(".top-rated-wrapper ul li", {backgroundColor: '#ffffff'})

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    const workInfo: any = gsap.utils.toArray('.work-info');
    gsap.set(workInfo, {autoAlpha: 0, y: 50});
    workInfo.forEach((box: any) => {
      const anim = gsap.to(box, { duration: 1, autoAlpha: 1, y: 0, stagger: 0.3, delay: 0.5, paused: true });
      ScrollTrigger.create({
        trigger: box,
        start: 'top 90%',
        once: true,
        onEnter: self => {
          self.progress === 1 ? anim.progress(1) : anim.play();
        }
      });
    });
  }
}
