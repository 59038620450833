<app-header></app-header>
<app-header-menu *ngIf="isHeaderMenuOpen"></app-header-menu>
<div class="scroller">
  <div class="header-class"></div>
  <div class="page-grid-block">
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
  </div>
  <div class="main-service-wrapper">
      <div class="background-overlay bg-services"></div>
      <section class="service-in-hero">
          <app-tech-logo></app-tech-logo>
          <div class="service-in-hero-container">
              <div class="service-in-hero-block">
                  <div class="service-in-deail-block">
                      <div class="page-title">
                          <h1>Mobile Apps</h1>
                      </div>
                      <div class="service-in-info-detail page-info">
                          <!-- <p>Techcompose Solutions is a Top Mobile App development company in India and the USA. Contact us to Hire Mobile App developers for iOS and Android app development services. we offer scalable end-to-end mobile app development and maintenance solutions from requirement analysis to deployment and rollout. Whether you want to create a database driven app or standalone, our experienced and professional app developers have both skill and expertise to make an app that works across all devices, regardless of screen size, OS version, and processors.</p> -->
                          <ul>
                              <li>Simple User Interface</li>
                              <li>Easy to operate and Fast</li>
                              <li>For iOS, Android and Tablets</li>
                              <li>Hybrid Frameworks like Flutter, ReactNative</li>
                              <li>Availability of information on hand</li>
                              <li>Live modules, Collaborative features</li>
                              <li>Offline / Online Sync / Sharing</li>
                              <li>Multi-language support</li>
                              <li>Privacy and Data Security</li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      <section class="philosophie">
          <div class="wrapper">
              <div class="point point-1">
                  <article>
                      <div class="article-inner">
                          <h3>Android</h3>
                          <div class="article-desc-block">
                              <p>Android OS is dominating 50% of the overall Smartphone device market share. Whether you want to create any database driven android app or standalone, our experienced and professional Android app developers have both skill and expertise to develop a required Android app that works across all Android devices, irrespective of screen size, OS version, and different processors.</p>
                              <p>Techcompose Solutions is a recognized Android application development company which makes your business or start-up a touch away on Android. We are amongst top android app development companies in India, and we develop unique, creative and end-user centric mobile apps to give your end customers a ceaseless & user-friendly experience. Our proficient Android App Developers and passionate Mobile UI Designers convert your unfashioned ideas into complete user-satisfying products. Work with us for the exceptional results in a very competitive budget.</p>
                          </div>
                          <a [routerLink]="[ '/', 'technologies', 'android']">Read more
                              <i class="link-icon">
                                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M14.5687 7.92313C14.3312 7.68545 13.946 7.68545 13.7081 7.92313C13.4704 8.16062 13.4704 8.5461 13.7081 8.78378L18.3174 13.3931H6.0705C5.73339 13.3931 5.45996 13.6666 5.45996 14.0037C5.45996 14.341 5.73339 14.6142 6.0705 14.6142H18.3113L13.702 19.2236C13.5876 19.3375 13.5234 19.4926 13.5234 19.654C13.5234 19.8156 13.5876 19.9703 13.702 20.0844C13.8163 20.1997 13.9729 20.2636 14.1354 20.2615C14.2974 20.2607 14.4529 20.1972 14.5687 20.0844L20.216 14.4373C20.3309 14.3222 20.395 14.166 20.3932 14.0037C20.3921 13.8417 20.3286 13.6868 20.216 13.5704L14.5687 7.92313Z" fill="currentColor"/>
                                      <path d="M14.0072 0.877724C8.69816 0.876768 3.91155 4.0746 1.87993 8.97957C-0.151319 13.8847 0.972243 19.5305 4.72746 23.2834C9.8527 28.4086 18.162 28.4086 23.2871 23.2834C28.4121 18.1585 28.4121 9.84921 23.2871 4.72396C20.8313 2.25426 17.4899 0.86931 14.0072 0.877724ZM14.0072 25.9087C7.4442 25.9087 2.10231 20.5666 2.10231 14.0037C2.10231 7.4407 7.4442 2.0988 14.0072 2.0988C20.5701 2.0988 25.9122 7.4407 25.9122 14.0037C25.9122 20.5666 20.5701 25.9087 14.0072 25.9087Z" fill="currentColor"/>
                                  </svg>
                              </i>
                          </a>
                      </div>
                  </article>
                  <div class="services-img-block">
                      <img src="assets/images/services/sub-page/android.webp" alt="Android" width="1955" height="1275">
                  </div>
              </div>
              <div class="point point-2">
                  <article>
                      <div class="article-inner">
                          <h3>iOS</h3>
                          <div class="article-desc-block">
                              <p>iOS application development business sector is unimaginably lucrative, however with a specific end goal to be effective, you need to have the privilege applies, the right usefulness, the privilege rollout, and the right backing. That is precisely what you’ll discover with Techcompose Solution. We are top iOS app Development Company and we are in the business since 2013 and amid that time.</p>
                              <p>We are renowned iOS Mobile App Development company, We have done iOS App development for different iOS apps like residential guide Apps, Social networking Apps, m-commerce Apps, educational Apps, Entertainment Apps, restaurant App and many other for small businesses to large businesses. Hire our iOS app developers who always go extra miles to provide you the highly updated version of an application with most advanced features.</p>
                          </div>
                          <a [routerLink]="[ '/', 'technologies', 'ios']">Read more
                              <i class="link-icon">
                                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M14.5687 7.92313C14.3312 7.68545 13.946 7.68545 13.7081 7.92313C13.4704 8.16062 13.4704 8.5461 13.7081 8.78378L18.3174 13.3931H6.0705C5.73339 13.3931 5.45996 13.6666 5.45996 14.0037C5.45996 14.341 5.73339 14.6142 6.0705 14.6142H18.3113L13.702 19.2236C13.5876 19.3375 13.5234 19.4926 13.5234 19.654C13.5234 19.8156 13.5876 19.9703 13.702 20.0844C13.8163 20.1997 13.9729 20.2636 14.1354 20.2615C14.2974 20.2607 14.4529 20.1972 14.5687 20.0844L20.216 14.4373C20.3309 14.3222 20.395 14.166 20.3932 14.0037C20.3921 13.8417 20.3286 13.6868 20.216 13.5704L14.5687 7.92313Z" fill="currentColor"/>
                                      <path d="M14.0072 0.877724C8.69816 0.876768 3.91155 4.0746 1.87993 8.97957C-0.151319 13.8847 0.972243 19.5305 4.72746 23.2834C9.8527 28.4086 18.162 28.4086 23.2871 23.2834C28.4121 18.1585 28.4121 9.84921 23.2871 4.72396C20.8313 2.25426 17.4899 0.86931 14.0072 0.877724ZM14.0072 25.9087C7.4442 25.9087 2.10231 20.5666 2.10231 14.0037C2.10231 7.4407 7.4442 2.0988 14.0072 2.0988C20.5701 2.0988 25.9122 7.4407 25.9122 14.0037C25.9122 20.5666 20.5701 25.9087 14.0072 25.9087Z" fill="currentColor"/>
                                  </svg>
                              </i>
                          </a>
                      </div>
                  </article>
                  <div class="services-img-block">
                      <img src="assets/images/services/sub-page/ios.webp" alt="iOS" width="1920" height="1275">
                  </div>
              </div>
              <!-- <div class="point">
                  <article>
                      <div class="article-inner">
                          <h3>React Native</h3>
                          <div class="article-desc-block">
                              <p>React Native is a Javascript framework used for building native mobile applications using ReactJS. React Native handles various multiple platforms with grace. Almost majority of React Native APIs is cross-platform. React Native create applications for iOS and Android much faster as much as by 30% compared to others. This is possible due to a various library of React components available under open source. Now no more waiting for pending approvals from the app store for your application updates. Push your upcoming release of the app without the user having to update his app.</p>
                          </div>
                      </div>
                  </article>
                  <div class="services-img-block">
                      <img src="assets/images/services/sub-page/ios.webp" alt="React Native">
                  </div>
              </div> -->
          </div>
      </section>
  </div>
  <app-footer></app-footer>
</div>
