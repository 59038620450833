import { BuildeffectiveComponent } from './buildeffective/buildeffective.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DelayResolver } from '../delay-resolver';
import { ProjectsComponent } from './projects/projects.component';
import { WorkComponent } from './work.component';
import { FoodTrucksComponent } from './food-trucks/food-trucks.component';
import { ArWorkflowComponent } from './ar-workflow/ar-workflow.component';
import { LinkMyBooksComponent } from './link-my-books/link-my-books.component';
import { StomerijCollectiefComponent } from './stomerij-collectief/stomerij-collectief.component';
import { LocalGroceryComponent } from './local-grocery/local-grocery.component';
import { MiniflixComponent } from './miniflix/miniflix.component';
import { PercentologyComponent } from './percentology/percentology.component';
import { CcalertsComponent } from './ccalerts/ccalerts.component';
import { TalowizComponent } from './talowiz/talowiz.component';
import { InvoxiComponent } from './invoxi/invoxi.component';

const routes: Routes = [
  {
    path: '',
    component: WorkComponent,
    children: [
      {
        path: '',
        component: ProjectsComponent,
        resolve: {data: DelayResolver }
      },
      {
        path: 'build-effective',
        component: BuildeffectiveComponent,
        resolve: {data: DelayResolver }
      },
      {
        path: 'food-trucks',
        component: FoodTrucksComponent,
        resolve: {data: DelayResolver }
      },
      {
        path: 'ar-workflow',
        component: ArWorkflowComponent,
        resolve: {data: DelayResolver }
      },
      {
        path: 'linkmybooks',
        component: LinkMyBooksComponent,
        resolve: {data: DelayResolver }
      },
      {
        path: 'stomerijcollectief',
        component: StomerijCollectiefComponent,
        resolve: {data: DelayResolver }
      },
      {
        path: 'local-grocery',
        component: LocalGroceryComponent,
        resolve: {data: DelayResolver }
      },
      {
        path: 'miniflix',
        component: MiniflixComponent,
        resolve: {data: DelayResolver }
      },
      {
        path: 'percentology',
        component: PercentologyComponent,
        resolve: {data: DelayResolver }
      },
      {
        path: 'ccalerts',
        component: CcalertsComponent,
        resolve: {data: DelayResolver }
      },
      {
        path: 'talowiz',
        component: TalowizComponent,
        resolve: {data: DelayResolver }
      },
      {
        path: 'facturazen',
        component: InvoxiComponent,
        resolve: {data: DelayResolver }
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WorkRoutingModule {}
