<app-header></app-header>
<app-header-menu *ngIf="isHeaderMenuOpen"></app-header-menu>
<div class="scroller">
  <div class="header-class"></div>
  <div class="page-grid-block">
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
    <div class="page-grid-column"></div>
  </div>
  <div class="grid-background-overlay"></div>
  <section class="projectWrapper">
      <app-tech-logo></app-tech-logo>
      <div class="project-container">
          <div class="project-hero-block">
              <div class="project-deail-block">
                  <!-- <span>Our Work</span> -->
                  <div class="page-title">
                      <h1>Some of our recent projects </h1>
                  </div>
                  <div class="work-info-detail mainWorkInfo page-info">
                      <p>Our Portfolio crosses different industry verticals and borders. </p>
                  </div>
              </div>
          </div>
      </div>
  </section>

  <section class="project-list-wrapper">
      <div class="list-container">
          <a class="project-list" [routerLink]="['build-effective']">
              <div class="thumbnail-container">
                  <div class="image-wrapper">
                      <div class="project-img-block">
                          <picture>
                              <img src="assets/images/project-images/project-thumbnail/build-effective.webp" alt="Build-Effective">
                          </picture>
                      </div>
                  </div>
                  <div class="project-info">
                      <h3>Buildeffective</h3>
                      <p>Connecting Products, People and Processes for residential construction projects</p>
                  </div>
              </div>
          </a>
          <a class="project-list" [routerLink]="['ar-workflow']">
              <div class="thumbnail-container">
                  <div class="image-wrapper">
                      <div class="project-img-block">
                          <picture>
                              <img src="assets/images/project-images/project-thumbnail/ar-workflow.webp" alt="AR-Workflow">
                          </picture>
                      </div>
                  </div>
                  <div class="project-info">
                      <h3>AR Workflow</h3>
                      <p>Set up Workflow to speedup payment collection process for Restoration Business</p>
                  </div>
              </div>
          </a>
          <a class="project-list" [routerLink]="['ccalerts']">
              <div class="thumbnail-container">
                  <div class="image-wrapper">
                      <div class="project-img-block">
                          <picture>
                              <img src="assets/images/project-images/project-thumbnail/ccalerts.webp" alt="CCAlerts">
                          </picture>
                      </div>
                  </div>
                  <div class="project-info">
                      <h3>CCAlerts</h3>
                      <p>Get Reminders for Creditcard bills, installment on Student loan and Mortgage loan</p>
                  </div>
              </div>
          </a>
          <a class="project-list" [routerLink]="['linkmybooks']">
              <div class="thumbnail-container">
                  <div class="image-wrapper">
                      <div class="project-img-block">
                          <picture>
                              <img src="assets/images/project-images/project-thumbnail/link-my-books.webp" alt="link-my-books">
                          </picture>
                      </div>
                  </div>
                  <div class="project-info">
                      <h3>Link My Books</h3>
                      <p>Accurate and automatic bookkeeping for Sellers of Amazon, eBay, ETSY, Shopify</p>
                  </div>
              </div>
          </a>
          <a class="project-list" [routerLink]="['talowiz']">
              <div class="thumbnail-container">
                  <div class="image-wrapper">
                      <div class="project-img-block">
                          <picture>
                              <img src="assets/images/project-images/project-thumbnail/talowiz.webp" alt="Talowiz">
                          </picture>
                      </div>
                  </div>
                  <div class="project-info">
                      <h3>Talowiz</h3>
                      <p>Transform hiring process with power of AI and Analytics in workflows</p>
                  </div>
              </div>
          </a>
          <a class="project-list" [routerLink]="['percentology']">
              <div class="thumbnail-container">
                  <div class="image-wrapper">
                      <div class="project-img-block">
                          <picture>
                              <img src="assets/images/project-images/project-thumbnail/percentology.webp" alt="percentology">
                          </picture>
                      </div>
                  </div>
                  <div class="project-info">
                      <h3>Percentology</h3>
                      <p>Using systematized processes that employ industry best practices, we solve the issues costing the industry billions a year in lost time, money, and waste</p>
                  </div>
              </div>
          </a>
          <a class="project-list" [routerLink]="['facturazen']">
            <div class="thumbnail-container">
                <div class="image-wrapper">
                    <div class="project-img-block">
                        <picture>
                            <img src="assets/images/project-images/project-thumbnail/facturazen.webp" alt="Facturazen">
                        </picture>
                    </div>
                </div>
                <div class="project-info">
                    <h3>Facturazen</h3>
                    <p>Simple Invoice generation platform for Businesses and Freelancers. Mobile friendly, support for multi-language and multi-currency. PDF generation and send Invoice as e-mail to customer.</p>
                </div>
            </div>
          </a>
          <ng-container *ngIf="false">
              <a class="project-list" [routerLink]="['stomerijcollectief']">
                  <div class="thumbnail-container">
                      <div class="image-wrapper">
                          <div class="project-img-block">
                              <picture>
                                  <img src="assets/images/project-images/project-thumbnail/stomerij-collectief.webp" alt="stomerij-collectief">
                              </picture>
                          </div>
                      </div>
                      <div class="project-info">
                          <h3>Stomerij collectief</h3>
                          <p>Using systematized processes that employ industry best practices, we solve the issues costing the industry billions a year in lost time, money, and waste</p>
                      </div>
                  </div>
              </a>
              <a class="project-list" [routerLink]="['food-trucks']">
                  <div class="thumbnail-container">
                      <div class="image-wrapper">
                          <div class="project-img-block">
                              <picture>
                                  <img src="assets/images/project-images/project-thumbnail/food-truck.webp" alt="food-truck">
                              </picture>
                          </div>
                      </div>
                      <div class="project-info">
                          <h3>Food Truck</h3>
                          <p>Using systematized processes that employ industry best practices, we solve the issues costing the industry billions a year in lost time, money, and waste</p>
                      </div>
                  </div>
              </a>

              <a class="project-list" [routerLink]="['local-grocery']">
                  <div class="thumbnail-container">
                      <div class="image-wrapper">
                          <div class="project-img-block">
                              <picture>
                                  <img src="assets/images/project-images/project-thumbnail/local-grocery.webp" alt="local-grocery">
                              </picture>
                          </div>
                      </div>
                      <div class="project-info">
                          <h3>Local Grocery</h3>
                          <p>Using systematized processes that employ industry best practices, we solve the issues costing the industry billions a year in lost time, money, and waste</p>
                      </div>
                  </div>
              </a>
              <a class="project-list" [routerLink]="['miniflix']">
                  <div class="thumbnail-container">
                      <div class="image-wrapper">
                          <div class="project-img-block">
                              <picture>
                                  <img src="assets/images/project-images/project-thumbnail/miniflix.webp" alt="miniflix">
                              </picture>
                          </div>
                      </div>
                      <div class="project-info">
                          <h3>Miniflix</h3>
                          <p>Using systematized processes that employ industry best practices, we solve the issues costing the industry billions a year in lost time, money, and waste</p>
                      </div>
                  </div>
              </a>
          </ng-container>
      </div>
  </section>
  <app-footer></app-footer>
</div>
