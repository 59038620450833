import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class CanonicalService {
  constructor(@Inject(DOCUMENT) private dom: Document) { }
  setCanonicalURL(url?: string) {
    let canURL = url == undefined ? this.dom.URL : url;
    // this.dom.find()
    canURL = canURL.replace('http://localhost:1864/', 'https://www.techcompose.com/');
    const link = this.dom.querySelectorAll('link[rel=\'canonical\']');
    if (link && link.length > 0) {
      link[0].setAttribute('href', canURL);
    }
  }
}
