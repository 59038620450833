import { AfterViewInit, Component, OnInit } from '@angular/core';
import SplitType from 'split-type';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { Title, Meta } from '@angular/platform-browser';

import Scrollbar, { ScrollbarPlugin } from 'smooth-scrollbar';
import { sharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-blog-list',
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.scss']
})
export class BlogListComponent implements AfterViewInit, OnInit {

  bodyScrollBar: Scrollbar | undefined;
  public isHeaderMenuOpen: boolean = false;
  public currentUrl: string =  window.location.href;
  public baseUrl: string = window.location.origin;

  constructor(private metaTagService: Meta, private titleService: Title, public sharedservice: sharedService) { }

  ngOnInit() {
    this.titleService.setTitle("Blogs | Official Company Blogs | Techcompose Solutions");

    this.metaTagService.updateTag({ name: 'keywords', content: 'Blogs on Technology, Digital Marketing, SEO, Ruby on Rails, Angular, Laravel, Wordpress, Android, iOS, Software Testing, Web Design, Website Development.' });
    this.metaTagService.updateTag({ property: 'og:title', content: 'Techcompose | Blogs | Official Company Blogs | Techcompose Solutions' });
    this.metaTagService.updateTag({ property: 'og:type', content: 'website' });
    this.metaTagService.updateTag({ property: 'og:url', content: `${this.currentUrl}` });
    this.metaTagService.updateTag({ property:"og:image", content: `${this.baseUrl}/assets/images/thumb.png` });
    this.metaTagService.updateTag({ property: 'og:description', content: 'Blogs on Technology, Digital Marketing, SEO, Ruby on Rails, Angular, Laravel, Wordpress, Android, iOS, Software Testing, Web Design, Website Development.' });
    this.metaTagService.removeTag("name='google-site-verification'");

    this.sharedservice.showHeaderMenu$.subscribe((data) => {
      this.isHeaderMenuOpen = data;
      if (this.bodyScrollBar) {
        this.bodyScrollBar.updatePluginOptions('modal', { open: this.isHeaderMenuOpen });
      }
    });

    gsap.registerPlugin(ScrollTrigger);
    const scroller = document.querySelector('.scroller')as HTMLElement;

    this.bodyScrollBar = Scrollbar.init(scroller, { damping: 0.1, delegateTo: document, alwaysShowTracks: true });

    const content = this;
    ScrollTrigger.scrollerProxy(".scroller", {
      scrollTop(value) {
        if (content.bodyScrollBar) {
          if (arguments.length) {
            content.bodyScrollBar.scrollTop = value || 0;
          }
          return content.bodyScrollBar.scrollTop;
        } else {
          return 0;
        }
      }
    });

    this.bodyScrollBar.addListener(ScrollTrigger.update);
    ScrollTrigger.defaults({ scroller: scroller });

  }


  public ngAfterViewInit(): void {
    gsap.registerPlugin(ScrollTrigger);
    const aboutpagetitle = new SplitType('.page-title h1', {
        types: 'chars, words',
        absolute: true
    });
    gsap.from(aboutpagetitle.chars, {
        opacity: 0,
        x: 20,
        duration: 0.7,
        stagger: 0.03,
    });
    gsap.fromTo('.blog-info-detail p', {
      opacity: 0,
      y: 30,
    }, {
        opacity: 1,
        y: 0,
        duration: 0.5,
        delay: 0.5
    });

    const info: any = gsap.utils.toArray('.blog-list-col');
    // Set things up
    gsap.set(info, {autoAlpha: 0, y: 50});
    info.forEach((box: any) => {
      // Set up your animation
      const anim = gsap.to(box, { duration: 1, autoAlpha: 1, y: 0, paused: true });
      // Use callbacks to control the state of the animation
      ScrollTrigger.create({
        trigger: box,
        start: 'top 90%',
        once: true,
        onEnter: self => {
          self.progress === 1 ? anim.progress(1) : anim.play();
        }
      });
    });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    ScrollTrigger.create({
      trigger: ".header-class",
      start: "+50px",
      end: "bottom -=1000%",
      toggleClass: { targets: ".header", className: "active" }
    });

  }

}
class ModalPlugin extends ScrollbarPlugin {
  static pluginName = 'modal';

  static defaultOptions = {
    isHeaderMenuOpen: false
  };

  transformDelta(delta: any) {
      return this.options.open ? { x: 0, y: 0 } : delta;
  }
}

Scrollbar.use(ModalPlugin, /* OverscrollPlugin */);
